import moment from 'moment-timezone';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { dateFormat } from 'src/app/constants/date';
import { selectOrderPreferences } from 'src/app/store/orderSlice';
import { hasDurationMoreThanOneDay } from 'src/app/utils/date';
import { OrderTypeUtil, getTravellerOccupancy } from 'src/app/utils/utils';
import { Accommodation } from 'src/data/models/Accommodation';
import { EventDetail } from 'src/data/models/EventDetail';
import { PackageType } from 'src/data/models/Order';
import { Ticket } from 'src/data/models/Ticket';
import { ConfirmLabel } from 'src/view/components';
import { Body } from 'src/view/components/Body/Body';
import $ from './TravelDetailTable.module.scss';

interface TravelDetailTableProps extends WithTranslation {
    eventDetail: EventDetail;
    orderSelectedTicket: Ticket | null;
    packageType?: PackageType;
    orderSelectedAccommodation: Accommodation | null;
    onHotelDetailClick: (accommodation: Accommodation) => void;
}

const TravelDetailTable = ({
    t,
    eventDetail,
    orderSelectedTicket,
    packageType,
    orderSelectedAccommodation,
    onHotelDetailClick,
}: TravelDetailTableProps) => {
    const orderPreferences = useSelector(selectOrderPreferences);
    const renderTravelDetail = (text: React.ReactNode, fontWeightMedium?: boolean) => (
        <Body
            small
            fontWeight={fontWeightMedium ? 'medium' : undefined}
            marginTop={false}
            marginBottom={false}
        >
            {text}
        </Body>
    );

    const date = moment(eventDetail.dateTime).format('DD MMM YYYY, H:mm');
    const dateEnd = moment(eventDetail.dateTimeEnd).format('DD MMM YYYY, H:mm');

    const { children, adults, roomLayout } = getTravellerOccupancy();

    const checkinDate = moment(orderPreferences?.dateStart).format(dateFormat);
    const checkoutDate = moment(orderPreferences?.dateEnd).format(dateFormat);

    return (
        <table className={$.detailsTable}>
            <tbody>
                <tr>
                    <td>{renderTravelDetail(t('traveldetail_event'), true)}</td>
                    <td>{renderTravelDetail(eventDetail.title)}</td>
                </tr>
                <tr>
                    <td>{renderTravelDetail(t('traveldetail_kickoff'), true)}</td>
                    <td>
                        <Body small marginTop={false} marginBottom={false}>
                            <span className={$.eventDate}>{date}</span>
                            <ConfirmLabel
                                isConfirmed={eventDetail.dateConfirmed}
                                className={$.confirmedLabel}
                                withTooltip
                            />
                        </Body>
                    </td>
                </tr>
                {hasDurationMoreThanOneDay(eventDetail.dateTime, eventDetail.dateTimeEnd) && (
                    <tr>
                        <td>{renderTravelDetail(t('traveldetail_end'), true)}</td>
                        <td>
                            <Body small marginTop={false} marginBottom={false}>
                                <span className={$.eventDate}>{dateEnd}</span>
                            </Body>
                        </td>
                    </tr>
                )}

                {packageType && orderPreferences && OrderTypeUtil.hasHotel(packageType) && (
                    <>
                        <tr>
                            <td>{renderTravelDetail(t('ticketOutJourney'), true)}</td>
                            <td>
                                <Body small marginTop={false} marginBottom={false}>
                                    <span className={$.eventDate}>{checkinDate}</span>
                                </Body>
                            </td>
                        </tr>
                        <tr>
                            <td>{renderTravelDetail(t('ticketReturnJourney'), true)}</td>
                            <td>
                                <Body small marginTop={false} marginBottom={false}>
                                    <span className={$.eventDate}>{checkoutDate}</span>
                                </Body>
                            </td>
                        </tr>
                    </>
                )}
                <tr>
                    <td>{renderTravelDetail(t('traveldetail_venue'), true)}</td>
                    <td>{renderTravelDetail(eventDetail.venue.name)}</td>
                </tr>
                {orderSelectedTicket && (
                    <tr>
                        <td>{renderTravelDetail(t('traveldetail_seats'), true)}</td>
                        <td>{renderTravelDetail(orderSelectedTicket.name)}</td>
                    </tr>
                )}
                {OrderTypeUtil.hasHotel(packageType) && (
                    <tr>
                        <td>{renderTravelDetail(t('traveldetail_persons'), true)}</td>
                        <td>
                            {renderTravelDetail(
                                <>
                                    {adults === 1
                                        ? t('traveldetail_adults_1')
                                        : t('traveldetail_adults', {
                                              adults,
                                          })}
                                </>
                            )}
                            {children > 0 &&
                                renderTravelDetail(
                                    <>
                                        <br />
                                        {t('travelDetailChildren', {
                                            count: children,
                                        })}
                                    </>
                                )}
                            {roomLayout &&
                                roomLayout.length > 0 &&
                                renderTravelDetail(
                                    <>
                                        <br />
                                        {t('travelDetailRooms', {
                                            count: roomLayout.length,
                                        })}
                                    </>
                                )}
                        </td>
                    </tr>
                )}
                {OrderTypeUtil.hasHotel(packageType) && orderSelectedAccommodation && (
                    <tr>
                        <td>{renderTravelDetail(t('traveldetail_hotel'), true)}</td>
                        <td>
                            <Body small marginTop={false} marginBottom={false}>
                                {orderSelectedAccommodation.name}
                            </Body>
                        </td>
                    </tr>
                )}
            </tbody>
        </table>
    );
};

export default withTranslation()(TravelDetailTable);

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import $ from 'src/view/components/PaymentMethodSelector/PaymentMethodSelector.module.scss';
import { PaymentGateway } from 'src/data/services/payments';
import PaymentMethodCheckbox from 'src/view/components/PaymentMethodCheckbox';

type Props = {
    selectedGateway?: PaymentGateway | null;
    gateways: PaymentGateway[];
    onSelectGateway: (gateway: PaymentGateway) => void;
    fullWidth?: boolean;
};

export default function PaymentMethodSelector({
    selectedGateway,
    gateways,
    onSelectGateway,
    fullWidth,
}: Props) {
    const { t } = useTranslation();

    if (gateways.length === 0) {
        return null;
    }

    const getGatewayPrice = (charge?: string): string => {
        if (charge) {
            return `+${charge}`;
        }

        return t('payment_free');
    };

    const hasCharge = (charge?: string) => {
        return !!charge;
    };

    return (
        <div className={$.pickPayment}>
            {gateways.map((gateway) => (
                <PaymentMethodCheckbox
                    key={gateway.name}
                    gateway={gateway}
                    checked={selectedGateway?.name === gateway.name}
                    onChangeHandler={() => {
                        onSelectGateway(gateway);
                    }}
                    greenPriceText={!hasCharge(gateway.charge)}
                    priceLabel={getGatewayPrice(gateway.charge)}
                    fullWidth={fullWidth}
                />
            ))}
        </div>
    );
}

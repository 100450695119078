import React from 'react';
import { Body } from '../Body/Body';
import { makeStyles } from '@mui/styles';
import { formatMoney } from 'src/data/services/formatting';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
    priceContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
    },
}));

interface OrderSummaryPriceProps {
    price: string;
    currency: string;
    paxes: number;
}

export const OrderSummaryPrice = ({ price, currency, paxes }: OrderSummaryPriceProps) => {
    const classes = useStyles();
    const { t } = useTranslation();
    return (
        <div className={classes.priceContainer}>
            <Body marginTop={false} marginBottom={false} fontWeight="bold">
                {t('traveldetail_totalfor_mobile', {
                    occupancy: paxes,
                })}
            </Body>
            <Body fontWeight="bold" marginTop={false} marginBottom={false}>
                {formatMoney(price, {
                    hideZeroDecimals: true,
                    currency,
                })}
            </Body>
        </div>
    );
};

import { useCallback, useEffect, useState } from 'react';
import { VerifyEmailResponse, postVerifyEmail } from 'src/data/services/email';

const emailValueStorageName = 'dev-co-email-value';
const emailValidStorageName = 'dev-co-email-valid';
const isDev = process.env.NODE_ENV === 'development';

export function useVerifyEmail(emailValue: string | undefined) {
    const [emailErrors, setEmailErrors] = useState<VerifyEmailResponse | undefined>();
    const resetEmailErrors = useCallback(() => setEmailErrors(undefined), []);

    const [emailIsValidating, setEmailIsValidating] = useState(false);

    const hasEmailError = emailErrors !== undefined && emailErrors.certainly_wrong;

    useEffect(() => {
        if (!emailValue?.trim()) return;

        // On development env, we store the result of the verify email call and the email value in localstorage.
        // We do this because the verify email endpoint has a rate limit. Normally the verify email call
        // will be fired on page load/re-render. On development we hit the rate limit couple times because of this.
        // This if will reduce the calls made during development.
        if (isDev) {
            const cachedEmailValue = localStorage.getItem(emailValueStorageName);
            const isCachedEmailValid = localStorage.getItem(emailValidStorageName);

            if (cachedEmailValue === emailValue && isCachedEmailValid === 'true') return;

            localStorage.setItem(emailValueStorageName, emailValue);
        }

        const verifyEmail = async (email: string) => {
            setEmailIsValidating(true);

            const result = await postVerifyEmail(email);

            setEmailErrors(result);
            setEmailIsValidating(false);

            if (isDev) localStorage.setItem(emailValidStorageName, `${!result.certainly_wrong}`);
        };

        const timer = setTimeout(() => {
            if (emailValue) verifyEmail(emailValue);
        }, 500);

        return () => {
            clearTimeout(timer);
            resetEmailErrors();
            setEmailIsValidating(false);
        };
    }, [emailValue]);

    return { emailErrors, hasEmailError, emailIsValidating, resetEmailErrors };
}

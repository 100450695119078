import makeStyles from '@mui/styles/makeStyles';
import classnames from 'classnames';
import { fonts } from 'src/app/constants/fonts';
import { colors, fontSizes } from 'src/app/constants/theme';

const useStyles = makeStyles((t) => ({
    button: {
        fontFamily: fonts.normal,
        border: 'none',
        boxShadow: 'none',
        color: colors.blue,
        '&:hover': {
            cursor: 'pointer',
        },
        fontSize: fontSizes.sm1,
    },
    buttonNormal: {
        background: colors.backgroundPrimary,
        padding: '6px 8px',
    },
    buttonUnderlined: {
        background: 'transparent',
        fontSize: '1rem',
        textDecoration: 'underline',
        lineHeight: '22px',
        padding: 0,
    },
}));

interface ReadMoreButtonProps {
    text: string;
    onClick: () => void;
    variant?: 'normal' | 'underline';
}

export const ReadMoreButton = ({ text, variant = 'normal', onClick }: ReadMoreButtonProps) => {
    const classes = useStyles();

    return (
        <button
            className={classnames(
                variant === 'underline' && classes.buttonUnderlined,
                variant === 'normal' && classes.buttonNormal,
                classes.button
            )}
            onClick={onClick}
        >
            {text}
        </button>
    );
};

import classnames from 'classnames';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import MobileContext from 'src/app/context/MobileContext';
import { formatTicketDeliveryString } from 'src/app/helpers/formatTicketDeliveryString';
import {
    getTicketTypesTranslation,
    hasPickupAtVenue,
} from 'src/app/mappers/ticketTypes/mapTicketTypesTranslation';
import { pushStepEventToDataLayer } from 'src/app/utils/googleAnalytics';
import { EventDetail } from 'src/data/models/EventDetail';
import { EventTicket } from 'src/data/models/EventTicket';
import { PackageType } from 'src/data/models/Order';
import { Venue } from 'src/data/models/Venue';
import { Occupancy } from 'src/data/services/cache';
import { formatMoney } from 'src/data/services/formatting';
import { InfoMessage } from 'src/view/components';
import { Body } from 'src/view/components/Body/Body';
import FormCheckbox from 'src/view/components/FormCheckbox/FormCheckbox';
import { Heading } from 'src/view/components/Heading/Heading';
import $ from './SeatPicker.module.scss';

const ACTION_NEXT = 'next';
const ACTION_PREV = 'prev';

interface Props extends WithTranslation {
    eventDetail: EventDetail;
    tickets: EventTicket[];
    occupancy: Occupancy;
    venue: Venue;
    event: EventDetail;
    onSelection: (ticketId: string) => void;
    selectedTicketCategoryId: string | null;
    packageType: PackageType;
    onConfirmSeats: () => Promise<void>;
    disabled: boolean;
    isLoading: boolean;
}

interface State {
    activeImageIndex: number;
}

class SeatPicker extends React.Component<Props, State> {
    public constructor(props: Props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);

        this.state = {
            activeImageIndex: 0,
        };
    }

    componentDidMount(): void {
        pushStepEventToDataLayer({
            event_type: 'step_start',
            step_name: 'seating',
        });
    }

    private getActiveTicket(): EventTicket | null {
        const { tickets, selectedTicketCategoryId } = this.props;

        return tickets.find((t) => t.categoryId === selectedTicketCategoryId) || null;
    }

    public setActiveImage = (direction: typeof ACTION_NEXT | typeof ACTION_PREV) => {
        const ticket = this.getActiveTicket();

        if (ticket === null) return;

        const { activeImageIndex } = this.state;

        const calc = direction === ACTION_NEXT ? 1 : -1;
        let newKey = activeImageIndex + calc;

        if (ticket.images && !ticket.images[newKey]) {
            newKey = direction === ACTION_NEXT ? 0 : ticket.images.length - 1;
        }

        this.setState({ activeImageIndex: newKey });
    };

    private handleChange(event: React.ChangeEvent) {
        const { tickets, onSelection } = this.props;

        const ticket = tickets.find(
            (possibleTicket) => possibleTicket.categoryId === event.target.id
        );

        this.setState({ activeImageIndex: 0 });

        if (ticket !== undefined && onSelection !== undefined) {
            onSelection(ticket.categoryId);
        }
    }

    render() {
        const { t, tickets, occupancy, venue, selectedTicketCategoryId, eventDetail } = this.props;
        const { activeImageIndex } = this.state;

        let description = '';
        let hasImages = false;
        let matchedTicket: EventTicket | null = null;

        if (selectedTicketCategoryId) {
            matchedTicket =
                tickets.find((et) => et.categoryId === selectedTicketCategoryId) || null;

            if (matchedTicket) {
                description = matchedTicket.description || '';
                hasImages = matchedTicket.images?.length > 0 || false;
            }
        }

        if (occupancy.adults.length === 0) {
            return;
        }

        return (
            <>
                <div className={$.seatPicker} data-cy="seat-picker">
                    <ul data-cy="seating-plan-categories">
                        {tickets.length === 1 && (
                            <li data-cy="one-category-left-message">
                                <InfoMessage text={t('ticket_one_category_available')} />
                            </li>
                        )}
                        {tickets.map((possibleTicket: EventTicket, index) => (
                            <li
                                key={index}
                                data-cy={`seating-plan-category seating-plan-category-${index + 1}`}
                            >
                                <FormCheckbox
                                    boldTitle={false}
                                    secondary
                                    id={possibleTicket.categoryId}
                                    name="tickets"
                                    title={possibleTicket.name}
                                    value={possibleTicket.name}
                                    price={formatMoney(possibleTicket.supplementPP.toString(), {
                                        hideZeroDecimals: true,
                                    })}
                                    checked={possibleTicket.categoryId === selectedTicketCategoryId}
                                    onChange={this.handleChange}
                                    fullWidth
                                />
                            </li>
                        ))}
                    </ul>
                    <div className={$.stadium}>
                        {matchedTicket && matchedTicket.seatplanImage && (
                            <div className={$.hasImage}>
                                <img src={matchedTicket.seatplanImage} alt="Stadium" />
                            </div>
                        )}

                        {matchedTicket && !matchedTicket.seatplanImage && (
                            <div className={$.noImage}>
                                <img
                                    src="/images/temp/no-seatingplan-image.png"
                                    srcSet="/images/temp/no-seatingplan-image.png 1x, /images/temp/no-seatingplan-image@2x.png 2x"
                                    alt="Stadium"
                                />
                                <p className={$.noImageNotification}>{t('seatpicker_noimage')}</p>
                            </div>
                        )}
                        {matchedTicket !== null && (
                            <>
                                <div className={$.details}>
                                    <div
                                        className={classnames([
                                            $.content,
                                            !hasImages && $.noImages,
                                        ])}
                                    >
                                        <Heading variant="h3" dataCy="matched-ticket-name">
                                            {matchedTicket.name}
                                        </Heading>
                                        <Body marginTop={false} marginBottom={false}>
                                            <pre>{description || t('ticket_seatdetail')}</pre>
                                        </Body>
                                    </div>
                                    {hasImages && (
                                        <div className={$.images}>
                                            <button
                                                type="button"
                                                className={$.left}
                                                onClick={() => this.setActiveImage(ACTION_PREV)}
                                            >
                                                <img
                                                    src="/images/icon-arrow-left-white.svg"
                                                    alt="<"
                                                />
                                            </button>
                                            <button
                                                type="button"
                                                className={$.right}
                                                onClick={() => this.setActiveImage(ACTION_NEXT)}
                                            >
                                                <img
                                                    src="/images/icon-arrow-right-white.svg"
                                                    alt="<"
                                                />
                                            </button>
                                            <div className={$.imagesContainer}>
                                                {matchedTicket.images.map((image, index) => (
                                                    <React.Fragment key={image.url}>
                                                        <img
                                                            src={image.url}
                                                            alt={image.title}
                                                            className={classnames([
                                                                $.image,
                                                                activeImageIndex === index &&
                                                                    $.active,
                                                            ])}
                                                        />
                                                    </React.Fragment>
                                                ))}
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div className={$.highlights}>
                                    {hasPickupAtVenue(
                                        eventDetail.tickets,
                                        selectedTicketCategoryId
                                    ) ? (
                                        <span className={$.ticketDeliveryText}>
                                            {t('ticket_ticket_delivery_part_1', {
                                                ticketType: getTicketTypesTranslation(
                                                    tickets,
                                                    selectedTicketCategoryId,
                                                    t
                                                ),
                                            })}
                                            <strong className={$.ticketDeliveryTextDays}>
                                                {t('ticket_ticket_delivery_part_1_1')}
                                            </strong>
                                            {t('ticket_ticket_delivery_part_3')}
                                        </span>
                                    ) : (
                                        <span className={$.ticketDeliveryText}>
                                            {t('ticket_ticket_delivery_part_1', {
                                                ticketType: getTicketTypesTranslation(
                                                    tickets,
                                                    selectedTicketCategoryId,
                                                    t
                                                ),
                                            })}{' '}
                                            <strong className={$.ticketDeliveryTextDays}>
                                                {formatTicketDeliveryString(
                                                    eventDetail.ticketSentEarliestDays || 0,
                                                    eventDetail.ticketSentLatestDays || 0,
                                                    t
                                                )}
                                            </strong>{' '}
                                            {t('ticket_ticket_delivery_part_3')}
                                        </span>
                                    )}
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </>
        );
    }
}

SeatPicker.contextType = MobileContext;

export default withTranslation()(SeatPicker);

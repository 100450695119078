import { fetchUrl } from 'src/data/services/fetchUrl';
import { PackageType } from 'src/data/models/Order';

export type PaymentRequestResponse = {
    id: string;
    amount: string;
    currency: string;
    order_number: string;
    expires_at: string;
    event_id?: string;
    package_type?: PackageType;
    ticket_category_id?: string;
};

export const fetchPaymentRequest = async (id: string) => {
    return fetchUrl<PaymentRequestResponse>({
        endpoint: `/v2/payment-requests/${id}`,
        method: 'GET',
    });
};

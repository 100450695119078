export interface EventTicketEntity {
    id: string;
    category_id: string;
    name: string;
    seatplan_image: string;
    supplement_pp_for_num_tickets: { number: string };
    description?: string;
    possible_ticket_types: string[];
    fixed_quantity_step: number;
    images: {
        url: string;
        title: string;
    }[];
}

export enum TicketType {
    Ticket = 'Ticket',
    Electronic = 'Electronic',
    Paper = 'Paper',
    Mobile = 'Mobile',
    PickupAtVenue = 'PickupAtVenue',
}

export class EventTicket {
    public readonly id: string;
    public readonly categoryId: string;
    public readonly name: string;
    public readonly seatplanImage: string;
    public readonly supplementPP: { number: string };
    public readonly description?: string;
    public readonly possibleTicketTypes: string[];
    public readonly fixed_quantity_step: number;
    public images: { url: string; title: string }[];

    public constructor(ticket: EventTicketEntity) {
        this.id = ticket.id;
        this.categoryId = ticket.category_id;
        this.name = ticket.name;
        this.seatplanImage = ticket.seatplan_image;
        this.supplementPP = ticket.supplement_pp_for_num_tickets;
        this.possibleTicketTypes = ticket.possible_ticket_types;
        this.images = ticket.images && ticket.images.length > 0 ? ticket.images : [];
        this.fixed_quantity_step = ticket.fixed_quantity_step;
        if (ticket.description) {
            this.description = ticket.description;
        }
    }
}

import * as React from 'react';
import { Body } from 'src/view/components/Body/Body';
import { Heading } from 'src/view/components/Heading/Heading';
import Button from '../Button/Button';
import $ from './TakeOverMessage.module.scss';

interface Props {
    callToActionLabel: string;
    description: string;
    eventName?: string | null;
    imageAlt: string;
    imageSrc: string;
    onCallToActionClick: () => void;
    title: string;
    copyRight?: string;
}

const TakeOverMessage: React.FunctionComponent<Props> = ({
    callToActionLabel,
    description,
    eventName = null,
    imageAlt,
    imageSrc,
    onCallToActionClick,
    title,
    copyRight,
}) => {
    return (
        <section className={$.wrap}>
            <div className={$.imgWrap}>
                {copyRight && <div className={$.copyright}>{copyRight}</div>}
                <img alt={imageAlt} src={imageSrc} className={$.img} />
            </div>
            <Heading align="center" variant="h1" className={$.title}>
                {title}
            </Heading>
            <div className={$.content}>
                <Body align="center">{description}</Body>
                {eventName && (
                    <Heading variant="h4" align="center" uppercase={false}>
                        {eventName}
                    </Heading>
                )}

                <Button
                    className={$.button}
                    text={callToActionLabel}
                    onClick={onCallToActionClick}
                />
            </div>
        </section>
    );
};

export default TakeOverMessage;

import React from 'react';

export const ChatIcon = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="25.385"
        height="20.002"
        viewBox="0 0 25.385 20.002"
    >
        <g data-name="Group 3739">
            <g data-name="Group 3738">
                <path
                    data-name="Path 6860"
                    d="M22.7 54.288H10.011a2.688 2.688 0 0 0-2.685 2.685v3.222H2.685A2.688 2.688 0 0 0 0 62.879v5.569a2.69 2.69 0 0 0 1.932 2.577v2.511a.753.753 0 0 0 1.244.572l3.466-2.975H13a2.688 2.688 0 0 0 2.685-2.685v-1.639H18.1l3.763 3.485a.753.753 0 0 0 1.265-.553v-2.966a2.689 2.689 0 0 0 2.256-2.651v-7.151a2.688 2.688 0 0 0-2.684-2.685zm-8.525 14.16A1.18 1.18 0 0 1 13 69.627H6.363a.753.753 0 0 0-.49.182L3.438 71.9v-1.52a.753.753 0 0 0-.753-.753 1.18 1.18 0 0 1-1.179-1.179v-5.569A1.18 1.18 0 0 1 2.685 61.7h4.641v2.424a2.688 2.688 0 0 0 2.685 2.685h4.164v1.639zm9.7-4.324A1.18 1.18 0 0 1 22.7 65.3h-.324a.753.753 0 0 0-.753.753v1.962L18.908 65.5a.753.753 0 0 0-.512-.2h-8.385a1.18 1.18 0 0 1-1.179-1.178v-7.149a1.18 1.18 0 0 1 1.179-1.179H22.7a1.18 1.18 0 0 1 1.179 1.179z"
                    transform="translate(0 -54.288)"
                />
            </g>
        </g>
        <g data-name="Group 3741">
            <g data-name="Group 3740">
                <path
                    data-name="Path 6861"
                    d="M236.038 137.515H227.3a.753.753 0 1 0 0 1.506h8.741a.753.753 0 1 0 0-1.506z"
                    transform="translate(-215.312 -133.389)"
                />
            </g>
        </g>
        <g data-name="Group 3743">
            <g data-name="Group 3742">
                <path
                    data-name="Path 6862"
                    d="M236.038 200.3H227.3a.753.753 0 1 0 0 1.506h8.741a.753.753 0 1 0 0-1.506z"
                    transform="translate(-215.312 -193.063)"
                />
            </g>
        </g>
    </svg>
);

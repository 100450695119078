import * as React from 'react';
import $ from './CloseButton.module.scss';

interface Props {
    onClick: () => void;
}

const CloseButton = (props: Props) => {
    const { onClick } = props;

    return (
        <button type="button" className={$.wrap} onClick={onClick}>
            <svg viewBox="0 0 16 16" className={$.cross}>
                <g fillRule="evenodd" className={$.paths}>
                    <path d="M2.035.707L15.493 14.23a.938.938 0 0 1-.029 1.35l-.01.01a1 1 0 0 1-1.385-.03L.609 2.035A.938.938 0 0 1 .64.686l.01-.01a1 1 0 0 1 1.386.031z" />
                    <path d="M14.069.707L.609 14.23a.938.938 0 0 0 .03 1.35l.01.01a1 1 0 0 0 1.386-.03L15.493 2.035a.938.938 0 0 0-.029-1.35l-.01-.01a1 1 0 0 0-1.385.031z" />
                </g>
            </svg>
        </button>
    );
};

export default CloseButton;

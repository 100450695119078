import Box from '@mui/material/Box';
import { isAxiosError } from 'axios';
import sumBy from 'lodash/sumBy';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ValidationErrors } from 'src/app/enum/ValidationErrors';
import TravellerDetailsForm, {
    TravellerDetailsFormValues,
} from 'src/app/forms/TravellerDetails/TravellerDetailsForm';
import { getAgeOnEventDate } from 'src/app/forms/TravellerDetails/TravellerDetailsFormValidation';
import { useNavigate } from 'src/app/hooks/use-navigate';
import { TicketsNotAvailableModal } from 'src/app/modals/payment/TicketsNotAvailableModal';
import TravellerFormModel, { TravellerBirthDate } from 'src/app/models/TravellerFormModel';
import { useTypedDispatch } from 'src/app/store';
import { selectHasConsentedNewsLetter } from 'src/app/store/TravellerDataSlice';
import {
    requireActiveSessionOrReset,
    resetSession,
    selectIsB2BMode,
    selectSession,
} from 'src/app/store/appSlice';
import { selectEventDetail } from 'src/app/store/eventSlice';
import {
    AvailabilityErrorEnum,
    fetchAvailability,
    selectAvailability,
    selectDiscounts,
    selectOrder,
    selectOrderSummary,
    selectVouchers,
} from 'src/app/store/orderSlice';
import { RouteHelper } from 'src/app/utils/RouteHelper';
import { clearExpiryTimeout, expiryWatcher } from 'src/app/utils/expiry-watcher';
import {
    pushAddShippingInfoEventToDataLayer,
    pushBeginCheckoutDetailsEventToDataLayer,
    pushStepEventToDataLayer,
} from 'src/app/utils/googleAnalytics';
import { unflatten } from 'src/app/utils/object';
import { OrderTypeUtil } from 'src/app/utils/utils';
import { Error } from 'src/data/models/ErrorListResponse';
import { EventDetail } from 'src/data/models/EventDetail';
import { getBooking, getOccupancy } from 'src/data/services/cache';
import { BookerDTO, TravellerDTO, setOrderTravelers, validateOrder } from 'src/data/services/order';
import { ErrorMessage, Page } from 'src/view/components';
import BackLink from 'src/view/components/BackLink/BackLink';
import Button from 'src/view/components/Button/Button';
import { Heading } from 'src/view/components/Heading/Heading';
import { VSpacer } from 'src/view/components/Page';
import { NotFound } from '../NotFound/NotFound';
import $ from './Details.module.scss';
import { TravellerError } from './types';

export const Details = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useTypedDispatch();
    const { eventId } = useParams<{ eventId: string }>();

    const eventDetail = useSelector(selectEventDetail);
    const session = useSelector(selectSession);
    const order = useSelector(selectOrder);
    const isB2BAllowed = useSelector(selectIsB2BMode);
    const orderSummary = useSelector(selectOrderSummary);
    const discounts = useSelector(selectDiscounts);
    const vouchers = useSelector(selectVouchers);
    const hasConsentedNewsLetter = useSelector(selectHasConsentedNewsLetter);

    const [generalTravellerErrors, setGeneralTravellerErrors] = useState<TravellerError[]>([]);
    const [generalCustomerErrors, setGeneralCustomerErrors] = useState<string[]>([]);
    const [showNotMatchingError, setShowNotMatchingError] = useState(false);
    const [validationErrors, setValidationErrors] = useState<string[]>();
    const [error, setError] = useState<string | null>(null);

    const [showTicketsNotAvailableModal, setShowTicketsNotAvailableModal] = useState(false);
    const isHotelPackageType = order && OrderTypeUtil.hasHotel(order.packageType);
    const isOnlyHasTicketType = order && OrderTypeUtil.onlyHasTicket(order.packageType);
    const availability = useSelector(selectAvailability);

    useEffect(() => {
        requireActiveSessionOrReset(eventId);
        pushBeginCheckoutDetailsEventToDataLayer();
        pushStepEventToDataLayer({
            event_type: 'step_start',
            step_name: 'details',
        });

        const hasSessionWithoutOrder = !!session && !order?.containsAllElements();

        if (hasSessionWithoutOrder) {
            navigate(
                RouteHelper.getTicketRoute(session.eventId, {
                    category_id: session.baseTicketCategoryId,
                    package_type: session.preferredPackageType,
                })
            );
        }
    }, [session, eventId]);

    useEffect(() => {
        const bookingCode = getBooking();
        if (!session || !availability || bookingCode) {
            console.log(
                '\x1b[31m%s\x1b[0m',
                'EXPIRY WATCHER SKIPPED - No session, no availability or booking code is already present'
            );

            return;
        }

        expiryWatcher(session, availability, dispatch);

        return () => {
            clearExpiryTimeout();
        };
    }, [session, availability, dispatch]);

    const { roomLayout } = getOccupancy();
    const roomLayoutAdults = sumBy(roomLayout, (r) => r.adults);
    const roomLayoutChildren = sumBy(roomLayout, (r) => r.children);

    const travellersMatchesOccupancy = (values: TravellerDetailsFormValues, bookOther: boolean) => {
        if (isHotelPackageType) {
            const { travellers } = values;

            const travellerComposition = getTravelersComposition(
                travellers,
                eventDetail,
                bookOther
            );

            return (
                roomLayoutAdults === travellerComposition?.adults &&
                roomLayoutChildren === travellerComposition?.children
            );
        }

        return true;
    };

    const submitTravellersInformation = async (
        values: TravellerDetailsFormValues,
        bookOther: boolean
    ) => {
        setShowNotMatchingError(false);
        setValidationErrors(undefined);

        if (!session || !order) return null;

        if (eventDetail?.travellerDataRequired && !travellersMatchesOccupancy(values, bookOther)) {
            setShowNotMatchingError(true);

            return;
        }

        const formatDateOfBirth = (dateOfBirth?: TravellerBirthDate) => {
            if (!dateOfBirth) return '';

            const { year, month, day } = dateOfBirth;

            const birthdayString = `${year}-${month}-${day}`;

            return moment(birthdayString, 'YYYY-M-D').format('YYYY-MM-DD');
        };

        const mapValidationErrors = (errors: Error[]) => {
            const translatedErrors = errors.map((error) => {
                if (
                    error.code === ValidationErrors.ERROR_NOT_ENOUGH_ADULT_TRAVELLERS ||
                    error.code === ValidationErrors.ERROR_TOO_MANY_ADULT_TRAVELLERS
                ) {
                    const roomAdults = error.payload?.num_adults_in_occupancy_set;
                    const travellerAdults = error.payload?.num_adult_travellers;

                    return t('details_occupancy_not_match_travellers_adults', {
                        roomAdults,
                        roomAdultsString:
                            roomAdults === 1
                                ? t('details_not_matching_roomLayout_adult')
                                : t('details_not_matching_roomLayout_adults'),
                        travellerAdults,
                        travellerAdultsString:
                            travellerAdults === 1
                                ? t('details_not_matching_roomLayout_adult')
                                : t('details_not_matching_roomLayout_adults'),
                    });
                }

                if (
                    error.code === ValidationErrors.ERROR_NOT_ENOUGH_CHILD_TRAVELLERS ||
                    error.code === ValidationErrors.ERROR_TOO_MANY_CHILD_TRAVELLERS
                ) {
                    const roomChildren = error.payload?.num_children_in_occupancy_set;
                    const travellerChildren = error.payload?.num_child_travellers;

                    return t('details_occupancy_not_match_travellers_children', {
                        roomChildren,
                        roomChildrenString:
                            roomChildren === 1
                                ? t('details_not_matching_roomLayout_child')
                                : t('details_not_matching_roomLayout_children'),
                        travellerChildren,
                        travellerChildrenString:
                            travellerChildren === 1
                                ? t('details_not_matching_roomLayout_child')
                                : t('details_not_matching_roomLayout_children'),
                    });
                }

                if (error.code === AvailabilityErrorEnum.ERROR_TICKET_NOT_AVAILABLE) {
                    setShowTicketsNotAvailableModal(true);
                    return t('tickets_not_available_error_description_short');
                }

                return error.code;
            });

            setValidationErrors(translatedErrors);
        };

        const booker = values.travellers[0];

        if (!booker.email || !booker.phoneNumber) {
            return;
        }

        const bookerDto: BookerDTO = {
            birth_date: formatDateOfBirth(booker.dateOfBirth),
            country_code: booker.country || '',
            email: booker.email,
            first_name: booker.firstName || '',
            last_name: booker.lastName || '',
            nationality: booker.nationality || '',
            address_line_1: booker.addressLine1,
            address_line_2: booker.addressLine2,
            zip_code: booker.zipcode,
            city: booker.city,
            phone_number: booker.phoneNumber,
            company_name: isB2BAllowed ? booker.companyName : undefined,
        };

        let dtoTravellers = values.travellers;

        if (bookOther) dtoTravellers = dtoTravellers.slice(1);

        const travellersDto: TravellerDTO[] = dtoTravellers.map((traveller) => {
            return {
                birth_date: formatDateOfBirth(traveller.dateOfBirth),
                first_name: traveller.firstName || '',
                last_name: traveller.lastName || '',
                nationality: traveller.nationality || '',
            };
        });

        try {
            await setOrderTravelers(
                order.id,
                order?.secret,
                bookerDto,
                travellersDto,
                isB2BAllowed ? booker.reference : undefined,
                values.restrictionsConfirmed
            );

            const validationResult = await validateOrder(order.id, order.secret);

            if (validationResult.errors.length > 0) {
                mapValidationErrors(validationResult.errors);
                return;
            }

            pushAddShippingInfoEventToDataLayer(booker.email);

            const discountCode = vouchers[0]?.code || discounts[0]?.code || 'false';
            pushStepEventToDataLayer({
                event_type: 'step_complete',
                step_name: 'details',
                step_data: `${
                    bookOther ? 'for somebody else' : 'main traveller'
                }|${hasConsentedNewsLetter}|${discountCode}`,
            });

            return navigate(RouteHelper.getPaymentRoute(session.eventId));
        } catch (err) {
            if (isAxiosError<{ errors: Record<string, string> }>(err) && err.response) {
                const { status, data } = err.response;

                if (status === 422) {
                    const { customerErrors, travellerErrors } = getFormattedValidationErrors(
                        data.errors
                    );

                    setGeneralCustomerErrors(customerErrors);
                    setGeneralTravellerErrors(travellerErrors);

                    document.body.scrollTo({
                        top: 0,
                        behavior: 'smooth',
                    });

                    return;
                }
            }

            setError(t('general_error'));
        }
    };

    if (!eventDetail || !session) {
        return <NotFound />;
    }

    const ticketsPageRoute = RouteHelper.getTicketRoute(session.eventId, {
        category_id: session?.baseTicketCategoryId || undefined,
        package_type: session?.preferredPackageType || undefined,
    });

    return (
        <Page step={2} eventDetail={eventDetail}>
            <TicketsNotAvailableModal
                onClick={() => {
                    dispatch(fetchAvailability(true));
                    navigate(ticketsPageRoute);
                }}
                onClose={() => setShowTicketsNotAvailableModal(false)}
                open={showTicketsNotAvailableModal}
            />
            <div className={$.back}>
                {(isHotelPackageType || isOnlyHasTicketType) && (
                    <BackLink to={RouteHelper.getHotelRoute(session.eventId)}>
                        {isHotelPackageType
                            ? t('hotel_change')
                            : isOnlyHasTicketType
                              ? t('ticket_change')
                              : ''}
                    </BackLink>
                )}
            </div>
            <div className={$.headerWrap}>
                <Heading variant="h2" className={$.header}>
                    {t('details_enterdata')}
                </Heading>
            </div>

            <TravellerDetailsForm
                awayTeam={eventDetail.teamAway?.name}
                eventDate={eventDetail.dateTime}
                restrictions={eventDetail.restrictions}
                travellerDataRequired={eventDetail.travellerDataRequired}
                onSubmit={(values, bookOther) => submitTravellersInformation(values, bookOther)}
                numberOfAdults={isHotelPackageType ? roomLayoutAdults : orderSummary?.numAdults}
                numberOfChildren={isHotelPackageType ? roomLayoutChildren : undefined}
                travellerErrors={generalTravellerErrors}
                customerErrors={generalCustomerErrors}
                validationErrors={validationErrors}
                onValuesChanged={() => setShowNotMatchingError(false)}
                notMatchingError={(values, bookOther) => {
                    const travellerComposition = getTravelersComposition(
                        values.travellers,
                        eventDetail,
                        bookOther
                    );

                    return showNotMatchingError
                        ? t('details_not_matching_roomLayout', {
                              roomAdults: roomLayoutAdults,
                              roomAdultsString:
                                  roomLayoutAdults === 1
                                      ? t('details_not_matching_roomLayout_adult')
                                      : t('details_not_matching_roomLayout_adults'),
                              roomChildren: roomLayoutChildren,
                              roomChildrenString: roomLayoutChildren
                                  ? t('details_not_matching_roomLayout_child')
                                  : t('details_not_matching_roomLayout_children'),
                              travellerAdults: travellerComposition?.adults,
                              travellerAdultsString:
                                  travellerComposition?.adults === 1
                                      ? t('details_not_matching_roomLayout_adult')
                                      : t('details_not_matching_roomLayout_adults'),
                              travellerChildren: travellerComposition?.children,
                              travellerChildrenString:
                                  travellerComposition?.children === 1
                                      ? t('details_not_matching_roomLayout_child')
                                      : t('details_not_matching_roomLayout_children'),
                          })
                        : undefined;
                }}
            />

            {error && <ErrorMessage content={error} />}
            {error && (
                <>
                    <Box mt={2}>
                        <Button
                            text={t('payment_completed_error_retry_order')}
                            onClick={() => dispatch(resetSession)}
                        />
                    </Box>
                </>
            )}

            <VSpacer />
        </Page>
    );
};

function getTravelersComposition(
    travelers: TravellerFormModel[],
    eventDetail: EventDetail | null,
    bookOther: boolean
) {
    if (!travelers || !eventDetail) return;

    let adults = 0;
    let children = 0;

    travelers.forEach((traveler) => {
        if (!traveler.dateOfBirth) return;

        const isChild = getAgeOnEventDate(traveler.dateOfBirth, eventDetail.dateTime) < 18;

        if (isChild) children++;
        else adults++;
    });

    if (bookOther && adults > 0) adults -= 1;

    return { adults, children };
}

// This take the error we get back from TW like "The travellers.0.last_name field is required" and converts it to something translatable.
// We have to get the last_name part en delete the _ since the translations are on details_lastname. The same happens for firstname, street etc.
const getTravellerErrorKey = (name: string) => {
    const splittedErrorMessage = name.split(' ').find((n) => n.includes('.')) || '';
    const fieldKeyErrorSplit = splittedErrorMessage.split('.');

    return fieldKeyErrorSplit[fieldKeyErrorSplit.length - 1].replace('_', '');
};

const getFormattedValidationErrors = (axiosErrors: Record<string, string>) => {
    const errors = unflatten(axiosErrors) as {
        travellers: Record<string, Record<string, string[]>>;
        customer: Record<string, string[]>;
    };

    const travellerErrors: TravellerError[] = [];
    const customerErrors: string[] = [];

    Object.keys(errors.customer || {}).forEach((indexKey) => {
        customerErrors.push(indexKey.replace('_', ''));
    });

    Object.keys(errors.travellers || {}).forEach((indexKey) => {
        const errorDetails = errors.travellers[indexKey];

        const errorStrings = Object.keys(errorDetails).map((field) =>
            getTravellerErrorKey(errorDetails[field][0])
        );

        const parsedKey = parseInt(indexKey);
        if (isNaN(parsedKey)) return;

        travellerErrors.push({ nTraveller: parsedKey, errors: errorStrings });
    });

    return { travellerErrors, customerErrors };
};

import React from 'react';
import classnames from 'classnames';
import $ from 'src/view/components/NotificationMessage/NotificationMessage.module.scss';

interface NotificationMessageProps {
    severity: 'error' | 'info' | 'success';
    message: string;
}

export default function NotificationMessage({
    severity = 'success',
    message,
}: NotificationMessageProps) {
    const hasSeverity = (type: typeof severity) => severity === type;

    return (
        <span
            className={classnames(
                hasSeverity('error') && $.errorNotification,
                hasSeverity('info') && $.infoNotification,
                hasSeverity('success') && $.successNotification
            )}
        >
            {message}
        </span>
    );
}

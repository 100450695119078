import React from 'react';

export const PenIcon = () => (
    <svg
        version="1.1"
        id="Laag_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 12 12"
        xmlSpace="preserve"
        height={12}
        width={12}
    >
        <g id="pen" transform="translate(0)">
            <path
                id="Path_8202"
                style={{ fill: '#B98B1' }}
                d="M0,11.5C0,11.7,0.1,11.9,0.3,12c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0L3.7,11L1,8.3L0,11.5z"
            />
            <path
                id="Path_8203"
                style={{ fill: '#B98B1' }}
                d="M0.4,12C0.2,12,0,11.8,0,11.6c0,0,0-0.1,0-0.1L1,8.2L3.8,11L0.4,12C0.4,12,0.4,12,0.4,12z
		 M1,8.3L0,11.5C0,11.7,0.1,11.9,0.3,12c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0L3.7,11L1,8.3z"
            />
            <path
                id="Path_8204"
                style={{ fill: '#B98B1' }}
                d="M11.7,1.8l-1.5-1.5c-0.4-0.4-1.1-0.4-1.5,0L8,1.1l3,3l0.7-0.7C12.1,2.9,12.1,2.2,11.7,1.8
		L11.7,1.8z"
            />
            <path
                id="Path_8205"
                style={{ fill: '#B98B1' }}
                d="M10.9,4.1l-3-3l0.8-0.8c0.4-0.4,1.1-0.4,1.5,0c0,0,0,0,0,0l1.5,1.5c0.4,0.4,0.4,1.1,0,1.5
		c0,0,0,0,0,0L10.9,4.1z M8,1.1l3,3l0.7-0.7c0.4-0.4,0.4-1.1,0-1.5l-1.5-1.5c-0.4-0.4-1.1-0.4-1.5,0L8,1.1z"
            />
            <path
                id="Path_8206"
                style={{ fill: '#B98B1' }}
                d="M1.3,7.7l6.1-6.1l3,3l-6.1,6.1L1.3,7.7z"
            />
            <path
                id="Path_8207"
                style={{ fill: '#B98B1' }}
                d="M4.3,10.7l-3-3l6.1-6.1l3,3L4.3,10.7z M1.4,7.7l3,3l6.1-6.1l-3-3L1.4,7.7z"
            />
        </g>
    </svg>
);

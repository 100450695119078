export function hasMarketingCookieConsent() {
    const consentString = document.cookie
        .split('; ')
        .find((row) => row.startsWith('CookieConsent='));
    return /marketing:true/.test(consentString || '') ? true : false;
}

export function hasStatisticsCookieConsent() {
    const consentString = document.cookie
        .split('; ')
        .find((row) => row.startsWith('CookieConsent='));
    return /statistics:true/.test(consentString || '') ? true : false;
}

import * as React from 'react';
import classnames from 'classnames';
import { Body } from 'src/view/components/Body/Body';
import $ from './ToggleSlider.module.scss';

interface Props {
    title?: string;
    active: boolean;
    setActive: (active: boolean) => void;
}

export default function ToggleSlider(props: Props) {
    const { title, active, setActive } = props;

    return (
        <div className={$.wrap}>
            {title && (
                <Body small marginTop={false} marginBottom={false} className={$.title}>
                    {title}
                </Body>
            )}
            <button className={$.slider} type="button" onClick={() => setActive(!active)}>
                <div className={classnames([$.sliderThumb, active && $.sliderThumbActive])} />
            </button>
        </div>
    );
}

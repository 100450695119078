/* eslint-disable lines-between-class-members */
export interface SerieEntity {
    id: string;
    name: string;
}

export class Serie {
    public readonly id: string;
    public readonly name: string;

    public constructor(serie: SerieEntity) {
        this.id = serie.id;
        this.name = serie.name;
    }
}

import React from 'react';
import classnames from 'classnames';
import $ from 'src/view/components/Discount/Discount.module.scss';
import { Discount } from 'src/data/models/Discount';
import DiscountRow from 'src/view/components/Discount/DiscountRow';
import { Notification } from 'src/app/core/lib/notification/notification';
import NotificationMessage from 'src/view/components/NotificationMessage/NotificationMessage';
import { DiscountStatusEnum, RemoveDiscountStatusType } from 'src/app/store/orderSlice';
import GroupedDiscountRow from 'src/view/components/Discount/GroupedDiscountRow';
import { getTotalVoucherValue } from 'src/app/core/lib/discount/discount';
import { FormLabel } from 'src/view/components/FormLabel/FormLabel';

export interface DiscountOverviewProps {
    label?: string;
    discounts: Discount[];
    vouchers: Discount[];
    onRemove: (d: Discount[]) => Promise<void>;
    notification?: Notification;
    discountRemoveStatus?: RemoveDiscountStatusType;
    totalOrderPrice: number;
    withBorders?: boolean;
}

export const DiscountOverview = ({
    label,
    discounts,
    vouchers,
    onRemove,
    notification,
    discountRemoveStatus,
    totalOrderPrice,
    withBorders = false,
}: DiscountOverviewProps) => {
    const renderDiscounts = () => {
        return discounts.map((d) => {
            return (
                <DiscountRow
                    key={d.code}
                    code={d.code}
                    type={d.type}
                    totalDiscountValue={parseInt(d.value)}
                    onIconClick={async () => {
                        await onRemove([d]);
                    }}
                    isLoading={discountRemoveStatus?.[d.code] === DiscountStatusEnum.FETCH}
                />
            );
        });
    };

    const renderVoucher = () => {
        return vouchers.map((v) => {
            return (
                <DiscountRow
                    key={v.code}
                    code={v.code}
                    type={v.type}
                    totalDiscountValue={getTotalVoucherValue(totalOrderPrice, discounts, vouchers)}
                    onIconClick={async () => {
                        await onRemove([v]);
                    }}
                    isLoading={discountRemoveStatus?.[v.code] === DiscountStatusEnum.FETCH}
                />
            );
        });
    };

    const removeAllVouchers = async () => {
        onRemove(vouchers);
    };

    const removeAllVouchersLoading = () => {
        let loading = false;

        vouchers.forEach((v) => {
            if (discountRemoveStatus?.[v.code] === DiscountStatusEnum.FETCH) loading = true;
        });

        return loading;
    };

    const renderGroupedVouchers = () => {
        return (
            <GroupedDiscountRow
                discountsCodes={vouchers.map((v) => v.code)}
                totalDiscountvalue={getTotalVoucherValue(totalOrderPrice, discounts, vouchers)}
                onIconClick={async () => {
                    await removeAllVouchers();
                }}
                isLoading={removeAllVouchersLoading()}
            />
        );
    };

    return (
        <div data-cy="discount-overview">
            {label && <FormLabel>{label}</FormLabel>}
            <div className={classnames($.discountList, withBorders && $.discountListWithBorders)}>
                {renderDiscounts()}
                {vouchers.length > 1 ? renderGroupedVouchers() : renderVoucher()}
            </div>
            {!!notification && (
                <div>
                    <NotificationMessage
                        severity={notification.type}
                        message={notification.message}
                    />
                </div>
            )}
        </div>
    );
};

import { TFunction } from 'i18next';
import { EventTicket, TicketType } from 'src/data/models/EventTicket';

export const getSelectedCategory = (tickets: EventTicket[], categoryId: string | null) => {
    return tickets.find((t) => t.categoryId === categoryId);
};

export const hasPickupAtVenue = (tickets: EventTicket[], categoryId: string | null) =>
    getSelectedCategory(tickets, categoryId)?.possibleTicketTypes.includes(
        TicketType.PickupAtVenue
    );

export const getTicketTypesTranslation = (
    tickets: EventTicket[],
    categoryId: string | null,
    t: TFunction
) => {
    const possibleTicketTypes = getSelectedCategory(tickets, categoryId)?.possibleTicketTypes;

    if (possibleTicketTypes && possibleTicketTypes.length > 1) {
        return t('ticket_ticket_delivery_tickets');
    }

    if (possibleTicketTypes?.includes(TicketType.Ticket)) {
        return t('ticket_ticket_delivery_eticket');
    }

    if (possibleTicketTypes?.includes(TicketType.Mobile)) {
        return t('ticket_ticket_delivery_mobile');
    }

    if (possibleTicketTypes?.includes(TicketType.Paper)) {
        return t('ticket_ticket_delivery_paper');
    }

    return t('ticket_ticket_delivery_tickets');
};

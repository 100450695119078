import { SVGProps } from 'react';

export function InfoIcon({ stroke, fill, ...props }: SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="14"
            fill="none"
            viewBox="0 0 14 14"
            {...props}
        >
            <circle cx="7" cy="7" r="6.5" stroke={stroke || 'currentColor'}></circle>
            <path
                fill={fill || 'currentColor'}
                stroke={stroke || 'currentColor'}
                d="M7.493 3.5H7.514a.454.454 0 01.336.143h0l.01.01c.17.167.19.466.034.659l-.042.045a.502.502 0 01-.703-.001l-.047-.05a.5.5 0 01.04-.65h0l.01-.01a.468.468 0 01.341-.146zM6.86 6.504c.14.197.203.436.186.673-.001.135-.016.27-.042.403v.001L6.86 6.504zm0 0a1.38 1.38 0 01.057-.001h.014l.014-.002a.635.635 0 01.446.147c.077.08.126.199.122.331h0v.014c0 .035-.004.12-.018.275h0v.003a1.782 1.782 0 01-.058.33h0l-.001.005-.365 1.392h0v.003a5.615 5.615 0 00-.089.42M6.86 6.504l-.306 2.874c-.028.13-.049.246-.064.35a2.397 2.397 0 00-.018.277v.01h0c-.003.132.05.25.128.324.137.116.301.17.463.16l.018-.001h.062a.987.987 0 01-.202-.7c.005-.126.019-.252.041-.377m0 0v.001l.493.086-.492-.089v.002z"
            ></path>
        </svg>
    );
}

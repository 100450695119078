import * as React from 'react';
import { Body } from 'src/view/components/Body/Body';
import $ from './InfoMessage.module.scss';

interface Props {
    text: string;
    missingFields?: string[];
}

export default function InfoMessage(props: Props) {
    const { text, missingFields } = props;

    return (
        <section className={$.wrap}>
            <p className={$.message}>{text}</p>
            {missingFields && (
                <ul className={$.missingFields}>
                    {missingFields.map((field) => (
                        <li className={$.field} key={field}>
                            <Body marginTop={false} marginBottom={false}>
                                {field}
                            </Body>
                        </li>
                    ))}
                </ul>
            )}
        </section>
    );
}

import { ReactNode, forwardRef } from 'react';
import $ from './Card.module.scss';

interface Props {
    children: ReactNode;
    variant?: 'normal' | 'inverted';
    className?: string;
}

const Card = forwardRef<HTMLDivElement, Props>(
    ({ children, className = '', variant = 'normal' }: Props, ref) => {
        const variantClass = variant === 'inverted' ? $.variantInverted : '';

        return (
            <div ref={ref} className={`${$.root} ${className} ${variantClass}`}>
                {children}
            </div>
        );
    }
);

export default Card;

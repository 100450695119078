import { PackageType } from 'src/data/models/Order';
import { withLocale } from '../constants/appLocale';
import { queryStringToObject } from './utils';

export interface CompletedRouteParams {
    status?: string;
    message?: string;
    order_id: string;
}

export interface EventRouteParams {
    [key: string]: string | undefined | null;
}

export const getUrl = (path: string, param: object): string =>
    (Object.entries(param) || []).reduce((accumulator, [key, value], index) => {
        if (value === undefined) {
            return accumulator;
        }

        return accumulator.includes('?')
            ? `${accumulator}&${key}=${value}`
            : `${accumulator}?${key}=${value}`;
    }, path);

export class RouteHelper {
    public static getTicketRoute(eventId: string, params: EventRouteParams) {
        if (params.category_id === null) {
            params.category_id = undefined;
        }

        const url = getUrl(`/${eventId}/ticket`, params);
        return withLocale(url);
    }

    public static getDetailsRoute(eventId: string) {
        return withLocale(`/${eventId}/details`);
    }

    public static getPaymentRoute(eventId: string) {
        return withLocale(`/${eventId}/payment`);
    }

    public static getSessionEndRoute(eventId: string) {
        return withLocale(`/${eventId}/session-end`);
    }

    public static getHotelRoute(eventId: string) {
        return withLocale(`/${eventId}/hotel`);
    }

    public static getCompletedRoute(params: CompletedRouteParams) {
        const url = getUrl('/completed', params);
        return withLocale(url);
    }

    public static getPackageTypeFromUrl(): PackageType | null {
        if (!window.location.search) {
            return null;
        }

        const { package_type } = queryStringToObject(window.location.search);

        if (!package_type) {
            return null;
        }

        const packageType = package_type.toLocaleUpperCase() as PackageType;

        if (Object.values(PackageType).includes(packageType)) {
            return PackageType[packageType];
        }

        return null;
    }

    public static getCategoryIdFromUrl() {
        if (!window.location.search) return;

        const { category_id } = queryStringToObject(window.location.search);

        return category_id;
    }
}

import { HTMLAttributes } from 'react';
import { Chevron } from 'src/images/icons/Chevron';
import $ from 'src/view/components/ContactLink/ContactLink.module.scss';

interface ContactLinkProps extends HTMLAttributes<HTMLElement> {
    href?: string;
    onClick?: () => void;
    type?: 'normal' | 'anchor';
    icon: JSX.Element;
    contactTitle: string | JSX.Element;
    subTitle?: string | JSX.Element;
}

export const ContactLink = ({
    href,
    onClick,
    type = 'normal',
    icon,
    contactTitle,
    subTitle,
    ...props
}: ContactLinkProps) => {
    if (type === 'anchor' && !href)
        console.warn('You set the type as anchor, but did not provide a href attribute');

    const renderContent = () => (
        <>
            <div className={$.iconWrapper}>{icon}</div>
            <div className={$.contactLinkTextWrapper}>
                <span className={$.contactLinkText}>{contactTitle}</span>
                {subTitle && <div className={$.contactLinkGreyText}>{subTitle}</div>}
            </div>
            <div className={$.rightIcon}>
                <Chevron />
            </div>
        </>
    );

    if (type === 'anchor')
        return (
            <a href={href} target="_blank" className={$.contactLink} onClick={onClick} {...props}>
                {renderContent()}
            </a>
        );

    return (
        <div className={$.contactLink} onClick={onClick} {...props}>
            {renderContent()}
        </div>
    );
};

import React from 'react';

export const Chevron = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="13.142"
        height="13.142"
        viewBox="0 0 13.142 13.142"
    >
        <path
            data-name="Union 53"
            d="M-8834.225 9.293a.775.775 0 0 1-.774-.774V.774a.775.775 0 0 1 .774-.774.775.775 0 0 1 .776.774v6.969h6.971a.774.774 0 0 1 .771.776.772.772 0 0 1-.771.774z"
            transform="rotate(-135 -4411.493 -1824.578)"
            style={{ fill: '#8c98b1' }}
        />
    </svg>
);

export const LockIcon = () => (
    <svg
        width="9"
        height="11"
        viewBox="0 0 9 11"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g
                id="sidebar-full"
                transform="translate(-31.000000, -964.000000)"
                fill="#00af87"
                fillRule="nonzero"
            >
                <g id="Group-4" transform="translate(29.000000, 732.000000)">
                    <g id="Group-7" transform="translate(2.000000, 223.000000)">
                        <path
                            d="M2.5,14 L6.5,14 L6.5,12.5 C6.5,11.9479139 6.30468945,11.4765645 5.9140625,11.0859375 C5.52343555,10.6953105 5.05208609,10.5 4.5,10.5 C3.94791391,10.5 3.47656445,10.6953105 3.0859375,11.0859375 C2.69531055,11.4765645 2.5,11.9479139 2.5,12.5 L2.5,14 Z M9,14.75 L9,19.25 C9,19.4583344 8.92708406,19.6354159 8.78125,19.78125 C8.63541594,19.9270841 8.45833437,20 8.25,20 L0.75,20 C0.541665625,20 0.364584062,19.9270841 0.21875,19.78125 C0.0729159375,19.6354159 0,19.4583344 0,19.25 L0,14.75 C0,14.5416656 0.0729159375,14.3645841 0.21875,14.21875 C0.364584062,14.0729159 0.541665625,14 0.75,14 L1,14 L1,12.5 C1,11.5416619 1.34374656,10.7187534 2.03125,10.03125 C2.71875344,9.34374656 3.54166187,9 4.5,9 C5.45833813,9 6.28124656,9.34374656 6.96875,10.03125 C7.65625344,10.7187534 8,11.5416619 8,12.5 L8,14 L8.25,14 C8.45833437,14 8.63541594,14.0729159 8.78125,14.21875 C8.92708406,14.3645841 9,14.5416656 9,14.75 Z"
                            id=""
                        ></path>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

export const colors = {
    successGreen: '#00af87',
    successGreenLight: '#00af8714',
    warningYellow: '#f9ae00',
    warningYellowLight: '#f9ae0014',
    darkBlue: '#1a1b33',
    grey: '#a0aabf',
    lightGrey: '#a0aabf',
    lightestGrey: '#eceff4',
    lightFlamingo: '#ffeaea',
    red: '#d60000',
    lightStone: '#c8ced9',
    blue: '#1fa4f2',
    linkColor: '#1fa4f2',
    backgroundPrimary: '#f7f9fc',
    greyHover: '#eceff4',
    textPrimary: '#1a1b33',
    yellow: '#f9ae00',
    black: '#1a1b33',
    inputBorderColor: '#76809a',
    formCheckedSuccessColorCssVariable: `var(--tco-form-checked-success-color, #1fa4f2)`,
};

export const layout = {
    borderRadiusBase: 4,
};

export const fontSizes = {
    sm1: '0.875rem',
};

import * as EventDetail from '../models/EventDetail';
import { fetchUrl } from './fetchUrl';
import { AccommodationEntity } from '../models/Accommodation';

export async function fetchEventDetail(eventId: string, categoryId: string | null) {
    return fetchUrl<EventDetail.EventDetailEntity>({
        endpoint: `/v2/events/${eventId}?include=organizer,base_package_ticket_options,content,venue,team_home,team_away,series${
            categoryId ? `&base_ticket_cat_id=${categoryId}` : ''
        }`,
        method: 'GET',
    });
}

export async function fetchEventAccomodations(eventId: string) {
    return fetchUrl<AccommodationEntity[]>({
        endpoint: `/v2/events/${eventId}/accommodations`,
        method: 'GET',
    });
}

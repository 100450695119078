import React from 'react';

export const ArrowDown = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="16" viewBox="0 0 16.992 21.221">
        <path
            data-name="Path 6243"
            d="M21.143 8.129a.9.9 0 0 0-.2-.279L13.353.255a.9.9 0 0 0-1.269 1.269L18.157 7.6H.9a.9.9 0 1 0 0 1.795h17.26l-6.07 6.069a.9.9 0 0 0-.264.635v.007a.9.9 0 0 0 .9.891.9.9 0 0 0 .634-.262l7.6-7.6a.9.9 0 0 0 .266-.637.9.9 0 0 0-.083-.369z"
            transform="rotate(90 8.496 8.496)"
            style={{ fill: '#212240' }}
        />
    </svg>
);

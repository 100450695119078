interface Settings {
    cache: {
        maxAgeOrder: number;
    };
}

// eslint-disable-next-line @typescript-eslint/no-object-literal-type-assertion
export default {
    cache: {
        maxAgeOrder: 24 * 60 * 60 * 1000,
    },
} as Settings;

import * as React from 'react';
import classnames from 'classnames';
import $ from './FormInput.module.scss';

export enum FormInputSize {
    Small,
    ActionCode,
    Medium,
    Large,
    PostCode,
}

export interface ValidationConfig {
    disableErrorValidation?: boolean;
    disableSuccessValidation?: boolean;
}

export interface FormInputProps {
    hasError?: boolean;
    success?: boolean;
    size: FormInputSize;
    onBlur?: React.EventHandler<React.FocusEvent<HTMLInputElement>>;
    onChange?: React.EventHandler<React.ChangeEvent<HTMLInputElement>>;
    maxLength?: number;
    pattern?: string;
    value?: string;
    defaultValue?: string;
    placeholder?: string;
    showErrorMessage?: boolean;
    required?: boolean;
    requiredErrorMessage?: string;
    border?: boolean;
    prefix?: string;
    className?: string;
    validationConfig?: ValidationConfig;
    name?: string;
    fullWidth?: boolean;
}

// eslint-disable-next-line react/prefer-stateless-function
const FormInput: React.FunctionComponent<FormInputProps> = ({
    success,
    hasError,
    size,
    onBlur,
    onChange = () => {},
    maxLength,
    pattern,
    defaultValue,
    placeholder,
    required,
    showErrorMessage = false,
    border = true,
    prefix,
    className,
    validationConfig,
    value,
    name = '',
    fullWidth,
}) => {
    const [showError, setShowError] = React.useState<boolean>(showErrorMessage);

    let sizeClassName = '';

    const handleOnBlur = (event) => {
        if (onBlur && typeof onBlur === 'function') {
            onBlur(event);
        }
    };

    // eslint-disable-next-line default-case
    switch (size) {
        case FormInputSize.Small:
            sizeClassName = $.small || '';
            break;
        case FormInputSize.ActionCode:
            sizeClassName = $.actionCode || '';
            break;
        case FormInputSize.Medium:
            sizeClassName = $.medium || '';
            break;
        case FormInputSize.Large:
            sizeClassName = $.large || '';
            break;
        case FormInputSize.PostCode:
            sizeClassName = $.postcode || '';
            break;
    }

    React.useEffect(() => {
        if (defaultValue) {
            setShowError(false);
        }
    }, [defaultValue]);

    React.useEffect(() => {
        setShowError(showErrorMessage);
    }, [showErrorMessage]);

    return (
        <React.Fragment>
            {prefix && <span className={$.prefix}>({prefix})</span>}
            <input
                name={name}
                className={classnames(
                    $.field,
                    sizeClassName,
                    fullWidth && $.fullWidthInput,
                    showError && !validationConfig?.disableErrorValidation && $.invalid,
                    hasError && $.invalid,
                    success && $.valid,
                    !border && $.noBorder,
                    validationConfig?.disableErrorValidation && $.noValidateError,
                    validationConfig?.disableSuccessValidation && $.noValidateSuccess,
                    className
                )}
                type="text"
                onBlur={handleOnBlur}
                onChange={onChange}
                pattern={pattern}
                placeholder={placeholder}
                required={required}
                value={value || ''}
                maxLength={maxLength}
            />
        </React.Fragment>
    );
};

export default FormInput;

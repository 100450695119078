import * as React from 'react';
import classnames from 'classnames';
import $ from './Spinner.module.scss';

interface Props {
    className?: string;
    color?: string;
    size?: number;
    type?: string;
    ['data-cy']?: string;
}

const Spinner: React.FunctionComponent<Props> = ({
    className,
    color = '#fff',
    size = 15,
    type = 'dark',
    ...props
}) => (
    <svg
        className={classnames($.root, className)}
        width={`${size}px`}
        height={`${size}px`}
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 24 24"
        {...props}
    >
        <defs>
            <path id="a" d="M0-1h24v24H0z" />
        </defs>
        <g fill="none" fillRule="evenodd">
            <mask id="b" fill="#fff">
                <use xlinkHref="#a" />
            </mask>
            <path
                className={type === 'light' ? $.spinnerPathLight : $.spinnerPathDark}
                d="M11.12 23a1.5 1.5 0 010-3A9 9 0 103.628 6.01a1.5 1.5 0 01-2.495-1.665A11.988 11.988 0 0111.12-1c6.627 0 12 5.373 12 12s-5.373 12-12 12z"
                mask="url(#b)"
                transform="rotate(-90 12 11)"
            />
        </g>
    </svg>
);

export default Spinner;

import classnames from 'classnames';
import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import MobileContext from 'src/app/context/MobileContext';
import { RootState, TypedDispatch } from 'src/app/store';
import {
    ExperimentVariantType,
    requireActiveSessionOrReset,
    selectSession,
    Session,
} from 'src/app/store/appSlice';
import { selectEventAccommodations, selectEventDetail } from 'src/app/store/eventSlice';
import {
    pushAccommodationSelection,
    selectAccommodationsAvailability,
    selectAvailability,
    selectBasePackage,
    selectEventAccommodationsWithAvailability,
    selectOrder,
    selectSelectedAccommodation,
    selectSelectedAccommodationIncludesBreakfast,
    selectSelectedTicket,
    setSelectedAccommodation,
} from 'src/app/store/orderSlice';
import { clearExpiryTimeout, expiryWatcher } from 'src/app/utils/expiry-watcher';
import { pushStepEventToDataLayer } from 'src/app/utils/googleAnalytics';
import { RouteHelper } from 'src/app/utils/RouteHelper';
import { OrderTypeUtil } from 'src/app/utils/utils';
import { Accommodation } from 'src/data/models/Accommodation';
import { Availability } from 'src/data/models/Availability';
import { AvailabilityAccommodation } from 'src/data/models/AvailabilityAccommodation';
import { AvailabilityBasePackage } from 'src/data/models/AvailabilityBaseAccommodation';
import { EventDetail } from 'src/data/models/EventDetail';
import { Order, PackageType } from 'src/data/models/Order';
import { Ticket } from 'src/data/models/Ticket';
import { CutleryIcon } from 'src/images/icons/CutleryIcon';
import { getMicroCopyVariant } from 'src/view/ABComponents/TCO504MicroCopyText/TCO504MicroCopyText';
import {
    Checkbox,
    ErrorMessage,
    HotelDetail,
    HotelList,
    Map,
    Page,
    PreviousStepBanner,
    ToggleSlider,
} from 'src/view/components';
import BackLink from 'src/view/components/BackLink/BackLink';
import { Body } from 'src/view/components/Body/Body';
import ConfirmButton from 'src/view/components/ConfirmButton/ConfirmButton';
import FilterSelect from 'src/view/components/FilterSelect';
import { Option } from 'src/view/components/FilterSelect/FilterSelect';
import { FormLabel } from 'src/view/components/FormLabel/FormLabel';
import { Heading } from 'src/view/components/Heading/Heading';
import { Loading } from '../Loading/Loading';
import { NotFound } from '../NotFound/NotFound';
import $ from './Hotel.module.scss';

interface Props extends WithTranslation, RouteComponentProps<{ eventId: string }> {
    availability: Availability | null;
    eventDetail: EventDetail | null;
    session: Session | null;
    dispatch: TypedDispatch<RootState>;
    order: Order | null;
    orderSelectedTicket: Ticket | null;
    eventAccommodations: Accommodation[];
    orderSelectedAccommodation: Accommodation | null;
    selectedAccommodationIncludesBreakfast: boolean | null;
    accommodationsAvailability: AvailabilityAccommodation[];
    basePackage: AvailabilityBasePackage | null;
    accommodations: Accommodation[] | null;
    microCopyVariant?: ExperimentVariantType;
}

interface State {
    openAccommodation?: Accommodation;
    openQuickView?: Accommodation;
    breakfast: boolean;
    filter: FilterState;
    mapActive: boolean;
    generalErrors: string[];
    refs: React.Ref<any>[];
    packageType: PackageType | undefined;
    loadingNextStep: boolean;
}

enum FilterState {
    Price = 0,
    DistanceCenter,
    DistanceVenue,
    Stars,
    Recommended,
}

function getAccommodationsSortedByFilter(
    filter: FilterState,
    accommodations: Accommodation[],
    breakfast: boolean
) {
    let resultAccommodations = [...accommodations];

    if (filter === FilterState.Recommended) {
        const recommended = resultAccommodations.filter((accomm) => accomm.recommended === true);
        const notRecommended = resultAccommodations.filter(
            (accomm) => accomm.recommended === false
        );

        resultAccommodations = recommended.concat(notRecommended);
    }

    resultAccommodations = resultAccommodations.sort((a, b) => {
        if (a.availability == null || b.availability == null) return 0;

        switch (filter) {
            case FilterState.Price:
                if (breakfast) {
                    return a.availability.cheapestBreakfast.supplementPP >
                        b.availability.cheapestBreakfast.supplementPP
                        ? 1
                        : -1;
                }
                return a.availability.cheapest.supplementPP > b.availability.cheapest.supplementPP
                    ? 1
                    : -1;
            case FilterState.DistanceCenter:
                return a.distanceToCityCenterRaw > b.distanceToCityCenterRaw ? 1 : -1;
            case FilterState.DistanceVenue:
                return a.distanceToVenueRaw > b.distanceToVenueRaw ? 1 : -1;
            case FilterState.Stars:
                return a.stars > b.stars ? -1 : 1;
            default:
                return 0;
        }
    });

    return resultAccommodations;
}

function filterAccommodationOnBreakfast(accommodations: Accommodation[]) {
    return accommodations.filter((accommodation) => accommodation.availability?.cheapestBreakfast);
}

// eslint-disable-next-line react/prefer-stateless-function
class Hotel extends React.Component<Props, State> {
    public hotelSelectorRef = React.createRef<HTMLDivElement>();

    public constructor(props: Props) {
        super(props);

        const { order } = this.props;

        this.state = {
            breakfast: false,
            filter: FilterState.Price,
            generalErrors: [],
            mapActive: true,
            refs: [],
            packageType: order?.packageType,
            loadingNextStep: false,
        };

        this.changeBreakfast = this.changeBreakfast.bind(this);
        this.changeFilter = this.changeFilter.bind(this);
        this.changeAccommodation = this.changeAccommodation.bind(this);
        this.openDetail = this.openDetail.bind(this);
        this.closeDetail = this.closeDetail.bind(this);
        this.closeHotelQuickView = this.closeHotelQuickView.bind(this);
    }

    public componentWillUnmount() {
        clearExpiryTimeout();
    }

    public componentDidMount() {
        const {
            eventDetail,
            session,
            match,
            selectedAccommodationIncludesBreakfast,
            dispatch,
            availability,
        } = this.props;

        requireActiveSessionOrReset(match.params.eventId);

        if (eventDetail && session) {
            this.processIncomingEventDetail(eventDetail);
        }

        if (selectedAccommodationIncludesBreakfast !== null) {
            this.setState({ breakfast: selectedAccommodationIncludesBreakfast });
        }

        pushStepEventToDataLayer({
            event_type: 'step_start',
            step_name: 'hotel',
        });

        expiryWatcher(session, availability, dispatch);
    }

    public componentDidUpdate(prevProps: Props) {
        const { eventDetail, session, availability, dispatch } = this.props;

        if (eventDetail && prevProps.eventDetail?.id !== eventDetail.id && session) {
            this.processIncomingEventDetail(eventDetail);
        }

        expiryWatcher(session, availability, dispatch);
    }

    private processIncomingEventDetail = (eventDetail: EventDetail) => {
        const { history, session, order, availability } = this.props;

        // if no order redirect to the ticket page
        if (!order || !availability || !order.hasTickets()) {
            history.push(
                RouteHelper.getTicketRoute(eventDetail.id, {
                    category_id: session?.baseTicketCategoryId || undefined,
                    package_type: session?.preferredPackageType,
                })
            );
            return;
        }

        // if order type does not include hotel redirect to ticket page
        if (!OrderTypeUtil.hasHotel(order.packageType)) {
            history.push(
                RouteHelper.getTicketRoute(eventDetail.id, {
                    category_id: session?.baseTicketCategoryId || undefined,
                    package_type: session?.preferredPackageType,
                })
            );
            return;
        }
    };

    public handleNextStepClicked = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();

        const { history, session, dispatch } = this.props;

        if (!session) {
            return;
        }

        this.setState({ loadingNextStep: true });

        try {
            await dispatch(pushAccommodationSelection());
            pushStepEventToDataLayer({
                event_type: 'step_complete',
                step_name: 'hotel',
            });
        } catch (err) {
            this.setState({ loadingNextStep: false });
            return;
        }

        history.push(RouteHelper.getDetailsRoute(session.eventId));
    };

    public setMapActive = (active: boolean) => {
        this.setState({ mapActive: active });
    };

    public changeFilter(value: string) {
        let filterState: FilterState;

        switch (+value) {
            case 1:
                filterState = FilterState.DistanceCenter;
                break;
            case 2:
                filterState = FilterState.DistanceVenue;
                break;
            case 3:
                filterState = FilterState.Stars;
                break;
            case 4:
                filterState = FilterState.Recommended;
                break;
            default:
                filterState = FilterState.Price;
        }
        this.setState({ filter: filterState });
    }

    public changeBreakfast() {
        const { orderSelectedAccommodation, selectedAccommodationIncludesBreakfast } = this.props;
        const { breakfast } = this.state;
        const newBreakfastState = !breakfast;

        this.setState({ breakfast: newBreakfastState }, () => {
            if (!orderSelectedAccommodation) {
                return;
            }

            // Breakfast was selected, but the current acc. doesn't have one. So reselect it.
            if (newBreakfastState && !selectedAccommodationIncludesBreakfast) {
                this.changeAccommodation(null);
                return;
            }

            this.changeAccommodation(orderSelectedAccommodation);
        });
    }

    public async changeAccommodation(accommodation: Accommodation | null) {
        const { order, dispatch } = this.props;
        const { breakfast } = this.state;

        if (!order) {
            return;
        }

        if (!accommodation) {
            dispatch(setSelectedAccommodation(null));
            return;
        }

        dispatch(setSelectedAccommodation({ id: accommodation.id, breakfast }));
    }

    public openDetail(accommodation: Accommodation) {
        this.setState({ openAccommodation: accommodation });
    }

    public openHotelQuickView(accommodation: Accommodation) {
        const isMobile = this.context;
        let scrollTo = 0;

        if (isMobile) {
            const refPosition =
                this.hotelSelectorRef.current &&
                this.hotelSelectorRef.current.getBoundingClientRect();

            if (refPosition) {
                // Get element position relative to the document
                const { body, documentElement } = document;
                const scrollTop = window.pageYOffset || documentElement.scrollTop || body.scrollTop;
                const clientTop = documentElement.clientTop || body.clientTop || 0;
                const headerHeight = 150;

                // Scroll to that element instead of the beginning of the page
                scrollTo = refPosition.top + scrollTop - clientTop - headerHeight;
            }
        }

        document.querySelector('body')?.scrollTo(0, scrollTo);
        this.setState({ openQuickView: accommodation });
    }

    public closeHotelQuickView() {
        this.setState({ openQuickView: undefined });
    }

    public closeDetail() {
        this.setState({ openAccommodation: undefined });
    }

    public getBaseAccommodation(accommodations: Accommodation[]): Accommodation | null {
        const { basePackage } = this.props;

        const baseAccommodation =
            accommodations.find((a) => a.id === basePackage?.accommodationId) || null;

        return baseAccommodation;
    }

    public getCheapestAccommodation(
        accommodations: Accommodation[],
        breakfast: boolean
    ): Accommodation | null {
        if (breakfast) {
            // Remove acc. without breakfast
            accommodations.filter((a) => !!a.availability?.cheapestBreakfast);
        }

        const sortedAccommodations = accommodations.sort((a1, a2) => {
            if (
                !a1.availability ||
                (breakfast && !a1.availability.cheapestBreakfast) ||
                !a2.availability ||
                (breakfast && !a2.availability.cheapestBreakfast)
            ) {
                throw new Error(
                    'Accommodation is missing breakfast price, but breakfast is requested.'
                );
            }

            const aPrice = breakfast
                ? a1.availability?.cheapestBreakfast.supplementPP
                : a1.availability.cheapest.supplementPP;
            const bPrice = breakfast
                ? a2.availability?.cheapestBreakfast.supplementPP
                : a2.availability.cheapest.supplementPP;

            return aPrice - bPrice;
        });

        return sortedAccommodations[0] || null;
    }

    public render() {
        const {
            t,
            eventDetail,
            session,
            orderSelectedTicket,
            orderSelectedAccommodation,
            selectedAccommodationIncludesBreakfast,
            accommodations: unfilteredAccommodations,
        } = this.props;
        const { breakfast, filter, openAccommodation, mapActive, generalErrors, loadingNextStep } =
            this.state;

        let accommodations = unfilteredAccommodations;

        if (!accommodations?.length) return <Loading />;

        if (breakfast) {
            accommodations = filterAccommodationOnBreakfast(accommodations);
        }

        if (!orderSelectedAccommodation) {
            let accommodationToSelect = this.getBaseAccommodation(accommodations);

            if (!accommodationToSelect) {
                accommodationToSelect = this.getCheapestAccommodation(accommodations, breakfast);
            }

            if (!accommodationToSelect) {
                return <Loading />;
            }

            this.changeAccommodation(accommodationToSelect);
            return <Loading />;
        }

        accommodations = getAccommodationsSortedByFilter(filter, accommodations, breakfast);

        const accommodation =
            accommodations.find((a) => a.id === orderSelectedAccommodation?.id) || null;

        const selectionOptions: Option[] = [
            {
                title: t('hotel_optionprice'),
                subtitle: t('hotel_lowestfirst'),
            },
            {
                title: t('hotel_optioncenterdistance'),
            },
            {
                title: t('hotel_optioneventdistance'),
            },
            {
                title: t('hotel_optionstars'),
                subtitle: t('hotel_mostfirst'),
            },
            {
                title: t('hotel_optionrecommendations'),
            },
        ];

        if (!eventDetail || !session) {
            return <NotFound />;
        }

        return (
            <>
                <Page step={1} eventDetail={eventDetail}>
                    <div className={$.container}>
                        <div className={$.backlinkWrapper}>
                            <BackLink
                                to={RouteHelper.getTicketRoute(eventDetail.id, {
                                    category_id: session.baseTicketCategoryId || undefined,
                                    package_type: session.preferredPackageType,
                                })}
                            >
                                {t('ticket_change')}
                            </BackLink>
                        </div>

                        <PreviousStepBanner
                            eventDetail={eventDetail}
                            session={session}
                            orderSelectedTicket={orderSelectedTicket}
                        />
                        {generalErrors.length > 0 &&
                            generalErrors.map((error) => (
                                <div key={error}>
                                    <ErrorMessage content={error} />
                                </div>
                            ))}
                        <div>
                            <Heading variant="h2">{t('ticket_pickhotel')}</Heading>
                            <FormLabel className={$.sortHotels}>{t('hotel_sort')}</FormLabel>

                            <FilterSelect
                                options={selectionOptions}
                                selected={selectionOptions[filter]}
                                onChange={this.changeFilter}
                                wide
                                secondary
                            />
                            <div className={$.extraOptions}>
                                <Checkbox
                                    label={
                                        <>
                                            <CutleryIcon />
                                            <Body small marginTop={false} marginBottom={false}>
                                                {t('hotel_includesbreakfast')}
                                            </Body>
                                        </>
                                    }
                                    className={$.breakfastCheckboxContainer}
                                    classNameBox={$.breakfastCheckbox}
                                    id="breakfast"
                                    name="breakfast"
                                    onChange={this.changeBreakfast}
                                    checked={selectedAccommodationIncludesBreakfast || false}
                                />
                                <div className={$.desktopOnly}>
                                    <ToggleSlider
                                        title={t('map')}
                                        active={mapActive}
                                        setActive={this.setMapActive}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className={$.hotelSelector}>
                            <div
                                className={classnames([$.list, !mapActive && $.listFullWidth])}
                                ref={this.hotelSelectorRef}
                            >
                                <HotelList
                                    accommodations={accommodations}
                                    breakfast={breakfast}
                                    onChange={this.changeAccommodation}
                                    openDetail={this.openDetail}
                                    selectedAccommodation={accommodation}
                                    wide={!mapActive}
                                />
                                <div
                                    className={classnames(
                                        !mapActive ? $.buttonNoMap : $.buttonWithMap,
                                        $.button
                                    )}
                                >
                                    <ConfirmButton
                                        buttonProps={{
                                            text: this.props.microCopyVariant
                                                ? t('microCopyGeneralConfirmButtonText')
                                                : t('ticket_nextstep'),
                                            onClick: this.handleNextStepClicked,
                                            disabled: loadingNextStep,
                                            isLoading: loadingNextStep,
                                        }}
                                        microCopyText={[
                                            t('hotel_instant_confirmation'),
                                            t('hotel_easy_booking'),
                                            t('hotel_change_preferences'),
                                        ]}
                                        renderPreviousLast
                                        onClickPrevious={() => {
                                            this.props.history.push(
                                                RouteHelper.getTicketRoute(eventDetail.id, {
                                                    category_id:
                                                        session.baseTicketCategoryId || undefined,
                                                    package_type: session.preferredPackageType,
                                                })
                                            );
                                        }}
                                    />
                                </div>
                            </div>
                            <div className={classnames([$.map, mapActive && $.mapActive])}>
                                <Map
                                    height={650}
                                    accomodations={accommodations}
                                    breakfast={breakfast}
                                    eventDetail={eventDetail}
                                    selectAccommodation={this.changeAccommodation}
                                    selectedAccommodation={accommodation}
                                    defaultZoom={11}
                                />
                                <Body className={$.legend}>
                                    <span className={$.legendIcon} /> {t('city_centre')}
                                </Body>
                            </div>
                        </div>
                    </div>
                </Page>

                {openAccommodation && (
                    <HotelDetail
                        breakfast={breakfast}
                        accommodation={openAccommodation}
                        eventDetail={eventDetail}
                        onClick={this.closeDetail}
                        price={
                            breakfast
                                ? `${openAccommodation.availability?.cheapestBreakfast?.supplementPP}`
                                : `${openAccommodation.availability?.cheapest?.supplementPP}`
                        }
                    />
                )}
            </>
        );
    }
}

Hotel.contextType = MobileContext;

export const HotelPage = connect((state: RootState) => ({
    eventDetail: selectEventDetail(state),
    session: selectSession(state),
    order: selectOrder(state),
    availability: selectAvailability(state),
    orderSelectedTicket: selectSelectedTicket(state),
    orderSelectedAccommodation: selectSelectedAccommodation(state),
    eventAccommodations: selectEventAccommodations(state),
    selectedAccommodationIncludesBreakfast: selectSelectedAccommodationIncludesBreakfast(state),
    accommodationsAvailability: selectAccommodationsAvailability(state),
    basePackage: selectBasePackage(state),
    accommodations: selectEventAccommodationsWithAvailability(state),
    microCopyVariant: getMicroCopyVariant(state),
}))(withTranslation()(Hotel));

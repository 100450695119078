import * as React from 'react';

interface Props {
    children: string;
    className?: string;
}

const Html: React.FunctionComponent<Props> = ({ children, className }) => (
    <div className={className} dangerouslySetInnerHTML={{ __html: unescape(children) }} />
);

export default Html;

import React from 'react';
import { Body } from 'src/view/components/Body/Body';
import { useSelector } from 'react-redux';
import { RootState } from 'src/app/store';
import { getAbExperiment } from 'src/ab-testing/experiments';

interface MicroCopyTextProps {
    text: string[];
}

export const getMicroCopyVariant = (state: RootState) =>
    getAbExperiment(state, 'tco504', 'showMicroCopyText');

export const TCO504MicroCopyText = ({ text }: MicroCopyTextProps) => {
    const microCopyVariant = useSelector((state: RootState) => getMicroCopyVariant(state));

    if (!microCopyVariant) return null;

    if (text.length === 1) {
        return (
            <Body marginBottom={false} small grey>
                <span>{text[0]}</span>
            </Body>
        );
    }

    const renderTextBullets = text.map((text) => <li>{text}</li>);

    return (
        <Body marginTop={false} marginBottom={false} small grey>
            <ul className="buttonHelperUl">{renderTextBullets}</ul>
        </Body>
    );
};

import { yupResolver } from '@hookform/resolvers/yup';
import AddIcon from '@mui/icons-material/Add';
import { Alert, Grid, IconButton, Button as MuiButton, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import BigNumber from 'bignumber.js';
import classNames from 'classnames';
import { Moment } from 'moment';
import React, { useEffect } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { colors } from 'src/app/constants/theme';
import getRoomLayoutSelectorFormValidationScheme from 'src/app/forms/RoomLayoutSelector/RoomLayoutSelectorValidation';
import RoomLayoutFormModel from 'src/app/models/RoomLayoutFormModel';
import { selectIsB2BMode } from 'src/app/store/appSlice';
import { generateId } from 'src/app/utils/utils';
import { BinIcon } from 'src/images/icons/BinIcon';
import { FormSelect, InfoMessage } from 'src/view/components';
import Button from 'src/view/components/Button/Button';
import FieldErrorMessage from 'src/view/components/Form/FieldErrorMessage';
import { FormDateInput } from 'src/view/components/FormDateSelect/FormDateInput';
import { FormLabel } from 'src/view/components/FormLabel/FormLabel';
import { VSpacer } from 'src/view/components/Page';
import $ from './RoomLayoutSelectorForm.module.scss';

const useStyles = makeStyles((theme) => ({
    roomName: {
        fontWeight: 'bold',
        display: 'block',
        padding: theme.spacing(1),
        paddingBottom: 15,
        [theme.breakpoints.down('md')]: {
            padding: 0,
        },
    },
    deleteIconContainer: {
        [theme.breakpoints.down('md')]: {
            display: 'none',
        },
        '& .MuiIconButton-root': {
            marginBottom: 9,
        },
    },
    deleteIconMobile: {
        display: 'none',
        marginLeft: theme.spacing(1),
        cursor: 'pointer',
        [theme.breakpoints.down('md')]: {
            display: 'flex',
            alignItems: 'center',
        },
    },
    birthdayContainer: {
        padding: theme.spacing(2),
        background: colors.backgroundPrimary,
        marginBottom: theme.spacing(2),
    },
    confirmButton: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    addRoomButtonContainer: {
        marginTop: theme.spacing(2),
        textTransform: 'none',
    },
}));

interface RoomLayoutSelectorFormValues {
    rooms: RoomLayoutFormModel[];
}

interface RoomLayoutSelectorFormProps {
    maxPeople?: number;
    rooms: RoomLayoutFormModel[];
    onConfirm: (values: RoomLayoutSelectorFormValues) => void;
    eventDate: Moment;
    possibleCombinations: number[];
    hasFixedIncrement: boolean;
}

export const RoomLayoutSelectorForm = ({
    rooms,
    maxPeople = 10,
    onConfirm,
    eventDate,
    possibleCombinations,
    hasFixedIncrement,
}: RoomLayoutSelectorFormProps) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const isB2BMode = useSelector(selectIsB2BMode);

    const {
        control,
        handleSubmit,
        reset,
        watch,
        getValues,
        setValue,
        formState: { errors, isDirty },
    } = useForm<RoomLayoutSelectorFormValues>({
        mode: 'onSubmit',
        defaultValues: {
            rooms: rooms,
        },
        resolver: yupResolver(getRoomLayoutSelectorFormValidationScheme(t, eventDate)),
    });

    const {
        fields: roomFields,
        append,
        remove,
    } = useFieldArray({
        control,
        name: 'rooms',
    });

    const roomsWatch = watch('rooms');

    useEffect(() => {
        reset();
    }, []);

    const getAdultOptions = () => {
        const numberOfAdults = 10;

        return [...Array(numberOfAdults)].map((value, index) => ({
            label: `${index + 1} ${t('roomLayoutSelectorAdult', { count: index + 1 })}`,
            value: index + 1,
        }));
    };

    const getChildrenOptions = () => {
        const numberOfChildren = 10;

        return [...Array(numberOfChildren)].map((value, index) => ({
            label: `${index} ${t('roomLayoutSelectorChild', { count: index })}`,
            value: index,
        }));
    };

    const childrenAmountForRoom = (index: number) => roomsWatch[index].children;
    const getChildErrors = (roomIndex: number, childIndex: number) =>
        errors.rooms?.[roomIndex]?.childInformation?.[childIndex]?.dateOfBirth;

    const birthdayErrorFilter: string[] = ['min', 'max', 'isChildUnder18'];
    const shouldShowError = (type?: string) => {
        if (!type) return true;

        return birthdayErrorFilter.includes(type);
    };

    const calculateValidityOfSelection = () => {
        const totalSum = roomsWatch.reduce((sum, room) => {
            return sum.plus(room.adults).plus(room.children);
        }, new BigNumber(0));

        const isGreaterThanMaxPeople = totalSum.isGreaterThan(maxPeople);

        const isTotalPeopleValid = possibleCombinations.includes(totalSum.toNumber());

        return { isGreaterThanMaxPeople, isTotalPeopleValid };
    };

    const { isTotalPeopleValid, isGreaterThanMaxPeople } = calculateValidityOfSelection();

    const canDeleteFirstRoom = roomsWatch && roomsWatch?.length > 1;
    const possibleCombinationsString = possibleCombinations.join(',');

    return (
        <Grid container>
            <Grid xs={12} lg={12} marginBottom={3} item>
                {hasFixedIncrement && (
                    <Alert severity="info">
                        {t(
                            'possibleAmountOfPeopleForThisEvent',
                            'The possible number of people which be selected for this event are: '
                        )}
                        <Typography fontWeight={600}>{possibleCombinationsString}.</Typography>
                    </Alert>
                )}
            </Grid>
            <Grid container xs={12} lg={12}>
                {roomFields.map((room, roomIndex) => (
                    <React.Fragment key={`room-${room.id}`}>
                        <Grid
                            container
                            alignItems="flex-end"
                            spacing={2}
                            data-cy={`room-row room-row-${roomIndex + 1}`}
                        >
                            <Grid item xs={12} md={2} display="flex" alignItems="center">
                                <span className={classes.roomName}>
                                    {t('roomLayoutSelectorRoomIndex', {
                                        index: roomIndex + 1,
                                    })}
                                </span>
                                {canDeleteFirstRoom && (
                                    <div
                                        className={classes.deleteIconMobile}
                                        onClick={() => remove(roomIndex)}
                                    >
                                        <BinIcon />
                                    </div>
                                )}
                            </Grid>
                            <Grid
                                item
                                xs={6}
                                md={canDeleteFirstRoom ? 4.5 : 5}
                                data-cy="room-layout-adults"
                            >
                                {roomIndex === 0 && (
                                    <FormLabel grey>
                                        {t('roomLayoutSelectorAdult', { count: 1 })}
                                    </FormLabel>
                                )}
                                <Controller
                                    name={`rooms.${roomIndex}.adults`}
                                    control={control}
                                    render={({
                                        field: { onChange, value },
                                        fieldState: { error },
                                    }) => (
                                        <>
                                            <FormSelect
                                                options={[
                                                    {
                                                        options: getAdultOptions(),
                                                    },
                                                ]}
                                                onChange={onChange}
                                                value={value}
                                            />
                                            <FieldErrorMessage message={error?.message} />
                                        </>
                                    )}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={6}
                                md={canDeleteFirstRoom ? 4.5 : 5}
                                data-cy="room-layout-children"
                            >
                                {roomIndex === 0 && (
                                    <FormLabel grey>
                                        {t('roomLayoutSelectorChild', { count: 0 })} (&lt;18)
                                    </FormLabel>
                                )}
                                <Controller
                                    name={`rooms.${roomIndex}.children`}
                                    control={control}
                                    render={({
                                        field: { onChange, value },
                                        fieldState: { error },
                                    }) => (
                                        <>
                                            <FormSelect
                                                options={[
                                                    {
                                                        options: getChildrenOptions(),
                                                    },
                                                ]}
                                                onChange={(value) => {
                                                    const parsedValue = parseInt(value.toString());

                                                    if (isNaN(parsedValue)) return;

                                                    onChange(value);

                                                    const information = getValues().rooms[
                                                        roomIndex
                                                    ].childInformation.slice(0, parsedValue);

                                                    setValue(
                                                        `rooms.${roomIndex}.childInformation`,
                                                        information
                                                    );
                                                }}
                                                value={value}
                                            />
                                            <FieldErrorMessage message={error?.message} />
                                        </>
                                    )}
                                />
                            </Grid>
                            {canDeleteFirstRoom && (
                                <Grid item xs={1} className={classes.deleteIconContainer}>
                                    <IconButton
                                        onClick={() => remove(roomIndex)}
                                        data-cy={`room-layout-remove-room-number-${roomIndex + 1}`}
                                    >
                                        <BinIcon />
                                    </IconButton>
                                </Grid>
                            )}

                            <Grid item xs={0} md={2} />
                            <Grid item xs={12} md={9}>
                                {childrenAmountForRoom(roomIndex) > 0 && (
                                    <div className={classes.birthdayContainer}>
                                        <FormLabel grey>
                                            {t('roomLayoutSelectorDateOfBirthChildren')}
                                        </FormLabel>

                                        <Grid container spacing={4}>
                                            {[...Array(childrenAmountForRoom(roomIndex))].map(
                                                (_, index) => (
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        md={6}
                                                        key={`room-${room.id}-child-${index}`}
                                                        data-cy={`child-birthday-info-container room-${
                                                            roomIndex + 1
                                                        }-child-${index + 1}`}
                                                    >
                                                        <FormLabel>
                                                            {t('roomLayoutSelectorChildIndex', {
                                                                index: index + 1,
                                                            })}
                                                        </FormLabel>

                                                        <Controller
                                                            name={`rooms.${roomIndex}.childInformation.${index}.dateOfBirth`}
                                                            control={control}
                                                            render={({
                                                                field: { onChange, value },
                                                                fieldState: { error },
                                                            }) => (
                                                                <>
                                                                    <FormDateInput
                                                                        onChange={onChange}
                                                                        day={value?.day}
                                                                        month={value?.month}
                                                                        year={value?.year}
                                                                        dayError={
                                                                            !!getChildErrors(
                                                                                roomIndex,
                                                                                index
                                                                            )?.day
                                                                        }
                                                                        monthError={
                                                                            !!getChildErrors(
                                                                                roomIndex,
                                                                                index
                                                                            )?.month
                                                                        }
                                                                        yearError={
                                                                            !!getChildErrors(
                                                                                roomIndex,
                                                                                index
                                                                            )?.year
                                                                        }
                                                                    />

                                                                    {shouldShowError(
                                                                        getChildErrors(
                                                                            roomIndex,
                                                                            index
                                                                        )?.day?.type
                                                                    ) && (
                                                                        <FieldErrorMessage
                                                                            message={
                                                                                getChildErrors(
                                                                                    roomIndex,
                                                                                    index
                                                                                )?.day?.message
                                                                            }
                                                                            dataCy="day"
                                                                        />
                                                                    )}

                                                                    {shouldShowError(
                                                                        getChildErrors(
                                                                            roomIndex,
                                                                            index
                                                                        )?.month?.type
                                                                    ) && (
                                                                        <FieldErrorMessage
                                                                            message={
                                                                                getChildErrors(
                                                                                    roomIndex,
                                                                                    index
                                                                                )?.month?.message
                                                                            }
                                                                            dataCy="month"
                                                                        />
                                                                    )}

                                                                    {shouldShowError(
                                                                        getChildErrors(
                                                                            roomIndex,
                                                                            index
                                                                        )?.year?.type
                                                                    ) && (
                                                                        <FieldErrorMessage
                                                                            message={
                                                                                getChildErrors(
                                                                                    roomIndex,
                                                                                    index
                                                                                )?.year?.message
                                                                            }
                                                                            dataCy="year"
                                                                        />
                                                                    )}

                                                                    {shouldShowError(
                                                                        error?.type
                                                                    ) && (
                                                                        <FieldErrorMessage
                                                                            message={error?.message}
                                                                        />
                                                                    )}
                                                                </>
                                                            )}
                                                        />
                                                    </Grid>
                                                )
                                            )}
                                        </Grid>
                                    </div>
                                )}
                            </Grid>
                        </Grid>
                    </React.Fragment>
                ))}

                <Grid
                    xs={12}
                    lg={12}
                    marginLeft={'auto'}
                    item
                    className={classNames(classes.addRoomButtonContainer, $.addRoomButtonContainer)}
                >
                    <MuiButton
                        disableElevation
                        disableRipple
                        endIcon={<AddIcon />}
                        data-cy="room-layout-add-room-button"
                        onClick={() =>
                            append({
                                id: generateId(),
                                adults: 1,
                                children: 0,
                                childInformation: [],
                            })
                        }
                    >
                        {t('roomLayoutSelectorAddRoom')}
                    </MuiButton>
                </Grid>

                {isGreaterThanMaxPeople && (
                    <Grid
                        item
                        xs={12}
                        lg={12}
                        marginBottom={2}
                        data-cy="room-layout-max-people-message"
                    >
                        <VSpacer />
                        <InfoMessage
                            text={t('roomLayoutSelectorMaxPeopleError', {
                                max: maxPeople,
                                phonenumber: isB2BMode
                                    ? t('_hardcodedSupportMobileNumberB2B')
                                    : t('_hardcodedSupportPhoneNumber'),
                                email: isB2BMode
                                    ? t('_hardcodedSupportSalesEmailB2B')
                                    : t('_hardcodedSupportEmail'),
                            })}
                        />
                    </Grid>
                )}

                {!isTotalPeopleValid && !isGreaterThanMaxPeople && isDirty && (
                    <Grid xs={12} lg={12} marginBottom={2} data-cy="room-layout-max-people-message">
                        <VSpacer />
                        <Alert severity="info">
                            {t(
                                'notValidNumberOfPeopleWarning',
                                'The current total of the people cannot be booked for this event. Please select a different number of people. The possible totals are: '
                            )}
                            <Typography fontWeight={600}>{possibleCombinationsString}.</Typography>
                        </Alert>
                    </Grid>
                )}

                <VSpacer />

                <Grid xs={12} lg={12} className={classes.confirmButton}>
                    <Button
                        text={t('roomLayoutSelectorConfirmRoomLayout')}
                        disabled={!isTotalPeopleValid || isGreaterThanMaxPeople}
                        onClick={() => handleSubmit(onConfirm)()}
                        dataCy="confirm-room-layout-button"
                    />
                </Grid>
            </Grid>
        </Grid>
    );
};

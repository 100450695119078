import React from 'react';

export const BinIcon = () => (
    <svg
        version="1.1"
        id="Laag_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 14 16"
        xmlSpace="preserve"
        width={14}
    >
        <g id="Bin" transform="translate(-31)">
            <g id="Group_4742" transform="translate(31.972 4.728)">
                <g id="Group_4741">
                    <path
                        id="Path_8178"
                        style={{ fill: '#8B98B1' }}
                        d="M0,0l0.8,10c0.1,0.7,0.7,1.3,1.4,1.3h7.6c0.7,0,1.3-0.6,1.4-1.3l0.8-10H0z M3.7,9.4
				C3.4,9.4,3.2,9.2,3.2,9L2.8,1.4c0-0.3,0.2-0.5,0.4-0.5c0,0,0,0,0,0c0.3,0,0.5,0.2,0.5,0.4c0,0,0,0,0,0l0.5,7.5
				C4.2,9.2,4,9.4,3.7,9.4C3.7,9.4,3.7,9.4,3.7,9.4L3.7,9.4z M6.5,8.9c0,0.3-0.2,0.5-0.4,0.5C5.8,9.4,5.6,9.2,5.6,9c0,0,0,0,0,0V1.4
				c0-0.3,0.2-0.5,0.4-0.5c0.3,0,0.5,0.2,0.5,0.4c0,0,0,0,0,0V8.9z M9.3,1.4L8.8,9c0,0.3-0.2,0.5-0.5,0.5C8.1,9.4,7.9,9.2,7.9,9
				c0,0,0,0,0,0l0.5-7.5c0-0.3,0.2-0.4,0.5-0.4C9.1,0.9,9.3,1.2,9.3,1.4C9.3,1.4,9.3,1.4,9.3,1.4L9.3,1.4z"
                    />
                </g>
            </g>
            <g id="Group_4744" transform="translate(31)">
                <g id="Group_4743">
                    <path
                        id="Path_8179"
                        style={{ fill: '#8B98B1' }}
                        d="M13.1,1.9h-2.8V1.4C10.3,0.6,9.6,0,8.9,0c0,0,0,0,0,0H5.1C4.4,0,3.7,0.6,3.7,1.4c0,0,0,0,0,0
				v0.5H0.9C0.4,1.9,0,2.3,0,2.8s0.4,0.9,0.9,0.9l0,0h12.1c0.5,0,0.9-0.4,0.9-0.9C14,2.3,13.6,1.9,13.1,1.9L13.1,1.9z M9.3,1.9H4.7
				V1.4c0-0.3,0.2-0.5,0.5-0.5h3.7c0.3,0,0.5,0.2,0.5,0.5V1.9z"
                    />
                </g>
            </g>
        </g>
    </svg>
);

import { useEffect, useRef } from 'react';

export const usePageViewEvent = ({
    country,
    language,
    shouldInitialize,
}: {
    country: string;
    language: string;
    shouldInitialize: boolean;
}) => {
    const hasFiredPageViewEvent = useRef(false);

    useEffect(() => {
        if (!shouldInitialize) return;

        if (hasFiredPageViewEvent.current) return;

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({ page: null });
        window.dataLayer.push({
            event: 'pageView',
            page: {
                pagePath: window.location.pathname,
                pageType: 'checkout',
                pageCountry: country,
                pageLanguage: language,
            },
        });
        hasFiredPageViewEvent.current = true;
    }, [country, language, shouldInitialize]);

    return null;
};

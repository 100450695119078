import React from 'react';

export const KlarnaIcon = () => (
    <svg
        data-name="Group 1834"
        xmlns="http://www.w3.org/2000/svg"
        width="35.218"
        height="23.279"
        viewBox="0 0 35.218 23.279"
    >
        <path data-name="Image 134" style={{ fill: '#fb81a0' }} d="M0 0h35.218v23.28H0z" />
        <path
            data-name="Path 5337"
            d="M8.617 8.076h-1.34a3.429 3.429 0 0 1-1.381 2.763l-.529.4 2.055 2.8H9.11l-1.893-2.581a4.737 4.737 0 0 0 1.4-3.382zm-4.788 0h1.37v5.959h-1.37zm5.677 0h1.292v5.959H9.506zM22.129 9.8a1.494 1.494 0 0 0-1.274.577v-.463h-1.226v4.121h1.244v-2.159a.868.868 0 0 1 .925-.931c.541 0 .853.324.853.925v2.171h1.232v-2.625A1.633 1.633 0 0 0 22.129 9.8zm-7.425.114v.262a2.145 2.145 0 0 0-1.226-.378 2.175 2.175 0 0 0 0 4.349 2.145 2.145 0 0 0 1.224-.371v.264h1.227V9.914zm-1.125 3.118a1.088 1.088 0 0 1-1.117-1.056 1.119 1.119 0 0 1 2.235 0 1.088 1.088 0 0 1-1.118 1.056zm4.265-2.583v-.535h-1.261v4.121h1.268v-1.922c0-.649.7-1 1.189-1h.012v-1.2a1.547 1.547 0 0 0-1.208.536zm9.87-.535v.262a2.145 2.145 0 0 0-1.226-.378 2.175 2.175 0 0 0 0 4.349 2.145 2.145 0 0 0 1.226-.371v.264h1.232V9.914zm-1.123 3.118a1.088 1.088 0 0 1-1.117-1.056 1.119 1.119 0 0 1 2.235 0 1.085 1.085 0 0 1-1.118 1.056zm3.31-3a.1.1 0 0 0-.108-.1h-.114v.312h.05v-.107h.06l.048.114h.06l-.054-.126a.093.093 0 0 0 .058-.091zm-.108.048h-.064v-.1h.06c.036 0 .054.018.054.048s-.008.055-.05.055z"
        />
        <path
            data-name="Path 5338"
            d="M29.775 9.806a.3.3 0 0 0-.294.294.3.3 0 0 0 .294.294.294.294 0 1 0 0-.589zm0 .535a.236.236 0 0 1-.234-.24.24.24 0 0 1 .234-.24.236.236 0 0 1 .234.24.24.24 0 0 1-.234.24zm.487 2.235a.775.775 0 1 0 .775.775.777.777 0 0 0-.775-.776z"
        />
    </svg>
);

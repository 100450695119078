import * as React from 'react';
import $ from './IconLabel.module.scss';

export enum IconLabelType {
    BlueCheckmark = 'BlueCheckmark',
    Breakfast = 'breakfast',
    Edit = 'edit',
    Back = 'back',
    GreenHeader = 'greenHeader',
    Checkmark = 'checkmark',
}

interface Props {
    children: React.ReactNode;
    type: IconLabelType;
}

const IconLabel = (props: Props) => {
    const { children, type } = props;
    return <span className={$[type]}>{children}</span>;
};

export default IconLabel;

export const lastKnownEntryPointSessionStorageKey = 'last_known_entrypoint';

export const setLastKnownEntryPoint = (url: string) =>
{
    sessionStorage.setItem(lastKnownEntryPointSessionStorageKey, url);
}

export const getLastKnownEntryPoint = (): string|null => {
    return sessionStorage.getItem(lastKnownEntryPointSessionStorageKey);
}

interface Country {
    code: string;
    dialCode: string;
    flag: string;
    name: string;
    highlight?: true;
}

export const countries: Country[] = [
    {
        code: 'AF',
        dialCode: '+93',
        flag: '/images/flags/af.png',
        name: 'Afghanistan',
    },
    {
        code: 'AL',
        dialCode: '+355',
        flag: '/images/flags/al.png',
        name: 'Albania',
    },
    {
        code: 'DZ',
        dialCode: '+213',
        flag: '/images/flags/dz.png',
        name: 'Algeria',
    },
    {
        code: 'AS',
        dialCode: '+1684',
        flag: '/images/flags/as.png',
        name: 'American Samoa',
    },
    {
        code: 'AD',
        dialCode: '+376',
        flag: '/images/flags/ad.png',
        name: 'Andorra',
    },
    {
        code: 'AO',
        dialCode: '+244',
        flag: '/images/flags/ao.png',
        name: 'Angola',
    },
    {
        code: 'AI',
        dialCode: '+1264',
        flag: '/images/flags/ai.png',
        name: 'Anguilla',
    },
    {
        code: 'AG',
        dialCode: '+1268',
        flag: '/images/flags/ag.png',
        name: 'Antigua and Barbuda',
    },
    {
        code: 'AR',
        dialCode: '+54',
        flag: '/images/flags/ar.png',
        name: 'Argentina',
    },
    {
        code: 'AM',
        dialCode: '+374',
        flag: '/images/flags/am.png',
        name: 'Armenia',
    },
    {
        code: 'AW',
        dialCode: '+297',
        flag: '/images/flags/aw.png',
        name: 'Aruba',
    },
    {
        code: 'AU',
        dialCode: '+61',
        flag: '/images/flags/au.png',
        name: 'Australia',
    },
    {
        code: 'AT',
        dialCode: '+43',
        flag: '/images/flags/at.png',
        name: 'Austria',
    },
    {
        code: 'AZ',
        dialCode: '+994',
        flag: '/images/flags/az.png',
        name: 'Azerbaijan',
    },
    {
        code: 'BS',
        dialCode: '+1242',
        flag: '/images/flags/bs.png',
        name: 'Bahamas',
    },
    {
        code: 'BH',
        dialCode: '+973',
        flag: '/images/flags/bh.png',
        name: 'Bahrain',
    },
    {
        code: 'BD',
        dialCode: '+880',
        flag: '/images/flags/bd.png',
        name: 'Bangladesh',
    },
    {
        code: 'BB',
        dialCode: '+1246',
        flag: '/images/flags/bb.png',
        name: 'Barbados',
    },
    {
        code: 'BY',
        dialCode: '+375',
        flag: '/images/flags/by.png',
        name: 'Belarus',
    },
    {
        code: 'BE',
        dialCode: '+32',
        flag: '/images/flags/be.png',
        name: 'België/Belgique',
        highlight: true,
    },
    {
        code: 'BZ',
        dialCode: '+501',
        flag: '/images/flags/bz.png',
        name: 'Belize',
    },
    {
        code: 'BJ',
        dialCode: '+229',
        flag: '/images/flags/bj.png',
        name: 'Benin',
    },
    {
        code: 'BM',
        dialCode: '+1441',
        flag: '/images/flags/bm.png',
        name: 'Bermuda',
    },
    {
        code: 'BT',
        dialCode: '+975',
        flag: '/images/flags/bt.png',
        name: 'Bhutan',
    },
    {
        code: 'BO',
        dialCode: '+591',
        flag: '/images/flags/bo.png',
        name: 'Bolivia',
    },
    {
        code: 'BA',
        dialCode: '+387',
        flag: '/images/flags/ba.png',
        name: 'Bosnia and Herzegovina',
    },
    {
        code: 'BW',
        dialCode: '+267',
        flag: '/images/flags/bw.png',
        name: 'Botswana',
    },
    {
        code: 'BR',
        dialCode: '+55',
        flag: '/images/flags/br.png',
        name: 'Brazil',
    },
    {
        code: 'IO',
        dialCode: '+246',
        flag: '/images/flags/io.png',
        name: 'British Indian Ocean Territory',
    },
    {
        code: 'VG',
        dialCode: '+1284',
        flag: '/images/flags/vg.png',
        name: 'British Virgin Islands',
    },
    {
        code: 'BN',
        dialCode: '+673',
        flag: '/images/flags/bn.png',
        name: 'Brunei',
    },
    {
        code: 'BG',
        dialCode: '+359',
        flag: '/images/flags/bg.png',
        name: 'Bulgaria',
    },
    {
        code: 'BF',
        dialCode: '+226',
        flag: '/images/flags/bf.png',
        name: 'Burkina Faso',
    },
    {
        code: 'BI',
        dialCode: '+257',
        flag: '/images/flags/bi.png',
        name: 'Burundi',
    },
    {
        code: 'KH',
        dialCode: '+855',
        flag: '/images/flags/kh.png',
        name: 'Cambodia',
    },
    {
        code: 'CM',
        dialCode: '+237',
        flag: '/images/flags/cm.png',
        name: 'Cameroon',
    },
    {
        code: 'CA',
        dialCode: '+1',
        flag: '/images/flags/ca.png',
        name: 'Canada',
    },
    {
        code: 'CV',
        dialCode: '+238',
        flag: '/images/flags/cv.png',
        name: 'Cape Verde',
    },
    {
        code: 'KY',
        dialCode: '+1345',
        flag: '/images/flags/ky.png',
        name: 'Cayman Islands',
    },
    {
        code: 'CF',
        dialCode: '+236',
        flag: '/images/flags/cf.png',
        name: 'Central African Republic',
    },
    {
        code: 'TD',
        dialCode: '+235',
        flag: '/images/flags/td.png',
        name: 'Chad',
    },
    {
        code: 'CL',
        dialCode: '+56',
        flag: '/images/flags/cl.png',
        name: 'Chile',
    },
    {
        code: 'CN',
        dialCode: '+86',
        flag: '/images/flags/cn.png',
        name: 'China',
    },
    {
        code: 'CX',
        dialCode: '+61',
        flag: '/images/flags/cx.png',
        name: 'Christmas Island',
    },
    {
        code: 'CC',
        dialCode: '+61',
        flag: '/images/flags/cc.png',
        name: 'Cocos (Keeling) Islands',
    },
    {
        code: 'CO',
        dialCode: '+57',
        flag: '/images/flags/co.png',
        name: 'Colombia',
    },
    {
        code: 'KM',
        dialCode: '+269',
        flag: '/images/flags/km.png',
        name: 'Comoros',
    },
    {
        code: 'CK',
        dialCode: '+682',
        flag: '/images/flags/ck.png',
        name: 'Cook Islands',
    },
    {
        code: 'CR',
        dialCode: '+506',
        flag: '/images/flags/cr.png',
        name: 'Costa Rica',
    },
    {
        code: 'HR',
        dialCode: '+385',
        flag: '/images/flags/hr.png',
        name: 'Croatia',
    },
    {
        code: 'CU',
        dialCode: '+53',
        flag: '/images/flags/cu.png',
        name: 'Cuba',
    },
    {
        code: 'CW',
        dialCode: '+5999',
        flag: '/images/flags/cw.png',
        name: 'Curacao',
    },
    {
        code: 'CY',
        dialCode: '+357',
        flag: '/images/flags/cy.png',
        name: 'Cyprus',
    },
    {
        code: 'CZ',
        dialCode: '+420',
        flag: '/images/flags/cz.png',
        name: 'Czechia',
    },
    {
        code: 'DK',
        dialCode: '+45',
        flag: '/images/flags/dk.png',
        name: 'Denmark',
    },
    {
        code: 'DJ',
        dialCode: '+253',
        flag: '/images/flags/dj.png',
        name: 'Djibouti',
    },
    {
        code: 'DM',
        dialCode: '+1767',
        flag: '/images/flags/dm.png',
        name: 'Dominica',
    },
    {
        code: 'DO',
        dialCode: '+1809',
        flag: '/images/flags/do.png',
        name: 'Dominican Republic',
    },
    {
        code: 'CD',
        dialCode: '+243',
        flag: '/images/flags/cd.png',
        name: 'DR Congo',
    },
    {
        code: 'EC',
        dialCode: '+593',
        flag: '/images/flags/ec.png',
        name: 'Ecuador',
    },
    {
        code: 'EG',
        dialCode: '+20',
        flag: '/images/flags/eg.png',
        name: 'Egypt',
    },
    {
        code: 'SV',
        dialCode: '+503',
        flag: '/images/flags/sv.png',
        name: 'El Salvador',
    },
    {
        code: 'GQ',
        dialCode: '+240',
        flag: '/images/flags/gq.png',
        name: 'Equatorial Guinea',
    },
    {
        code: 'ER',
        dialCode: '+291',
        flag: '/images/flags/er.png',
        name: 'Eritrea',
    },
    {
        code: 'EE',
        dialCode: '+372',
        flag: '/images/flags/ee.png',
        name: 'Estonia',
    },
    {
        code: 'ET',
        dialCode: '+251',
        flag: '/images/flags/et.png',
        name: 'Ethiopia',
    },
    {
        code: 'FK',
        dialCode: '+500',
        flag: '/images/flags/fk.png',
        name: 'Falkland Islands',
    },
    {
        code: 'FO',
        dialCode: '+298',
        flag: '/images/flags/fo.png',
        name: 'Faroe Islands',
    },
    {
        code: 'FJ',
        dialCode: '+679',
        flag: '/images/flags/fj.png',
        name: 'Fiji',
    },
    {
        code: 'FI',
        dialCode: '+358',
        flag: '/images/flags/fi.png',
        name: 'Finland',
    },
    {
        code: 'FR',
        dialCode: '+33',
        flag: '/images/flags/fr.png',
        name: 'France',
        highlight: true,
    },
    {
        code: 'GF',
        dialCode: '+594',
        flag: '/images/flags/gf.png',
        name: 'French Guiana',
    },
    {
        code: 'PF',
        dialCode: '+689',
        flag: '/images/flags/pf.png',
        name: 'French Polynesia',
    },
    {
        code: 'GA',
        dialCode: '+241',
        flag: '/images/flags/ga.png',
        name: 'Gabon',
    },
    {
        code: 'GM',
        dialCode: '+220',
        flag: '/images/flags/gm.png',
        name: 'Gambia',
    },
    {
        code: 'GE',
        dialCode: '+995',
        flag: '/images/flags/ge.png',
        name: 'Georgia',
    },
    {
        code: 'DE',
        dialCode: '+49',
        flag: '/images/flags/de.png',
        name: 'Deutschland',
        highlight: true,
    },
    {
        code: 'GH',
        dialCode: '+233',
        flag: '/images/flags/gh.png',
        name: 'Ghana',
    },
    {
        code: 'GI',
        dialCode: '+350',
        flag: '/images/flags/gi.png',
        name: 'Gibraltar',
    },
    {
        code: 'GR',
        dialCode: '+30',
        flag: '/images/flags/gr.png',
        name: 'Greece',
    },
    {
        code: 'GL',
        dialCode: '+299',
        flag: '/images/flags/gl.png',
        name: 'Greenland',
    },
    {
        code: 'GD',
        dialCode: '+1473',
        flag: '/images/flags/gd.png',
        name: 'Grenada',
    },
    {
        code: 'GP',
        dialCode: '+590',
        flag: '/images/flags/gp.png',
        name: 'Guadeloupe',
    },
    {
        code: 'GU',
        dialCode: '+1671',
        flag: '/images/flags/gu.png',
        name: 'Guam',
    },
    {
        code: 'GT',
        dialCode: '+502',
        flag: '/images/flags/gt.png',
        name: 'Guatemala',
    },
    {
        code: 'GG',
        dialCode: '+44',
        flag: '/images/flags/gg.png',
        name: 'Guernsey',
    },
    {
        code: 'GN',
        dialCode: '+224',
        flag: '/images/flags/gn.png',
        name: 'Guinea',
    },
    {
        code: 'GW',
        dialCode: '+245',
        flag: '/images/flags/gw.png',
        name: 'Guinea-Bissau',
    },
    {
        code: 'GY',
        dialCode: '+592',
        flag: '/images/flags/gy.png',
        name: 'Guyana',
    },
    {
        code: 'HT',
        dialCode: '+509',
        flag: '/images/flags/ht.png',
        name: 'Haiti',
    },
    {
        code: 'HN',
        dialCode: '+504',
        flag: '/images/flags/hn.png',
        name: 'Honduras',
    },
    {
        code: 'HK',
        dialCode: '+852',
        flag: '/images/flags/hk.png',
        name: 'Hong Kong',
    },
    {
        code: 'HU',
        dialCode: '+36',
        flag: '/images/flags/hu.png',
        name: 'Hungary',
    },
    {
        code: 'IS',
        dialCode: '+354',
        flag: '/images/flags/is.png',
        name: 'Iceland',
    },
    {
        code: 'IN',
        dialCode: '+91',
        flag: '/images/flags/in.png',
        name: 'India',
    },
    {
        code: 'ID',
        dialCode: '+62',
        flag: '/images/flags/id.png',
        name: 'Indonesia',
    },
    {
        code: 'IR',
        dialCode: '+98',
        flag: '/images/flags/ir.png',
        name: 'Iran',
    },
    {
        code: 'IQ',
        dialCode: '+964',
        flag: '/images/flags/iq.png',
        name: 'Iraq',
    },
    {
        code: 'IE',
        dialCode: '+353',
        flag: '/images/flags/ie.png',
        name: 'Ireland',
    },
    {
        code: 'IM',
        dialCode: '+44',
        flag: '/images/flags/im.png',
        name: 'Isle of Man',
    },
    {
        code: 'IL',
        dialCode: '+972',
        flag: '/images/flags/il.png',
        name: 'Israel',
    },
    {
        code: 'IT',
        dialCode: '+39',
        flag: '/images/flags/it.png',
        name: 'Italy',
    },
    {
        code: 'CI',
        dialCode: '+225',
        flag: '/images/flags/ci.png',
        name: 'Ivory Coast',
    },
    {
        code: 'JM',
        dialCode: '+1876',
        flag: '/images/flags/jm.png',
        name: 'Jamaica',
    },
    {
        code: 'JP',
        dialCode: '+81',
        flag: '/images/flags/jp.png',
        name: 'Japan',
    },
    {
        code: 'JE',
        dialCode: '+44',
        flag: '/images/flags/je.png',
        name: 'Jersey',
    },
    {
        code: 'JO',
        dialCode: '+962',
        flag: '/images/flags/jo.png',
        name: 'Jordan',
    },
    {
        code: 'KZ',
        dialCode: '+76',
        flag: '/images/flags/kz.png',
        name: 'Kazakhstan',
    },
    {
        code: 'KE',
        dialCode: '+254',
        flag: '/images/flags/ke.png',
        name: 'Kenya',
    },
    {
        code: 'KI',
        dialCode: '+686',
        flag: '/images/flags/ki.png',
        name: 'Kiribati',
    },
    {
        code: 'XK',
        dialCode: '+383',
        flag: '/images/flags/xk.png',
        name: 'Kosovo',
    },
    {
        code: 'KW',
        dialCode: '+965',
        flag: '/images/flags/kw.png',
        name: 'Kuwait',
    },
    {
        code: 'KG',
        dialCode: '+996',
        flag: '/images/flags/kg.png',
        name: 'Kyrgyzstan',
    },
    {
        code: 'LA',
        dialCode: '+856',
        flag: '/images/flags/la.png',
        name: 'Laos',
    },
    {
        code: 'LV',
        dialCode: '+371',
        flag: '/images/flags/lv.png',
        name: 'Latvia',
    },
    {
        code: 'LB',
        dialCode: '+961',
        flag: '/images/flags/lb.png',
        name: 'Lebanon',
    },
    {
        code: 'LS',
        dialCode: '+266',
        flag: '/images/flags/ls.png',
        name: 'Lesotho',
    },
    {
        code: 'LR',
        dialCode: '+231',
        flag: '/images/flags/lr.png',
        name: 'Liberia',
    },
    {
        code: 'LY',
        dialCode: '+218',
        flag: '/images/flags/ly.png',
        name: 'Libya',
    },
    {
        code: 'LI',
        dialCode: '+423',
        flag: '/images/flags/li.png',
        name: 'Liechtenstein',
    },
    {
        code: 'LT',
        dialCode: '+370',
        flag: '/images/flags/lt.png',
        name: 'Lithuania',
    },
    {
        code: 'LU',
        dialCode: '+352',
        flag: '/images/flags/lu.png',
        name: 'Luxembourg',
    },
    {
        code: 'MO',
        dialCode: '+853',
        flag: '/images/flags/mo.png',
        name: 'Macau',
    },
    {
        code: 'MK',
        dialCode: '+389',
        flag: '/images/flags/mk.png',
        name: 'Macedonia',
    },
    {
        code: 'MG',
        dialCode: '+261',
        flag: '/images/flags/mg.png',
        name: 'Madagascar',
    },
    {
        code: 'MW',
        dialCode: '+265',
        flag: '/images/flags/mw.png',
        name: 'Malawi',
    },
    {
        code: 'MY',
        dialCode: '+60',
        flag: '/images/flags/my.png',
        name: 'Malaysia',
    },
    {
        code: 'MV',
        dialCode: '+960',
        flag: '/images/flags/mv.png',
        name: 'Maldives',
    },
    {
        code: 'ML',
        dialCode: '+223',
        flag: '/images/flags/ml.png',
        name: 'Mali',
    },
    {
        code: 'MT',
        dialCode: '+356',
        flag: '/images/flags/mt.png',
        name: 'Malta',
    },
    {
        code: 'MH',
        dialCode: '+692',
        flag: '/images/flags/mh.png',
        name: 'Marshall Islands',
    },
    {
        code: 'MQ',
        dialCode: '+596',
        flag: '/images/flags/mq.png',
        name: 'Martinique',
    },
    {
        code: 'MR',
        dialCode: '+222',
        flag: '/images/flags/mr.png',
        name: 'Mauritania',
    },
    {
        code: 'MU',
        dialCode: '+230',
        flag: '/images/flags/mu.png',
        name: 'Mauritius',
    },
    {
        code: 'YT',
        dialCode: '+262',
        flag: '/images/flags/yt.png',
        name: 'Mayotte',
    },
    {
        code: 'MX',
        dialCode: '+52',
        flag: '/images/flags/mx.png',
        name: 'Mexico',
    },
    {
        code: 'FM',
        dialCode: '+691',
        flag: '/images/flags/fm.png',
        name: 'Micronesia',
    },
    {
        code: 'MD',
        dialCode: '+373',
        flag: '/images/flags/md.png',
        name: 'Moldova',
    },
    {
        code: 'MC',
        dialCode: '+377',
        flag: '/images/flags/mc.png',
        name: 'Monaco',
    },
    {
        code: 'MN',
        dialCode: '+976',
        flag: '/images/flags/mn.png',
        name: 'Mongolia',
    },
    {
        code: 'ME',
        dialCode: '+382',
        flag: '/images/flags/me.png',
        name: 'Montenegro',
    },
    {
        code: 'MS',
        dialCode: '+1664',
        flag: '/images/flags/ms.png',
        name: 'Montserrat',
    },
    {
        code: 'MA',
        dialCode: '+212',
        flag: '/images/flags/ma.png',
        name: 'Morocco',
    },
    {
        code: 'MZ',
        dialCode: '+258',
        flag: '/images/flags/mz.png',
        name: 'Mozambique',
    },
    {
        code: 'MM',
        dialCode: '+95',
        flag: '/images/flags/mm.png',
        name: 'Myanmar',
    },
    {
        code: 'NA',
        dialCode: '+264',
        flag: '/images/flags/na.png',
        name: 'Namibia',
    },
    {
        code: 'NR',
        dialCode: '+674',
        flag: '/images/flags/nr.png',
        name: 'Nauru',
    },
    {
        code: 'NP',
        dialCode: '+977',
        flag: '/images/flags/np.png',
        name: 'Nepal',
    },
    {
        code: 'NL',
        dialCode: '+31',
        flag: '/images/flags/nl.png',
        name: 'Nederland',
        highlight: true,
    },
    {
        code: 'NC',
        dialCode: '+687',
        flag: '/images/flags/nc.png',
        name: 'New Caledonia',
    },
    {
        code: 'NZ',
        dialCode: '+64',
        flag: '/images/flags/nz.png',
        name: 'New Zealand',
    },
    {
        code: 'NI',
        dialCode: '+505',
        flag: '/images/flags/ni.png',
        name: 'Nicaragua',
    },
    {
        code: 'NE',
        dialCode: '+227',
        flag: '/images/flags/ne.png',
        name: 'Niger',
    },
    {
        code: 'NG',
        dialCode: '+234',
        flag: '/images/flags/ng.png',
        name: 'Nigeria',
    },
    {
        code: 'NU',
        dialCode: '+683',
        flag: '/images/flags/nu.png',
        name: 'Niue',
    },
    {
        code: 'NF',
        dialCode: '+672',
        flag: '/images/flags/nf.png',
        name: 'Norfolk Island',
    },
    {
        code: 'KP',
        dialCode: '+850',
        flag: '/images/flags/kp.png',
        name: 'North Korea',
    },
    {
        code: 'MP',
        dialCode: '+1670',
        flag: '/images/flags/mp.png',
        name: 'Northern Mariana Islands',
    },
    {
        code: 'NO',
        dialCode: '+47',
        flag: '/images/flags/no.png',
        name: 'Norway',
    },
    {
        code: 'OM',
        dialCode: '+968',
        flag: '/images/flags/om.png',
        name: 'Oman',
    },
    {
        code: 'PK',
        dialCode: '+92',
        flag: '/images/flags/pk.png',
        name: 'Pakistan',
    },
    {
        code: 'PW',
        dialCode: '+680',
        flag: '/images/flags/pw.png',
        name: 'Palau',
    },
    {
        code: 'PS',
        dialCode: '+970',
        flag: '/images/flags/ps.png',
        name: 'Palestine',
    },
    {
        code: 'PA',
        dialCode: '+507',
        flag: '/images/flags/pa.png',
        name: 'Panama',
    },
    {
        code: 'PG',
        dialCode: '+675',
        flag: '/images/flags/pg.png',
        name: 'Papua New Guinea',
    },
    {
        code: 'PY',
        dialCode: '+595',
        flag: '/images/flags/py.png',
        name: 'Paraguay',
    },
    {
        code: 'PE',
        dialCode: '+51',
        flag: '/images/flags/pe.png',
        name: 'Peru',
    },
    {
        code: 'PH',
        dialCode: '+63',
        flag: '/images/flags/ph.png',
        name: 'Philippines',
    },
    {
        code: 'PN',
        dialCode: '+64',
        flag: '/images/flags/pn.png',
        name: 'Pitcairn Islands',
    },
    {
        code: 'PL',
        dialCode: '+48',
        flag: '/images/flags/pl.png',
        name: 'Poland',
    },
    {
        code: 'PT',
        dialCode: '+351',
        flag: '/images/flags/pt.png',
        name: 'Portugal',
    },
    {
        code: 'PR',
        dialCode: '+1787',
        flag: '/images/flags/pr.png',
        name: 'Puerto Rico',
    },
    {
        code: 'QA',
        dialCode: '+974',
        flag: '/images/flags/qa.png',
        name: 'Qatar',
    },
    {
        code: 'CG',
        dialCode: '+242',
        flag: '/images/flags/cg.png',
        name: 'Republic of the Congo',
    },
    {
        code: 'RO',
        dialCode: '+40',
        flag: '/images/flags/ro.png',
        name: 'Romania',
    },
    {
        code: 'RU',
        dialCode: '+7',
        flag: '/images/flags/ru.png',
        name: 'Russia',
    },
    {
        code: 'RW',
        dialCode: '+250',
        flag: '/images/flags/rw.png',
        name: 'Rwanda',
    },
    {
        code: 'RE',
        dialCode: '+262',
        flag: '/images/flags/re.png',
        name: 'Reunion',
    },
    {
        code: 'BL',
        dialCode: '+590',
        flag: '/images/flags/bl.png',
        name: 'Saint Barthelemy',
    },
    {
        code: 'KN',
        dialCode: '+1869',
        flag: '/images/flags/kn.png',
        name: 'Saint Kitts and Nevis',
    },
    {
        code: 'LC',
        dialCode: '+1758',
        flag: '/images/flags/lc.png',
        name: 'Saint Lucia',
    },
    {
        code: 'MF',
        dialCode: '+590',
        flag: '/images/flags/mf.png',
        name: 'Saint Martin',
    },
    {
        code: 'PM',
        dialCode: '+508',
        flag: '/images/flags/pm.png',
        name: 'Saint Pierre and Miquelon',
    },
    {
        code: 'VC',
        dialCode: '+1784',
        flag: '/images/flags/vc.png',
        name: 'Saint Vincent and the Grenadines',
    },
    {
        code: 'WS',
        dialCode: '+685',
        flag: '/images/flags/ws.png',
        name: 'Samoa',
    },
    {
        code: 'SM',
        dialCode: '+378',
        flag: '/images/flags/sm.png',
        name: 'San Marino',
    },
    {
        code: 'SA',
        dialCode: '+966',
        flag: '/images/flags/sa.png',
        name: 'Saudi Arabia',
    },
    {
        code: 'SN',
        dialCode: '+221',
        flag: '/images/flags/sn.png',
        name: 'Senegal',
    },
    {
        code: 'RS',
        dialCode: '+381',
        flag: '/images/flags/rs.png',
        name: 'Serbia',
    },
    {
        code: 'SC',
        dialCode: '+248',
        flag: '/images/flags/sc.png',
        name: 'Seychelles',
    },
    {
        code: 'SL',
        dialCode: '+232',
        flag: '/images/flags/sl.png',
        name: 'Sierra Leone',
    },
    {
        code: 'SG',
        dialCode: '+65',
        flag: '/images/flags/sg.png',
        name: 'Singapore',
    },
    {
        code: 'SX',
        dialCode: '+1721',
        flag: '/images/flags/sx.png',
        name: 'Sint Maarten',
    },
    {
        code: 'SK',
        dialCode: '+421',
        flag: '/images/flags/sk.png',
        name: 'Slovakia',
    },
    {
        code: 'SI',
        dialCode: '+386',
        flag: '/images/flags/si.png',
        name: 'Slovenia',
    },
    {
        code: 'SB',
        dialCode: '+677',
        flag: '/images/flags/sb.png',
        name: 'Solomon Islands',
    },
    {
        code: 'SO',
        dialCode: '+252',
        flag: '/images/flags/so.png',
        name: 'Somalia',
    },
    {
        code: 'ZA',
        dialCode: '+27',
        flag: '/images/flags/za.png',
        name: 'South Africa',
    },
    {
        code: 'GS',
        dialCode: '+500',
        flag: '/images/flags/gs.png',
        name: 'South Georgia',
    },
    {
        code: 'KR',
        dialCode: '+82',
        flag: '/images/flags/kr.png',
        name: 'South Korea',
    },
    {
        code: 'SS',
        dialCode: '+211',
        flag: '/images/flags/ss.png',
        name: 'South Sudan',
    },
    {
        code: 'ES',
        dialCode: '+34',
        flag: '/images/flags/es.png',
        name: 'Spain',
        highlight: true,
    },
    {
        code: 'LK',
        dialCode: '+94',
        flag: '/images/flags/lk.png',
        name: 'Sri Lanka',
    },
    {
        code: 'SD',
        dialCode: '+249',
        flag: '/images/flags/sd.png',
        name: 'Sudan',
    },
    {
        code: 'SR',
        dialCode: '+597',
        flag: '/images/flags/sr.png',
        name: 'Suriname',
    },
    {
        code: 'SJ',
        dialCode: '+4779',
        flag: '/images/flags/sj.png',
        name: 'Svalbard and Jan Mayen',
    },
    {
        code: 'SZ',
        dialCode: '+268',
        flag: '/images/flags/sz.png',
        name: 'Swaziland',
    },
    {
        code: 'SE',
        dialCode: '+46',
        flag: '/images/flags/se.png',
        name: 'Sweden',
    },
    {
        code: 'CH',
        dialCode: '+41',
        flag: '/images/flags/ch.png',
        name: 'Switzerland',
    },
    {
        code: 'SY',
        dialCode: '+963',
        flag: '/images/flags/sy.png',
        name: 'Syria',
    },
    {
        code: 'ST',
        dialCode: '+239',
        flag: '/images/flags/st.png',
        name: 'Sao Tome and Pri­ncipe',
    },
    {
        code: 'TW',
        dialCode: '+886',
        flag: '/images/flags/tw.png',
        name: 'Taiwan',
    },
    {
        code: 'TJ',
        dialCode: '+992',
        flag: '/images/flags/tj.png',
        name: 'Tajikistan',
    },
    {
        code: 'TZ',
        dialCode: '+255',
        flag: '/images/flags/tz.png',
        name: 'Tanzania',
    },
    {
        code: 'TH',
        dialCode: '+66',
        flag: '/images/flags/th.png',
        name: 'Thailand',
    },
    {
        code: 'TL',
        dialCode: '+670',
        flag: '/images/flags/tl.png',
        name: 'Timor-Leste',
    },
    {
        code: 'TG',
        dialCode: '+228',
        flag: '/images/flags/tg.png',
        name: 'Togo',
    },
    {
        code: 'TK',
        dialCode: '+690',
        flag: '/images/flags/tk.png',
        name: 'Tokelau',
    },
    {
        code: 'TO',
        dialCode: '+676',
        flag: '/images/flags/to.png',
        name: 'Tonga',
    },
    {
        code: 'TT',
        dialCode: '+1868',
        flag: '/images/flags/tt.png',
        name: 'Trinidad and Tobago',
    },
    {
        code: 'TN',
        dialCode: '+216',
        flag: '/images/flags/tn.png',
        name: 'Tunisia',
    },
    {
        code: 'TR',
        dialCode: '+90',
        flag: '/images/flags/tr.png',
        name: 'Turkey',
    },
    {
        code: 'TM',
        dialCode: '+993',
        flag: '/images/flags/tm.png',
        name: 'Turkmenistan',
    },
    {
        code: 'TC',
        dialCode: '+1649',
        flag: '/images/flags/tc.png',
        name: 'Turks and Caicos Islands',
    },
    {
        code: 'TV',
        dialCode: '+688',
        flag: '/images/flags/tv.png',
        name: 'Tuvalu',
    },
    {
        code: 'UG',
        dialCode: '+256',
        flag: '/images/flags/ug.png',
        name: 'Uganda',
    },
    {
        code: 'UA',
        dialCode: '+380',
        flag: '/images/flags/ua.png',
        name: 'Ukraine',
    },
    {
        code: 'AE',
        dialCode: '+971',
        flag: '/images/flags/ae.png',
        name: 'United Arab Emirates',
    },
    {
        code: 'GB',
        dialCode: '+44',
        flag: '/images/flags/gb.png',
        name: 'United Kingdom',
        highlight: true,
    },
    {
        code: 'US',
        dialCode: '+1',
        flag: '/images/flags/us.png',
        name: 'United States',
    },
    {
        code: 'VI',
        dialCode: '+1340',
        flag: '/images/flags/vi.png',
        name: 'United States Virgin Islands',
    },
    {
        code: 'UY',
        dialCode: '+598',
        flag: '/images/flags/uy.png',
        name: 'Uruguay',
    },
    {
        code: 'UZ',
        dialCode: '+998',
        flag: '/images/flags/uz.png',
        name: 'Uzbekistan',
    },
    {
        code: 'VU',
        dialCode: '+678',
        flag: '/images/flags/vu.png',
        name: 'Vanuatu',
    },
    {
        code: 'VA',
        dialCode: '+3906698',
        flag: '/images/flags/va.png',
        name: 'Vatican City',
    },
    {
        code: 'VE',
        dialCode: '+58',
        flag: '/images/flags/ve.png',
        name: 'Venezuela',
    },
    {
        code: 'VN',
        dialCode: '+84',
        flag: '/images/flags/vn.png',
        name: 'Vietnam',
    },
    {
        code: 'WF',
        dialCode: '+681',
        flag: '/images/flags/wf.png',
        name: 'Wallis and Futuna',
    },
    {
        code: 'EH',
        dialCode: '+212',
        flag: '/images/flags/eh.png',
        name: 'Western Sahara',
    },
    {
        code: 'YE',
        dialCode: '+967',
        flag: '/images/flags/ye.png',
        name: 'Yemen',
    },
    {
        code: 'ZM',
        dialCode: '+260',
        flag: '/images/flags/zm.png',
        name: 'Zambia',
    },
    {
        code: 'ZW',
        dialCode: '+263',
        flag: '/images/flags/zw.png',
        name: 'Zimbabwe',
    },
    {
        code: 'AX',
        dialCode: '+358',
        flag: '/images/flags/ax.png',
        name: 'Aland Islands',
    },
];

export const getHighlightedCountries = (highlightedArray: string[]) =>
    countries.filter((n) => highlightedArray.includes(n.code));

export const getCountryByCode = (code: string) => countries.find((n) => n.code === code);

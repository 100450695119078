import React from 'react';

export const WhatsAppIcon = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="19.598"
        height="19.686"
        viewBox="0 0 19.598 19.686"
    >
        <path
            d="M16.742 2.861a9.759 9.759 0 0 0-15.358 11.77L0 19.686l5.173-1.356a9.753 9.753 0 0 0 4.663 1.187 9.758 9.758 0 0 0 6.9-16.656M9.84 17.869a8.1 8.1 0 0 1-4.128-1.13l-.3-.176-3.07.8.819-2.992-.193-.307a8.111 8.111 0 1 1 6.871 3.8m4.45-6.064c-.244-.122-1.443-.712-1.666-.793s-.386-.122-.549.122-.63.793-.772.956-.284.183-.528.061a6.66 6.66 0 0 1-1.961-1.21 7.347 7.347 0 0 1-1.356-1.692c-.142-.244-.015-.376.107-.5s.244-.285.366-.427a1.663 1.663 0 0 0 .244-.407.449.449 0 0 0-.02-.427C8.092 7.364 7.6 6.164 7.4 5.676s-.4-.411-.549-.418-.3-.009-.467-.009a.9.9 0 0 0-.65.305 2.733 2.733 0 0 0-.853 2.033 4.74 4.74 0 0 0 1 2.521 10.867 10.867 0 0 0 4.165 3.68 14.043 14.043 0 0 0 1.39.513 3.344 3.344 0 0 0 1.536.1 2.511 2.511 0 0 0 1.646-1.159 2.037 2.037 0 0 0 .142-1.159c-.061-.1-.223-.163-.467-.285"
            style={{ fillRule: 'evenodd' }}
        />
    </svg>
);

import {
    detailsHighlightedNationalities,
    getHighlightedNationalities,
    nationalities,
} from 'src/app/constants/nationalities';
import { OptionsListItem, SelectOption } from 'src/view/components/FormSelect/FormSelect';
import { countries, getHighlightedCountries } from 'src/app/constants/countries';

// get dropdown input options for countries and nationalities
const getNationalityOptions = () =>
    nationalities.map((n) => ({
        label: n.name,
        value: n.code,
    }));

const getHighlightedNationalityOptions = (): SelectOption[] =>
    getHighlightedNationalities(detailsHighlightedNationalities)
        .sort()
        .map((n) => ({
            label: n.name,
            value: n.code,
        }));

export const mapNationalitiesToDropdownOptions = (): OptionsListItem[] => [
    {
        groupName: 'Most selected',
        options: getHighlightedNationalityOptions(),
    },
    {
        groupName: 'All',
        options: getNationalityOptions(),
    },
];

const getCountryOptions = (): SelectOption[] =>
    countries.map((c) => ({
        label: c.name,
        value: c.code,
    }));

const getHighlightedCountryOptions = (): SelectOption[] =>
    getHighlightedCountries(detailsHighlightedNationalities)
        .sort()
        .map((n) => ({
            label: n.name,
            value: n.code,
        }));

export const mapCountriesToDropdownOptions = (): OptionsListItem[] => [
    {
        groupName: 'Most selected',
        options: getHighlightedCountryOptions(),
    },
    {
        groupName: 'All',
        options: getCountryOptions(),
    },
];

const getHighlightedCountryDialOptions = (): SelectOption[] =>
    getHighlightedCountries(detailsHighlightedNationalities)
        .sort()
        .map((n) => ({
            label: `${n.name} ${n.dialCode}`,
            value: n.dialCode,
        }));

const getCountryDialOptions = (): SelectOption[] =>
    countries.map((c) => ({
        label: `${c.name} ${c.dialCode}`,
        value: c.dialCode,
    }));

export const mapCountriesDialDropdownOptions = (): OptionsListItem[] => [
    {
        groupName: 'Most selected',
        options: getHighlightedCountryDialOptions(),
    },
    {
        groupName: 'All',
        options: getCountryDialOptions(),
    },
];

import * as Yup from 'yup';
import moment from 'moment';
import { TFunction } from 'i18next';

export const getDateOfBirthValidation = (t: TFunction) => {
    const currentYear = moment(new Date()).year();

    return Yup.object().shape({
        day: Yup.number()
            .typeError(t('birthday_day_empty_error'))
            .required(t('birthday_day_empty_error'))
            .min(1, t('birthday_day_range_error'))
            .max(31, t('birthday_day_range_error')),
        month: Yup.number()
            .typeError(t('birthday_month_empty_error'))
            .required(t('birthday_month_empty_error'))
            .min(1, t('birthday_month_range_error'))
            .max(12, t('birthday_month_range_error')),
        year: Yup.number()
            .typeError(t('birthday_year_empty_error'))
            .required(t('birthday_year_empty_error'))
            .max(currentYear, t('birthday_year_error', { currentYear })),
    });
};

import { useState, useEffect } from 'react';
import { breakpoints } from 'src/app/constants/breakpoints';

interface Size {
    width: number | undefined;
    height: number | undefined;
}

interface UseWindowSizeType {
    windowSize: Size;
    isTablet?: boolean;
    isMobile?: boolean;
    isDesktop?: boolean;
}

const useWindowSize = (): UseWindowSizeType => {
    const [windowSize, setWindowSize] = useState<Size>({
        width: window.innerWidth,
        height: window.innerHeight,
    });

    useEffect(() => {
        function handleResize() {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }

        window.addEventListener('resize', handleResize);

        handleResize();

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const getDevices = () => {
        if (!windowSize || !windowSize.width) return;

        const isTablet =
            windowSize?.width < breakpoints.large && windowSize?.width >= breakpoints.small;
        const isMobile = windowSize?.width < breakpoints.small;
        const isDesktop = windowSize?.width >= breakpoints.large;

        return {
            windowSize,
            isTablet,
            isMobile,
            isDesktop,
        };
    };

    return { ...getDevices(), windowSize };
};

export default useWindowSize;

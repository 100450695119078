import * as React from 'react';
import { Body } from 'src/view/components/Body/Body';
import $ from './ErrorMessage.module.scss';

interface Props {
    content: string | JSX.Element;
    missingFields?: string[];
}

export default function ErrorMessage(props: Props) {
    const { content, missingFields } = props;

    return (
        <section className={$.wrap}>
            <Body className={$.message} marginTop={false} marginBottom={false}>
                {content}
            </Body>
            {missingFields && (
                <ul className={$.missingFields}>
                    {missingFields.map((field) => (
                        <li className={$.field} key={field}>
                            <Body marginTop={false} marginBottom={false}>
                                {field}
                            </Body>
                        </li>
                    ))}
                </ul>
            )}
        </section>
    );
}

import React from 'react';
import $ from './Page.module.scss';

export enum SizeEnum {
    Small,
    Normal,
    Large,
}

interface Props {
    size?: SizeEnum;
}

function VSpacer({ size }: Props) {
    const sizeClasses = {
        [SizeEnum.Small]: $.vspacerSizeSmall,
        [SizeEnum.Normal]: '',
        [SizeEnum.Large]: $.vspacerSizeLarge,
    };

    return <div className={`${$.vspacer} ${size && sizeClasses[size]}`} />;
}

export default VSpacer;

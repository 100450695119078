import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectEventDetail } from 'src/app/store/eventSlice';

/** IMPORTANT: If the user wants to book for more than 10 people, they must always call us! */
const MAX_OCCUPANCY = 10;

/** Extract info about fixed quantity increments from event details. */
export function useFixedQuantityIncrements() {
    const eventDetail = useSelector(selectEventDetail);

    return useMemo(() => {
        const tickets = eventDetail?.tickets || [];
        const fixedQuantityIncrements = tickets.map((t) => t.fixed_quantity_step);
        const isEveryIncrementLargerThanTen = fixedQuantityIncrements.every(
            (option) => option > 10
        );

        const hasFixedStepIncrement = !fixedQuantityIncrements.includes(1);
        const incrementalStepOptions = getOptionsFromFixedQuantityIncrements(
            fixedQuantityIncrements,
            MAX_OCCUPANCY
        );

        return { hasFixedStepIncrement, isEveryIncrementLargerThanTen, incrementalStepOptions };
    }, [eventDetail]);
}

export function getOptionsFromFixedQuantityIncrements(
    increments: number[],
    maxOccupancy: number
): number[] {
    if (increments.includes(1)) {
        // Returns an array of numbers from [1 to maxOccupancy]
        return Array.from({ length: maxOccupancy }, (_, index) => index + 1);
    }

    /**
     * IMPORTANT: If the user wants to book for more than 10 people, they must always call us!
     * This explains the reason for maxOccupancy being set to 10.
     *
     * Multiple fixed_quantity_step values can exist per event in the seating plans.
     * The purpose of these increment steps is to determine available booking options for the specific event.
     *
     * Example with Darts:
     *  - Tables are available only for 4 people. So, no tickets available for 1, 2, or 3 people for Darts anyway.
     *  - The user can book for 4, 8, 12 people, and so on, as long as they are available.
     *  - The fixed_quantity_step values for Darts are [4].
     *  - The dropdown should display options [4, 8, and more than 10], excluding options like 2 people.
     *  - This prevents users from displaying options of 1, 2, or 3 people for Darts, avoiding confusion about the increment of 4. Otherwise, they will see 'Tickets not available'.
     *
     * Example with Football:
     *  - VIP offers tickets in increments of 2 seats, and Standard in increments of 3 seats.
     *  - Combined array: [2, 3, 4, 6, 8, 9, 10, and more than 10].
     *  - If the user selects 2, only VIP tickets are shown. If 3, only Standard tickets are shown.
     *  - If the user selects 6 (applicable to both VIP and Standard), both options are displayed.
     *  - Now the user doesn't see options of 1, 5, 7 which are not possible for this event anyway. There are no available packages for these values.
     */

    /**
     * Per increment, exhaustively add possible options.
     * Then combine them in to one list of unique options.
     * Examples:
     *  - fixedQuantityIncrements = [2, 3] results in [2, 3, 4, 6, 8, 9, 10, and more than 10].
     *  - fixedQuantityIncrements = [3, 5] results in [3, 5, 6, 9, 10].
     *  - fixedQuantityIncrements = [5, 6] results in [5, 6, and more than 10].
     */

    const result = new Set<number>();

    for (let i = 0; i < increments.length; i++) {
        const increment = increments[i];
        let currentOption = increment;
        // Given maxOccupancy is 10,
        // if increment is 2, the set should add [2, 4, 6, 8, 10].
        // if increment is 3, the set should add [3, 6, 9].
        // if increment is 5, the set should add [5, 10].
        // if increment is 6, the set should add [6].
        while (currentOption <= maxOccupancy) {
            result.add(currentOption);
            currentOption += increment;
        }
    }

    return Array.from(result).sort((a, b) => a - b);
}

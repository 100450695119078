/* eslint-disable lines-between-class-members */
import { Facility, FacilityEntity } from './Facility';

export interface AccommodationContentEntity {
    description: string;
    facilities: FacilityEntity;
}

export class AccommodationContent {
    public readonly description: string;
    public readonly facilities: Facility;

    public constructor(content: AccommodationContentEntity) {
        this.description = content.description;
        this.facilities = new Facility(content.facilities);
    }
}

import { useEffect, useRef } from 'react';
import { getEnvironment } from 'src/app/utils/config';

export const useCookieBot = ({
    language,
    shouldInitialize,
}: {
    language: string;
    shouldInitialize: boolean;
}) => {
    const isCookiebotAdded = useRef(false);

    useEffect(() => {
        if (isCookiebotAdded.current || !shouldInitialize) return;

        const COOKIE_BOT_ID = getEnvironment('REACT_APP_COOKIEBOT_ID');

        if (!COOKIE_BOT_ID) return;

        const script = document.createElement('script');
        script.setAttribute('id', 'Cookiebot');
        script.setAttribute('src', 'https://consent.cookiebot.eu/uc.js');
        script.setAttribute('data-cbid', COOKIE_BOT_ID);
        script.setAttribute('type', 'text/javascript');
        script.setAttribute('data-culture', language);
        script.setAttribute('data-consentmode-defaults', 'disabled');
        script.setAttribute('async', 'true');

        document.head.appendChild(script);

        isCookiebotAdded.current = true;

        return () => {
            document.head.removeChild(script);
        };
    }, [language, shouldInitialize]);

    return null;
};

export interface Nationality {
    code: string;
    name: string;
    highlighted?: boolean;
}

export const nationalities: Nationality[] = [
    { code: 'AF', name: 'Afghan' },
    { code: 'AL', name: 'Albanian' },
    { code: 'DZ', name: 'Algerian' },
    { code: 'US', name: 'American' },
    { code: 'MP', name: 'American (Overseas U.S.)' },
    { code: 'VI', name: 'American (Virgin Islands)' },
    { code: 'AD', name: 'Andorran' },
    { code: 'AO', name: 'Angolan' },
    { code: 'AQ', name: 'Antarctic' },
    { code: 'AG', name: 'Antiguan' },
    { code: 'AR', name: 'Argentine' },
    { code: 'AM', name: 'Armenian' },
    { code: 'AU', name: 'Australian' },
    { code: 'CX', name: 'Australian (Christmasisland)' },
    { code: 'CC', name: 'Australian (Cocos-islands)' },
    { code: 'NF', name: 'Australian (Extern territorium)' },
    { code: 'HM', name: 'Australian (Overseas Ind. Ocean)' },
    { code: 'AT', name: 'Austrian' },
    { code: 'AZ', name: 'Azerbaijani' },
    { code: 'BS', name: 'Bahamian' },
    { code: 'BH', name: 'Bahraini' },
    { code: 'BD', name: 'Bangladeshi' },
    { code: 'BB', name: 'Barbadian' },
    { code: 'BY', name: 'Belarusian' },
    { code: 'BE', name: 'Belgisch/Belge', highlighted: true },
    { code: 'BZ', name: 'Belize' },
    { code: 'BJ', name: 'Beninese' },
    { code: 'BT', name: 'Bhutanese' },
    { code: 'BO', name: 'Bolivian' },
    { code: 'BA', name: 'Bosnian' },
    { code: 'BW', name: 'Botswanan' },
    { code: 'BR', name: 'Brazilian' },
    { code: 'GB', name: 'British', highlighted: true },
    { code: 'KY', name: 'British (British Overseas territorium)' },
    { code: 'AI', name: 'British (British Overseas)' },
    { code: 'FK', name: 'British (Falklandislands)' },
    { code: 'GI', name: 'British (Gibraltar)' },
    { code: 'BM', name: 'British (Overseas Atl. Ocean)' },
    { code: 'PN', name: 'British (Overseas)' },
    { code: 'MS', name: 'British (Overseas)' },
    { code: 'LC', name: 'British (Saint Lucia)' },
    { code: 'VG', name: 'British (Virgin Islands)' },
    { code: 'BN', name: 'Bruneian' },
    { code: 'BG', name: 'Bulgarian' },
    { code: 'BF', name: 'Burkinese' },
    { code: 'BU', name: 'Burmese' },
    { code: 'MM', name: 'Burmese' },
    { code: 'BI', name: 'Burundian' },
    { code: 'KH', name: 'Cambodian' },
    { code: 'CM', name: 'Cameroonian' },
    { code: 'CA', name: 'Canadian' },
    { code: 'CV', name: 'Cape Verdean' },
    { code: 'CF', name: 'Central-African' },
    { code: 'TD', name: 'Chadian' },
    { code: 'CL', name: 'Chilean' },
    { code: 'CN', name: 'Chinese' },
    { code: 'MO', name: 'Chinese (Macau)' },
    { code: 'CO', name: 'Colombian' },
    { code: 'KM', name: 'Comorian' },
    { code: 'CG', name: 'Congolese' },
    { code: 'CK', name: 'Cookisland' },
    { code: 'CR', name: 'Costa Rican' },
    { code: 'HR', name: 'Croatian' },
    { code: 'CU', name: 'Cuban' },
    { code: 'CY', name: 'Cypriot' },
    { code: 'CZ', name: 'Czech' },
    { code: 'DK', name: 'Danish' },
    { code: 'DJ', name: 'Djiboutian' },
    { code: 'DO', name: 'Dominican' },
    { code: 'NL', name: 'Nederlands', highlighted: true },
    { code: 'AW', name: 'Dutch (Aruba)' },
    { code: 'AN', name: 'Dutch (Netherlands Antilles)' },
    { code: 'TP', name: 'East-Timorese' },
    { code: 'EC', name: 'Ecuadorean' },
    { code: 'EG', name: 'Egyptian' },
    { code: 'GQ', name: 'Equatorial Guinean' },
    { code: 'ER', name: 'Eritrean' },
    { code: 'EE', name: 'Estonian' },
    { code: 'ET', name: 'Ethiopian' },
    { code: 'FO', name: 'Faroese' },
    { code: 'FJ', name: 'Fijian' },
    { code: 'PH', name: 'Filipijnse' },
    { code: 'FI', name: 'Finnish' },
    { code: 'FR', name: 'Français', highlighted: true },
    { code: 'GF', name: 'Français (French-Guyana)' },
    { code: 'PF', name: 'Français (French-Polynesia)' },
    { code: 'GP', name: 'Français (Guadeloupe)' },
    { code: 'MQ', name: 'Français (Martinique)' },
    { code: 'RE', name: 'Français (Overseas)' },
    { code: 'NC', name: 'Français (Overseas)' },
    { code: 'GA', name: 'Gabonese' },
    { code: 'GM', name: 'Gambian' },
    { code: 'GE', name: 'Georgian' },
    { code: 'DE', name: 'Deutsch', highlighted: true },
    { code: 'GH', name: 'Ghanaian' },
    { code: 'GR', name: 'Greek' },
    { code: 'GL', name: 'Greenlandic' },
    { code: 'GD', name: 'Grenadian' },
    { code: 'GU', name: 'Guamanian' },
    { code: 'GT', name: 'Guatemalan' },
    { code: 'GG', name: 'Guernseyse' },
    { code: 'GW', name: 'Guinea-Bissau' },
    { code: 'GN', name: 'Guinean' },
    { code: 'GY', name: 'Guyanese' },
    { code: 'HT', name: 'Haitian' },
    { code: 'HN', name: 'Honduran' },
    { code: 'HK', name: 'Hongkong' },
    { code: 'HU', name: 'Hungarian' },
    { code: 'IS', name: 'Icelandic' },
    { code: 'IN', name: 'Indian' },
    { code: 'ID', name: 'Indonesian' },
    { code: 'IR', name: 'Iranian' },
    { code: 'IQ', name: 'Iraqi' },
    { code: 'IE', name: 'Irish' },
    { code: 'IL', name: 'Israeli' },
    { code: 'IT', name: 'Italian' },
    { code: 'CI', name: 'Ivorian' },
    { code: 'JM', name: 'Jamaican' },
    { code: 'JP', name: 'Japanese' },
    { code: 'JE', name: 'Jerseyse' },
    { code: 'JO', name: 'Jordan' },
    { code: 'KZ', name: 'Kazakh' },
    { code: 'KE', name: 'Kenyan' },
    { code: 'KI', name: 'Kiribati' },
    { code: 'KO', name: 'Kosovo' },
    { code: 'KW', name: 'Kuwaiti' },
    { code: 'KG', name: 'Kyrgyz' },
    { code: 'LA', name: 'Laotian' },
    { code: 'LV', name: 'Latvian' },
    { code: 'LB', name: 'Lebanese' },
    { code: 'LS', name: 'Lesotho' },
    { code: 'LR', name: 'Liberian' },
    { code: 'LY', name: 'Libyan' },
    { code: 'LI', name: 'Liechtenstein' },
    { code: 'LT', name: 'Lithuanian' },
    { code: 'LU', name: 'Luxembourg' },
    { code: 'MK', name: 'Macedonian' },
    { code: 'MG', name: 'Madagascan' },
    { code: 'MW', name: 'Malawian' },
    { code: 'MY', name: 'Malaysian' },
    { code: 'MV', name: 'Maldivian' },
    { code: 'ML', name: 'Malian' },
    { code: 'MT', name: 'Maltese' },
    { code: 'MH', name: 'Marshall Islander' },
    { code: 'MR', name: 'Mauritanian' },
    { code: 'MU', name: 'Mauritian' },
    { code: 'MX', name: 'Mexican' },
    { code: 'FM', name: 'Micronesian' },
    { code: 'MD', name: 'Moldovan' },
    { code: 'MC', name: 'Monegasque' },
    { code: 'MN', name: 'Mongolian' },
    { code: 'ME', name: 'Montenegrin' },
    { code: 'MA', name: 'Moroccan' },
    { code: 'MZ', name: 'Mozambican' },
    { code: 'NA', name: 'Namibian' },
    { code: 'NR', name: 'Nauru Tibetan' },
    { code: 'NP', name: 'Nepalese' },
    { code: 'NZ', name: 'New Zealand' },
    { code: 'TK', name: 'New Zealand (Overseas)' },
    { code: 'NI', name: 'Nicaraguan' },
    { code: 'NG', name: 'Nigerian' },
    { code: 'NE', name: 'Nigerien' },
    { code: 'NU', name: 'Niuese' },
    { code: 'KP', name: 'North Korean' },
    { code: 'NO', name: 'Norwegian' },
    { code: 'BV', name: 'Norwegian (Bouvet-island)' },
    { code: 'SJ', name: 'Norwegian (Spitsbergen en Jan Mayen)' },
    { code: 'OM', name: 'Omani' },
    { code: 'PK', name: 'Pakistani' },
    { code: 'PW', name: 'Palau Tibetan' },
    { code: 'PS', name: 'Palestinian' },
    { code: 'PA', name: 'Panamanian' },
    { code: 'PG', name: 'Papua New Guinea' },
    { code: 'PY', name: 'Paraguayan' },
    { code: 'PE', name: 'Peruvian' },
    { code: 'PL', name: 'Polish' },
    { code: 'PT', name: 'Portuguese' },
    { code: 'PR', name: 'Puerto Rican' },
    { code: 'QA', name: 'Qatari' },
    { code: 'RO', name: 'Romanian' },
    { code: 'RU', name: 'Russian' },
    { code: 'RW', name: 'Rwandan' },
    { code: 'KN', name: 'Saint Kitts-Nevis' },
    { code: 'VC', name: 'Saint Vincent and Grenadine' },
    { code: 'SV', name: 'Salvadorean' },
    { code: 'WS', name: 'Samoan' },
    { code: 'SM', name: 'San Marinese' },
    { code: 'ST', name: 'Sao Tomean and Principean' },
    { code: 'SA', name: 'Saudi Arabian' },
    { code: 'SN', name: 'Senegalese' },
    { code: 'RS', name: 'Serbian' },
    { code: 'SC', name: 'Seychellois' },
    { code: 'SL', name: 'Sierra Leonian' },
    { code: 'SG', name: 'Singaporean' },
    { code: 'SK', name: 'Slovak' },
    { code: 'SI', name: 'Slovenian' },
    { code: 'SB', name: 'Solomon Islander' },
    { code: 'SO', name: 'Somali' },
    { code: 'ZA', name: 'South-African' },
    { code: 'KR', name: 'South-Korean' },
    { code: 'ES', name: 'Spanish', highlighted: true },
    { code: 'LK', name: 'Sri Lankan' },
    { code: 'SD', name: 'Sudanese' },
    { code: 'SR', name: 'Surinamese' },
    { code: 'SZ', name: 'Swazi' },
    { code: 'SE', name: 'Swedish' },
    { code: 'CH', name: 'Swiss' },
    { code: 'SY', name: 'Syrian' },
    { code: 'TJ', name: 'Tadjik' },
    { code: 'TW', name: 'Taiwanese' },
    { code: 'TZ', name: 'Tanzanian' },
    { code: 'TH', name: 'Thai' },
    { code: 'TG', name: 'Togolese' },
    { code: 'TO', name: 'Tongan' },
    { code: 'TT', name: 'Trinidadian and Tobagan' },
    { code: 'TN', name: 'Tunisian' },
    { code: 'TR', name: 'Turkish' },
    { code: 'TM', name: 'Turkmen' },
    { code: 'TV', name: 'Tuvaluan' },
    { code: 'UG', name: 'Ugandan' },
    { code: 'UA', name: 'Ukrainian' },
    { code: 'UY', name: 'Uruguayan' },
    { code: 'UZ', name: 'Uzbek' },
    { code: 'AE', name: 'United Arab Emirates nationality' },
    { code: 'VU', name: 'Vanuatuan' },
    { code: 'VA', name: 'Vatican' },
    { code: 'VE', name: 'Venezuelan' },
    { code: 'VN', name: 'Vietnamese' },
    { code: 'EH', name: 'Western Sahara' },
    { code: 'YE', name: 'Yemeni' },
    { code: 'YU', name: 'Yugoslav' },
    { code: 'ZR', name: 'Zaïrean' },
    { code: 'ZM', name: 'Zambian' },
    { code: 'ZN', name: 'Zanzibarese' },
    { code: 'ZW', name: 'Zimbabwean' },
];

export const getHighlightedNationalities = (highlightedArray: string[]) =>
    nationalities.filter((n) => highlightedArray.includes(n.code));

export const getNationalityByCode = (code: string) => nationalities.find((n) => n.code === code);

export const detailsHighlightedNationalities = nationalities
    .filter((n) => n.highlighted)
    .map((nationality) => nationality.code);

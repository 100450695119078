import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import { breakpoints } from 'src/app/constants/breakpoints';
import { Body } from 'src/view/components/Body/Body';
import Button from 'src/view/components/Button/Button';
import { Heading } from 'src/view/components/Heading/Heading';
import { Modal, ModalBody, ModalProps } from 'src/view/components/Modal/Modal';

const useStyles = makeStyles((t) => ({
    modalContent: {
        display: 'flex',
        [t.breakpoints.down(breakpoints.large)]: {
            flexDirection: 'column',
        },
    },
    image: {
        maxHeight: 460,
        [t.breakpoints.down(breakpoints.large)]: {
            maxHeight: 350,
        },
        overflow: 'hidden',
        flex: 1,
        '& img': {
            height: '100%',
            width: '100%',
            objectFit: 'cover',
        },
    },
    text: {
        flex: 1,
    },
    textContent: {
        height: '100%',
        padding: '0 2rem 2rem 2rem',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        [t.breakpoints.down(breakpoints.large)]: {
            padding: '0 1rem 1rem 1rem',
        },
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: '8rem',
        [t.breakpoints.down(breakpoints.large)]: {
            marginTop: '4rem',
        },
    },
    modal: {
        padding: 0,
    },
}));

interface TicketsNotAvailableModalProps extends Omit<ModalProps, 'children'> {
    onClick: () => void;
}

export const TicketsNotAvailableModal = ({
    open,
    onClose,
    onClick,
}: TicketsNotAvailableModalProps) => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <Modal onClose={onClose} open={open} modalDialogClassName={classes.modal} smallModal>
            <ModalBody dataCy="ticket-out-of-stock-modal">
                <div className={classes.modalContent}>
                    <div className={classes.image}>
                        <img src="/images/failed_75.png" />
                    </div>
                    <div className={classes.text}>
                        <div className={classes.textContent}>
                            <div>
                                <Heading variant="h1" marginBottom={false}>
                                    {t('tickets_not_available_error_title')}
                                </Heading>
                                <Body>{t('tickets_not_available_error_description')}</Body>
                            </div>

                            <div className={classes.buttonContainer}>
                                <Button
                                    onClick={onClick}
                                    text={t('tickets_not_available_error_change_category')}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </ModalBody>
        </Modal>
    );
};

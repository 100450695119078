import { type AppLocale } from 'src/app/constants/appLocale';

export default class MoneyFormatter {
    private defaultLocale: string;
    private defaultCurrency: string;
    private defaultNumberFormat: Intl.NumberFormat;

    public constructor(defaultLocale: AppLocale, defaultCurrency: string) {
        const [language, country] = defaultLocale.split('-');
        // de => de-DE
        this.defaultLocale = [language, country || language.toUpperCase()].join('-');
        this.defaultCurrency = defaultCurrency;

        this.defaultNumberFormat = this.makeNumberFormat(this.defaultCurrency);
    }

    private makeNumberFormat(currency: string): Intl.NumberFormat {
        return new Intl.NumberFormat(this.defaultLocale, {
            style: 'currency',
            currency,
            useGrouping: false,
        });
    }

    private isValidMoneyString(value: string): boolean {
        const regex = new RegExp('^-?(\\d*\\.?)?\\d{1,2}$', 'g');

        return regex.test(value);
    }

    public parseMoneyString(value: string): number {
        if (value.includes(',')) {
            throw new Error('Unsupported money string, it should not include a comma.');
        }

        if (!this.isValidMoneyString(value)) {
            throw new Error('Unsupported money string, it should look like this: 123.12');
        }

        return Number(value);
    }

    public format(value: number, currency?: string): string {
        const format = currency ? this.makeNumberFormat(currency) : this.defaultNumberFormat;

        return format.format(value);
    }

    public formatFromString(value: string, currency?: string): string {
        return this.format(this.parseMoneyString(value), currency);
    }
}

import { Divider } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock';
import classnames from 'classnames';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { colors } from 'src/app/constants/theme';
import { blockFadeStyles } from 'src/app/styles/blockFadeStyles';
import { ImageSlider } from 'src/app/views/ImageSlider';
import { Accommodation } from 'src/data/models/Accommodation';
import { EventDetail } from 'src/data/models/EventDetail';
import { fetchAccommodationDetail } from 'src/data/services/accommodations';
import { formatMoney } from 'src/data/services/formatting';
import { CutleryIcon } from 'src/images/icons/CutleryIcon';
import { Price } from 'src/view/components';
import { Body } from 'src/view/components/Body/Body';
import { Heading } from 'src/view/components/Heading/Heading';
import { ReadMoreButton } from 'src/view/components/ReadMoreButton/ReadMoreButton';
import { CloseButton, Map, Stars } from 'src/view/components/index';
import IconLabel, { IconLabelType } from '../IconLabel/IconLabel';
import Spinner from '../Spinner';
import $ from './HotelDetail.module.scss';

const useStyles = makeStyles((theme) => ({
    detailText: blockFadeStyles(111, 42),
    facilitiesContainer: blockFadeStyles(140, 42),
    readMore: {
        marginTop: theme.spacing(1),
        fontWeight: 400,
    },
    legendContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: theme.spacing(2),
    },
    legend: {
        background: colors.backgroundPrimary,
        padding: 10,
        display: 'flex',
        alignItems: 'center',
    },
    swiper: {
        background: colors.backgroundPrimary,
    },
}));

interface HotelDetailProps extends WithTranslation {
    eventDetail: EventDetail;
    accommodation?: Accommodation;
    breakfast?: boolean;
    onClick: () => void;
    price: string;
}

const HotelDetail = ({
    eventDetail,
    accommodation,
    onClick,
    t,
    price,
    breakfast = false,
}: HotelDetailProps) => {
    const classes = useStyles();
    const [accommodationDetail, setAccommmodationDetail] = useState<Accommodation>();
    const [isLoading, setLoading] = useState<boolean>(false);
    const [images, setImages] = useState<string[]>([]);
    const [image, setImage] = useState<string>('');
    const [error, setError] = useState<Error | null>(null);
    const [showDescription, setShowDescription] = useState(false);
    const [showFacilities, setShowFacilities] = useState(false);

    const rootRef = React.createRef<HTMLDivElement>();

    useEffect(() => {
        if (!accommodation) {
            return;
        }

        disableBodyScroll(rootRef.current);
        setLoading(true);

        fetchAccommodationDetail(accommodation.id)
            .then((res) => {
                setAccommmodationDetail(res);

                if (res.images && res.images.length > 0) {
                    setImages(res.images.map((img) => img.medium));
                }

                if (accommodation.image) {
                    setImage(accommodation.image);
                } else {
                    setImage('/images/no-hotel-image.jpg');
                }
            })
            .catch((err) => setError(err))
            .finally(() => setLoading(false));
    }, [accommodation]);

    if (!isLoading && (!accommodation || !accommodationDetail)) {
        return null;
    }

    function handleSetup() {
        clearAllBodyScrollLocks();
        onClick();
    }

    function getFacilityValue(value) {
        const timeRegex = new RegExp(/(\d{1,2}:\d{1,2}:\d{1,2})/);
        const isTime = timeRegex.test(value);

        if (isTime) {
            return moment(value, 'HH:mm:ss').format('HH:mm');
        }

        return value;
    }

    function renderFacility(facility: { [id: string]: string | boolean | null }) {
        if (!facility.value) {
            return null;
        }

        return (
            <li key={`facility${facility.name}`}>
                <IconLabel type={IconLabelType.BlueCheckmark}>
                    <span className={$.blackText}>{facility.name}</span>
                    {facility.value !== true && (
                        <span className={$.lightText}>{getFacilityValue(facility.value)}</span>
                    )}
                </IconLabel>
            </li>
        );
    }

    const { catering, general, meals, things } = accommodationDetail?.content?.facilities || {};
    const { description } = accommodationDetail?.content || {};

    const hasFacilities =
        (catering && catering.length > 0) ||
        (general && general.length > 0) ||
        (meals && meals.length > 0) ||
        (things && things.length > 0);

    return (
        <React.Fragment>
            <button type="button" className={$.background} onClick={handleSetup} />
            <article className={$.roomLayout} ref={rootRef}>
                <div className={$.content}>
                    {isLoading && (
                        <div className={$.spinnerWrap}>
                            <Spinner color="#222222" size={25} />
                        </div>
                    )}

                    {!isLoading && !error && accommodation && accommodationDetail && (
                        <>
                            <div className={$.headerContainer}>
                                <div className={$.closeButtonHotel}>
                                    <CloseButton onClick={handleSetup} />
                                </div>

                                <div className={$.headerDetailsContainer}>
                                    <div className={$.title}>
                                        <Heading
                                            marginBottom={false}
                                            variant="h2"
                                            className={$.header}
                                            capitalize
                                        >
                                            {accommodationDetail.name}
                                        </Heading>

                                        {accommodation.stars !== 0 && (
                                            <Stars stars={accommodation.stars} big />
                                        )}
                                    </div>

                                    <div className={$.verticalDivider}></div>

                                    <div className={$.price}>
                                        <div className={$.priceText}>
                                            <Price
                                                price={formatMoney(price, {
                                                    hideZeroDecimals: true,
                                                })}
                                                bold
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <ImageSlider
                                images={images}
                                enableThumbnails
                                containerClassName={classes.swiper}
                                maxHeight={300}
                                thumbNailsMaxHeight={100}
                            />

                            <div className={$.modalBody}>
                                <div className={$.distanceBreakfastWrapper}>
                                    <div>
                                        <Body className={$.distance} marginBottom={false}>
                                            {accommodation.distanceToCityCenter}{' '}
                                            {t('hotel_tocentre')}
                                        </Body>
                                        <Body className={$.distance} marginTop={false}>
                                            {accommodation.distanceToVenue} {t('hotel_tostadium')}
                                        </Body>
                                    </div>
                                    <div>
                                        {breakfast &&
                                            accommodation.availability?.breakfastPossible && (
                                                <Body className={$.breakfast} marginBottom={false}>
                                                    <CutleryIcon /> {t('hotel_includesbreakfast')}
                                                </Body>
                                            )}
                                        {!breakfast &&
                                            accommodation.availability?.breakfastPossible &&
                                            accommodation.availability.cheapest
                                                .breakfastIncluded && (
                                                <Body marginTop={false}>
                                                    <CutleryIcon /> {t('hotel_includesbreakfast')}
                                                </Body>
                                            )}
                                    </div>
                                </div>
                                {description && (
                                    <>
                                        <div
                                            className={classnames(
                                                $.detailText,
                                                !showDescription && classes.detailText
                                            )}
                                        >
                                            <Body>{description}</Body>
                                        </div>
                                        <div className={classes.readMore}>
                                            <ReadMoreButton
                                                text={
                                                    showDescription
                                                        ? t('read_less')
                                                        : t('read_more')
                                                }
                                                onClick={() => setShowDescription(!showDescription)}
                                                variant="underline"
                                            />
                                        </div>

                                        <Divider />
                                    </>
                                )}

                                {hasFacilities && (
                                    <>
                                        <div>
                                            <Heading variant="h3" capitalize marginTop={false}>
                                                {t('hoteldetail_facilities')}
                                            </Heading>
                                            <div
                                                className={classnames(
                                                    !showFacilities && classes.facilitiesContainer,
                                                    $.features
                                                )}
                                            >
                                                {general && general.length > 0 && (
                                                    <ul>
                                                        {general.map((name) =>
                                                            renderFacility(name)
                                                        )}
                                                    </ul>
                                                )}
                                                <div>
                                                    {things && things.length > 0 && (
                                                        <ul>
                                                            {things.map((name) =>
                                                                renderFacility(name)
                                                            )}
                                                        </ul>
                                                    )}

                                                    {meals && meals.length > 0 && (
                                                        <ul>
                                                            {meals.map((name) =>
                                                                renderFacility(name)
                                                            )}
                                                        </ul>
                                                    )}

                                                    {catering && catering.length > 0 && (
                                                        <ul>
                                                            {catering.map((name) =>
                                                                renderFacility(name)
                                                            )}
                                                        </ul>
                                                    )}
                                                </div>
                                            </div>
                                            <div className={classes.readMore}>
                                                <ReadMoreButton
                                                    text={
                                                        showFacilities
                                                            ? t('show_less_facilities')
                                                            : t('show_all_facilities')
                                                    }
                                                    onClick={() =>
                                                        setShowFacilities(!showFacilities)
                                                    }
                                                    variant="underline"
                                                />
                                            </div>
                                        </div>

                                        <Divider />
                                    </>
                                )}

                                <div>
                                    <Heading variant="h3" capitalize marginTop={false}>
                                        {t('hotel_details_location')}
                                    </Heading>
                                    <Map
                                        accomodations={[accommodation]}
                                        eventDetail={eventDetail}
                                        height={377}
                                    />
                                    <div className={classes.legendContainer}>
                                        <div>
                                            <Body
                                                className={$.distance}
                                                marginBottom={false}
                                                marginTop={false}
                                            >
                                                {accommodation.distanceToCityCenter}{' '}
                                                {t('hotel_tocentre')}
                                            </Body>
                                            <Body
                                                className={$.distance}
                                                marginTop={false}
                                                marginBottom={false}
                                            >
                                                {accommodation.distanceToVenue}{' '}
                                                {t('hotel_tostadium')}
                                            </Body>
                                        </div>
                                        <div className={classes.legend}>
                                            <span className={$.legendIcon} /> {t('city_centre')}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </article>
        </React.Fragment>
    );
};

export default withTranslation()(HotelDetail);

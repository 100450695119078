import { useEffect, useRef } from 'react';
import { isInE2ETestingEnvironment } from '../utils/utils';

const WEST_HAM_TICKETS_CHECKOUT_CONTAINER = 'https://sst.westhamtickets.com/tm-container';
/**  Also counts as fallback for all other domains */
const P1_TRAVEL_CHECKOUT_CONTAINER = 'https://sst.p1travel.com/tm-container';

/** Perform a side effect that initializes Google Tag Manager (aka GTM). */
export function useTagManager({ shouldInitialize }: { shouldInitialize: boolean }) {
    const hasInitializedGtm = useRef(false);

    useEffect(() => {
        if (isInE2ETestingEnvironment || hasInitializedGtm.current || !shouldInitialize) return;

        const gtmContainerSrc = window.location.hostname.includes('westhamtickets')
            ? WEST_HAM_TICKETS_CHECKOUT_CONTAINER
            : P1_TRAVEL_CHECKOUT_CONTAINER;

        const script = document.createElement('script');
        script.setAttribute('data-cookieconsent', 'ignore');
        script.text = `
          (function(w,d,s,l){
            w[l]=w[l]||[];
            w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});
            var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';
            j.async=true;
            j.src='${gtmContainerSrc}';
            f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer');
        `;

        const head = document.head || document.getElementsByTagName('head')[0];
        head.insertBefore(script, head.firstChild);
        hasInitializedGtm.current = true;

        return () => {
            // Cleanup: Remove the script when the component unmounts
            document.head.removeChild(script);
        };
    }, [hasInitializedGtm, shouldInitialize]);
}

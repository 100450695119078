import { getCountryByCode } from 'src/app/constants/countries';
import { getAppLocale } from '../hooks/use-locale';

export const getTranslatedCountryByCode = (code: string) => {
    const locale = getAppLocale();
    if (!locale) return;

    const countryCode = getCountryByCode(code)?.code;
    const countryName = getCountryByCode(code)?.name;

    if (!countryCode) return countryName;

    return new Intl.DisplayNames(locale, {
        type: 'region',
    }).of(countryCode);
};

import React, { PropsWithChildren } from 'react';
import classnames from 'classnames';
import $ from './Card.module.scss';

export default ({
    children,
    bottomDivider = false,
    topDivider = false,
}: PropsWithChildren<{
    bottomDivider?: boolean;
    topDivider?: boolean;
}>) => (
    <div
        className={classnames([
            $.body,
            bottomDivider && $.hasBottomDivider,
            topDivider && $.hasTopDivider,
        ])}
    >
        {children}
    </div>
);

import { type AppLocale } from 'src/app/constants/appLocale';

/* eslint-disable lines-between-class-members */
export interface ConfigEntity {
    is_b2b: boolean;
    currency: string;
    /** Locale but underline-separated, e.g. en_US. */
    locale: string;
    website_url: string | null;
    websites: string[];
    payment: {
        gateways: string[];
        gateway_issuers: {
            CREDITCARD: string[];
            IDEAL: string[];
        };
    };
    gtm_id: string | null;
    gtm_auth: string | null;
    gtm_preview: string | null;
    logo: string | null;
    name: string | null;
    live_chat_license_code: string | null;
    trustpilot_key: string | null;
    robin_chat_api_key: string | null;
    terms_and_conditions_url: string | null;
}

export class Config {
    public readonly currency: string;
    public readonly locale: AppLocale;
    public readonly websiteUrl: string | null;
    public readonly websites: string[];
    public readonly payment: {
        gateways: string[];
        gatewayIssuers: {
            creditCard: string[];
            ideal: string[];
        };
    };
    public readonly gtmId: string | null;
    public readonly gtmAuth: string | null;
    public readonly gtmPreview: string | null;

    public readonly name: string;
    public readonly liveChatLicenseCode: number | null;
    public readonly trustpilotBusinessId: string | null;
    public readonly robinChatApiKey: string | null;
    public readonly b2bAllowed: boolean;
    public readonly termsAndConditionsUrl: string | null;

    public constructor(config: ConfigEntity) {
        this.currency = config.currency;
        this.locale = config.locale.replace('_', '-') as AppLocale;
        this.name = config.name ? config.name : 'Travelflow';
        this.b2bAllowed = config.is_b2b;
        this.termsAndConditionsUrl = config.terms_and_conditions_url;

        if (config.websites) {
            this.websiteUrl = config.websites[0] ?? null;
        } else {
            this.websiteUrl = config.website_url;
        }

        this.websites = config.websites ?? [];
        this.payment = {
            gateways: config.payment.gateways,
            gatewayIssuers: {
                creditCard: config.payment.gateway_issuers.CREDITCARD,
                ideal: config.payment.gateway_issuers.IDEAL,
            },
        };

        this.gtmId = config.gtm_id;
        this.gtmAuth = config.gtm_auth;
        this.gtmPreview = config.gtm_preview;

        this.liveChatLicenseCode = config.live_chat_license_code
            ? parseInt(config.live_chat_license_code)
            : null;

        this.trustpilotBusinessId = config.trustpilot_key;
        this.robinChatApiKey = config.robin_chat_api_key;
    }
}

import React from 'react';
import ChatService from 'src/data/services/ChatService';
import { useExternalScript } from 'src/app/hooks/UseExternalScript';
import useWindowSize from 'src/app/hooks/UseWindowSize';
interface RobinChatProps {
    apiKey: string;
}

export default function RobinChat({ apiKey }: RobinChatProps) {
    const { isTablet } = useWindowSize();
    useExternalScript(`https://selfservice.robinhq.com/external/robin/${apiKey}.js`);

    ChatService.init({
        hideTab: isTablet ? true : false,
        mobileHideTab: true,
    });

    return null;
}

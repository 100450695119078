import React from 'react';
import { BaseComponent } from 'src/app/interfaces/BaseComponent';
import classnames, { Argument } from 'classnames';
import $ from './Body.module.scss';

export interface BodyProps extends BaseComponent {
    marginTop?: boolean;
    marginBottom?: boolean;
    small?: boolean;
    grey?: boolean;
    align?: 'left' | 'center' | 'right';
    fontWeight?: 'normal' | 'medium' | 'bold';
    inline?: boolean;
}

export const Body = ({
    children,
    className,
    style,
    marginTop = true,
    marginBottom = true,
    small = false,
    grey = false,
    align = 'left',
    fontWeight = 'normal',
    inline,
    dataCy,
}: BodyProps) => {
    const extraClasses: Argument = {
        [`${className}`]: !!className,
        [$.noMarginTop]: !marginTop,
        [$.noMarginBottom]: !marginBottom,
        [$.greyText]: grey,
        [$.alignLeft]: align === 'left',
        [$.alignCenter]: align === 'center',
        [$.alignRight]: align === 'right',
        [$.fontWeightMedium]: fontWeight === 'medium',
        [$.fontWeightBold]: fontWeight === 'bold',
    };

    if (inline) {
        return (
            <span
                className={classnames($.body, small && $.bodySmall, extraClasses)}
                data-cy={dataCy}
            >
                {children}
            </span>
        );
    }

    return (
        <p
            className={classnames($.body, small && $.bodySmall, extraClasses)}
            style={style}
            data-cy={dataCy}
        >
            {children}
        </p>
    );
};

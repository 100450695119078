import {
    Action,
    AnyAction,
    combineReducers,
    configureStore,
    ThunkAction,
    ThunkDispatch,
} from '@reduxjs/toolkit';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { createMigrate, createTransform, persistReducer, persistStore } from 'redux-persist';
import { createFilter } from 'redux-persist-transform-filter';
import autoMergeLevel2 from 'redux-persist/es/stateReconciler/autoMergeLevel2';
import { MigrationManifest, PersistConfig } from 'redux-persist/es/types';
import storage from 'redux-persist/lib/storage';
import { appConstants } from 'src/app/constants/app';
import appReducer, { AppState } from 'src/app/store/appSlice';
import eventReducer, {
    AccommodationStateStatus,
    EventState,
    EventStateStatus,
} from 'src/app/store/eventSlice';
import orderReducer, {
    AvailabilityStatusEnum,
    DiscountStatusEnum,
    OrderState,
    OrderStatusEnum,
} from 'src/app/store/orderSlice';
import travellerDataReducer, {
    travellerDataInitialState,
    TravellerDataState,
} from 'src/app/store/TravellerDataSlice';

const rootReducer = combineReducers({
    app: appReducer,
    event: eventReducer,
    order: orderReducer,
    travellerData: travellerDataReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

const appSlicePersistFilter = createFilter('app', ['session', 'browserSession', 'b2bMode']);

/**
 * This transformer will clear the app.browserSession state when there's no browserSession found,
 * which will happen when the user has closed the browser / tab.
 */
const appSliceBrowserSessionTransformer = createTransform(
    null,
    (outboundState: AppState, key) => {
        if (sessionStorage.getItem('has_session') !== 'y') {
            return { ...outboundState, browserSession: {} };
        }

        return outboundState;
    },
    { whitelist: ['app'] }
);

const travellerDataTransformer = createTransform(
    null,
    (outBoundState: TravellerDataState, _) => {
        const { updatedAt } = outBoundState;

        if (
            !updatedAt ||
            moment().diff(updatedAt, 'minutes') >= appConstants.travellerDataExpirationMinutes
        )
            return travellerDataInitialState;

        return outBoundState;
    },
    { whitelist: ['travellerData'] }
);

// Set the order, availability and discount fetch status to the correct status when state is hydrated from the persist storage
// otherwise app can go into infinite loading states
const orderTransform = createTransform(
    (inboundState: OrderState, key) => {
        const getStatus = () => {
            // check if the status is loading
            if (
                inboundState.status === OrderStatusEnum.FETCH_CREATE ||
                inboundState.status === OrderStatusEnum.FETCH_PREFERENCES_UPDATE ||
                inboundState.status === OrderStatusEnum.FETCH_UPDATE
            ) {
                if (inboundState.orderEntity) return OrderStatusEnum.OK;
                return OrderStatusEnum.EMPTY;
            }

            // if status is not equal to a loading state, just return the status from local storage (because this can also be an error state)
            return inboundState.status;
        };

        const getAvailabilityStatus = () => {
            if (inboundState.availabilityStatus === AvailabilityStatusEnum.FETCH) {
                if (inboundState.availabilityEntity) return AvailabilityStatusEnum.OK;
                return AvailabilityStatusEnum.EMPTY;
            }

            return inboundState.availabilityStatus;
        };

        const getDiscountStatus = () => {
            if (inboundState.discountStatus.status === DiscountStatusEnum.FETCH) {
                if (inboundState.discounts.length > 0) return DiscountStatusEnum.OK;
                return DiscountStatusEnum.EMPTY;
            }

            return inboundState.discountStatus.status;
        };

        return {
            ...inboundState,
            status: getStatus(),
            availabilityStatus: getAvailabilityStatus(),
            discountStatus: { ...inboundState.discountStatus, status: getDiscountStatus() },
        };
    },
    (outboundState) => outboundState,
    { whitelist: ['order'] }
);

// Set the event fetch status to the correct status when state is hydrated from the persist storage
const eventTransform = createTransform(
    (inboundState: EventState, key) => {
        const getStatus = () => {
            if (inboundState.status === EventStateStatus.FETCHING) {
                if (inboundState.detailEntity) return EventStateStatus.OK;
                return EventStateStatus.FETCHING;
            }

            return inboundState.status;
        };

        const getAccomodationStatus = () => {
            if (inboundState.accommodations.status === AccommodationStateStatus.FETCHING) {
                if (inboundState.accommodations.data) return AccommodationStateStatus.OK;
                return AccommodationStateStatus.EMPTY;
            }

            return inboundState.accommodations.status;
        };

        return {
            ...inboundState,
            status: getStatus(),
            accommodations: {
                ...inboundState.accommodations,
                status: getAccomodationStatus(),
            },
        };
    },
    (outboundState) => outboundState,
    { whitelist: ['event'] }
);

const migrations: MigrationManifest = {
    [appConstants.persistedDataVersion]: (state) => {
        return {
            ...state,
            travellerData: travellerDataInitialState,
        };
    },
};

const persistConfiguration: PersistConfig<RootState> = {
    key: 'root',
    version: appConstants.persistedDataVersion, // increase this number when data structure changes and provide a migration
    storage,
    whitelist: ['app', 'event', 'order', 'travellerData'],
    stateReconciler: autoMergeLevel2,
    transforms: [
        appSlicePersistFilter,
        appSliceBrowserSessionTransformer,
        travellerDataTransformer,
        orderTransform,
        eventTransform,
    ],
    migrate: createMigrate(migrations, { debug: process.env.NODE_ENV === 'development' }),
};

const store = configureStore({
    reducer: persistReducer(persistConfiguration, rootReducer),
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
});

export type AppThunk = ThunkAction<void, RootState, null, Action<string>>;

export const persistor = persistStore(store, null, () => {
    /**
     * When the state has been properly rehydrated from storage, set the session flag
     * in sessionStorage. This way when the page get's refreshed, we can now if it's
     * still the same session or it's a new one.
     */
    sessionStorage.setItem('has_session', 'y');
});

export type TypedDispatch<T> = ThunkDispatch<T, any, AnyAction>;

/** useDispatch with types inferred from RootState
 * https://github.com/reduxjs/redux-thunk/issues/333#issuecomment-1286695992
 */
export function useTypedDispatch() {
    return useDispatch<TypedDispatch<RootState>>();
}

export default store;

import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Moment } from 'moment';
import { appConstants } from 'src/app/constants/app';
import TravellerFormModel from 'src/app/models/TravellerFormModel';
import { RootState } from 'src/app/store/index';

export interface TravellerDataState {
    version: number;
    updatedAt?: Moment;
    bookOther: boolean;
    booker?: TravellerFormModel;
    travellers: TravellerFormModel[];
    consentedNewsLetter: boolean;
}
export const travellerDataInitialState: TravellerDataState = {
    version: appConstants.persistedDataVersion,
    updatedAt: undefined,
    consentedNewsLetter: false,
    bookOther: false,
    booker: undefined,
    travellers: [],
};

export const travellerDataSlice = createSlice({
    name: 'travellerData',
    initialState: travellerDataInitialState,
    reducers: {
        setUpdatedAt: (state, action: PayloadAction<Moment>) => {
            state.updatedAt = action.payload;
        },
        setBookOther: (state, action: PayloadAction<boolean>) => {
            state.bookOther = action.payload;
        },
        setBookerDetails: (state, action: PayloadAction<TravellerFormModel>) => {
            state.booker = action.payload;
        },
        setBookerEmail: (state, action: PayloadAction<string>) => {
            if (!state.booker) return;

            state.booker.email = action.payload;
            state.travellers[0].email = action.payload;
        },
        setTravellersDetails: (state, action: PayloadAction<TravellerFormModel[]>) => {
            state.travellers = action.payload;
        },
        setConsentedNewsletter: (state, action: PayloadAction<boolean>) => {
            state.consentedNewsLetter = action.payload;
        },
    },
});

export const {
    setBookOther,
    setBookerDetails,
    setBookerEmail,
    setTravellersDetails,
    setUpdatedAt,
    setConsentedNewsletter,
} = travellerDataSlice.actions;

export default travellerDataSlice.reducer;

export const selectBookOther = createSelector(
    (state: RootState) => state.travellerData.bookOther,
    (bookOther: boolean) => bookOther
);

export const selectBookerDetails = createSelector(
    (state: RootState) => state.travellerData.booker,
    (booker?: TravellerFormModel) => booker
);

export const selectTravellerDetails = createSelector(
    (state: RootState) => state.travellerData.travellers,
    (travellers: TravellerFormModel[]) => travellers
);

export const selectHasConsentedNewsLetter = createSelector(
    (state: RootState) => state.travellerData.consentedNewsLetter,
    (consent: boolean) => consent
);

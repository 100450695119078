import React from 'react';

export const CarteBancaire = () => (
    <svg
        version="1.1"
        id="layer"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width="36.631"
        x="0px"
        y="0px"
        viewBox="-153 -46 652 652"
        style={{ enableBackground: 'new -153 -46 652 652' }}
        xmlSpace="preserve"
    >
        <g>
            <defs>
                <rect id="SVGID_1_" x="-193.3" y="26.3" width="723.2" height="500" />
            </defs>
            <clipPath id="SVGID_2_">
                <use xlinkHref="#SVGID_1_" style={{ overflow: 'visible' }} />
            </clipPath>
            <g style={{ clipPath: 'url(#SVGID_2_)' }}>
                <g>
                    <defs>
                        <rect id="SVGID_3_" x="-193.3" y="26.3" width="723.2" height="500" />
                    </defs>
                    <clipPath id="SVGID_4_">
                        <use xlinkHref="#SVGID_3_" style={{ overflow: 'visible' }} />
                    </clipPath>
                    <g style={{ clipPath: 'url(#SVGID_4_)' }}>
                        <image
                            style={{ overflow: 'visible' }}
                            width="1507"
                            height="1042"
                            xlinkHref="data:image/jpeg;base64,/9j/4AAQSkZJRgABAgEAlgCWAAD/7AARRHVja3kAAQAEAAAAHgAA/+4AIUFkb2JlAGTAAAAAAQMA
EAMCAwYAAB/eAAAliwAAM3D/2wCEABALCwsMCxAMDBAXDw0PFxsUEBAUGx8XFxcXFx8eFxoaGhoX
Hh4jJSclIx4vLzMzLy9AQEBAQEBAQEBAQEBAQEABEQ8PERMRFRISFRQRFBEUGhQWFhQaJhoaHBoa
JjAjHh4eHiMwKy4nJycuKzU1MDA1NUBAP0BAQEBAQEBAQEBAQP/CABEIBBQF5gMBIgACEQEDEQH/
xACvAAEBAQEBAQEAAAAAAAAAAAAAAQQDBQIGAQEBAQEBAQAAAAAAAAAAAAAAAQMCBAUQAAEBCAIC
AwADAAMAAAAAAAAEYHABERQFNSZCAwIzMRIyQUM0IRMWEQAAAwYHAQEAAgIBBQAAAAAAAgMBodFy
kkNwsTNzswQFNMERExK0ITFhgiMUEgABAgUEAgMAAgIDAAAAAAAAMQJgAcKDRLFCcoJxQ0HBw4Ey
MCFAMzT/2gAMAwEAAhEDEQAAAO2LTh+t8DrObrjo5F6uQ6OcOrkOs5l6OQ6uQ6zmOjkXq5DrOcOr
kOrkXq5Q6uQ6uQ6znDq5F6uQ6uUOrkjq5F6znDq5Dq5DrORerkOriOrkOrlF7TkOrkOril6uQ6uI
6uQ6uJerkOrlDtORerlDtOQ6uUXs4jq5SOziXq5Dq4jtOQ6uJeziOrlDs4js4l6uI7OI7TlF7OI7
OI6uI7OJeziOriOzijs4xeziOziO04js4l7OI6uI7OI7TiXs4o7OA7OJezjDs4js4w7ziXs4o7Ti
OziXtOI7OMO84l7OMjvOI7OBeziOzgXs4yPbcnHX6DDuw+r4odcQKAlgACwAAECgAQAQlLAAAQKA
lEACpRAARYoQlEACxYARYoCUQCUsAlEEoEWALFgBFigJRAAsWACUQKlEWALFEEARYoCURYAqUQAE
WKAlEWQCxYARSwEUQQlLFgBFipRFglSxYJRFixRJUsWHqq469/Duw+j4wd8RYAqWAAKlgAERYAoC
WAALFgAAlgCgQAALFgEJRAoCUQCUsABFigJRAJSwQlECgRYAsWACUQKlEACxRAJRFigRUQALFgAl
EWKBFEACxYAJRFigJURYJSxYARYoEVECpRFglEWKlEWQlLFglECxUeorjr3sO7D6Pjh1wlgCpRAA
qWAACWAKABAAqWAAAECgJUQAALAAJYAoEWACUsABFigRYBLFgAlECgRYAsWACUQKBFgCpRAARZKA
lEWAKlEABFLAARYAqUQACUsEARRAqUQCURYoRFEAlLFglECpURYJSxYRS+oM+vew7sPo+OHXCACi
AAKIAACAKABAAJUsAABFgCgRYAAJSwAEWKABFgCpYAJUQKBFgCxYAJRAqUQALFEAlECgRRAAsWAQ
lECgJRAAsWACURYoCUQALFEABFSwAEWALFEAlEWSgJRFhFLAJRFipURYJS+oOOvdw7sO3yA64gUA
CABUsAoBKIJQAIsAAWAAAAgUACLAAFSwAAixQAIAJYsAEogUCABYsAEogVKIAFSiAAixQEoiwCVK
IACKWAAiwBUoiwASiBQIsAUCLAIAilgEoiwBYsAEoipYCKIFiiLACKWSo9Qcde5h3Ydvkh1xAoAE
AACwAAECgAJRAAqWAAACUQKABFgACxYBAEWWhCUQALAAJYAqUQALFgAlECgRYAJSwARFLAARRAJS
xYAJRFigJRAAsUQAEWKAlEWAKlRFgABFigJRAAsUQAEVLARRFglLFEB6gz79vDuw7fJDriBQAIAA
FgAAEsAUACLAAFiwAAAixQgAKiwCEpYAACLFASwASlgAIsUBKIAFiwASiBQEoggFiwAAilgEogAV
KIsAEogUCKIAFiwAilgAEoggFSiLABKIsUBKIsAVKIsAiKWASiKIF9QZ9e1h3YdflB1xAoAgAACo
AAUlkAoACWAALFgAAAlgCgAAQALFgAAlECgRYAJSwAEWKAlEACxYAJURYoCURYAsUQACUQKBFEAC
xRAARYoCURYAqUQAEUsABFRAAqURYAJRFLAARRAAsUQQlEWKB6gz79nDuw6/KDriBQEogAAWAAAS
wBQAEsAAVKIsAAAIFAAASwABYsAAEogVKIsAEpYACLFASiABYqIACKIFAiwBQIogAIsUACLABKWL
ABKIsUBKIAFiiAAilgAhKIsAVKIsAAIpYACKIsAWKIISj01cd+xh3Ye/lh3xAoAEAACpYAAJRAoA
AEAACpRAAAJRAoAAEWAALFgABFigARYAJSwAEUQKERYAqUQACURYoEUQAKlEWACUQKAlEWAKlEAB
FECpRFgACxRAAJRFkoCURYAoEURYAJRFigJRFgCxR6Yz79jDuw9/LE64BQBAAAFSiAAAgUAABLAA
FASiCgAhKILQAAIAAFiwAAixQAIsAhKWAASiBQIsAAWLAACLFAAiiABYogAIpYACKIAFiiAAixQE
oiiABYoiwCEoixQEoiiLAFSiKIACKWASiKPTGffr4d2Hv5sHWYKAlgAACwAAAEACgAJYAAAoCUQA
AAEWKAAlEAACxZQQBFigARYAJSwACURYoEUQAKlEABFECgARYAJSxYAARSwAEWACUsWAEURYoAEU
QAKlEWQAlEUsAABFEACxRFgAlEWL6as+/Ww7sPXzYOuAAEsAAAVLKAAAgAUAACAAABQEAAAACLFA
ASiAABYsAAIsUACLAAFiwAAixQEoiwABYsAAIpYABKIAFSiLABKIsUBKIsAVKIABKIsUABKIsAVK
iLAABKIpYACKIsAUCKID0xn362Dfg6+cHXAACWAAKAlgAAAlECgAARYBQAKAlEAAAAlECgAJRAAA
sWAAEWKABFgACxRAARYoAEUQALFEAABFLAARRAAqUQACUQKBFEACxRFgABFLAAJRFgEAsURYAAJR
FigARRFgC+mM+/Vwb8F+cHXAAEAFAoCAAAABFigAAJRAAAAoCWAUAAAlECgAJRAAAsWAAEWAKBFE
ACpRAAJRFigARYAAsUQACURYoAEUQALFEABFLAARRAAqURYAARRAoCVEUQAKBFEWAAEUsWAUlHpK
z09TBvwPnB1wABAAAoEAAAFAQAKAAAlEAAACgRYAAABSUQKABFgAACxYAAJYAoCUQAALFgABFigA
RRAAoEWAACURYoCURYAJSxYAJRFigARRAAqURRAAJRFkoUAlEUQAKBFEWACURS+kM+/Uwb8E+eHX
AAgFAAoEWAAAAEWAKAABFlAAAAoACUQAAACUQWgAJRAAAsWAAEUQKAlEAACxYAAJRAoAEUQAKlEW
AAEUQKAlEWAKlEAAlEUsAAlEWAALFEWAAEUsWAACURRFgCpRFEB6Yz09PBvwT54dcAJYAAAoCWUA
AAAlECgAAARYABQAKAAlEAAAAlECgAJRBQAKlEAAlECgARYAAqUQACURSwACURYAAqURYAARSwAE
UQALFEAABFEWKABFEACpRFgAABFEWKAAlEUQAL6Qz79PBvwTwBeAEsoAAFAgAAAAAqLFAAAAAiwA
AACgUBLAAAABKIsUAACKIAFSiAAAixQAqKiLKCAWLAABKqLJQoBKIogAVKIsAAIpYACLAAFSiLAA
BKIsUACKIsAAVKIogAAIoilgAEo9IZ6ejh3YefAHXACAAAChUAAAAABAAoAAUAlEAAAAAChUWAAA
ACURYoAACUQAALFgABFECgARRAAqUQAACURYoAAVFEACpRAAJRFigJURVQAKBFEWAAEUsAAlEUQA
AKlEUQAAEURYvpKz09DDuw8+ALwFRYAAAoCWAAAUABFgCgAAABSUQAAAAAKBFgFAAAAQKAAABFgA
CxRAAJRAoACUQAAKlEWAACURYoUAlEWAALFEAERRAoUBFEWAAKlEUQACURYoACURRAAAoEURYAek
M9PQw7sPHhDrgBLAAALUsAAAAAAEqoFAAAAAASiACgAAAUABKqLAAACKIFAAAiwABUogAAIoixQA
IsAAAVKIsAAAIogUBKIsAAWKIACKqLFAASiKIAAFiiLAACKIsUAACKIsAAUK9FWWm/Duw8eELwJV
gAAoAEAFAAAAARYoAAUAABFgAAVUWAAAKAAlEWAAAUBFECgAJRAAqURYAAARRAoAEURYAAqURYAA
ARRAoCURYAJSxYAABUUQKAAlEWAAKlEUQAAEURYoAACVUUeiM9N+Hdhy8QdcJYAAAoUBAAAAABQh
YAKAAAFAARYALBQRZQAAAAALFgAAAFARYoACUQAALFEAAAlEWKAAAlEWAAKBFEWAAEUsAAlEWAKB
FEAAFJRFigAARRFgACpRFEWAACURVsWAHpDPTdh3YcvEReLLKAABQIAKAAAAAAgoFAAAAAAACgAA
IsAAoAAAFiwAAAACkoixQAIsAAUCKIsAAIoilgAAEoiwAABUogAAIogUCKIsAAVKIsAoACKIFAAA
iiLAAFAiiLKAAA9EZ67sO7Dj4oOuAAAAtASwAAAACgAEogUAKAAAAAACgAIogAAAoAAFiiAAAAAS
qiyUKAiwAABQIogAAAEoixQAAIoiwABYoiwAAilgAEoiiABQIqoAABKIpYAAACKIsAoFAiiLD0hn
ptw7sOPjgvAUAACiFlgFAAAAAEtARYoAAAUAAAAAAFAJRFEsFBFVAAAoEUQAAAAEWAKBFEWAAUCp
RBAUAAAlEWKAAAlEWAAKlEWACURSwAAEURYABalEUQAACURSwAAUBFEWAAL6Iz024N+DDxh1wAAF
AqWAAAAUAAAlEsFhQKAAAAFAAAAABQAAAACWAAUCgARRAAAARRAoAAEUQAAKFARRFgAABFEUsAAl
EWAAKlEAABFEWKAAlEUQAUCxRFgAAAFRRFigAARR6Iz12YN+DDxheAoAFAAgoAAAAAKASwABQAoA
AAAAKAAAACgAAAIogAAoFASiLAAABKIFAAAAiiAAABQpKIsAAAAIpYABKIogAVKIsAAEoilgAAIo
iygAUCKIogAoACKIsUD0Rnpswb8Hn8gdcAABaAQAABQAAAAAVFgCgABQAAAAAUAAAAFAAAAARRAB
QAKAlEWAAACURYAoAACURRAABQKBFEWAAAEUQKABFEACgRRAAAJRFigAJVRRFgAACpVRRAAAAeiM
9deDfg8/lC5hQKABABQAAAAUAlhUFBFloAAAAUAAAAAFAAAABQAAAEUQAAAUCgJRFgAAABFECgAA
AJRFEAFAAoEUQAAEUQKABFEAACpRFgAAlEUsFAAARRFgFAAoEURZXojLXXg34PN5Q6zBQAoBLAAA
KAAAACosALALFCgAAAAoAAAALAAUAAKAAAAAiiAAAAC0CKIAAAABKIogUAAABKIogAoAFiiLAACK
IsUABKIsAAVKIogAAEqopYAAAKiiKIAALfRGWmrBvwebzBcwUKASwqWosAAAAoAAABKqLACpQCKq
KIABYKKSiKIoiiKqWCgSiKIqoFAAAAiiLAAAAFASqiiAAAAAAiiLFAAAASiKIAKBUoiwAASiLFAA
SiKIAAFSiLAKAAAiqixQAAAIqvQGWurBvwebzBcwUAKAiwACgAAAAoAAAKAAAACgAAAAoAAAAKAA
AAAiqixQAAAEoiwAAAABQEqooiwAAAAAiiLAFAAASiLAAFSiKqAAAiiBQAEoiwAAC1KIogAoACKI
pYKA9EZ66MO7D5PMFzCgUAAKiwAACgAAAoAAAAKAAAACgAAAoAAAAKAAAAAASiLKBQAAAIoiiLAA
AAFACooiwAAAAASiKIFAASiLAAALYoiwAASiKWAAAiiLKAABQqKIsAAAoD0Bnrpwb8Hk84XgAAKA
IKCLKAAACgAAAoAAAKAAAACgAAAoAAAAAKAAAAAAAiqgUAAAABKIogAAAAAUBKqKIAAAABKIsUAB
KIoiwCgVKIsAAAIoixQAoBKIogoAFASqiiA9BWeujBuw+PzheAoAABKqWCpSLKAAACgAAAoAAAAK
AAAACgAABaiwAAACgAAAAAAAIqosUAAAAAACKIogAAAAoFSiKIAAABKIsUAABKIogoAFiiLAAABK
qKWAACgIoiwCgAX0Bnr3w7sPj84XgAAKAASqgKgLAKAAACgAAAoAAAAKAAKIqooiiKAoAACKIogo
AAAAAAAAABKIqoFAAAAASiKIsCiACgAUBKIogAEoiiKIFAAiiLKAABYoiwCgAEoiqixQAApKIo3q
z17Yd2HxYBeAAoAAKASiCgAAoAAAKAAACgAAAAoBYKAKAAACgAAAAEoiqiwAAAAAAAAAASiKqLAF
AAAAAASiKIAKAABYogAAAEoixQAoCKIsAAAtSiKIAKAAiiLLQAPQGevbDuw+LALwAAFAASpVSiUQ
UAAAFAABQAAAAVUoAAFAAAABYAAAFAAAoAAAVFEURRAFEAAAAAAAABFVFigAAAAAJRFEVUAACpRF
EUQACURVRYoAAEURZQAAKlVFEWAAUAlG9We3XDuw+HALwFAAABQAAUAlEVUUAJVRRFEoBQAAAUAA
AFgAAAAUAAAFAAAAAAABaAAAAAlEUQAAAAAAAUBFEUQKAAAABFEAFAAAsUQAAUBFEWKAFAJRFVAA
AoVFEWAHoDPbrh3YfDgF4AACgAAoAAKAACgAAAoAAKBAAAAoAAAKAAKIqooihKIoCooiiKIsAAAA
oAAAAAFAiiKIoiiAAACgAEoiiKIFAAAAiqiiLAAFSiKqLAAACKqKWACgEoiiLKAABd6s9emHdh8G
IXgAKAACgAoEACgAAoAAAKAAACgAAAoUAACgAAAoAAAAAAKAAASiKIoiiKIsAoAAAAAFASiKIoiy
gAAAAAIogUAKAASiKIAKBQIoiwCgAEqoogUAKAiiLD0Bnt0w7sPgxC8AAgUAAFABQAAUAAAFAABQ
AAAtSgAAFAAABQAAAUAAAAAAFAAAAAAAAAJRFEWUAAAAAACgARVRRAAAAAARRFVFECgAJRFVFgAC
hUURYBQACURVQKABvGe3TDuw/PxC5gBQAUAAFAABQAUAURRBQFSgUAAAFAAABQAAAUAACFEVUURR
FEURRFEAFoAAAAAAAAAAUBFEWAAAAAAKFAARRFgAAAFAJRFigBQAEURZQAAWpRFEAFAARRvGe33h
3Yfn5BeAQAKAACgAoAUiqiiKJSgAAoAAAKAAACgAAoEqUACgAAAAAoAAAAAAKAigCKIoiwBQAAAA
AoABKIoiiLAAAKABQEoiiKIsoAABKqKIFACgIoiygAAVKqKIAK3jLbpg3YfnZBcwAoAKAAWKoAoA
AKAACgAAoAAAKAABBaAAACgAAAoAAAAAKAAAAAAAACgAAAIoiiKIsAUAKAAAAAASiKqLAAAAAKBY
oiwACgEoiqgUAAKiiKIKABUqt4y2+sO7D87IGYUAFAFVKAAUAFAABQAAUAAAFACpFVKAAAUAAAFA
AABQAAAAABVRRFEURYAAABQAAAAAAAAEURRFVAoAAAAAAUBFEUQpBQAAKlVFEAFAARRFloAAUlEU
QVvGW33g34Pm5hcwApYKKACgAAoAKAACgAQqoolAAKAACgAAAoAAoiqlAAAAAKAAAAACgAAAAIoi
iKIsAAoAAAAAAAKAiiKIogUAKAAAASqiiLAAKABQIqosAAoBKIqoFACt4y2uHdh+bmDMKUAoAKAC
gAAoVIqooigKAACgAAAoAAAWooiiUAoAAECgAAAAAoAAAAAAKAAAABQAAEoiiKqLAAAAAAKAAAAA
CkoiiLFCgAAAqKIoiwCgAVKqKIAKAAiq3DLf6wb8HzMwuZQFABQAUCBRQAFABQAAAUAAFCkURVRQ
AAFAgAAUAAAAVUURRFEURRFEVUURRFEURRFEAFAAAAAAAoAUABFEURRFlAAAAABQAAUBFEUQKFAA
BSURRBQAAWxRFgFbxltcG/B8zhYZ0UAFABSwlFABQAAUAAFAAFUAAFAABYAAAAFFEUSlAAAAAABQ
AAAAAAUAAAAAAlEVUURYAAAAAABQAKAABKUABFVFEURRBQAAAUlEURVQKAFARRFVAABbuGW1wb8H
y8xbwAFABQpKWABQAUAAFKAAUAAFgAAUAAAVUUSgAFAAABQAAAAAAAUAAAAAFAAAAAAAAABUURRF
gAAFAAAAABQAAUAAFoCURRFlAABQAEVUWKAFAJRFVuGW7BvwfLzWVwFABQCy2ABQAUAKBQAUCABQ
AAtRRFEVUoAABQAAAUACFEURRKAUAAAAAFAAAAAARVRRAoAAAAAUAAAAAFAJRFEURVRRFEVUURYA
BQAAAWgRRFVFgAFAJVRRFihW4ZbMG/B8rgW8ABQFS2ABQAUsoFAABYAFAAFVFAAUAAFAAAAC2RRK
AAUAAAAAAFAAAAAABQAAAAAAUlEURRAAoAUAAAAFAAAABQAAUAAFAJRFEVUAAFAAqVUUQUAAFbhl
vMG/B8rihwFABSiBQAUsoFABYAAFFEVQAAUAAFAACpFUAAAFAAAAABQABRFEURRFVFEURRFEURVQ
AAAAAAAUAAlEURYCkVUURRFgFopFJFVFgKsAFAABQAAUAAlVFEUQUAAFsURVbVZbfODfg+TypeAA
pZUCgAooCgQKAFqKAAoAAKACikigKAAACgAAAoUiiKIoAAACgAAAQAAKABQAAAIqosAAAAAoAAAA
AKAAACgAAoAAKAACgEoiiKqKILQAAoCKIqoAK3DLf5w7sPyeQcBQoFgAUUBQIFCkpQAUAAFCpFEV
QAUAAAFACkVUUAAABQIAAAAAAAFAAAAABQAAAAAWgARRFEURYBQAAAAUAAALUURVRRFEURVQAAUA
AFABQAEVUURYoUAFbVZb/GDfh+RyF4AWWwAKAooEChSUoAKAFsigAKAACgAooiiKAoAAECgAAAAA
CqiiKIoigAAKAAAAAiqiiLAsAAAAoAFAASqiiKIoACkolAKBAAoAAKAAC0AAKAiiKqKIKAACkoiq
2jLf4w7sPyIDgWgQKAUoEChQKACgCrAAAoAKFIolKAACwAAKAAAKIqooAAAAAACgAAAAAAoAAAAA
KAiiKIsAAoAAAAKAAACgAAoUiiKqKIqooiiKIqosBaiiACgAoFAiqiiA3DPfnh3YfjwHAtgUAFUA
WLBRQAUAVYAFAAFUAFAgAUAALUURRKAUAAAAAFAAAAAAAhVRRFEURYAFVFEACgABQAACURRFlAAA
BQABYFVFAAUAFAABQAAUAAFABQEURVRRFlAAbRn6OeHdh+PAcLKgUFKAUCKUAFCoFABQApKUAFgA
UKRRFUAAAFAAABQABSRRFEURVRQAAAAAAFAAAABQEURRAoAUAAAAFASgAFAAABQAUACFVKAUlEUR
VRRFEVUURVRRFihQAUAlG0Z78sO7D8YDgWwAKoAsCqAKWVAoAKAFoAALACqACgAAoAAAqyKIoigA
KAAAAAAACgAAAAAAoAAAAKAASiKIsAtAAAACgAAoAAKKSKJSgAoAAKAACgAoAKAACgEqooijYrP0
ccO7D8YDhZUCllAoEUoKAUsACgFKACwAAqgAoAKAKSKAoAAAKAAAAACgAABSKIoiiKqKJQASiKIq
ooiiAACgAAAEqgIoiiKIqooiiUoAAKACgAAoUigKACgAoAAKSiKqKIsAoDaM9/RznyPaDkECqAAK
AoQKAACqAAAKBAAoCgACgAAAAoAAAAAAAKAABAAAAAKAAAKAAAAAQAUAKAAAAAACgAAAAAoAAAKB
AFAKAAACgAAAoAAKAAgLCtA41//aAAgBAgABBQDrhD6SgSgSgSgSgSgSgSgSgSgSgSgSgSgSgSgS
gSgSgSgSgSgSgSgSgSgSgSgSgSgSgSgSgSgSgSgSgSgSgSgSgSgSgSgSgSgSgSgSgSgSgSgSgSgS
gSgSgSgSgSgSgSgSgSgSgSgSgSgSgSgSgSgSgSgSgSgSgSgSgSgSgSgSgSgSgSgSgSgSgSgSgSgS
gSh9uv8ADO8uv8M7y6/wzvLr/DO8vD8M7y8PwzvLw/DO8vD8M7y8PwzvLw/DO8vD8M7y8PwzvLw/
LO8vD8s7y8PyzvLw/LO8vD8s7y8PyzvLw/LO8vD8s7y8fyzvLx/LO8vH8s7y8fyzvLx/LO8vH8s7
y8fyzvLx/LO8vH4Z3l4/DO8vH4Z3l4/DO8vH4Z3lD4Z3lD4Z3lD4Z3lD4Z3lD4Z3lD4Z3lD4Z3lD
4Z3lD4Z3lD4Z3lBnuUGe5QZ7kz3JnuTPcme5M9yZ7kz3JnuTPcme5M9yZ7l/LO8o/LO8o/LO8o/L
O8o/LO8o/LO8o/LO8v/aAAgBAwABBQDt8o/9n2ifaJ9on2ifaJ9on2ifaJ9on2ifaJ9on2ifaJ9o
n2ifaJ9on2ifaJ9ok4k4k4k4k4k4k4k4k4k4k4k4k4k4k4k4k4k4k4k4k4k4k4k4k4k4k4k4k4k4
k4k4k4k4k4k4k4k4k4k4k4k4k4k4k4k4k4k4k4k4k4k4k4k4k4k4k4k4k4k4k4kyZMmTJkyZMmTJ
/wDHb7Gd/jt9jO/x2+xnf47fYzv8dvsZ3+O32M7/AB2+xnf47fYzv8dvsZ3+O32M7x7fYzvHt9jO
8e32M7x7fYzvHt9jO8e32M7x7fYzvHt9jO8e32M7x7fYzvHt9jO8e32M7x7fYzvHt9jO8e32M7x7
fYzvHt9jO8e32M7x7fYzvHt9jO8e32M7w7fYzvDt9jO8O32M7w7fYzvDt9jO8O32M7w7fYzvDt9j
O8O32M7w7fYzvDt9jO8O32M7w7fYzvDt9jO8O32M7w7fYzvDt9jO8O32M7w7fYzvDt9jO8O32M7w
7fYzvDt9jO8O32M7/X2+xnf6+32M7/X2+xnf6+32M7/X2+xnf6+32M7/AF9vsZ3+vt9jO/19vsZ3
+vz/AGzvH//aAAgBAQABBQC8qO/xulUpKpSVSkqlJVKSqUlUpKpSVSkqlJVKSqUlUpKpSVSkqlJV
KSqUlUpKpSVSkqlJVKiqVFUqKpUVSoqlRVKiqVFUqKpUVSoqlRVKiqVFUqKpUVSoqlRVKirVFWqK
tUVaoq1RVqirVFWqKtUVaoq1RVqirVFWqKtUVaoq1RVqirVFWqKtUVaoq1RVqirVFWqKtWVasq1Z
VqyrVlWrKtWVasq1ZVqyrVlWrKxWVasq1ZWKysVlYrKxWVisrFZWKysVlYrKxWVisrFZWKysVlYr
KxWVisrFZWKysWFYrKxYViwrFhWLCsWFYsKxYViwrFhWLCsWFYsKxYViwrFhWrCsWFasK1YVqwrV
hWrCtWFasK1YVqwrVhWrCtWFasK1YVqwrVhWrCtWFasK1YVqwrVhWrCtWlasK1aVq0rVpWrStWla
tK1aVq0rVpWrStWlatK1aVy0rlpXLSuWlctK5aVy0rlpXLSuWlctK5aVy0rlpXLSuWlctK5aVy0r
lpXLiuXFcuK5cVy4rlxXLiuXFcuLasV+VnveWd3bMNe8s7u2Ya95Z3dsw17yru7Zhr3lnd2zDXvL
O7tmHveWd3bMPe8s7u2Ye95Z3dsw97yzu7Zh73lnd23D3vLO7tuHveWd3bcPe8s7u24i95Z3dtxF
7yzu7biL3lnd23EXvLO7tuIveWd3bcRe8s7u24i95Z3dtxF7yzu7biL3lnd27EXvLO7t2IveWd3b
sTe8s7u3Ym95Z3duxN7yzu7dib3lnd27E3vLO7t2JveWd3bsTe8s7u3Ym95Z3duxN7yzu7dib3ln
d27E3vLO7t2KveWd3bsVe8s7u3Yq95Z3dvxV7yzu7fir5lnd2/FXzLO7t+KvmWd3b8VfMs7u34q+
ZZ3dvxV8yzu7fir5lnd2/FXzLO7t+KvmWd3b8VfMs7u34u+ZZ3dvxd8yzu7fi75lnd2/F3zLO7t+
LvmWd3b8XfMs7u34u+ZZ3aDF3zLO7QYu+ZZ3aDF3zLO7QYu+ZZ3aDF3zLO7QYu+ZZ3aDF3zLO7QY
y+ZZ3aDGXzLO7QYy+ZZ3aDGXzLO7QYy+ZZ3aDGXzLO7QYy+ZZ3aDGXzLO7QYy+ZZ3aDGXzLO7QYy
+ZZ3aDGXzLO7Q4y+ZZ3aHGXzLO7Q4y+ZZ3aHGXzLO7Q42+ZZ3aHG3zLO7Q42+ZZ3aHG3zLO7Q42+
ZZ3aHG3zLO7Q42+ZZ3aHG3zLO7Q42+ZZ3aHG3zLO7Q42+ZZ3aHG3zLO7Q42+ZZ3aHG3zLO7Q42+Z
Z3aHG3zLO7Q42+ZZ3aLHXzLO7RY6+ZZ3aLHXzLO7RY6+ZZ3aLHXzLO7RY6+ZZ3aLHXzLO7RY6+ZZ
3aLHXzLO7RY6+ZZ3aLHXzLO7RY6+ZZ3aLHXzLO7RY6+ZZ3aLH3zLO7RY++ZZ3aLH3zLO7RY++ZZ3
aPH3zLO7R4++ZZ3aPH3zLO7R4++ZZ3aPH3zLO7R4++ZZ3aPH3zLO7R4++ZZ3aPH3zLO7R/4L5lnd
o/8ABfMs7tH/AIL5lndo/wDBfMs7tH/gvmWd2j/wXH/zddqZqZqZqZqZqhqhqhqhqhqhqhqhqpqp
qpqpqpqpqpqpqpqpqxqxqxqxqxqxqxqxqxqxqxqxqxqxqxqxqxqxq5q5q5q5q5q5q5q5q5q5q5q5
q5q5q5q5q5q5q5q5q5q5q5q5q5q5q5q5q5q5q5q5q5q5q5q5q5q5q5q5q5q5q5q5q5q5q5q5rBrB
rBrBrBrBrBrBrBrBrBrBrBq5q5q5q5q5q5q5q5q5q5q5q5q5q5q5q5q5q5q5q5q5q5q5q5q5q5q5
q5q5q5q5q5q5q5q5q5q5q5q5q5q5q5q5q5q5q5q5q5q5q5q5q5rBrBrBrBrBrBrBrBrBrBrBrBrB
rBrBrBrBrBrBrBrBrBrBrBrBrBrBrBrBq5q5q5q50f8An6b/2gAIAQICBj8Ab/rbISQggkhBBBJC
CCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCC
CCCCCCCCHekbxlD3ekbxlD3ekbxlD3ekbxlD3ekbxlD3ekbxlD3ekbxlD3ekbxlD3ekbxlD3ekbx
lD3ekbxlD3ekbxlD3ekbxlD3ekbxlD3ekbxlD3ekb4lD3ekb4lD3ekb4lD3ekb4lD3ekl4lD3ekl
4lD3ekl4lD3ekl4lD3ekl4lD3ekl4lD3ekl4h65SS8Q9cpJeIeuUkvEPXKSXiHrlJLxD1ykl4h65
SS8Q9cpJeIeuUEvEPXKCUPXKCUPXKCUPXKCUPXKCUPXKCUPXKIfuUQ/coh+5RD9yiH7lEP3KIfuU
Q/coh+5RD9yiH7lEP3KIfuUQ/coh+5RD9yiH7lEP3KIfuUQ/coJw9coJw9coJw9coNptNptNp8Hw
fB8f4/k+T5Pk+Tcbjcbjcbjcbjcbjcbjcbjcbjcbzebzebzebzebzebzebzebzebzebz2HsPYew9
h7D2HsPYew9h7D2ntPae09p7T2nuPce49x7j3HuPce8957z3nvPee8939qdT/9oACAEDAgY/AH/7
n/d2osxZizFmLMWYsxZizFmLMWYsxZizFmLMWYsxZizFmLMWYsxZizFmLMWYsxZizFmLMWYooooo
ooooooooooooooooooooooooov8Ay/4+x/N2sPfx9j+btYe6/Y/m7WHuv2P5u1h7r9j+btYe6/Y/
m7WHuv2P5u1h7r9j+btYe61D+btYe61D+btYe61D+btYe6VD+btYe6VD+btYe6VD+btYe6VD+btY
e6VD+btYe6VD+btYe6VD+btYe6VD+btYe6VD+btYe6VD+btYe6VD+btYe6VD+btYe6VD+btYet1D
+btYet1D+btYet1j+btYet1j+btYet1j+btYet1j+btYet1j+btYet1j+btYet1j+btYet1j+btY
et1j+btYet1j+btYet1j+btYet1j+btYet1j+btYet1j+btYetVj+btYetVj+btYetVj+btYetVj
+btYetVj+btYetVj+btYetVj+btYetVj+btYetVj+btYetfoP5u1h61+g/m7WHrX6D+btYetfoP5
u1h61+g/m7WHrX6D+btYes/oP5u1h6z+g/m7WHrP6D+btYes/oP5u1h6z+g/m7WHrP6D+btYes/o
P5u1h6z+g/m7WHrP6D+btYes/oO/8v8Aaar/ACYhiGIYhiGIYhhmGYZhmGYZhmGYZhmGYZhmGYZh
mGYZhmGYZhmGYZhmGYZhGEYRhGEYRhGEYRhGEYRhGEYRhGEYRhGEYRhGGYZhmGYZhmGYZhmGYZhm
GYZhmGYZhGEYRhGEYRhGEYRhGEYRhGEYRhGEYRhGEYv/AF/x/fT7P//aAAgBAQEGPwDslKqdjGH/
AOGMM1jP+g1j1NiNY9TYjWPU2I1j1NiNY9TYjWPU2I1j1NiNY9TYjWPU2I1j1NiNY9TYjWPU2I1j
1NiNY9TYjWPU2I1j1NiNY9TYjWPU2I1j1NiNY9TYjWPU2I1j1NiNY9TYjWPU2I1j1NiNZSpsRrKV
NiNZSpsRrKVNiNZSpsRrKVNiNZSpsRrKVNiNZSpsRrKVNiNZSpsRrKVNiNZSpsRrKVNiNZSpsRrK
VNiNZSpsRrKVNiNZSpsRrKVNiNZSpsRrKVNiNZSpsRrKVNiNZSpsRrKVNiNZSpsRrKVNiNZSpsRr
KVNiNZSs0RrKVmiNZSs0RrKVmiNZSs0RrKVmiNZSs0RrKVmiNZSs0RrKVmiNZSs0RrKVmiNdSs0R
rqVmiNdSs0RrqVmiNdSs0RrqVmiNdSs0RrqVmiNdSs0RrqVmiNdSs0RrqVmiNdSs0RrqVmiNdSs0
RrqVmiNdSs0RrqVmiNdSs0RrqVmiNdSs0RrqVmiNdSs0RrqVmiNdSs0RrqVmiNdSs0RrqVmiNdSs
0RrqVmiNdSs0RrqVmiNdSs0RrqVmiNdSs0RrqVmiNdSs0RrqVmiNdSs0RrqVmiNdSs0RrqVmiNdS
s0RrqVmiNdSs0RrqVmiNdSs0RrqVmiNdSs0RrqVmiNdSs0RrqVmiNdSs0RrqVmiNdSs0RrqVmiNd
Ss0RrqVmiNdSs0RrqVmiNdSs0R9ClZojXUrNEfQpWaI+hSs0R9ClZoj6FKzRH0KVmiPoVrNEfQrW
aI+hWs0R9CtZoj6FazRH0K1miPoVrNEfQrWaI+hWs0R9CtZoj6FazRH0K1miPoVrNEfQrWaI+hWs
0R9CtZoj6FazRH0K1miPoVrNEfQrWaI+hWs0R9CtZoj6FazRH0K1miPoVrNEfQrWaI+hWs0R9CtZ
oj6FazRH0K1miPoVrNEfQrWaI+hWs0R9CtZoj6FazRH0K1miPoVrNEfQrWaI+hWs0R9CtZoj6Faz
RH0K1miPoVrNEfQrWaI+hWs0R9CtZoj6FazRH0K1miPoVrNEfQrWaI+hWs0R9KtZoj2DNXUaYqSD
Stac38s/lcjP+P8AkdqduWHntbSH+wmO1P8AmHntbSH+wmO1P+YeeztIf7CY7U/5h57O0h/sJjtT
/mHns7SH+wmO1P8AmHns7SH+wmO1P+YeeztIc6Y7U/5h57O0hzpjtT/mHns7SHOmO1P+YeeztIc6
Y7U/5h57G0hzpjtTtyw89jaQ5yDtTtyw89jaQ5yDtT/mHnsbSHOQdqduWHnsbSHOQdqduWHnsbSH
OQdqduWHnsbSHOQdqduWHnsbSHOQdqduWHnsbSHOQdqduWHnr7SHOQdqduWHnr7SHOQdqduWHnr7
SHOQdqduWHnr7SHOQdqduWHnr7aHOQdqduWHnr7aHOQdqduWHnr7aHOQdqf8w89fbQ5yDtTtyw89
fbQ5yDtT/mHnr7aHOQdqf8w89fbR5yDtTtyw89bbR5yDtTtyw89bbR5yDtT/AJh5622jzkHan/MP
PW20ecg7U/5h5622jzkHan/MPPW20ecg7U/5h5622jzkHan/ADDz1ttHnIO1P+Yeetto85B2p/zD
z1ttHnIO1P8AmHnrbaPMQdqf8w89bbR5iDtT/mHnq7aPMQdqf8w89XbR5iDtTtyw89XbR5iDtTty
w89XbR5iDtTtyw89XbR5iDtTtyw89XbR5iDtTtyw89XbR5iDtTtyw89XbR5iDtTtyw89XbR5iDtT
tyw89XbR5iDtTtyw89XbR5iDtTtyw89XbR5iDtTtyw89XbR5iDtTtyw89XbR5iDtTtyw89TbR5iD
tTtyw89TbR5iDtTtyw89TbR5iDtTtyw89TbR5iDtTtyw89TbR5iDtTtyw89TbR5iDtTtyw89TbR5
iDtT/mHnqbaPMQdqduWHnqbaPMQdqduWHnqbaPMQdqduWHnqbaPMQdqduWHnqbaPMQdqduWHnqba
PMQdqduWHnqbaPMQdqduWHnqbaPMQdqduWHnp7aPMQdqduWHnp7aPMQdqduWHnp7aPMQdqduWHnp
7aPMQdqduWHnp7aPMQdqduWHnp7aPMQdqduWHnp7aXMQdqduWHnp7aXMQdqduWHnp7aXMQdqduWH
np7aXMQdqduWHnp7aXMQdqduWHnp7aXMQdqduWHnp7aXMQdqduWHnp7aXMQdqduWHnp7aXMQdqdu
WHnpbaXMQdqduWHnpbaXMQdqduWHnpbaXMQdqduWHnpbaXKQdqduWHnpbaXKQdqduWHnpbaXKQdq
duWHnpbaXKQdqduWHnpbaXKQdqduWHnpSJcpB2p25YeelIlykHanblh56UiXKQdqduWHnpSJcpB2
p25YeelIlykHanblh56UiXKQdqduWHnpSJcpB2p25YeelIlykHanblh56MiXKQdqduWHnoyJcpR2
p25YeejIlylHanblh56MiXKUdqduWHnoyJcpR2p25YeejIlylHanblh56MiXKUdqduWHnoyJcpR2
p25YeejIlylHanblh56MiXKUdqduWHnoyJcpR2p25YeejIlylHanblh56MiXKUdqduWHnoSJcpR2
p25YeehIlylHanblh56EiXKUdqduWHnoSJcpR2p25YeehIlylHanblh56EiXKUdqduWHnoSJcpR2
p25YeehIlylHanblh56EiXKUdqduWHnoSJcpR2p25YeehIlylHanblh56EifKUdqduWHnfkT5Sjt
Ttyw878ifKULf/V/b/f/AJf+z/H+f4/n/sL7xfeL7xfeL7xfeL7xfeLzxeeLzxeeLzxeeLzxeeLz
xeeLzxeeLzxeeLzxeeLzxeeLzxeeLzxeeLzxeeLzxeeLzxeeLzxeeLzxeeLzxeeLzxeeLzxeeLzx
eeLzxeeLzxeeLzxeeLzxeeLzxeeLzxeeLzxeeLzxeeLzxeeLzxeeLzxeeLzxeeLzxeeLzxeeLzxe
eLzxeeLzxeeLzxeeLzxeeLzxeeLzxeeLzxeeLzxeeLzxeeLzxeeLzxeeLzxeeLzxeeLzxeeLzxee
LzxeeLzxeeLzxeeLzxeeLzxeeLzxeeLzxeeLzxeeLzxeeLzxeeLzxeeLzxeeLzxeeLzxeeLzxeeL
zxeeLzxeeLzxeeLzxeeLzxeeLzxeeLzxeeLzxeeLzxeeLzxeeLzxeeLzxeeLzxeeLzxeeLzxeeLz
xeeLzxeeLzxeeLzxeeLzxeeLzxeeLzxeeLzxeeLzxeeLzx2v6/7f6v8AEn938/z/AD/H+bP8f4/8
h//Z"
                            transform="matrix(0.48 0 0 0.48 -193.3054 26.2542)"
                        ></image>
                    </g>
                </g>
            </g>
        </g>
        <g>
            <defs>
                <rect id="SVGID_5_" x="-193.3" y="26.3" width="723.2" height="500" />
            </defs>
            <clipPath id="SVGID_6_">
                <use xlinkHref="#SVGID_5_" style={{ overflow: 'visible' }} />
            </clipPath>
            <path
                style={{ clipPath: 'url(#SVGID_6_)', fill: '#FFFFFF' }}
                d="M456.8,210.6c0-8.3-1.6-16.4-4.8-24.3c-3.1-7.5-7.7-14.2-13.8-20c-6.7-6.4-14-10.9-22-13.5
		c-8-2.5-18.9-3.8-32.6-3.8H198.8v61.6v61.6h184.7c13.7,0,24.6-1.3,32.6-3.8c8-2.5,15.3-7,22-13.4c6.1-5.8,10.7-12.5,13.8-20
		C455.2,226.9,456.8,218.8,456.8,210.6"
            />
            <path
                style={{ clipPath: 'url(#SVGID_6_)', fill: '#FFFFFF' }}
                d="M456.8,350.3c0-8.3-1.6-16.4-4.8-24.4c-3.1-7.6-7.7-14.2-13.8-20.1c-6.7-6.4-14-10.9-22-13.5
		c-8-2.6-18.9-3.8-32.6-3.8H198.8v61.7v61.7h184.7c13.7,0,24.6-1.3,32.6-3.8c8-2.6,15.3-7,22-13.5c6.1-5.8,10.7-12.5,13.8-20.1
		C455.2,366.7,456.8,358.6,456.8,350.3"
            />
            <path
                style={{ clipPath: 'url(#SVGID_6_)', fill: '#FFFFFF' }}
                d="M182.2,272.2c-3.7-79.7-61-131.1-151.1-131.1c-93,0-151.3,55-151.3,139.4c0,87.1,60.6,139.4,151.3,139.4
		c90.8,0,147.5-51.7,151.1-131.4H35.9v-16.4H182.2z"
            />
        </g>
    </svg>
);

import { CSSProperties } from 'react';

export interface CheckIconProps {
    color?: CSSProperties['color'];
    className?: string;
}

export const CheckIcon = ({ color, className }: CheckIconProps) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="14.324"
        height="10.362"
        viewBox="0 0 14.324 10.362"
        className={className}
    >
        <path
            id="Path_8180"
            data-name="Path 8180"
            d="M155.295,167.409l-7.924,7.924a1.218,1.218,0,0,1-1.724,0l-3.962-3.962a1.219,1.219,0,1,1,1.724-1.724l3.1,3.1,7.062-7.062a1.219,1.219,0,0,1,1.724,1.724Z"
            transform="translate(-141.328 -165.328)"
            fill={`${color || '#fff'}`}
        />
    </svg>
);

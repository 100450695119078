import React, { useMemo, useState } from 'react';
import $ from 'src/view/features/discounts/DiscountsFeature.module.scss';
import {
    addDiscountToOrder,
    DiscountStatusEnum,
    removeDiscountFromOrder,
    selectDiscountRemoveStatus,
    selectDiscounts,
    selectDiscountStatus,
    selectOrder,
    selectOrderSummary,
    selectVouchers,
    setDiscountStatus,
} from 'src/app/store/orderSlice';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
    mapAddDiscountStatusToNotification,
    mapRemoveDiscountStatusToNotification,
} from 'src/app/core/lib/discount/discount';
import { DiscountInput, DiscountOverview } from 'src/view/components/Discount';
import NotificationMessage from 'src/view/components/NotificationMessage/NotificationMessage';
import { Discount } from 'src/data/models/Discount';
import { Collapsable } from 'src/view/components/Collapsable/Collapsable';
import { useTypedDispatch } from 'src/app/store';

export function DiscountsFeature() {
    const dispatch = useTypedDispatch();
    const { t } = useTranslation();
    const discounts = useSelector(selectDiscounts);
    const vouchers = useSelector(selectVouchers);
    const discountStatus = useSelector(selectDiscountStatus);
    const discountRemoveStatus = useSelector(selectDiscountRemoveStatus);
    const orderSummary = useSelector(selectOrderSummary);
    const order = useSelector(selectOrder);

    const [discountInputValue, setDiscountInputValue] = useState('');

    const notificationMessage = mapAddDiscountStatusToNotification(
        discountStatus.status,
        t,
        discountStatus.expiry
    );

    const mappedDiscountNotification = useMemo(() => {
        const hasInvalidDiscountCode = discountRemoveStatus
            ? Object.values(discountRemoveStatus).some(
                  (value) => value === DiscountStatusEnum.CODE_COULD_NOT_REMOVE
              )
            : false;

        return hasInvalidDiscountCode
            ? mapRemoveDiscountStatusToNotification(DiscountStatusEnum.CODE_COULD_NOT_REMOVE, t)
            : undefined;
    }, [discountRemoveStatus]);

    const onDiscountInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (discountStatus.status !== DiscountStatusEnum.EMPTY) {
            dispatch(setDiscountStatus({ status: DiscountStatusEnum.EMPTY }));
        }

        const discountInputValue = e.target.value.trim();

        setDiscountInputValue(discountInputValue);
    };

    const onAddDiscount = () => {
        if (!order) return;
        dispatch(
            addDiscountToOrder(order.id, order.secret, discountInputValue, () => {
                setDiscountInputValue('');
            })
        );
    };

    const onDiscountBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (discountStatus.status !== DiscountStatusEnum.EMPTY) {
            dispatch(setDiscountStatus({ status: DiscountStatusEnum.EMPTY }));
        }

        if (e.target.value === '') {
            dispatch(setDiscountStatus({ status: DiscountStatusEnum.CODE_IS_REQUIRED }));
        }
    };

    const onRemoveDiscount = async (discount: Discount[]) => {
        if (!order) return;
        await dispatch(removeDiscountFromOrder(order.id, order.secret, discount));
    };

    return (
        <Collapsable
            initialOpened={false}
            title={t('ticket_discount_title')}
            dataCy="ticket-discount-container"
        >
            <div className={$.discountSectionWrapper} data-cy="discount-section-wrapper">
                <div className={$.discountSection}>
                    <DiscountInput
                        label={t('ticket_discount_input_label')}
                        onAdd={onAddDiscount}
                        onInputChange={onDiscountInputChange}
                        onBlur={onDiscountBlur}
                        placeholder={t('ticket_discount_input_placeholder')}
                        notification={notificationMessage}
                        value={discountInputValue}
                        disabled={discountStatus.status === DiscountStatusEnum.FETCH}
                    />

                    {(discounts.length > 0 || vouchers.length > 0) && (
                        <>
                            <div
                                className={$.discountOverviewSection}
                                data-cy="discount-overview-section"
                            >
                                <DiscountOverview
                                    label={t('ticket_discount_overview_title')}
                                    discounts={discounts}
                                    vouchers={vouchers}
                                    onRemove={onRemoveDiscount}
                                    discountRemoveStatus={discountRemoveStatus}
                                    withBorders
                                    totalOrderPrice={
                                        orderSummary
                                            ? Number(orderSummary.priceTotal) * orderSummary.numPax
                                            : 0
                                    }
                                />
                            </div>
                            {!!mappedDiscountNotification && (
                                <div
                                    className={$.discountOverviewError}
                                    data-cy="discount-notification"
                                >
                                    <NotificationMessage
                                        severity={mappedDiscountNotification.type}
                                        message={mappedDiscountNotification.message}
                                    />
                                </div>
                            )}
                        </>
                    )}
                </div>
                <div className={$.informationTitle}>
                    <span>{t('ticket_discount_information')}</span>
                </div>
                <ul className={$.informationList}>
                    <li>{t('ticket_discount_information_item_1')}</li>
                    <li>{t('ticket_discount_information_item_2')}</li>
                </ul>
            </div>
        </Collapsable>
    );
}

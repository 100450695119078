import * as React from 'react';
import { Faq, WhyPartner } from '..';
import { Item } from '../WhyPartner/WhyPartner';
import $ from './Help.module.scss';
import { SizeEnum } from 'src/view/components/Page/VSpacer';
import { Modal, ModalBody } from 'src/view/components/Modal/Modal';
import { Body } from 'src/view/components/Body/Body';
import { ContactPossiblitiesList } from 'src/app/views/contactPossibilties/ContactPossiblitiesList';
import classNames from 'classnames';
import { VSpacer } from 'src/view/components/Page';

interface Props {
    isB2B?: boolean;
    open?: boolean;
    title: string;
    text: string;
    online: boolean;
    b2bTextSupport?: string;
    b2bTextSales?: string;
    b2bPhoneNumberSupport: string;
    b2bPhoneNumberSales: string;
    b2bEmailSupport: string;
    b2bEmailSales: string;
    phoneNumber: string;
    whatsappUrl: string;
    image: {
        url: string;
        title: string;
    };
    icon: {
        url: string;
        title: string;
    };
    values: string[];
    faq?: { [key: string]: string | { [key: string]: string }[] };
    whyEquipo?: { [key: string]: string | { [key: string]: string } | Item[] };
    closeModal: () => void;
    timezone?: string;
}

const Help = (props: Props) => {
    const {
        isB2B,
        open = true,
        title,
        phoneNumber,
        b2bPhoneNumberSupport,
        b2bPhoneNumberSales,
        b2bEmailSupport,
        b2bEmailSales,
        text,
        b2bTextSupport,
        b2bTextSales,
        image,
        faq,
        whyEquipo,
        closeModal,
        whatsappUrl,
        timezone,
        online = false,
    } = props;

    return (
        <Modal title={title} onClose={closeModal} open={open}>
            <ModalBody>
                <div className={$.content}>
                    <div className={$.block}>
                        <div className={$.intro}>
                            <div className={$.employee}>
                                <div className={$.imgWrap}>
                                    <img
                                        src={image.url}
                                        alt={image.title}
                                        title={image.title}
                                        className={$.img}
                                    />
                                </div>
                                <div
                                    className={classNames(
                                        $.status,
                                        online ? $.statusOnline : $.statusOffline
                                    )}
                                />
                            </div>
                            <Body className={$.text}>
                                {text}{' '}
                                {timezone && <span className={$.timezone}> (UTC {timezone})</span>}.
                            </Body>
                        </div>

                        {!isB2B && (
                            <ContactPossiblitiesList
                                whatsappUrl={whatsappUrl}
                                phoneNumber={phoneNumber}
                            />
                        )}

                        {isB2B && (
                            <>
                                <Body marginBottom={false} marginTop={false}>
                                    {b2bTextSupport}
                                </Body>
                                <ContactPossiblitiesList
                                    phoneNumber={b2bPhoneNumberSupport}
                                    email={b2bEmailSupport}
                                    config={{
                                        hide: {
                                            chat: true,
                                        },
                                    }}
                                />
                                <Body marginBottom={false} className={$.supportText}>
                                    {b2bTextSales}
                                </Body>
                                <ContactPossiblitiesList
                                    phoneNumber={b2bPhoneNumberSales}
                                    email={b2bEmailSales}
                                    config={{
                                        hide: {
                                            chat: true,
                                        },
                                    }}
                                />
                            </>
                        )}
                    </div>
                    <div className={$.block}>
                        {whyEquipo && (
                            <WhyPartner
                                title={whyEquipo.title as string}
                                values={whyEquipo.values as Item[]}
                                icon={whyEquipo.icon as { [key: string]: string }}
                                darkText
                            />
                        )}
                        <VSpacer size={SizeEnum.Small} />
                        {faq && (
                            <Faq
                                title={faq.title as string}
                                faqItems={faq.faqItems as { [key: string]: string }[]}
                            />
                        )}
                    </div>
                </div>
            </ModalBody>
        </Modal>
    );
};

export default Help;

/** reconstruct the object based on the compound key of the properties.
 * e.g. {"a.b.c": 123} -> {a: {b: {c: 123}}}
 */
export const unflatten = (data: Record<string, any>, separator = '.') => {
    const obj: Record<string, any> = {};
    for (const compoundKey in data) {
        const value = data[compoundKey];

        const keys = compoundKey.split(separator);
        keys.reduce(function (node, key, index) {
            // Create the next node if not existed.
            // If is the last node, assign the value.
            if (!node[key]) {
                const isLastNode = keys.length - 1 == index;
                node[key] = isLastNode ? value : {};
            }
            // Move the pointer to the current node.
            return node[key];
        }, obj);
    }
    return obj;
};

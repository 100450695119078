import { TFunction } from 'i18next';

type FaqVariants = 'normal' | 'olympics' | null;

export type FaqItemsType = ReturnType<typeof getFaqItems>;

export const getFaqItems = (t: TFunction, variant: FaqVariants = 'normal') => {
    if (variant === 'olympics') {
        const supportEmail = t('_hardcoded_support_email_olympics');
        const hospitalityLink = t('_hardcoded_hospitality_packages_page_link');

        return [
            {
                question: t('olympics_faq_item_1_title'),
                answer: t('olympics_faq_item_1_description', { url: hospitalityLink }),
            },
            {
                question: t('olympics_faq_item_2_title'),
                answer: t('olympics_faq_item_2_description'),
            },
            {
                question: t('olympics_faq_item_3_title'),
                answer: t('olympics_faq_item_3_description', { emailUrl: supportEmail }),
            },
        ];
    }

    return [
        {
            question: t('faq_1_question'),
            answer: t('faq_1_answer'),
        },
        {
            question: t('faq_2_question'),
            answer: t('faq_2_answer'),
        },
        {
            question: t('faq_3_question'),
            answer: t('faq_3_answer'),
        },
    ];
};

import * as React from 'react';
import classnames from 'classnames';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Heading } from 'src/view/components/Heading/Heading';
import { Body } from 'src/view/components/Body/Body';
import $ from './SupportBlock.module.scss';
import { ContactPossiblitiesList } from 'src/app/views/contactPossibilties/ContactPossiblitiesList';

interface Props extends WithTranslation {
    title: string;
    text: string;
    b2bTextSupport: string;
    b2bTextSales: string;
    b2bEmailSales: string;
    b2bEmailSupport: string;
    image: {
        url: string;
        title: string;
    };
    phoneNumber: string;
    b2bPhoneNumberSales: string;
    b2bPhoneNumberSupport: string;
    whatsappUrl: string;
    online: boolean;
    isB2B: boolean;
    timezone?: string;
}

const SupportBlock = (props: Props) => {
    const {
        t,
        title,
        text,
        b2bTextSupport,
        b2bTextSales,
        image,
        phoneNumber,
        online,
        whatsappUrl,
        isB2B = false,
        timezone,
        b2bEmailSupport,
        b2bEmailSales,
        b2bPhoneNumberSupport,
        b2bPhoneNumberSales,
    } = props;

    const renderEmployeeImage = () => (
        <div className={$.employeeImageContainer}>
            <div
                className={classnames($.employeeImage)}
                style={{
                    backgroundImage: `url(${image.url})`,
                }}
            />
            <div className={classnames($.badge, online ? $.onlineBadge : $.offlineBadge)} />
        </div>
    );

    return (
        <div className={$.base}>
            <Heading variant="h3" marginTop={false}>
                {title}
            </Heading>

            <div className={$.wrapper}>
                {renderEmployeeImage()}

                <Body className={$.supportParagraph} marginBottom={false}>
                    {text}
                    {timezone && <span className={$.timezone}> (UTC {timezone})</span>}.
                </Body>
            </div>

            {!isB2B && (
                <div>
                    <ContactPossiblitiesList phoneNumber={phoneNumber} whatsappUrl={whatsappUrl} />
                </div>
            )}

            {isB2B && (
                <div>
                    <Body marginBottom={false} className={$.supportText}>
                        {b2bTextSupport}
                    </Body>
                    <ContactPossiblitiesList
                        phoneNumber={b2bPhoneNumberSupport}
                        email={b2bEmailSupport}
                        config={{
                            hide: {
                                chat: true,
                            },
                        }}
                    />
                    <Body marginBottom={false} className={$.supportText}>
                        {b2bTextSales}
                    </Body>
                    <ContactPossiblitiesList
                        phoneNumber={b2bPhoneNumberSales}
                        email={b2bEmailSales}
                        config={{
                            hide: {
                                chat: true,
                            },
                        }}
                    />
                </div>
            )}
        </div>
    );
};

export default withTranslation()(SupportBlock);

import { PackageType } from 'src/data/models/Order';

export enum PackageTypeSource {
    Default,
    Hotlink,
    Cache,
}

export interface PackageTypeMeta {
    source: PackageTypeSource;
    packageType: PackageType;
    overwrittenPackageType?: PackageType | null;
    overwritesCache: boolean;
}

const defaultMeta: PackageTypeMeta = {
    source: PackageTypeSource.Default,
    packageType: PackageType.TICKET_ONLY,
    overwrittenPackageType: null,
    overwritesCache: false,
};

export function getTargetPackageType(
    hotlinkType: PackageType | null,
    cachedType?: PackageType | null
): PackageTypeMeta {
    if (cachedType && !hotlinkType) {
        return {
            source: PackageTypeSource.Cache,
            packageType: cachedType,
            overwrittenPackageType: null,
            overwritesCache: false,
        };
    }

    if (hotlinkType) {
        return {
            source: PackageTypeSource.Hotlink,
            packageType: hotlinkType,
            overwrittenPackageType: cachedType,
            overwritesCache: hotlinkType !== cachedType,
        };
    }

    return defaultMeta;
}

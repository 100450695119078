import * as Sentry from '@sentry/react';
import { AppLocale } from 'src/app/constants/appLocale';
import MoneyFormatter from 'src/app/core/lib/money/MoneyFormatter';
import { getAppLocale } from 'src/app/hooks/use-locale';
import store from 'src/app/store';

let moneyFormatter: MoneyFormatter;

export function init(locale: AppLocale, defaultCurrency: string) {
    moneyFormatter = new MoneyFormatter(locale, defaultCurrency);
}

interface MoneyFormattingOptions {
    hideZeroDecimals?: boolean;
    zeroDecimalReplace?: string;
    currency?: string;
}
/**
 * Utility function to format a given monetary value based on the user's locale and currency settings.
 *
 * This function supports different locales (e.g., FR: "20€", NL: "€20", EN-GB: "£20"),
 * and automatically formats the currency symbol according to the locale.
 * It also handles fractional values (e.g., "£20.49").
 *
 * Special behavior:
 * - If `options.hideZeroDecimals` is true, zero decimals (e.g., "20.00") are hidden,
 *   meaning whole numbers are displayed without any decimals (e.g., "£20").
 * - Otherwise, the function shows 2 decimal places when the value has a fractional part,
 *   or 0 decimal places for whole numbers.
 *
 * @param {string} value - The monetary value to format as a string.
 * @param {MoneyFormattingOptions} [options] - Optional formatting options (e.g., hideZeroDecimals, currency).
 * @returns {string} - The formatted money value according to the locale and currency. Default currency is EUR
 */
export function formatMoney(value: string, options?: MoneyFormattingOptions): string {
    const locale = getAppLocale();
    const amount = getValidNumber(value);
    const hasDecimal = amount % 1 !== 0; // Check if the value contains a fractional part
    const storeState = store.getState();
    const currency = storeState.app?.partnerConfig?.currency || 'EUR';

    const minimumFractions = options?.hideZeroDecimals ? 0 : hasDecimal ? 2 : 0;

    const formatter = new Intl.NumberFormat(locale, {
        style: 'currency',
        currency,
        maximumFractionDigits: 2,
        minimumFractionDigits: minimumFractions,
    });

    return formatter.format(amount);
}

const getValidNumber = (value: string) => {
    const amount = Number(value);
    if (isNaN(amount)) {
        Sentry.captureException(new Error(`Invalid money amount: ${value}`));

        return 0;
    }

    return Number(value);
};

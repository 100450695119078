import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import $ from './Footer.module.scss';
import { PayIcons } from '..';
import { PaymentGateway } from 'src/data/services/payments';
import TrustpilotWidget from '../TrustpilotWidget';
import { hasMarketingCookieConsent } from 'src/app/utils/consent';
import { Body } from 'src/view/components/Body/Body';
import classnames from 'classnames';
import { LockIcon } from 'src/images/icons/LockIcon';
import { getFaqData } from 'src/view/features/Faq/FaqBlockFeature';
import Help from 'src/view/components/Help';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectIsB2BMode } from 'src/app/store/appSlice';
import { selectEventDetail } from 'src/app/store/eventSlice';
import { isOlympicsSeries } from 'src/app/constants/olympics';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props extends WithTranslation {
    gateways: PaymentGateway[];
    trustpilotBusinessId?: string | null | undefined;
}

const Footer = (props: Props) => {
    const { t, gateways, trustpilotBusinessId } = props;
    const showThrustpilotWrapper = trustpilotBusinessId && hasMarketingCookieConsent();
    const [faqOpen, setFaqOpen] = useState(false);
    const { whyEquipo, faq, help } = getFaqData(t);
    const isB2B = useSelector(selectIsB2BMode);
    const eventDetail = useSelector(selectEventDetail);

    return (
        <div className={$.container}>
            <div className={$.helpContainer}>
                <div className={$.helpText}>
                    <span>{t('stepindicator_help')}</span>
                    <button
                        className="helpMobile"
                        type="button"
                        onClick={() => {
                            setFaqOpen(true);
                        }}
                    >
                        {t('stepindicator_contact_us_button')}
                    </button>
                </div>

                {faqOpen && (
                    <Help
                        {...help}
                        isB2B={isB2B}
                        faq={faq}
                        phoneNumber={help.phoneNumber}
                        whatsappUrl={help.whatsappUrl}
                        whyEquipo={whyEquipo}
                        closeModal={() => setFaqOpen(false)}
                        showContactPossibilities={!isB2B}
                    />
                )}
            </div>
            <footer className={classnames($.base, !showThrustpilotWrapper && $.baseAlignRight)}>
                {showThrustpilotWrapper && (
                    <div className={$.thrustPilotWrapper}>
                        <TrustpilotWidget businessunitId={trustpilotBusinessId} theme="light" />
                    </div>
                )}

                <div className={$.paySafeWrapper}>
                    <Body
                        marginTop={false}
                        marginBottom={false}
                        className={classnames($.columnTitle, $.paySafe)}
                    >
                        {t('footer_safepayment')}
                    </Body>
                    <Body marginTop={false} marginBottom={false} className={$.secure}>
                        <LockIcon />
                        {t('progresscard_booksafe')}
                    </Body>
                    <PayIcons
                        gateways={gateways}
                        iconVariant={
                            isOlympicsSeries(eventDetail?.serie.id || '') ? 'large' : undefined
                        }
                    />
                </div>
            </footer>
        </div>
    );
};

export default withTranslation()(Footer);

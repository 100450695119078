import { getStyle, setStyle } from 'src/data/services/cache';

const styleTargetId = 'theme-style-target';

export const initThemeStyle = () => {
    const style = document.createElement('style');
    style.id = styleTargetId;
    document.head.appendChild(style);

    const cachedStyle = getStyle();

    if (!cachedStyle) return;

    style.innerHTML = cachedStyle;
};

export const setThemeStyle = (style: string, writeCache: boolean = true) => {
    const styleElement = document.getElementById(styleTargetId);

    if (!styleElement) return;

    styleElement.innerHTML = style;

    if (writeCache) {
        setStyle(style);
    }
};

import { get, remove, set } from 'local-storage';
import moment from 'moment-timezone';
import Settings from 'src/Settings';
import { AppLocale } from 'src/app/constants/appLocale';
import RoomLayoutFormModel from 'src/app/models/RoomLayoutFormModel';
import store from 'src/app/store';
import { selectEventDetail } from 'src/app/store/eventSlice';
import { Preferences } from 'src/data/models/Preferences';
import { Traveler } from 'src/data/models/Traveler';

export function resetOrder() {
    remove('children');
    remove('adults');
    remove('roomLayout');
    remove('outDate');
    remove('inDate');
    // remove('bookingcode');
    remove('rooms');
    remove('userStartedEnteringDetails');
}

export function removeBookingCode() {
    remove('bookingcode');
}

export function setSessionEndBackLink(backLink: string) {
    set('sessionEndBackLink', backLink);
}
/** Is used to cache the session back link before deleting the session.
 * Otherwise, since the session is deleted upon landing at /session-end, the correct back link would be lost.
 */
export function getSessionEndBackLink() {
    return get<string>('sessionEndBackLink');
}

export function removeSessionEndBackLink() {
    remove('sessionEndBackLink');
}

export function setStyle(style: string) {
    set('themeStyle', style);
}

export function getStyle() {
    return get<string>('themeStyle');
}

export function getOrderCachedAt() {
    const dateString = get<Date>('orderCachedAt');

    if (!dateString) {
        return dateString;
    }

    return new Date(dateString);
}

export interface Occupancy {
    adults: Traveler[];
    roomLayout: RoomLayoutFormModel[];
}

/** Default in API is 2 adults */
const getDefaultTravelers = () =>
    Array.from({ length: 2 }).map(() => new Traveler({ type: 'adult' }));

export function getOccupancy(): Occupancy {
    const adults = get<Traveler[]>('adults') || getDefaultTravelers();
    const roomLayout = get<RoomLayoutFormModel[]>('roomLayout') || [];

    return { adults, roomLayout };
}

export function getPreferences() {
    const { adults, roomLayout } = getOccupancy();
    const { inDate, outDate } = getInOutDate();

    const preferences: Preferences = {
        adults,
        roomLayout,
        inDate,
        outDate,
    };

    return preferences;
}

export function setChildren(children: Traveler[]) {
    set('children', children);
}

export function setAdults(adults: Traveler[]) {
    set('adults', adults);
}

export function setRooms(rooms: number) {
    set('rooms', rooms);
}

export function setRoomLayout(roomLayout?: RoomLayoutFormModel[]) {
    if (roomLayout == null) {
        remove('roomLayout');
        return;
    }

    set('roomLayout', roomLayout);
}

export function getInOutDate() {
    const outDate = get<number>('outDate') || 0;
    const inDate = get<number>('inDate') || 0;
    return { outDate, inDate };
}

export function getInOutDateFormats() {
    const { outDate, inDate } = getInOutDate();
    const eventDetail = selectEventDetail(store.getState());

    if (eventDetail == null) {
        return { startDate: null, endDate: null };
    }

    const outDateBase = moment(eventDetail.dateMinimumStart);
    const inDateBase = moment(eventDetail.dateMinimumEnd);

    const startDate = outDateBase.add(-outDate, 'days');
    const endDate = inDateBase.add(inDate, 'days');

    return { startDate, endDate };
}

export function setInDate(inDate: number) {
    set('inDate', inDate);
}

export function setOutDate(outDate: number) {
    set('outDate', outDate);
}

export function setBooking(code: string) {
    set('bookingcode', code);
}

export function getUserStartedEnteringDetails() {
    return get<boolean>('userStartedEnteringDetails');
}

export function setUserStartedEnteringDetails(status: boolean) {
    set('userStartedEnteringDetails', status);
}

export function getPartnerReferrerUrl() {
    return get<string>('partnerReferrerUrl');
}

export function setPartnerReferrerUrl(url: string) {
    return set<string>('partnerReferrerUrl', url);
}

export function getBooking() {
    return get<string>('bookingcode');
}

export function setOrderCachedAt() {
    set('orderCachedAt', new Date());
}

export function bustOldCache() {
    const maxAge = Settings.cache.maxAgeOrder;

    const orderCachedAt = getOrderCachedAt();
    const now = new Date();

    const orderCacheAge = orderCachedAt ? now.getTime() - orderCachedAt.getTime() : 0;

    if (!orderCachedAt || orderCacheAge > maxAge) {
        resetOrder();
    }
}

export function setCachedLocale(lang: AppLocale) {
    set('appLocale', lang);
}

export function getCachedLocale() {
    return get<string>('appLocale');
}

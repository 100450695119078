import { TFunction } from 'i18next';
import moment, { Moment } from 'moment';
import { getDateOfBirthValidation } from 'src/app/forms/genericValidation/dateOfBirthValidation';
import * as Yup from 'yup';

const getRoomLayoutSelectorFormValidationScheme = (t: TFunction, eventDate: Moment) => {
    return Yup.object({
        rooms: Yup.array().of(
            Yup.object().shape({
                adults: Yup.number().required(),
                children: Yup.number(),
                childInformation: Yup.array().of(
                    Yup.object().shape({
                        dateOfBirth: getDateOfBirthValidation(t).test(
                            'isChildUnder18',
                            t('birthday_children_age_error'),
                            (birthDate) => {
                                if (!birthDate) return false;

                                const filledDate = moment(
                                    `${birthDate.day}-${birthDate.month}-${birthDate.year}`,
                                    'DD-MM-YYYY'
                                );

                                const ageOnEventDate = moment(eventDate).diff(filledDate, 'years');

                                return ageOnEventDate < 18;
                            }
                        ),
                    })
                ),
            })
        ),
    });
};

export default getRoomLayoutSelectorFormValidationScheme;

import React, { useState, useEffect } from 'react';
import { isMobile as checkIfMobile, createDebounce } from 'src/app/utils/utils';

const MobileContext = React.createContext<boolean>(false);

export default MobileContext;

const mobileDebounce = createDebounce(200);

export function MobileProvider({ children }: React.PropsWithChildren<{}>) {
    const [isMobile, setIsMobile] = useState(checkIfMobile());

    useEffect(() => {
        setIsMobile(checkIfMobile());
        const handleResize = () => {
            mobileDebounce(() => {
                setIsMobile(checkIfMobile());
            });
        };
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return <MobileContext.Provider value={isMobile}>{children}</MobileContext.Provider>;
}

import { Availability } from '../models/Availability';
import { PackageType } from '../models/Order';
import { OrderTypeUtil } from 'src/app/utils/utils';

export function getPrice(
    accommodationId: string | null | undefined,
    availability: Availability,
    ticketId: string | null,
    packageType: PackageType,
    breakfast: boolean
) {
    let accommodationSupplement = 0;
    const accommodationAvailability = accommodationId
        ? availability.findAccommodationById(accommodationId)
        : null;
    const ticketAvailability = ticketId !== null ? availability.findTicketById(ticketId) : null;

    if (OrderTypeUtil.hasHotel(packageType) && accommodationAvailability != null) {
        const cheapest = breakfast
            ? accommodationAvailability.cheapestBreakfast
            : accommodationAvailability.cheapest;

        accommodationSupplement = cheapest == null ? 0 : cheapest.supplementPP;
    }

    let price = 0;

    if (availability != null) {
        const { price: newPrice } = availability.basePackage;
        if (newPrice.TICKET_HOTEL) {
            price = Number(newPrice.TICKET_HOTEL);
        } else if (newPrice.TICKET_ONLY) {
            price = Number(newPrice.TICKET_ONLY);
        }
    }

    let totalPrice = price + accommodationSupplement;

    if (ticketAvailability != null) {
        totalPrice += ticketAvailability.supplementPP;
    }

    return { accommodationSupplement, price, totalPrice };
}

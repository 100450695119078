/* eslint-disable lines-between-class-members */
export interface ImageEntity {
    type: string;
    small: string;
    medium: string;
    large: string;
}

export class Image {
    public readonly small: string;
    public readonly medium: string;
    public readonly large: string;

    public constructor(image: ImageEntity) {
        this.small = image.small;
        this.medium = image.medium;
        this.large = image.large;
    }
}

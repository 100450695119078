interface ChatServiceConfig {
    hideTab?: boolean;
    mobileHideTab?: boolean;
    onInit?: () => void;
}

class ChatService {
    public isInitialized: boolean = false;

    public init(config?: ChatServiceConfig) {
        window.robin_settings = {
            hide_tab: config?.hideTab,
            mobile_hide_tab: config?.mobileHideTab,
            callback: (event) => {
                if (event === 'init') {
                    this.isInitialized = true;

                    config?.onInit?.();
                }
            },
        };
    }

    public show = () => window.__robin?.show();

    public getOnlineStatus = () => window.__robin?.getOnlineStatus() || false;
}

export default new ChatService();

import { ProgressBarStep } from 'src/view/components/ProgessBar/interfaces';
import { RouteHelper } from 'src/app/utils/RouteHelper';
import { TFunction } from 'i18next';

export const getProgressSteps = (t: TFunction, eventId?: string): ProgressBarStep[] => [
    {
        step: 1,
        name: t('stepindicator_createtrip'),
        to: eventId ? RouteHelper.getTicketRoute(eventId, {}) : null,
    },
    {
        step: 2,
        name: t('stepindicator_details'),
        // This is a UX workaround.
        // Because of the Adyen in-app flow and TW limitation,
        // the order is booked and no longer editable when user reaches the payment page.
        // User needs to go to step 1 to create a new order.
        to: eventId ? RouteHelper.getTicketRoute(eventId, {}) : null,
    },
    {
        step: 3,
        name: t('stepindicator_payment'),
        to: eventId ? RouteHelper.getPaymentRoute(eventId) : null,
    },
];

/* eslint-disable lines-between-class-members */
export interface CityEntity {
    name: string;
    latitude: number;
    longitude: number;
}

export class City {
    public readonly name: string;
    public readonly latitude: number;
    public readonly longitude: number;

    public constructor(city: CityEntity) {
        this.name = city.name;
        this.latitude = city.latitude;
        this.longitude = city.longitude;
    }
}

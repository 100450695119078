/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable lines-between-class-members */
import { getReversedDate } from 'src/app/utils/utils';

export class Traveler {
    public type: 'traveller' | 'booker';
    public readonly birthday?: string = '';
    public readonly gender?: string = 'M';
    public readonly firstName?: string = '';
    public readonly lastName?: string = '';
    public readonly nationality: string = '';

    public constructor(traveller) {
        this.type = traveller.type;
        this.birthday = traveller.birthday || this.birthday;
        this.gender = traveller.gender || this.gender;
        this.firstName = traveller.firstName || this.firstName;
        this.lastName = traveller.lastName || this.lastName;
        this.nationality = traveller.nationality || this.nationality;
    }

    // eslint-disable-next-line @typescript-eslint/explicit-member-accessibility
    toBackendFormat(traveler: Traveler) {
        const { birthday, firstName, lastName, gender, nationality } = traveler;

        return {
            birth_date: getReversedDate(birthday),
            first_name: firstName,
            last_name: lastName,
            gender,
            nationality,
        };
    }
}

export class Booker extends Traveler {
    public readonly type = 'booker';
    public readonly addressLine1: string = '';
    public readonly addressLine2?: string;
    public readonly zipCode?: string;
    public readonly city: string = '';
    public readonly email: string = '';
    public readonly phoneNumber: string = '';
    public readonly countryCode: string = '';
    public readonly countryDialCode: string = '';

    public constructor(booker) {
        super(booker);
        this.addressLine1 = booker.addressLine1 || this.addressLine1;
        this.addressLine2 = booker.addressLine2;
        this.zipCode = booker.zipCode;
        this.city = booker.city || this.city;
        this.email = booker.email || this.email;
        this.phoneNumber = booker.phoneNumber || this.phoneNumber;
        this.countryCode = booker.countryCode || this.countryCode;
        this.countryDialCode = booker.countryDialCode || this.countryDialCode;
    }

    // eslint-disable-next-line @typescript-eslint/explicit-member-accessibility
    toBackendFormat(booker: Booker) {
        const {
            addressLine1,
            birthday,
            city,
            addressLine2,
            countryCode,
            email,
            firstName,
            lastName,
            gender,
            phoneNumber,
            zipCode,
            nationality,
            countryDialCode,
        } = booker;

        return {
            address_line_1: addressLine1,
            birth_date: getReversedDate(birthday),
            city,
            address_line_2: addressLine2,
            country_code: countryCode,
            email,
            first_name: firstName,
            last_name: lastName,
            gender,
            phone_number: countryDialCode + phoneNumber,
            zip_code: zipCode,
            nationality,
        };
    }
}

import { TFunction } from 'i18next';

export const formatTicketDeliveryString = (
    ticketSentEarliestDays: number,
    ticketSentLatestDays: number,
    t: TFunction
) => {
    return (
        <>
            {ticketSentEarliestDays === 1 && ticketSentLatestDays === 1 ? (
                <>{t('ticket_ticket_delivery_part_2_1')}</>
            ) : (
                <>
                    {t('ticket_ticket_delivery_part_2', {
                        earliestDay:
                            ticketSentEarliestDays === 0
                                ? t('ticket_ticket_delivery_part_2_2')
                                : ticketSentEarliestDays,
                        latestDay: ticketSentLatestDays,
                    })}
                </>
            )}
        </>
    );
};

import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { EventDetail } from 'src/data/models/EventDetail';
import { Accommodation } from 'src/data/models/Accommodation';
import { Availability } from 'src/data/models/Availability';
import { Ticket } from 'src/data/models/Ticket';
import { PaymentGateway } from 'src/data/services/payments';
import { PackageType } from 'src/data/models/Order';
import { Discount } from 'src/data/models/Discount';
import { RemoveDiscountStatusType } from 'src/app/store/orderSlice';
import TravelDetailTable from 'src/view/components/TravelDetailTable/TravelDetailTable';
import { Occupancy } from 'src/data/services/cache';
import TravelDetailList from '../TravelDetailList';
import { Modal, ModalBody } from 'src/view/components/Modal/Modal';
import $ from './TravelDetail.module.scss';

interface Props extends WithTranslation {
    open: boolean;
    gateways: PaymentGateway[];
    availability: Availability | null | undefined;
    eventDetail: EventDetail;
    accommodation?: Accommodation;
    onClick: () => void;
    openHotelDetail: (accommodation: Accommodation) => void;
    step: number;
    showPricing: boolean;
    leadTimeDays: number;
    leadTimePercentage: number;
    orderSelectedTicket: Ticket | null;
    orderSelectedAccommodation: Accommodation | null;
    orderSelectedAccommodationIncludesBreakfast: boolean | null;
    packageType?: PackageType;
    discounts: Discount[];
    vouchers: Discount[];
    onRemoveDiscount: (d: Discount | Discount[]) => void;
    discountRemoveStatus: RemoveDiscountStatusType;
    totalOrderPrice: number;
    occupancy: Occupancy;
}

class TravelDetail extends React.Component<Props> {
    public constructor(props: Props) {
        super(props);
    }

    public render() {
        const {
            eventDetail,
            t,
            openHotelDetail,
            step,
            showPricing,
            leadTimeDays,
            leadTimePercentage,
            availability,
            orderSelectedTicket,
            orderSelectedAccommodation,
            orderSelectedAccommodationIncludesBreakfast,
            gateways,
            packageType,
            discounts,
            vouchers,
            onRemoveDiscount,
            discountRemoveStatus,
            totalOrderPrice,
            onClick,
            open = false,
        } = this.props;

        const renderTravelDetails = () => {
            return (
                <div className={$.topRow}>
                    <div className={$.block}>
                        <TravelDetailTable
                            eventDetail={eventDetail}
                            orderSelectedTicket={orderSelectedTicket}
                            packageType={packageType}
                            orderSelectedAccommodation={orderSelectedAccommodation}
                            onHotelDetailClick={openHotelDetail}
                        />
                    </div>
                    <div className={$.block}>
                        <TravelDetailList
                            discounts={discounts}
                            vouchers={vouchers}
                            packageType={packageType}
                            gateways={gateways}
                            availability={availability}
                            eventDetail={eventDetail}
                            step={step}
                            showPricing={showPricing}
                            leadTimeDays={leadTimeDays}
                            leadTimePercentage={leadTimePercentage}
                            orderSelectedTicket={orderSelectedTicket}
                            orderSelectedAccommodation={orderSelectedAccommodation}
                            orderSelectedAccommodationIncludesBreakfast={
                                orderSelectedAccommodationIncludesBreakfast
                            }
                            onRemoveDiscount={onRemoveDiscount}
                            discountRemoveStatus={discountRemoveStatus}
                            totalOrderPrice={totalOrderPrice}
                        />
                    </div>
                </div>
            );
        };

        return (
            <Modal
                title={t('progresscard_your_ticket')}
                onClose={onClick}
                open={open}
                className={$.wrap}
            >
                <ModalBody>{renderTravelDetails()}</ModalBody>
            </Modal>
        );
    }
}

export default withTranslation()(TravelDetail);

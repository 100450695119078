import moment from 'moment-timezone';
import 'moment/min/locales';
import { useEffect, useState } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { Redirect, Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import { environment } from 'src/app/utils/utils';
import { MobileProvider } from './app/context/MobileContext';

import { Theme, ThemeProvider } from '@mui/material';
import { isAxiosError } from 'axios';
import { QueryClient, QueryClientProvider } from 'react-query';
import {
    initialize,
    resetSession,
    selectIsReady,
    selectPartnerConfig,
    selectSession,
    setSessionOrder,
} from 'src/app/store/appSlice';
import { resetOrder, selectOrder } from 'src/app/store/orderSlice';
import * as ExceptionLogger from 'src/app/utils/exceptionLogger';
import { setActiveOrderScope } from 'src/app/utils/exceptionLogger';
import { Order as OrderModel } from 'src/data/models/Order';
import { bustOldCache, getCachedLocale, setCachedLocale } from 'src/data/services/cache';
import * as formatting from 'src/data/services/formatting';
import * as Order from 'src/data/services/order';
import RobinChat from 'src/view/components/RobinChat/RobinChat';
import { type AppLocale } from './app/constants/appLocale';
import { useCustomTheme } from './app/hooks/use-custom-theme';
import { useInitAnalytics } from './app/hooks/use-init-analytics';
import { useAppLocale } from './app/hooks/use-locale';
import { Completed } from './app/pages/Completed/Completed';
import { Details } from './app/pages/Details/Details';
import { HotelPage } from './app/pages/Hotel/Hotel';
import { Loading } from './app/pages/Loading/Loading';
import { NotFound } from './app/pages/NotFound/NotFound';
import { PaymentPage } from './app/pages/Payment/Payment';
import { PaymentRequest } from './app/pages/PaymentRequest/PaymentRequest';
import { SessionEnd } from './app/pages/SessionEnd/SessionEnd';
import { TicketPage } from './app/pages/Ticket/Ticket';
import { RootState, TypedDispatch, useTypedDispatch } from './app/store';

declare module '@mui/styles/defaultTheme' {
    interface DefaultTheme extends Theme {}
}

/**
 * Will reset the order cache when the order id is not
 * found in the backend.
 */
async function clearCacheIfOrderDoesNotExistAnymore(
    order: OrderModel | null,
    dispatch: TypedDispatch<RootState>,
    locale: AppLocale | null
) {
    if (!order || !locale) return;

    try {
        await Order.getOrder(order.id, order.secret, locale);
    } catch (err) {
        if (isAxiosError(err)) {
            // Sentry.captureException(err, (scope) => {
            //     scope.setTag('origin', 'App.tsx');

            //     return scope;
            // });

            if (err.response?.status === 404) {
                dispatch(resetOrder());
            }
        }
    }
}

const redirects = [
    {
        from: '/ticket/:eventId',
        to: {
            pathname: '/:eventId/ticket',
            search: window.location.search,
        },
    },
];

const baseRoutes = [
    {
        path: '/:eventId/ticket',
        component: TicketPage,
        exact: true,
    },
    {
        path: '/:eventId/hotel',
        component: HotelPage,
        exact: true,
    },
    {
        path: '/:eventId/details',
        component: Details,
        exact: true,
    },
    {
        path: '/:eventId/payment',
        component: PaymentPage,
        exact: true,
    },
    {
        path: '/:eventId/session-end',
        component: SessionEnd,
        exact: true,
    },
    {
        path: '/completed',
        component: Completed,
        exact: true,
    },
    {
        path: '/payment-request/:paymentRequestId',
        component: PaymentRequest,
        exact: true,
    },
];

const routes = [
    ...baseRoutes,
    // In order to support `locale` route param.
    ...baseRoutes.map(({ path, ...route }) => ({ ...route, path: '/:locale' + path })),
    {
        path: '*',
        component: NotFound,
        exact: false,
    },
];

const queryClient = new QueryClient();

function App() {
    const locale = useAppLocale();
    const [initialized, setInitialized] = useState(false);
    const config = useSelector(selectPartnerConfig);
    const isReady = useSelector(selectIsReady);
    const session = useSelector(selectSession);
    const order = useSelector(selectOrder);
    const dispatch = useTypedDispatch();
    const theme = useCustomTheme();

    useInitAnalytics(initialized);

    useEffect(() => {
        if (initialized) return;

        bustOldCache();

        const env = environment();
        if (env !== 'local' && env !== 'development') {
            ExceptionLogger.init();
        }

        Promise.all([
            dispatch(initialize()),
            clearCacheIfOrderDoesNotExistAnymore(order, dispatch, locale),
        ]).then(() => setInitialized(true));
    }, [dispatch, initialized, order, locale]);

    useEffect(() => {
        const cachedLocale = getCachedLocale();

        // If there is no cached locale, set it and continue.
        if (!cachedLocale) {
            setCachedLocale(locale);
        }
        // If the cached locale is different from the current locale, set the new locale and reset session
        else if (cachedLocale !== locale) {
            setCachedLocale(locale);
            resetSession();
        }
    }, [locale]);

    useEffect(() => {
        if (!config) return;

        ExceptionLogger.setConfigScope(config);
        formatting.init(locale, config.currency);

        moment.locale(locale.split('-')[0]);
    }, [config, locale]);

    useEffect(() => {
        if (!order) {
            if (session) {
                dispatch(setSessionOrder({ orderId: '', orderSecret: '' }));
            }
            return;
        }

        setActiveOrderScope(order);
    }, [order, session]);

    return (
        <MobileProvider>
            <ThemeProvider theme={theme}>
                <QueryClientProvider client={queryClient}>
                    {isReady && (
                        <Router>
                            <Switch>
                                {redirects.map((redirect) => (
                                    <Redirect key={redirect.from} {...redirect} />
                                ))}
                                {routes.map((route) => (
                                    <Route key={route.path} {...route} />
                                ))}
                            </Switch>
                        </Router>
                    )}

                    {!isReady && <Loading />}

                    {config?.robinChatApiKey && <RobinChat apiKey={config.robinChatApiKey} />}
                </QueryClientProvider>
            </ThemeProvider>
        </MobileProvider>
    );
}

export default hot(module)(App);

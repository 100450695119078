export type ThemeSettings = {
    showTopbar: boolean;
};

const defaultThemeSettings: ThemeSettings = {
    showTopbar: true,
};

export interface ConfigCheckoutEntity {
    general: {
        logo: string;
        copyright_line: string | null;
        support_photo: string | null;
        support_text: string | null;
        name: string | null;
    };
    theme: {
        css: string | null;
        settings: ThemeSettings;
    };
}

export class ConfigCheckout {
    public readonly general: ConfigCheckoutEntity['general'];

    public readonly theme: {
        css: string;
        settings: ThemeSettings;
    };

    public constructor(config: ConfigCheckoutEntity) {
        this.general = {
            ...config.general,
            name: config.general.name || 'Travelflow',
        };

        const themeSettings = config.theme?.settings || {};

        this.theme = {
            css: config.theme?.css || '',
            settings: Object.assign({}, defaultThemeSettings, themeSettings),
        };
    }
}

import * as React from 'react';
import classnames from 'classnames';
import { Heading } from 'src/view/components/Heading/Heading';
import { Body } from 'src/view/components/Body/Body';
import $ from './WhyPartner.module.scss';

export type Item = {
    title: string;
    link?: {
        text: string;
        href: string;
    };
    popover: string;
};

interface Props {
    title: string;
    values: Item[];
    icon: { component: JSX.Element };
    darkText?: boolean;
}

const WhyPartner = (props: Props) => {
    const { title, values, icon, darkText } = props;

    return (
        <section className={$.base} data-testid="WhyPartner">
            <Heading variant="h3" marginTop={false}>
                {title}
            </Heading>
            <ul className={$.list}>
                {values.map((item) => (
                    <li
                        key={item.title}
                        className={classnames($.listItem, darkText && $.darkListItem)}
                    >
                        <span className={$.icon}>{icon.component}</span>
                        <Body marginTop={false} marginBottom={false}>
                            {item.title}
                        </Body>
                        {item.link && (
                            <a
                                target="_blank"
                                rel="noreferrer"
                                className={$.whyPartnerLink}
                                href={item.link.href}
                            >
                                {item.link.text}
                            </a>
                        )}
                    </li>
                ))}
            </ul>
        </section>
    );
};

export default WhyPartner;

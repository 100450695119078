import { useTranslation } from 'react-i18next';
import { VerifyEmailResponse } from 'src/data/services/email';
import $ from 'src/view/components/DetailsTraveler/DetailsTraveler.module.scss';
import FieldErrorMessage from 'src/view/components/Form/FieldErrorMessage';

export function EmailErrorMessage({
    emailErrors,
    selectSuggestion,
}: {
    emailErrors: VerifyEmailResponse;
    selectSuggestion: (suggestion: string) => void;
}) {
    const { t } = useTranslation();

    const hasSuggestions = emailErrors.suggestions?.length > 0;

    if (emailErrors.certainly_wrong)
        return (
            <FieldErrorMessage
                dataCy="invalid-email-message"
                message={t('details_email_invalid')}
            />
        );
    if (emailErrors?.possibly_wrong && !hasSuggestions)
        return (
            <FieldErrorMessage
                dataCy="possibly-invalid-email-message"
                message={t('details_email_possibly_invalid')}
            />
        );

    if (emailErrors?.possibly_wrong && hasSuggestions) {
        return (
            <FieldErrorMessage
                message={
                    <>
                        {t('details_email_possibly_invalid_with_suggestions')}{' '}
                        {emailErrors?.suggestions
                            ?.filter((value): value is string => value !== null && value.length > 0)
                            .map((suggestion) => {
                                return (
                                    <span
                                        key={suggestion}
                                        className={$.suggestion}
                                        onClick={() => {
                                            selectSuggestion(suggestion);
                                        }}
                                    >
                                        {suggestion}
                                    </span>
                                );
                            })}
                    </>
                }
            />
        );
    }

    return null;
}

import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import $ from './HotelList.module.scss';
import HotelItem from '../HotelItem';
import { Accommodation } from 'src/data/models/Accommodation';
import { ShowMoreButton } from 'src/app/views/ShowMoreButton';
import { Divider } from '@mui/material';

interface Props extends WithTranslation {
    accommodations: Accommodation[];
    selectedAccommodation?: Accommodation | null;
    breakfast: boolean;
    onChange: (accomodation: Accommodation) => void;
    openDetail: (accommodation: Accommodation) => void;
    openQuickView?: (Accommodation: Accommodation) => void;
    wide?: boolean;
}

interface State {
    currentPage: number;
    isLoaded: boolean;
}

class HotelList extends React.Component<Props, State> {
    public hotelsShownPerPage = 3;

    public constructor(props) {
        super(props);

        this.state = {
            currentPage: 1,
            isLoaded: false,
        };
    }

    public showMoreHotels = () => {
        const { currentPage } = this.state;
        const newCurrentPage = currentPage + 1;

        this.setState({
            currentPage: newCurrentPage,
        });
    };

    public render() {
        const { t, breakfast, openDetail, onChange, accommodations, selectedAccommodation, wide } =
            this.props;

        const { currentPage } = this.state;
        const pages = Math.ceil(accommodations.length / this.hotelsShownPerPage);
        const paginatedAccommodations = accommodations.slice(
            0,
            this.hotelsShownPerPage * currentPage
        );

        const selectedHotel = selectedAccommodation
            ? (accommodations || []).find(
                  (accommodation) => accommodation.id === selectedAccommodation.id
              )
            : null;

        const selectedAccommodationIsInList =
            paginatedAccommodations.filter((a) => a.id === selectedAccommodation?.id).length > 0;

        return (
            <>
                {!!selectedHotel && !selectedAccommodationIsInList && (
                    <>
                        <HotelItem
                            accommodation={selectedHotel}
                            selected
                            breakfast={breakfast}
                            key={selectedHotel?.id}
                            onChange={() => onChange(selectedHotel)}
                            openDetail={openDetail}
                            wide={wide}
                        />

                        <Divider />
                    </>
                )}

                <div className={$.container}>
                    {paginatedAccommodations.map((accommodation) => (
                        <HotelItem
                            accommodation={accommodation}
                            selected={
                                selectedAccommodation &&
                                accommodation.id === selectedAccommodation.id
                            }
                            breakfast={breakfast}
                            key={accommodation.id}
                            onChange={() => onChange(accommodation)}
                            openDetail={openDetail}
                            wide={wide}
                        />
                    ))}

                    {currentPage < pages && (
                        <ShowMoreButton
                            text={`${t('hotel_details_showing_number_of', {
                                number: this.hotelsShownPerPage * currentPage,
                                total: accommodations.length,
                            })}`}
                            onClick={this.showMoreHotels}
                            showMoreText={t('hotel_show_more')}
                        />
                    )}
                </div>
            </>
        );
    }
}

export default withTranslation()(HotelList);

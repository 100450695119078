import { TFunction } from 'i18next';

export const getContactData = (t: TFunction) => {
    return {
        phoneNumber: t('_hardcodedSupportPhoneNumber'),
        whatsappUrl: t('_hardcoded_support_whatsapp_url'),
        email: t('_hardcodedSupportEmail'),
        b2bPhoneNumberSales: t('_hardcodedSupportMobileNumberB2B'),
        b2bPhoneNumberSupport: t('_hardcodedSupportMobileNumberB2B'),
        b2bPhoneNumberWhatsapp: t('_hardcodedSupportMobileNumberB2B'),
        b2bEmailSales: t('_hardcodedSupportSalesEmailB2B'),
        b2bEmailSupport: t('_hardcoded_support_email_b2b'),
    };
};

import moment, { Moment } from 'moment';

export type AppConstants = {
    fallbackWebsite: string;
    persistedDataVersion: number;
    metadataSourceName: string;
    travellerDataExpirationMinutes: number;
    termsAndConditionsUrl: string;
    customerServiceHours: {
        min: Moment;
        max: Moment;
    };
};

const minCustomerServiceDate = new Date();
minCustomerServiceDate.setUTCHours(8);
minCustomerServiceDate.setUTCMinutes(0);

const maxCustomerServiceDate = new Date();
maxCustomerServiceDate.setUTCHours(16);
maxCustomerServiceDate.setUTCMinutes(0);

export const appConstants: AppConstants = {
    fallbackWebsite: "https://www.p1travel.com",
    persistedDataVersion: 1,
    metadataSourceName: 'travelware-checkout',
    travellerDataExpirationMinutes: 1440, // equals a day
    termsAndConditionsUrl: 'https://www.p1travel.com/terms-and-conditions/',
    customerServiceHours: {
        min: moment(minCustomerServiceDate),
        max: moment(maxCustomerServiceDate),
    },
};

import React from 'react';

export const PhoneIcon = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20.679"
        height="20.767"
        viewBox="0 0 20.679 20.767"
    >
        <defs>
            <clipPath id="nmk26zk2pa">
                <path
                    data-name="Rectangle 1806"
                    style={{ fill: 'none' }}
                    d="M0 0h20.679v20.767H0z"
                />
            </clipPath>
        </defs>
        <g data-name="Group 3753" style={{ clipPath: 'url(#nmk26zk2pa)' }}>
            <path
                data-name="Path 6867"
                d="M20.679 16.459a2.268 2.268 0 0 0-.585-1.524l-.009-.009-.157-.156-2.521-2.525a2.3 2.3 0 0 0-1.649-.753h-.011a2.36 2.36 0 0 0-1.67.749l-1.377 1.37c-.087-.046-.176-.1-.257-.135a4.763 4.763 0 0 1-.438-.238 15.648 15.648 0 0 1-3.736-3.4A8.976 8.976 0 0 1 7.1 7.984c.346-.322.679-.649.992-.974.124-.127.253-.257.384-.388a2.4 2.4 0 0 0 .773-1.679v-.017a2.379 2.379 0 0 0-.773-1.681L7.223 2a34.718 34.718 0 0 1-.425-.434 23.132 23.132 0 0 0-.863-.853A2.34 2.34 0 0 0 4.292 0h-.01a2.4 2.4 0 0 0-1.671.724L1.044 2.3A3.474 3.474 0 0 0 .01 4.519v.006C0 4.656 0 4.788 0 4.919a8.03 8.03 0 0 0 .61 3.068 19.825 19.825 0 0 0 3.524 5.891 21.754 21.754 0 0 0 7.227 5.659 11.374 11.374 0 0 0 4.09 1.21h.025c.083 0 .171.008.278.008h.11a3.571 3.571 0 0 0 2.655-1.183l.008-.009.013-.017a10.359 10.359 0 0 1 .791-.819c.2-.192.406-.392.6-.6a2.269 2.269 0 0 0 .739-1.679m-1.694.49-.144.142-.007.007c-.171.183-.351.36-.547.547a12.214 12.214 0 0 0-.893.927 2.062 2.062 0 0 1-1.531.682h-.075l-.2-.008a9.86 9.86 0 0 1-3.528-1.056h-.006a20.188 20.188 0 0 1-6.728-5.268 18.47 18.47 0 0 1-3.27-5.451v-.008a6.529 6.529 0 0 1-.517-2.544c0-.091 0-.181.006-.269v-.022a1.972 1.972 0 0 1 .589-1.242l1.566-1.56.009-.009a.889.889 0 0 1 .59-.275.825.825 0 0 1 .569.277l.007.009.011.01c.274.253.536.524.823.814.144.149.291.3.437.445l1.256 1.261.008.007a.887.887 0 0 1 .3.6.887.887 0 0 1-.3.6h-.005l-.009.009-.39.394c-.392.4-.753.766-1.147 1.115l-.011.01-.023.018a1.066 1.066 0 0 0-.35.788 1.037 1.037 0 0 0 .083.408l.013.034v.011A10.167 10.167 0 0 0 7.074 10.8a17.028 17.028 0 0 0 4.1 3.737h.006a6.4 6.4 0 0 0 .577.312c.148.072.3.153.438.24l.01.005.046.026a1.123 1.123 0 0 0 .523.134h.026a1.136 1.136 0 0 0 .793-.36l1.572-1.571.01-.011a.864.864 0 0 1 .581-.287.8.8 0 0 1 .552.282l.007.008 2.548 2.544.012.011a.76.76 0 0 1 .113 1.069"
            />
        </g>
    </svg>
);

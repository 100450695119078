import classnames from 'classnames';
import { PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';
import { withLocale } from 'src/app/constants/appLocale';
import { Body } from 'src/view/components/Body/Body';
import { ArrowLeft } from '../../../images/icons/ArrowLeft';
import $ from './BackLink.module.scss';

interface BackLinkProps {
    to: string;
    wrapperStyle?: string;
    linkStyle?: string;
}

const BackLink = ({ to, wrapperStyle, children, linkStyle }: PropsWithChildren<BackLinkProps>) => {
    return (
        <div className={classnames($.backLinkWrapper, wrapperStyle)}>
            <ArrowLeft />
            <Link
                to={withLocale(to)}
                className={classnames($.link, linkStyle)}
                data-cy="back-to-trip"
            >
                <Body fontWeight="bold" marginTop={false} marginBottom={false}>
                    {children}
                </Body>
            </Link>
        </div>
    );
};

export default BackLink;

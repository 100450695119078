import * as Sentry from '@sentry/react';
import moment from 'moment';
import { Dispatch } from 'redux';
import { Availability } from 'src/data/models/Availability';
import { Session } from '../store/appSlice';
import { expireAvailability } from '../store/orderSlice';
import { RouteHelper } from './RouteHelper';

let expiryTimeoutId: number | null = null;
let isTimerRunning = false;
let lastAvailabilityHash: undefined | string = '';

// Is used in tests to reset the global variables
export const resetExpiryWatcherState = () => {
    expiryTimeoutId = null;
    isTimerRunning = false;
    lastAvailabilityHash = '';
};

// Refactor to a function to use in both class and functional components
export const expiryWatcher = (
    session: Session | null,
    availability: Availability | null,
    dispatch: Dispatch
) => {
    const startExpiryWatcher = () => {
        /** We don't want to attempt to start any watcher if there is no order id or order secret. */
        if (!session?.orderId || !session?.orderSecret) {
            clearExpiryTimeout();

            return;
        }

        /** It means that the order hasn't been started yet */
        if (!availability) {
            clearExpiryTimeout();

            return;
        }
        // THIS IS THE REAL EXPIRY TIME. WILL BE REPLACED AFTER TESTING
        const secondsTillExpiry = moment(availability.expiryTime).diff(moment(), 'seconds');
        console.log(secondsTillExpiry);

        /** (Can be better) Each availability has a different hash. It is used to differenciate between updated & freshly fetched availabilities */
        const isTheSameAvailabilityHash =
            availability && availability?.hash === lastAvailabilityHash;

        /** It means that the timer has already been initialized and is the same availability.
         * This is to prevent the timer from being initialized multiple times,
         *  because this function is being called inside hooks like useEffect and componentDidUpdate, which can sometimes cause multiple calls.
         */
        if (isTimerRunning && isTheSameAvailabilityHash) {
            return;
        }

        /** Clear any timeout before initializing a new one */
        if (expiryTimeoutId) {
            console.log(
                '\x1b[31m%s\x1b[0m',
                'EXPIRY WATCHER - Cleanup before starting new timeout -',
                expiryTimeoutId
            );

            clearTimeout(expiryTimeoutId);
        }

        lastAvailabilityHash = availability?.hash;
        isTimerRunning = true;

        expiryTimeoutId = window.setTimeout(() => {
            resetAndRedirect();
        }, secondsTillExpiry * 1000);

        console.log(
            '\x1b[32m%s\x1b[0m',
            'EXPIRY WATCHER - New timeout started with an ID -',
            expiryTimeoutId
        );
        console.log(
            '\x1b[36m%s\x1b[0m',
            'EXPIRY WATCHER - Will expire in minutes - ',
            moment(availability?.expiryTime).diff(moment(), 'minutes')
        );
    };
    const resetAndRedirect = () => {
        dispatch(expireAvailability());
        expiryTimeoutId = null;

        console.log(
            '\x1b[31m%s\x1b[0m',
            'EXPIRY WATCHER - Expired - Redirecting to session end',
            expiryTimeoutId
        );

        if (session?.eventId) {
            /** Means that we are already there */
            if (window.location.pathname.includes('session-end')) return;

            window.location.href = RouteHelper.getSessionEndRoute(session.eventId);
        } else {
            Sentry.captureException(
                'Session eventId was not found in resetAndRedircet in expiry watcher',
                (scope) => {
                    // will give us info whether the session even exists or not
                    scope.setExtra('session', session);
                    return scope;
                }
            );
        }
    };

    /** The main functionality which Invokes the timers */
    startExpiryWatcher();
};

//  * Ideally use the cleanup after each unmount of the component, and re-initialize if needed.
export const clearExpiryTimeout = () => {
    isTimerRunning = false;
    if (!expiryTimeoutId) {
        return;
    }

    console.log('\x1b[31m%s\x1b[0m', 'EXPIRY WATCHER - Clean timeout ID -', expiryTimeoutId);
    clearTimeout(expiryTimeoutId);
    expiryTimeoutId = null;
};

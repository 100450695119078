import React from 'react';

export const DeleteIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
        <g id="Group_891" data-name="Group 891" transform="translate(-1773 -800)">
            <circle
                id="Ellipse_101"
                data-name="Ellipse 101"
                cx="7"
                cy="7"
                r="7"
                transform="translate(1773 800)"
                fill="#1a1b33"
            />
            <rect
                id="Rectangle_824"
                data-name="Rectangle 824"
                width="2"
                height="8"
                transform="translate(1776.465 804.879) rotate(-45)"
                fill="#fff"
            />
            <rect
                id="Rectangle_825"
                data-name="Rectangle 825"
                width="2"
                height="8"
                transform="translate(1782.121 803.465) rotate(45)"
                fill="#fff"
            />
        </g>
    </svg>
);

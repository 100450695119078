import moment, { Moment } from 'moment';
import { Team, TeamEntity } from './Team';
import { VenueEntity, Venue } from './Venue';
import { BasePackageEntity, BasePackage } from './BaseAccommodation';
import { SerieEntity, Serie } from './Serie';
import { EventTicket, EventTicketEntity } from './EventTicket';
import { PackageType } from 'src/data/models/Order';

export interface Restrictions {
    allowAwayFans?: boolean;
    blacklistedCountries?: string[];
    blacklistedNationalities?: string[];
    requireFullCustomerAddress?: boolean;
}

/* eslint-disable lines-between-class-members */
export interface EventDetailEntity {
    id: string;
    name: string;
    type: string;
    date_time: string;
    date_time_end?: string;
    date_confirmed: boolean;
    date_bookable_start: string;
    date_bookable_end: string;
    date_minimum_start: string;
    date_minimum_end: string;
    content: {
        main_image: {
            alt_text: string | null;
            label: string | null;
            url: string | null;
        };
        description: string | null;
        marketing_label: string | null;
    };
    venue: VenueEntity;
    tickets: EventTicketEntity[];
    base_package: BasePackageEntity;
    series: SerieEntity;
    team_away?: TeamEntity;
    team_home?: TeamEntity;
    traveller_data_required?: boolean;
    tickets_sent_earliest_days: number;
    tickets_sent_latest_days: number;

    restrictions?: {
        allow_away_fans?: boolean;
        blacklisted_country_codes?: string[];
        blacklisted_nationality_codes?: string[];
        require_full_customer_address?: boolean;
    };
    organizer?: {
        id: string;
        name: string;
        has_terms_and_conditions?: boolean;
        terms_and_conditions_url?: string;
    };
}

export class EventDetail {
    public readonly id: string;
    public readonly title: string;
    public readonly name: string;
    public readonly type: string;
    public readonly dateConfirmed: boolean;
    public readonly dateBookableStart: Moment;
    public readonly dateBookableEnd: Moment;
    public readonly dateMinimumStart: Moment;
    public readonly dateMinimumEnd: Moment;
    public readonly dateTime: Moment;
    public readonly dateTimeEnd: Moment;
    public readonly venue: Venue;
    public readonly tickets: EventTicket[];
    public readonly basePackage?: BasePackage;
    public readonly serie: Serie;
    public readonly teamHome?: Team;
    public readonly teamAway?: Team;
    public readonly travellerDataRequired?: boolean;
    public readonly ticketSentEarliestDays?: number;
    public readonly ticketSentLatestDays?: number;
    public readonly restrictions: Restrictions;
    public readonly content: {
        main_image: {
            alt_text: string | null;
            label: string | null;
            url: string | null;
        };
        description: string | null;
        marketing_label: string | null;
    };
    public readonly organizer?: {
        id?: string;
        name?: string;
        hasTermsAndConditions?: boolean;
        termsAndConditionsUrl?: string;
    };
    public readonly termsAndConditionsUrl?: string;

    public constructor(eventData: EventDetailEntity) {
        this.id = eventData.id;
        this.name = eventData.name;
        this.title = eventData.name;
        this.type = eventData.type;
        this.dateConfirmed = eventData.date_confirmed || false;
        this.dateBookableStart = moment.utc(eventData.date_bookable_start);
        this.dateBookableEnd = moment.utc(eventData.date_bookable_end);
        this.dateMinimumStart = moment.utc(eventData.date_minimum_start);
        this.dateMinimumEnd = moment.utc(eventData.date_minimum_end);
        this.dateTime = moment.utc(eventData.date_time);
        this.dateTimeEnd = moment.utc(eventData.date_time_end);
        this.venue = new Venue(eventData.venue);
        this.tickets = eventData.tickets?.map((ticket) => new EventTicket(ticket)) || [];
        this.ticketSentEarliestDays = eventData.tickets_sent_earliest_days;
        this.ticketSentLatestDays = eventData.tickets_sent_latest_days;
        this.organizer = {
            id: eventData.organizer?.id,
            name: eventData.organizer?.name,
            hasTermsAndConditions: eventData.organizer?.has_terms_and_conditions,
            termsAndConditionsUrl: eventData.organizer?.terms_and_conditions_url,
        };

        if (eventData.base_package) {
            this.basePackage = new BasePackage(eventData.base_package);
        } else {
            this.basePackage = undefined;
        }
        this.content = eventData.content;
        this.serie = new Serie(eventData.series);
        this.teamHome = eventData.team_home && new Team(eventData.team_home);
        this.teamAway = eventData.team_away && new Team(eventData.team_away);
        this.travellerDataRequired =
            eventData.traveller_data_required === undefined
                ? true
                : eventData.traveller_data_required;
        this.restrictions = {
            allowAwayFans: eventData.restrictions?.allow_away_fans,
            blacklistedCountries: eventData.restrictions?.blacklisted_country_codes,
            blacklistedNationalities: eventData.restrictions?.blacklisted_nationality_codes,
            requireFullCustomerAddress: eventData.restrictions?.require_full_customer_address,
        };
    }

    public offersPackageType(type: PackageType): boolean {
        if (!this.basePackage) {
            return false;
        }

        return this.basePackage.hasPackageType(type);
    }

    public findTicketByCategoryId(categoryId: string): EventTicket | null {
        if (!this.tickets || this.tickets.length === 0) {
            return null;
        }

        return this.tickets.find((t) => t.categoryId === categoryId) || null;
    }
}

import React from 'react';

export const ArrowLeft = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="12.7" viewBox="0 0 16.473 13.178">
        <g data-name="Group 4171">
            <path
                data-name="Path 7330"
                d="m9.21.6.106.164 5.119 5.118H.446l-.174.038-.2.161-.072.25.035.169.165.2.25.077h13.984L9.3 11.91a.451.451 0 0 0-.128.315v.006l.035.171.161.195.25.077a.449.449 0 0 0 .315-.129l5.9-5.9a.447.447 0 0 0 .131-.312l-.042-.167-.122-.173L9.945.136 9.781.03 9.62 0l-.161.03-.144.093-.109.162-.029.146z"
                style={{ strokeWidth: '.5px' }}
                transform="rotate(180 8.108 6.462)"
            />
        </g>
    </svg>
);

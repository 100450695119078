import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectIsB2BMode, selectReferrerUrl } from 'src/app/store/appSlice';
import { selectBookerDetails } from 'src/app/store/TravellerDataSlice';
import { parseLink } from 'src/app/utils/utils';
import * as Cache from 'src/data/services/cache';
import PageLayout from 'src/view/components/PageLayout/PageLayout';
import FaqBlockFeature from 'src/view/features/Faq/FaqBlockFeature';
import Button from '../Button/Button';
import $ from './Thanks.module.scss';

const Thanks: React.FunctionComponent<WithTranslation> = ({ t }) => {
    const bookerDetails = useSelector(selectBookerDetails);
    const booking = Cache.getBooking();
    const partnerReferrerUrl = useSelector(selectReferrerUrl);
    const isB2BMode = useSelector(selectIsB2BMode);

    if (!bookerDetails) return null;

    const { firstName, email } = bookerDetails;

    const redirectToHomePage = function () {
        if (!partnerReferrerUrl) return;

        window.location.href = partnerReferrerUrl.href;
    };

    return (
        <PageLayout.Container>
            <PageLayout.ContentLeft>
                <h1 className={$.title}>
                    {firstName}, {t('thanks_title')}
                </h1>

                <h3>{t('thanks_receive_tickets')}</h3>
                <h3>{t('thanks_receive_tickets_matchday')}</h3>
                <p className={$.description}>{t('thanks_email', { email })}</p>
                <p className={$.description}>
                    {t('thanks_booking')} <b>{booking}</b>
                </p>

                {!isB2BMode ? (
                    <p className={$.description}>
                        <span className={$.span}>
                            {t('thanks_questions')} {t('thanks_call-us-at')}
                        </span>
                        <a href={`tel:${t('_hardcodedSupportPhoneNumber')}`} className={$.phone}>
                            {t('_hardcodedSupportPhoneNumber')}
                        </a>
                        <span className={$.span}>{t('thanks_or-use')}</span>
                        <a href={t('_hardcoded_support_whatsapp_url')} className={$.whatsappLink}>
                            Whatsapp
                        </a>
                    </p>
                ) : (
                    <div className={$.description}>
                        <p
                            className={$.supportParagraph}
                            dangerouslySetInnerHTML={{
                                __html: parseLink(
                                    t('support_help_text_b2b_1', {
                                        supportEmail: t('_hardcoded_support_email_b2b'),
                                        supportPhoneNumber: t(
                                            '_hardcoded_support_phone_number_b2b'
                                        ),
                                    })
                                ),
                            }}
                        />
                        <p
                            dangerouslySetInnerHTML={{
                                __html: parseLink(
                                    t('support_help_text_b2b_2', {
                                        salesEmail: t('_hardcodedSupportSalesEmailB2B'),
                                        supportPhoneNumber: t('_hardcodedSupportMobileNumberB2B'),
                                    })
                                ),
                            }}
                        />
                    </div>
                )}

                {partnerReferrerUrl && (
                    <Button
                        text={t('thanks_returnhome')}
                        onClick={redirectToHomePage}
                        className={$.homeButton}
                    />
                )}
            </PageLayout.ContentLeft>

            <PageLayout.ContentRight>
                <FaqBlockFeature />
            </PageLayout.ContentRight>
        </PageLayout.Container>
    );
};

export default withTranslation()(Thanks);

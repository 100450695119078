import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import useWindowSize from 'src/app/hooks/UseWindowSize';
import { RootState } from 'src/app/store';
import {
    getMicroCopyVariant,
    TCO504MicroCopyText,
} from 'src/view/ABComponents/TCO504MicroCopyText/TCO504MicroCopyText';
import { Body } from 'src/view/components/Body/Body';
import Button, { ButtonProps } from 'src/view/components/Button/Button';
import ErrorMessage from 'src/view/components/ErrorMessage';
import InfoMessage from 'src/view/components/InfoMessage';
import $ from './ConfirmButton.module.scss';

interface ConfirmButtonProps {
    inverted?: boolean;
    buttonProps: ButtonProps;
    infoNotifications?: string[];
    infoText?: string | JSX.Element;
    infoTextConfig?: {
        darkText?: boolean;
        bigText?: boolean;
    };
    errors?: string[];
    microCopyText?: string[];
    onClickPrevious?: () => void;
    previousText?: string;
    renderPreviousLast?: boolean;
}

const ConfirmButton = (props: ConfirmButtonProps) => {
    const {
        inverted,
        infoText = true,
        buttonProps,
        infoNotifications = [],
        errors = [],
        infoTextConfig,
        microCopyText,
        onClickPrevious,
        previousText,
        renderPreviousLast,
    } = props;
    const { t } = useTranslation();
    const { isTablet, isMobile } = useWindowSize();
    const shouldHaveMobileDesign = isTablet || isMobile;
    const microCopyVariant = useSelector((state: RootState) => getMicroCopyVariant(state));

    const renderBackToPreviousButton = () => {
        return (
            <>
                {microCopyVariant && microCopyText && onClickPrevious && (
                    <div
                        onClick={onClickPrevious}
                        className={classnames(
                            $.backToPrevious,
                            shouldHaveMobileDesign && $.backToPreviousTablet
                        )}
                    >
                        {previousText || t('back_to_previous_step')}
                    </div>
                )}
            </>
        );
    };

    return (
        <div className={$.row}>
            <div
                className={classnames(!microCopyVariant && $.col, microCopyVariant && 'fullWidth')}
            >
                <div
                    className={classnames(
                        $.confirmErrorWrap,
                        inverted && $.confirmButtonWrapInverted,
                        microCopyVariant && 'fullWidth',
                        microCopyVariant && $.confirmButtonMicroCopy
                    )}
                >
                    <Button className={$.confirmButton} leftAligned {...buttonProps} />

                    {!renderPreviousLast && !shouldHaveMobileDesign && renderBackToPreviousButton()}
                </div>
                {microCopyText && microCopyText.length > 0 && (
                    <TCO504MicroCopyText text={microCopyText} />
                )}
                {(renderPreviousLast || shouldHaveMobileDesign) && renderBackToPreviousButton()}
            </div>
            {!microCopyVariant && (
                <div className={classnames([$.col, $.colSpanTwo, inverted && $.rowInverted])}>
                    <div className={$.textWrapper}>
                        {errors?.length > 0 &&
                            errors.map((error) => (
                                <div className={$.errorWrap}>
                                    <ErrorMessage content={error} />
                                </div>
                            ))}

                        {infoNotifications.length > 0 &&
                            infoNotifications.map((infoItem) => (
                                <div className={$.infoWrap}>
                                    <InfoMessage text={infoItem} />
                                </div>
                            ))}

                        {infoText && infoNotifications?.length === 0 && errors?.length === 0 && (
                            <Body
                                marginTop={false}
                                marginBottom={false}
                                small={!infoTextConfig?.bigText}
                                grey={!infoTextConfig?.darkText}
                            >
                                {infoText}
                            </Body>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default ConfirmButton;

/* eslint-disable lines-between-class-members */
export interface FacilityEntity {
    general: { [id: string]: string | null }[];
    catering: { [id: string]: string | null }[];
    meals: { [id: string]: string | null }[];
    things: { [id: string]: string | null }[];
}

export class Facility {
    public readonly general: { [id: string]: boolean | string | null }[];
    public readonly catering: { [id: string]: boolean | string | null }[];
    public readonly meals: { [id: string]: boolean | string | null }[];
    public readonly things: { [id: string]: boolean | string | null }[];

    public constructor(facility: FacilityEntity) {
        this.general = facility.general;
        this.catering = facility.catering;
        this.meals = facility.meals;
        this.things = facility.things;
    }
}

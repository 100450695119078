import classnames from 'classnames';
import * as React from 'react';
import { Spinner } from '../../components';
import $ from './Button.module.scss';

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    disabled?: boolean;
    isLoading?: boolean;
    text?: string;
    link?: string;
    type?: React.ButtonHTMLAttributes<HTMLButtonElement>['type'];
    onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    white?: boolean;
    icon?: string;
    leftAligned?: boolean;
    className?: string;
    fullWidth?: boolean;
    alignIcon?: 'left' | 'right';
    iconClassName?: string;
    variant?: 'normal' | 'outlined';
    iconOnly?: boolean;
    dataCy?: string;
}

const Button = (props: ButtonProps) => {
    const {
        link,
        onClick,
        white,
        icon,
        type,
        disabled,
        isLoading,
        leftAligned,
        className,
        fullWidth,
        alignIcon = 'left',
        iconClassName,
        variant = 'normal',
        iconOnly,
        dataCy,
        text,
        ...buttonAttrbitues
    } = props;

    const buttonStyles = classnames([
        $.button,
        white && $.buttonWhite,
        disabled && $.buttonDisabled,
        leftAligned && $.buttonLeft,
        fullWidth && $.fullWidth,
        variant === 'outlined' && $.outlinedButton,
        className,
    ]);

    if (link) {
        return (
            <a href={link} className={$.button} data-testid="link">
                {text}
            </a>
        );
    }

    const buttonContent = !icon ? (
        text
    ) : (
        <>
            {!iconOnly && text}
            <img
                src={icon}
                alt="Icon"
                className={classnames(
                    alignIcon === 'right' ? $.iconRight : $.iconLeft,
                    iconClassName
                )}
            />
        </>
    );

    return (
        <button
            className={buttonStyles}
            onClick={onClick}
            type={type}
            data-testid="button"
            disabled={disabled || isLoading}
            data-cy={dataCy}
            {...buttonAttrbitues}
        >
            {isLoading && (
                <Spinner
                    className={classnames(iconOnly ? $.spinnerNoMargin : $.spinner)}
                    type={white ? 'dark' : 'light'}
                />
            )}
            {buttonContent}
        </button>
    );
};

export default Button;

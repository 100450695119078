import classNames from 'classnames';
import * as React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getProgressSteps } from 'src/app/constants/progressSteps';
import useInterval from 'src/app/hooks/UseInterval';
import $ from 'src/app/pages/Ticket/Ticket.module.scss';
import { hasMarketingCookieConsent } from 'src/app/utils/consent';
import { EventDetail } from 'src/data/models/EventDetail';
import { ConfirmLabel } from 'src/view/components';
import { Body } from 'src/view/components/Body/Body';
import { EventDateRenderer } from 'src/view/components/EventDateRenderer/EventDateRenderer';
import Icon from 'src/view/components/Icon';
import { ProgressBar } from 'src/view/components/ProgessBar/ProgressBar';
import TrustpilotWidget from 'src/view/components/TrustpilotWidget';
import './Header.scss';

interface Props {
    logo: {
        url: string;
        title: string;
    };
    active: number;
    uspData: { values: { title: string }[] };
    eventId?: string;
    partnerWebsiteUrl?: URL | string | null;
    showTrustpilotAsUspOnMobile?: boolean;
    trustpilotBusinessId?: string | null | undefined;
    hideTopbar?: boolean;
    eventDetail?: EventDetail;
}

const Header = (props: Props) => {
    const { t } = useTranslation();
    const {
        logo,
        active,
        uspData,
        eventId,
        partnerWebsiteUrl,
        showTrustpilotAsUspOnMobile,
        trustpilotBusinessId,
        hideTopbar,
        eventDetail,
    } = props;
    const [modalOpen, setModalOpen] = React.useState(false);
    const [activeUsp, setActiveUsp] = useState<number>(0);

    const usps = uspData.values.map((usp) => {
        if (!usp.title || usp.title.length < 1) {
            return undefined;
        }

        return usp.title;
    });

    const totalUsps = usps.length + (showTrustpilotAsUspOnMobile && trustpilotBusinessId ? 1 : 0);

    useInterval(() => {
        const nextUsp = activeUsp >= totalUsps - 1 ? 0 : activeUsp + 1;
        setActiveUsp(nextUsp);
    }, 5000);

    const activeUspClasses = ['uspActive0', 'uspActive1', 'uspActive2', 'uspActive3', 'uspActive4'];

    const description = eventDetail?.dateConfirmed
        ? t('event_confirmed_text')
        : t('event_not_confirmed_text');

    const partnerWebsiteUrlString = !partnerWebsiteUrl
        ? '#'
        : typeof partnerWebsiteUrl === 'string'
        ? partnerWebsiteUrl
        : encodeURI(partnerWebsiteUrl.toString());

    return (
        <header className="headerWrapper">
            {!hideTopbar && (
                <div className="topbar">
                    <div className="container">
                        <div className="leftColumn">
                            <ul className={`uspList ${activeUspClasses[activeUsp]}`}>
                                {usps.map((usp, index) => (
                                    <li key={index}>
                                        <Icon icon="check" className="uspIcon" /> {usp}
                                    </li>
                                ))}
                                {hasMarketingCookieConsent() &&
                                    showTrustpilotAsUspOnMobile &&
                                    trustpilotBusinessId && (
                                        <li className="trustpilotUsp">
                                            <TrustpilotWidget
                                                businessunitId={trustpilotBusinessId}
                                                theme="dark"
                                            />
                                        </li>
                                    )}
                            </ul>
                        </div>
                        <div className="rightColumn">
                            {hasMarketingCookieConsent() && trustpilotBusinessId && (
                                <TrustpilotWidget
                                    businessunitId={trustpilotBusinessId}
                                    theme="dark"
                                />
                            )}
                        </div>
                    </div>
                </div>
            )}

            <div className="main">
                <div className="container">
                    <div className="logoHelpColumn">
                        <a href={partnerWebsiteUrlString}>
                            <img
                                src={logo.url}
                                alt={logo.title}
                                title={logo.title}
                                className="logo"
                            />
                        </a>

                        {eventDetail && (
                            <div className="eventDetailsHeading">
                                <div
                                    style={{
                                        position: 'relative',
                                    }}
                                >
                                    <div
                                        className="eventName"
                                        style={{ maxWidth: '100%' }}
                                        data-cy="event-name"
                                    >
                                        {eventDetail.title}
                                    </div>
                                </div>
                                <Body
                                    marginTop={false}
                                    marginBottom={false}
                                    className={$.headerInfo}
                                >
                                    <span className="eventDate">
                                        <EventDateRenderer
                                            dateTime={eventDetail.dateTime}
                                            dateTimeEnd={eventDetail.dateTimeEnd}
                                        />
                                    </span>
                                    <ConfirmLabel
                                        isConfirmed={eventDetail.dateConfirmed}
                                        withTooltip
                                        eventDetail={eventDetail}
                                    />

                                    <div className={classNames('eventLocation')}>
                                        <span className={classNames($.venue, 'eventDate')}>
                                            {eventDetail.venue.name}
                                        </span>
                                        <span className={classNames($.bull, 'eventDate')}>
                                            &bull;
                                        </span>
                                        <span className={classNames($.league, 'eventDate')}>
                                            {eventDetail.serie.name}
                                        </span>
                                    </div>
                                </Body>
                            </div>
                        )}
                    </div>

                    <div className="stepperColumn">
                        <ProgressBar steps={getProgressSteps(t, eventId)} active={active} />
                    </div>
                </div>
            </div>

            <div className="mobileStepperColumn">
                <ProgressBar steps={getProgressSteps(t, eventId)} active={active} />
            </div>
        </header>
    );
};

export default Header;

/* eslint-disable @typescript-eslint/camelcase */
/* (because of the dataLayer objects that use snake case) */
import moment from 'moment';
import store from 'src/app/store';
import { selectBrowserSession, selectPartnerConfig } from 'src/app/store/appSlice';
import { selectEventDetail } from 'src/app/store/eventSlice';
import { OrderSummary, selectOrderSummary } from 'src/app/store/orderSlice';
import { OrderTypeUtil } from 'src/app/utils/utils';
import { EventDetail } from 'src/data/models/EventDetail';
import { PackageType } from 'src/data/models/Order';

interface EcommerceEventItemData {
    affiliation: string;
    parent_id: string;
    item_name: string;
    item_id: string;
    item_brand?: string;
    item_category: string;
    item_category2?: string;
    item_category3?: string;
    item_variant?: string;
    event_type?: string;
    currency: string;
    is_package_deal: 'yes' | 'no';
    quantity: number;
    date_start: string;
    hotel_nights?: number;
}

function getEcommerceEventItems(
    event: EventDetail,
    orderSummary: OrderSummary
): EcommerceEventItemData[] {
    const isPackageDeal = orderSummary.packageType !== PackageType.TICKET_ONLY;

    const items: EcommerceEventItemData[] = [
        {
            affiliation: window.location.hostname,
            parent_id: event.id,
            item_name: event.name,
            item_id: orderSummary.ticketCategoryId || '(not set)',
            item_category: 'event tickets',
            item_category3: event.serie.name,
            item_variant: orderSummary.ticketName,
            event_type: event.type,
            currency: orderSummary.currency,
            quantity: orderSummary.numPax,
            date_start: moment(event.dateTime).format('YYYYMMDD'),
            is_package_deal: isPackageDeal ? 'yes' : 'no',
        },
    ];

    if (OrderTypeUtil.hasHotel(orderSummary.packageType)) {
        items.push({
            affiliation: window.location.hostname,
            parent_id: orderSummary.accommodationId || '(not set)',
            item_id: orderSummary.accommodationId || '(not set)',
            item_name: orderSummary.accommodationName || '(not set)',
            item_brand: orderSummary.accommodationChainName || '(not set)',
            item_category: 'hotels',
            currency: orderSummary.currency,
            quantity: orderSummary.numRooms,
            is_package_deal: 'yes',
            date_start: orderSummary.dateStart.format('YYYYMMDD'),
            hotel_nights: orderSummary.dateEnd.diff(orderSummary.dateStart, 'days'),
        });
    }

    return items;
}

export function pushBeginCheckoutDetailsEventToDataLayer() {
    const storeState = store.getState();
    const orderSummary = selectOrderSummary(storeState);
    const event = selectEventDetail(storeState);

    if (!event || !orderSummary) return;

    const items = getEcommerceEventItems(event, orderSummary);

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        ecommerce: null,
    });

    window.dataLayer.push({
        event: 'begin_checkout',
        ecommerce: {
            currency: orderSummary.currency,
            value: orderSummary.selectedPriceTotal,
            items,
        },
    });
}

export function pushAddShippingInfoEventToDataLayer(email: string) {
    const storeState = store.getState();
    const orderSummary = selectOrderSummary(storeState);
    const event = selectEventDetail(storeState);

    if (!event || !orderSummary) return;

    const items = getEcommerceEventItems(event, orderSummary);

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        ecommerce: null,
    });

    window.dataLayer.push({
        event: 'add_shipping_info',
        'user_data.email_address': email,
        ecommerce: {
            shipping_tier: 'digital',
            currency: orderSummary.currency,
            value: orderSummary.selectedPriceTotal,
            items,
        },
    });
}

export function pushPaymentMethodAddedEventToDataLayer(paymentType: string) {
    const storeState = store.getState();
    const orderSummary = selectOrderSummary(storeState);
    const event = selectEventDetail(storeState);

    if (!event || !orderSummary) return;

    const items = getEcommerceEventItems(event, orderSummary);

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        ecommerce: null,
    });

    window.dataLayer.push({
        event: 'add_payment_info',
        ecommerce: {
            payment_type: paymentType,
            currency: orderSummary.currency,
            value: orderSummary.selectedPriceTotal,
            items,
        },
    });
}

export function getGADataLayerProperty(): { session_id: string; client_id: string } | undefined {
    const browserSession = selectBrowserSession(store.getState());
    const config = selectPartnerConfig(store.getState());

    if (config && config.gtmId) {
        const gtmId = browserSession.urlProvidedGtmId || config.gtmId;
        return window.google_tag_manager?.[gtmId]?.dataLayer.get('gtagApiResult');
    }
}

export type PushStepEventArgs = {
    event_type: 'step_start' | 'step_complete';
    step_name: 'package' | 'seating' | 'hotel' | 'details' | 'payment' | 'completed';
    step_data?: string;
};

export function pushStepEventToDataLayer(info: PushStepEventArgs) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        event: 'checkout_step',
        ...info,
    });
}

import React from 'react';
import { Body } from 'src/view/components/Body/Body';
import $ from './FormHintMessage.module.scss';

interface FormHintMessageProps {
    message: string;
    inline?: boolean;
}

const FormHintMessage = ({ message, inline }: FormHintMessageProps) => (
    <Body inline={inline} small className={$.hint} marginBottom={false}>
        {message}
    </Body>
);

export default FormHintMessage;

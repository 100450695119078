import { localizeOrigin } from 'src/app/constants/appLocale';
import { isOlympicsSeries } from 'src/app/constants/olympics';
import store from 'src/app/store';
import { selectBrowserSession, selectPartnerConfig } from 'src/app/store/appSlice';
import { formatPercentage } from 'src/app/utils/utils';
import { AMEIcon } from 'src/images/paymentIcons/AME';
import { CarteBancaire } from 'src/images/paymentIcons/cartebancaire';
import { IDealIcon } from 'src/images/paymentIcons/ideal';
import { KlarnaIcon } from 'src/images/paymentIcons/klarna';
import { MastercardIcon } from 'src/images/paymentIcons/mastercard';
import { PaypalIcon } from 'src/images/paymentIcons/paypal';
import { VisaIcon } from 'src/images/paymentIcons/visa';
import { getTravelwareApiConfig } from './fetchUrl';

const gatewayMap: Record<string, string> = {
    ideal: 'gateway=IDEAL',
    amex: 'gateway=CREDITCARD&cc_type=amex',
    mastercard: 'gateway=CREDITCARD&cc_type=mastercard',
    visa: 'gateway=CREDITCARD&cc_Type=visa',
    cartebancaire: 'gateway=CREDITCARD&cc_Type=cartebancaire',
    paypal: 'gateway=PAYPAL',
    sofort: 'gateway=SOFORT',
    voucher: 'gateway=VOUCHER',
};

export enum PaymentType {
    ORDER_PAYMENT = 'ORDER_PAYMENT',
    PAYMENT_REQUEST = 'PAYMENT_REQUEST',
}

export interface PaymentUrlOptions {
    id: string;
    gateway: PaymentGateway;
    type: PaymentType;
    idealIssuer?: string;
    callback?: string;
    ccType?: string;
    withVoucher?: boolean;
}

export interface PaymentGateway {
    displayName: string;
    name: string;
    icon?: JSX.Element;
    image?: string;
    charge?: string;
}

type CreditcardTypes = 'mastercard' | 'visa' | 'amex' | 'cartebancaire';

const getCreditcard = (paymentMethod: CreditcardTypes, config?: GatewayConfig) => {
    switch (paymentMethod) {
        case 'mastercard':
            return {
                name: 'MasterCard',
                icon: MastercardIcon(),
            };
            break;
        case 'cartebancaire':
            return {
                name: 'Carte Bancaire',
                icon: CarteBancaire(),
            };
        case 'visa':
            return {
                name: 'Visa',
                icon: config?.isOlympicsSeries ? (
                    <img src="/images/visa-olympic-games-paris-2024.jpg" />
                ) : (
                    VisaIcon()
                ),
            };
            break;
        default:
            return {
                name: 'American Express',
                icon: AMEIcon(),
            };
    }
};

interface GatewayConfig {
    isOlympicsSeries?: boolean;
}

export function mapGateways(
    payment: {
        gateways: string[];
        gatewayIssuers: {
            creditCard: string[];
            ideal: string[];
        };
    },
    config?: GatewayConfig
): PaymentGateway[] {
    const gateways: PaymentGateway[] = [];

    if (payment.gateways.includes('CREDITCARD') && payment.gatewayIssuers.creditCard) {
        payment.gatewayIssuers.creditCard.forEach((paymentMethod: string) => {
            gateways.push({
                displayName: getCreditcard(paymentMethod as CreditcardTypes).name,
                name: paymentMethod,
                icon: getCreditcard(paymentMethod as CreditcardTypes, config).icon,
            });
        });
    }

    if (payment.gateways.includes('IDEAL')) {
        gateways.push({
            displayName: 'iDeal',
            name: 'ideal',
            icon: IDealIcon(),
        });
    }

    if (payment.gateways.includes('SOFORT')) {
        gateways.push({
            displayName: 'Klarna',
            name: 'sofort',
            icon: KlarnaIcon(),
        });
    }

    if (payment.gateways.includes('PAYPAL')) {
        gateways.push({
            displayName: 'PayPal',
            name: 'paypal',
            icon: PaypalIcon(),
            charge: formatPercentage(1.5),
        });
    }

    return gateways;
}

export function getGateways(): PaymentGateway[] {
    const paymentConfig = selectPartnerConfig(store.getState())?.payment;

    if (!paymentConfig) {
        return [];
    }

    return mapGateways(paymentConfig, {
        isOlympicsSeries: isOlympicsSeries(store.getState().event.detailEntity?.series.id),
    });
}

function getPaymentCallbackUrl(targetPath: string): string {
    const browserSession = selectBrowserSession(store.getState());

    const query = browserSession.urlProvidedGtmId
        ? `?gtm_id=${browserSession.urlProvidedGtmId}`
        : '';

    return `${localizeOrigin(window.location.origin)}/${targetPath}${query}`;
}

export function getPaymentUrl(options: PaymentUrlOptions) {
    const travelwareApiConfig = getTravelwareApiConfig();
    if (!travelwareApiConfig) {
        throw new Error('Missing travelwareApiConfig');
    }

    const { id, gateway } = options;

    let gatewayOptions = options.withVoucher ? gatewayMap.voucher : gatewayMap[gateway.name];

    if (!gatewayOptions) {
        throw new Error('Unsupported payment type.');
    }

    let paymentPath;
    let returnPath;

    switch (options.type) {
        case PaymentType.ORDER_PAYMENT:
            paymentPath = `/payments/pay/${id}`;
            returnPath = 'completed';
            break;
        case PaymentType.PAYMENT_REQUEST:
            paymentPath = `/payment-requests/pay/${id}`;
            returnPath = `payment-request/${id}`;
            break;
        default:
            throw new Error('Unknown payment type ');
    }

    const callbackUrl = encodeURIComponent(getPaymentCallbackUrl(returnPath));

    const queryString = `${gatewayOptions}&callback=${callbackUrl}`;

    return `${travelwareApiConfig.baseUrl}${paymentPath}?${queryString}`;
}

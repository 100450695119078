export const blockFadeStyles = (maxHeight: number, maskHeight: number): any => ({
    maxHeight: maxHeight,
    position: 'relative',
    overflow: 'hidden',
    paddingBottom: maskHeight,
    paddingRight: 20,
    'mask-image': `linear-gradient(
            to bottom,
            black,
            black ,
            black calc(100% - ${maskHeight}px),
            transparent
        )`,
    'mask-size': '100%',
    'mask-position': '0 0, 100% 0',
    'mask-repeat': 'no-repeat, no-repeat',
    '& pre': {
        marginTop: 0,
        marginBottom: 0,
    },
});

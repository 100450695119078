import React from 'react';

export const ChevronDown = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="13.414"
        height="7.707"
        viewBox="0 0 13.414 7.707"
    >
        <path
            d="m0 0 6 6 6-6"
            transform="translate(.707 .707)"
            style={{ fill: 'none', stroke: '#49afde', strokeLinejoin: 'round', strokeWidth: '2px' }}
        />
    </svg>
);

import { CityEntity, City } from './City';

/* eslint-disable lines-between-class-members */
export interface VenueEntity {
    id: string;
    name: string;
    country_code: string;
    latitude: number;
    longitude: number;
    city: CityEntity;
}

export class Venue {
    public readonly id: string;
    public readonly name: string;
    public readonly countryCode: string;
    public readonly latitude: number;
    public readonly longitude: number;
    public readonly city: City;

    public constructor(venue: VenueEntity) {
        this.id = venue.id;
        this.name = venue.name;
        this.countryCode = venue.country_code;
        this.latitude = venue.latitude;
        this.longitude = venue.longitude;
        this.city = new City(venue.city);
    }
}

import i18next from 'i18next';
import HttpApi from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import {
    AppLocale,
    fallbackLocale,
    isKnownLocale,
    localeMapping,
} from 'src/app/constants/appLocale';
import { getEnvironment } from './app/utils/config';

const versionHash = getEnvironment('REACT_APP_VERSION_HASH') || null;
const versionParam = versionHash ? `?v=${versionHash}` : '';

export const i18nConfig = (initOptions?: {
    interPolationDefaults: { globalPartnerName: string | null };
    debugMode: boolean;
    locale: AppLocale;
}) => {
    const interpolationDefaults = initOptions?.interPolationDefaults || {};

    const locale =
        initOptions?.locale && isKnownLocale(initOptions.locale)
            ? initOptions.locale
            : fallbackLocale;

    return i18next
        .use(initReactI18next)
        .use(HttpApi)
        .init({
            fallbackLng: localeMapping[fallbackLocale],
            fallbackNS: 'translation',
            lng: localeMapping[locale],
            debug: initOptions?.debugMode || false,
            load: 'currentOnly', // NOTE: must set as "currentOnly". Its default is "all", which will also loads "en".
            backend: {
                loadPath: `/locales/{{lng}}/{{ns}}.json${versionParam}`,
            },
            interpolation: {
                escapeValue: false, // not needed for react as it escapes by default,
                defaultVariables: interpolationDefaults,
            },
        });
};

import classnames from 'classnames';
import { useReducer } from 'react';
import { Body } from 'src/view/components/Body/Body';
import { Html } from '..';
import { ArrowDown } from '../../../images/icons/ArrowDown';
import $ from './FaqItem.module.scss';

interface Props {
    question: string;
    answer: string;
    options?: { option: string }[]; // Adjusted interface for options
    /** The initial isOpen state of the item. */
    initialIsOpen?: boolean;
    /** Set to true to highlight the item with colored background */
    highlight?: boolean;
}

const FaqItem = (props: Props) => {
    const { question, answer, options, highlight, initialIsOpen } = props;
    const [isOpen, toggle] = useReducer((state) => !state, !!initialIsOpen);

    return (
        <li className={`${$.wrap} ${highlight && $.highlight}`}>
            <button
                onClick={toggle} // Toggle active state
                type="button"
                className={$.question}
                data-testid="button"
            >
                <Body marginTop={false} marginBottom={false} className={$.questionText}>
                    {question}
                </Body>
                <div className={classnames($.arrowDown, isOpen && $.arrowDownActive)}>
                    <ArrowDown />
                </div>
            </button>

            {isOpen && (
                <section className={$.answerWrap}>
                    <Html className={$.answer}>{answer.split('\n').join('<br />')}</Html>

                    {/* Render options if available */}
                    {options &&
                        options.map((option, index) => (
                            <Html key={index} className={$.answer}>
                                {option.option.split('\n').join('<br />')}
                            </Html>
                        ))}
                </section>
            )}
        </li>
    );
};

export default FaqItem;

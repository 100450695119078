/* eslint-disable lines-between-class-members */
export interface AvailabilityAccommodationRateEntity {
    breakfast_included: boolean;
    supplement_pp: number;
    rates: { [string: string]: string };
}

export class AvailabilityAccommodationRate {
    public readonly breakfastIncluded: boolean;
    public readonly supplementPP: number;
    public readonly rates: { [string: string]: string };

    public constructor(availability: AvailabilityAccommodationRateEntity) {
        this.breakfastIncluded = availability.breakfast_included;
        this.supplementPP = +availability.supplement_pp;
        this.rates = availability.rates;
    }
}

import classnames from 'classnames';
import * as React from 'react';
import { useEffect, useState } from 'react';
import useWindowSize from 'src/app/hooks/UseWindowSize';
import { InfoIcon } from 'src/app/icons/InfoIcon';
import { Modal, ModalBody } from 'src/view/components/Modal/Modal';
import $ from './Popover.module.scss';

interface Props {
    trigger: React.ReactNode;
    desktopContent: React.ReactNode;
    mobileContent?: React.ReactNode;
    mobileTitle?: string;
    buttonClassName?: string;
}

const Tooltip: React.FunctionComponent<Props> = ({
    trigger,
    buttonClassName,
    desktopContent,
    mobileTitle,
    mobileContent,
}) => {
    const { windowSize, isMobile, isDesktop } = useWindowSize();
    const [show, setShow] = React.useState(false);
    const ref = React.useRef<HTMLDivElement | null>(null);
    const [showModal, setShowModal] = useState(false);

    const close = () => {
        setShow(false);
    };

    const handleClickOutside = (e: MouseEvent) => {
        if (ref.current && ref.current.contains(e.target)) {
            return;
        }

        close();
    };

    React.useEffect(() => {
        if (show) {
            window.addEventListener('resize', close);
            window.addEventListener('scroll', close);
        }

        return () => {
            window.removeEventListener('resize', close);
            window.removeEventListener('scroll', close);
            document.removeEventListener('mousemove', handleClickOutside);
        };
    }, [show]);

    const open = () => {
        setShow(true);
    };

    const [leftPosition, setLeftPosition] = useState<number | undefined>();
    const [width, setWidth] = useState<number | undefined>();

    useEffect(() => {
        if (!leftPosition && !width) return;

        setLeftPosition(undefined);
        setWidth(undefined);
    }, [windowSize]);

    useEffect(() => {
        if (!isDesktop || !show || !ref.current || !windowSize?.width || leftPosition || width)
            return;

        const position = ref.current.getBoundingClientRect();

        const elementRightBound = position.left + position.width;

        if (elementRightBound > windowSize.width) {
            setLeftPosition(-40 - (elementRightBound - windowSize.width));

            return;
        }
    }, [show, isMobile, isDesktop]);

    return (
        <>
            {isDesktop && (
                <div className={classnames($.wrap)} onMouseEnter={open} onMouseLeave={close}>
                    <button
                        className={classnames($.button, show && $.buttonOpen, buttonClassName)}
                        type="button"
                        onClick={open}
                    >
                        {trigger}
                    </button>

                    <div
                        ref={ref}
                        className={classnames($.tooltip, $.bottom, show && $.tooltipOpen)}
                        style={{
                            left: leftPosition,
                            width,
                        }}
                    >
                        {desktopContent}
                    </div>
                </div>
            )}

            {!isDesktop && (
                <>
                    <div className={$.wrap}>
                        <button
                            className={classnames($.button, show && $.buttonOpen, buttonClassName)}
                            type="button"
                            onClick={() => setShowModal(true)}
                        >
                            {trigger}
                        </button>
                    </div>
                    <Modal
                        smallModal
                        open={showModal}
                        onClose={() => setShowModal(false)}
                        title={
                            mobileTitle || (
                                <span className={$.modalSvgTitle}>
                                    <InfoIcon />
                                </span>
                            )
                        }
                    >
                        <ModalBody>{mobileContent || desktopContent}</ModalBody>
                    </Modal>
                </>
            )}
        </>
    );
};

export default Tooltip;

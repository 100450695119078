import React from 'react';

export const SwiperArrow = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="8" height="14" viewBox="0 0 7.707 13.414">
        <path
            d="m0 0 6 6 6-6"
            transform="rotate(-90 6.707 6)"
            style={{ fill: 'none', stroke: 'white', strokeLinejoin: 'round', strokeWidth: 2 }}
        />
    </svg>
);

import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';
import { ChildlessBaseComponent } from 'src/app/interfaces/BaseComponent';
import { CheckIcon } from 'src/images/icons/CheckIcon';
import $ from './CheckIconTriangle.module.scss';

const useStyles = makeStyles(() => ({
    checkedIconContainer: {
        borderBottom: `16px solid transparent`,
        borderRight: `16px solid transparent`,
        height: 0,
        width: 0,
    },
    checkIcon: {
        position: 'absolute',
        top: -1,
        left: 2,
        transform: 'scale(.9)',
        zIndex: 2,
    },
}));

export const CheckIconTriangle = ({ className }: ChildlessBaseComponent) => {
    const classes = useStyles();
    return (
        <>
            <div
                className={classNames(classes.checkedIconContainer, $.successCheckMark, className)}
            ></div>
            <div className={classes.checkIcon}>
                <CheckIcon color="white" />
            </div>
        </>
    );
};

import * as React from 'react';
import classnames from 'classnames';
import $ from './FormListOption.module.scss';

interface Props {
    title: string;
    subTitle?: string;
    name: string;
    id: string;
    onChange: (id: string) => void;
    checked: boolean;
    secondary?: boolean;
}

class FormListOption extends React.Component<Props> {
    public constructor(props: Props) {
        super(props);

        this.onChange = this.onChange.bind(this);
    }

    public onChange() {
        const { onChange, id } = this.props;
        onChange(id);
    }

    public render() {
        const { title, id, name, subTitle, checked, secondary } = this.props;
        return (
            <div
                className={classnames([$.formrow, checked && $.selected, secondary && $.secondary])}
            >
                <input
                    className={$.checkbox}
                    type="radio"
                    name={name}
                    id={id}
                    value={title}
                    onChange={this.onChange}
                    checked={checked}
                />
                <label
                    className={classnames([
                        $.checklabel,
                        (!subTitle || subTitle.length === 0) && $.noSubtitle,
                        checked && $.checkLabelBold,
                    ])}
                    htmlFor={id}
                >
                    {title}
                    {subTitle && <small className={$.subTitle}>{subTitle}</small>}
                </label>
            </div>
        );
    }
}

export default FormListOption;

import { Divider } from '@mui/material';
import { makeStyles } from '@mui/styles';
import sumBy from 'lodash/sumBy';
import { useTranslation } from 'react-i18next';
import { countries } from 'src/app/constants/countries';
import { ChildlessBaseComponent } from 'src/app/interfaces/BaseComponent';
import { formatDate } from 'src/app/utils/date';
import { CardBody } from 'src/view/components/Card';
import Card from 'src/view/components/Card/Card';
import ConfirmLabel from 'src/view/components/ConfirmLabel/ConfirmLabel';
import { Heading } from 'src/view/components/Heading/Heading';
import $ from './OrderSummary.module.scss';
import { OrdersummaryItem } from './OrderSummaryItem';
import { OrderSummaryPrice } from './OrderSummaryPrice';
import { OrderSummaryTableProps } from './OrderSummaryTable';

const useStyles = makeStyles(() => ({
    heading: {
        marginBottom: '2rem',
    },
}));

export interface OrderSummaryEventVM {
    name: string;
    startDate: string;
    dateConfirmed: boolean;
    SentEarliestDays?: number | null;
    SentLatestDays?: number | null;
}

export interface OrderSummaryTicketVM {
    category: string;
    quantity: number;
    imageUrl?: string | null;
}

export interface OrderSummaryVenueVM {
    name: string;
    city?: string | null;
    countryCode?: string | null;
}

export interface OrderSummaryAccomodationVM {
    name: string;
    imageUrl?: string | null;
    checkIn: string;
    checkOut: string;
    paxes: number;
    rooms: number;
}

export interface OrderSummaryTotalPriceVM {
    amount: string;
    currency: string;
}

export interface OrderSummaryProps extends ChildlessBaseComponent {
    event: OrderSummaryEventVM;
    tickets: OrderSummaryTicketVM[];
    venue: OrderSummaryVenueVM;
    accomodation?: OrderSummaryAccomodationVM;
    totalPrice: OrderSummaryTotalPriceVM;
}

export const OrderSummary = ({
    event,
    venue,
    tickets,
    accomodation,
    totalPrice,
    className,
}: OrderSummaryProps) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const getTicketSummaryItems = (
        ticket: OrderSummaryTicketVM
    ): OrderSummaryTableProps['items'] => [
        {
            title: t('ticket_ticket_delivery_tickets'),
            value: (
                <span>
                    {ticket.quantity}x {ticket.category}
                </span>
            ),
        },
        {
            title: t('traveldetail_event'),
            value: event.name,
        },
        {
            title: t('traveldetail_kickoff'),
            value: (
                <div>
                    {formatDate(event.startDate)}
                    <ConfirmLabel
                        isConfirmed={event.dateConfirmed}
                        className={$.dateConfirmedLabel}
                    />
                </div>
            ),
        },
        {
            title: t('traveldetail_venue'),
            value: venue.name,
        },
        {
            title: t('hotel_details_location'),
            value: (
                <span>
                    {venue.city}
                    {venue.countryCode && (
                        <>, {countries.find((c) => c.code === venue.countryCode)?.name}</>
                    )}
                </span>
            ),
        },
    ];

    const accomodationSummaryItems = [
        {
            title: t('traveldetail_hotel'),
            value: accomodation?.name,
        },
        {
            title: t('traveldetail_persons'),
            value: (
                <span>
                    {accomodation?.paxes} {t('ticketTravelCompany').toLowerCase()}
                </span>
            ),
        },
        {
            title: t('ticket_rooms'),
            value: (
                <span>
                    {accomodation?.rooms}{' '}
                    {accomodation?.rooms === 1 ? t('room').toLowerCase() : t('rooms').toLowerCase()}
                </span>
            ),
        },
        {
            title: t('ticketOutJourney'),
            value: accomodation ? formatDate(accomodation?.checkIn, { hideHM: true }) : '',
        },
        {
            title: t('ticketReturnJourney'),
            value: accomodation ? formatDate(accomodation.checkOut, { hideHM: true }) : '',
        },
    ];

    const noImageUrl = '/images/temp/no-image-available-grey.png';

    return (
        <Card className={className}>
            <CardBody>
                <Heading marginTop={false} variant="h2" className={classes.heading}>
                    {t('payment_completed_order_overview')}
                </Heading>
                <div>
                    {tickets.map((ticket, index) => (
                        <OrdersummaryItem
                            key={index}
                            imageUrl={ticket.imageUrl}
                            title={t('payment_completed_your_tickets')}
                            alt={ticket.category}
                            tableItems={getTicketSummaryItems(ticket)}
                            noImageUrl={noImageUrl}
                        />
                    ))}

                    {accomodation && (
                        <>
                            <Divider />

                            <OrdersummaryItem
                                imageUrl={accomodation.imageUrl}
                                title={t('payment_completed_your_hotel')}
                                alt={accomodation.name}
                                tableItems={accomodationSummaryItems}
                                noImageUrl={noImageUrl}
                            />
                        </>
                    )}

                    <Divider />

                    <OrderSummaryPrice
                        price={totalPrice.amount}
                        currency={totalPrice.currency}
                        paxes={
                            accomodation
                                ? accomodation.paxes
                                : sumBy(tickets, (ticket) => ticket.quantity)
                        }
                    />
                </div>
            </CardBody>
        </Card>
    );
};

export enum NOTIFICATION_STATUS {
    ERROR = 'error',
    INFO = 'info',
    SUCCESS = 'success',
}

export interface Notification {
    type: NOTIFICATION_STATUS;
    message: string;
}

import { withTranslation, WithTranslation } from 'react-i18next';
import { Loading } from 'src/app/pages/Loading/Loading';
import { formatMoney } from 'src/data/services/formatting';
import { DeleteIcon } from 'src/images/icons';
import $ from 'src/view/components/Discount/Discount.module.scss';

export interface GroupedDiscountRowProps extends WithTranslation {
    onIconClick: () => void;
    isLoading?: boolean;
    discountsCodes: string[];
    totalDiscountvalue: number;
}

const GroupedDiscountRow = ({
    onIconClick,
    isLoading,
    discountsCodes,
    totalDiscountvalue,
}: GroupedDiscountRowProps) => {
    const renderVoucherCodes = () => {
        return discountsCodes.map((code) => <div className={$.greyCode}>{code}</div>);
    };

    return (
        <>
            <div className={$.discountRow}>
                <div className={$.discountTravelPart}>
                    {isLoading ? (
                        <span>
                            <Loading />
                        </span>
                    ) : (
                        <span onClick={onIconClick}>
                            <DeleteIcon />
                        </span>
                    )}

                    <span>{discountsCodes.length} Vouchers</span>
                </div>

                <span className={$.discountAmount}>
                    - {formatMoney(totalDiscountvalue.toString(), { hideZeroDecimals: true })}
                </span>
            </div>
            <div className={$.voucherCodeList}>{renderVoucherCodes()}</div>
        </>
    );
};

export default withTranslation()(GroupedDiscountRow);

import React from 'react';
import classnames from 'classnames';
import BigNumber from 'bignumber.js';
import StarIcon from '@mui/icons-material/Star';
import StarHalfIcon from '@mui/icons-material/StarHalf';
import makeStyles from '@mui/styles/makeStyles';
import { colors } from 'src/app/constants/theme';

const useStyles = makeStyles(() => ({
    stars: {
        display: 'flex',
        alignItems: 'center',

        '& svg': {
            color: colors.yellow,
            width: '1rem',
            height: '1rem',

            '&:last-of-type': {
                marginRight: 0,
            },
        },
    },
    starsBig: {
        '& svg': {
            transform: 'scale(1.2)',
        },
    },
}));

export default function Stars({ stars, big }: { stars: number; big?: boolean }) {
    const classes = useStyles();

    const flooredStars = Math.floor(stars);
    const hasHalfStar = new BigNumber(stars).minus(flooredStars).isGreaterThan(0);

    const renderStars = () => {
        return [...Array(flooredStars)].map((_, i) => <StarIcon key={`star-${i}`} />);
    };

    const renderHalfStars = () => {
        if (hasHalfStar) return <StarHalfIcon />;
    };

    return (
        <div className={classnames([classes.stars, big && classes.starsBig])}>
            {renderStars()}
            {hasHalfStar && renderHalfStars()}
        </div>
    );
}

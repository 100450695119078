import React from 'react';

export const MastercardIcon = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="34.706"
        height="23.411"
        viewBox="0 0 34.706 23.411"
    >
        <path
            data-name="Path 5410"
            d="M33.675 23.411H1.055A1.077 1.077 0 0 1 0 22.356V1.055A1.077 1.077 0 0 1 1.055 0h32.6A1.059 1.059 0 0 1 34.7 1.055V22.33a1.044 1.044 0 0 1-1.025 1.081z"
        />
        <g data-name="Group 1874">
            <path
                data-name="Rectangle 1270"
                transform="translate(13.687 5.068)"
                style={{ fill: '#ec4e1b' }}
                d="M0 0h7.383v13.3H0z"
            />
            <path
                d="M24.845 21.238a8.43 8.43 0 0 1 3.216-6.638 8.464 8.464 0 1 0 0 13.326 8.649 8.649 0 0 1-3.216-6.688z"
                transform="translate(-10.695 -9.507)"
                style={{ fill: '#db001c' }}
            />
            <path
                data-name="Path 5411"
                d="M116.88 64.934v-.283h.1V64.6h-.28v.051h.1v.283zm.54 0V64.6h-.077l-.1.232-.1-.232h-.077v.334h.051v-.257l.1.206h.051l.1-.206v.257z"
                transform="translate(-86.677 -47.981)"
                style={{ fill: '#fff' }}
            />
            <path
                data-name="Path 5412"
                d="M81.061 21.238A8.447 8.447 0 0 1 67.4 27.9a8.463 8.463 0 0 0 .026-13.3 8.428 8.428 0 0 1 13.635 6.637z"
                transform="translate(-50.06 -9.506)"
                style={{ fill: '#f58d17' }}
            />
        </g>
    </svg>
);

import React from 'react';
import { hasDurationMoreThanOneDay } from 'src/app/utils/date';
import moment, { Moment } from 'moment';
import { shortDateFormatWithTime } from 'src/app/constants/date';

interface EventDateRendererProps {
    dateTime: Moment;
    dateTimeEnd?: Moment | null;
}

export const EventDateRenderer = ({ dateTime, dateTimeEnd }: EventDateRendererProps) => {
    const renderDate = (): string => {
        if (dateTimeEnd && hasDurationMoreThanOneDay(dateTime, dateTimeEnd)) {
            return `${moment(dateTime).format(shortDateFormatWithTime)} - ${moment(
                dateTimeEnd
            ).format(shortDateFormatWithTime)}`;
        }

        return moment(dateTime).format(shortDateFormatWithTime);
    };

    return <>{renderDate()}</>;
};

import makeStyles from '@mui/styles/makeStyles';
import React, { CSSProperties } from 'react';
import { colors, layout } from 'src/app/constants/theme';
import { Price } from 'src/view/components';
import Button from 'src/view/components/Button/Button';
import { PriceProps } from 'src/view/components/Price/Price';

const useStyles = makeStyles((theme) => ({
    moreInfoContainer: {
        width: '100%',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    moreInfoButton: {
        background: colors.backgroundPrimary,
        textTransform: 'none',
        width: 'auto',
        padding: '10px 20px',
        height: 'auto',
        color: colors.darkBlue,
        borderRadius: layout.borderRadiusBase,
        '&:hover': {
            background: `${colors.greyHover} !important`,
        },
    },
}));

interface MoreInfoPriceContainerProps {
    onClickMoreInfo: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    priceProps: PriceProps;
    marginTop: CSSProperties['marginTop'];
    marginBottom: CSSProperties['marginBottom'];
    text?: string;
}

export const MoreInfoPriceContainer = ({
    onClickMoreInfo,
    priceProps,
    marginTop = '.5rem',
    marginBottom = '.5rem',
    text = 'More info',
}: MoreInfoPriceContainerProps) => {
    const classes = useStyles();

    return (
        <div className={classes.moreInfoContainer} style={{ marginTop, marginBottom }}>
            <Button
                className={classes.moreInfoButton}
                text={text}
                onClick={onClickMoreInfo}
                disabled={!onClickMoreInfo}
            />
            <Price {...priceProps} />
        </div>
    );
};

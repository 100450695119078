/* eslint-disable lines-between-class-members */
import moment from 'moment-timezone';
import md5 from 'md5';
import {
    AvailabilityBasePackage,
    AvailabilityBasePackageEntity,
} from './AvailabilityBaseAccommodation';
import {
    AvailabilityAccommodationEntity,
    AvailabilityAccommodation,
} from './AvailabilityAccommodation';
import { Ticket, TicketEntity } from './Ticket';

export interface AvailabilityEntity {
    base_package: AvailabilityBasePackageEntity;
    accommodations?: AvailabilityAccommodationEntity[];
    tickets: TicketEntity[];
    expiry: string;
}

export class Availability {
    public readonly basePackage: AvailabilityBasePackage;
    public readonly accomodations?: AvailabilityAccommodation[];
    public readonly tickets: Ticket[];
    public readonly expiry: number;
    public readonly expiryTime: string;
    public readonly hash: string;

    public constructor(availability: AvailabilityEntity) {
        this.basePackage = new AvailabilityBasePackage(availability.base_package);
        this.hash = md5(JSON.stringify(availability));

        if (availability.accommodations) {
            this.accomodations = availability.accommodations.map(
                accomodation => new AvailabilityAccommodation(accomodation)
            );
        }
        this.tickets = availability.tickets.map(ticket => new Ticket(ticket));
        // Availability is about 60 minutes. To prevent the availability expiring
        // while finalizing an order, manually expire the availability after 55 minutes
        this.expiry = moment(availability.expiry)
            .subtract(5, 'minutes')
            .local()
            .unix();

        this.expiryTime = availability.expiry;
    }

    public findTicketByCategoryId(categoryId: string): Ticket | null {
        return this.tickets.find(t => t.categoryId === categoryId) || null;
    }

    public findTicketById(id: string): Ticket | null {
        return this.tickets.find(t => t.id === id) || null;
    }

    public findAccommodationById(id: string): AvailabilityAccommodation | null {
        return this.accomodations?.find(a => a.id === id) || null;
    }
}

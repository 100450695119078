import moment from 'moment-timezone';
import { officeCloseTime, officeOpenTime } from 'src/app/constants/officeHours';

export const getOnlineStatusByOfficeHours = () => {
    const now = moment.utc(new Date()).toDate();

    const dateOfficeClosed = moment(new Date()).format('YYYY-MM-DD');
    const officeClosed = moment(`${dateOfficeClosed} ${officeCloseTime}`).tz('Europe/Amsterdam');

    const dateOfficeOpens = moment(new Date()).format('YYYY-MM-DD');
    const officeOpens = moment(`${dateOfficeOpens} ${officeOpenTime}`).tz('Europe/Amsterdam');

    return now <= officeClosed && now >= officeOpens;
};

import * as React from 'react';
import { PaymentGateway } from 'src/data/services/payments';
import classnames from 'classnames';
import $ from './PayIcons.module.scss';
import { Fragment } from 'react';

interface Props {
    gateways: PaymentGateway[];
    fullWidth?: boolean;
    iconVariant?: 'normal' | 'large';
}

export default function PayIcons({ gateways, fullWidth = false, iconVariant = 'normal' }: Props) {
    return (
        <div
            className={classnames(
                $.wrap,
                fullWidth && $.wrapFullWidth,
                iconVariant === 'large' && $.wrapIconLarge
            )}
        >
            {gateways.map((gateway, i) => (
                <Fragment key={`${gateway.name}-${i}`}>{gateway.icon}</Fragment>
            ))}
        </div>
    );
}

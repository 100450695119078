import { useCallback } from 'react';
import { useHistory } from 'react-router';
import { withLocale } from '../constants/appLocale';

/** a wrapper of history.push that prepend `/{locale}` if locale route param is present. */
export function useNavigate() {
    const history = useHistory();

    const navigate = useCallback((path: string) => {
        history.push(withLocale(path));
    }, []);

    return navigate;
}

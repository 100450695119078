import React from 'react';

import $ from './Loading.module.scss';
import $page from '../../../view/components/Page/Page.module.scss';
import { Spinner } from 'src/view/components';

export function Loading() {
    return (
        <div className={$page.main}>
            <div className={$page.content}>
                <div className={$page.left}>
                    <div className={$.loadingWrapper}>
                        <Spinner data-cy="loading-page" />
                    </div>
                </div>
            </div>
        </div>
    );
}

import classnames from 'classnames';
import { BaseComponent } from 'src/app/interfaces/BaseComponent';
import { Heading } from 'src/view/components/Heading/Heading';
import $ from './FormLabel.module.scss';

export interface FormLabelProps extends BaseComponent {
    grey?: boolean;
    marginTop?: boolean;
}

export const FormLabel = ({
    children,
    style,
    grey = true,
    marginTop,
    className,
}: FormLabelProps) => {
    return (
        <Heading
            variant="h4"
            marginTop={false}
            style={style}
            uppercase={false}
            grey={grey}
            className={classnames($.formLabel, className && className, {
                [$.grey]: grey,
                [$.marginTop]: marginTop,
            })}
        >
            {children}
        </Heading>
    );
};

import { AvailabilityErrorEnum } from 'src/app/store/orderSlice';
import { TFunction } from 'i18next';

export const mapAvailabilityErrorMessage = (error: AvailabilityErrorEnum, t: TFunction) => {
    switch (error) {
        case AvailabilityErrorEnum.NOT_AVAILABLE_TICKET:
            return t('availability_error_ticket');
        case AvailabilityErrorEnum.NOT_AVAILABLE_HOTEL:
            return t('availability_error_hotel');
        case AvailabilityErrorEnum.SERVER_ERROR:
            return t('availability_error_server');
        case AvailabilityErrorEnum.ERROR_ORDER_AVAILABILITY_EXPIRED:
            return t('availability_error_server');
        default:
            return t('availability_error_generic');
    }
};

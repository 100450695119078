import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { selectPartnerConfig, selectPaymentGateways } from 'src/app/store/appSlice';
import { RouteHelper } from 'src/app/utils/RouteHelper';
import { queryStringToObject } from 'src/app/utils/utils';
import { formatMoney } from 'src/data/services/formatting';
import { validatePaymentRequest } from 'src/data/services/order';
import { PaymentRequestResponse, fetchPaymentRequest } from 'src/data/services/paymentRequests';
import { PaymentGateway, PaymentType, getPaymentUrl } from 'src/data/services/payments';
import { ErrorMessage, InfoMessage, Page } from 'src/view/components';
import { Body } from 'src/view/components/Body/Body';
import Button from 'src/view/components/Button/Button';
import Card, { CardBody } from 'src/view/components/Card';
import CountDownTimer from 'src/view/components/CountDownTimer/CountDownTimer';
import { Heading } from 'src/view/components/Heading/Heading';
import { VSpacer } from 'src/view/components/Page';
import PageLayout from 'src/view/components/PageLayout/PageLayout';
import PaymentMethodSelector from 'src/view/components/PaymentMethodSelector';
import FaqBlockFeature from 'src/view/features/Faq/FaqBlockFeature';
import { Loading } from '../Loading/Loading';
import { NotFound } from '../NotFound/NotFound';
import $ from './PaymentRequest.module.scss';

interface PaymentRequestParams {
    paymentRequestId: string;
}

export function PaymentRequest() {
    const { t } = useTranslation();
    const { paymentRequestId } = useParams<PaymentRequestParams>();

    const gateways = useSelector(selectPaymentGateways);
    const config = useSelector(selectPartnerConfig);

    const [paymentRequestIsExpired, setPaymentRequestIsExpired] = useState(false);
    const [isAvailable, setIsAvailable] = useState(true);
    const [selectedGateway, setSelectedGateway] = useState<PaymentGateway | null>(null);
    const [isLoadingData, setIsLoadingData] = useState(false);
    const [loadingDataError, setLoadingDataError] = useState<string | null>(null);
    const [notFound, setNotFound] = useState(false);
    const [paymentRequest, setPaymentRequest] = useState<PaymentRequestResponse>();
    const [isWaitingForRedirectToPayment, setIsWaitingForRedirectToPayment] =
        useState<boolean>(false);

    const { status: callbackStatus } = queryStringToObject(window.location.search);

    const isCallback = !!callbackStatus;

    useEffect(() => {
        if (!gateways || gateways.length === 0 || selectedGateway !== null) return;

        setSelectedGateway(gateways[0]);
    }, [gateways, selectedGateway]);

    useEffect(() => {
        if (paymentRequest || isLoadingData || loadingDataError || notFound) return;

        setIsLoadingData(true);

        fetchPaymentRequest(paymentRequestId)
            .then((res) => {
                setPaymentRequest(res);
                setIsLoadingData(false);
            })
            .catch((err) => {
                const status = err.response?.status;

                if (status && status === 404) {
                    setNotFound(true);
                    setIsLoadingData(false);
                    return;
                }

                setLoadingDataError(err.message || '');
                setIsLoadingData(false);
            });
    }, [paymentRequest, isLoadingData, loadingDataError, notFound, paymentRequestId]);

    /**
     * Validate payment request before payment to prevent overselling.
     * Return true if still available; false otherwise.
     */
    const checkAvailability = useCallback(async () => {
        const { errors } = await validatePaymentRequest(paymentRequestId);

        if (errors.length > 0) {
            setIsAvailable(false);
            setIsWaitingForRedirectToPayment(false);
            return false;
        }
        return true;
    }, []);

    useEffect(() => {
        checkAvailability();
    }, [checkAvailability]);

    /** Return true if still valid and false if expired; */
    const verifyPaymentRequest = useCallback(() => {
        if (!paymentRequest || !isAvailable) return false;

        const isExpired = moment().isAfter(paymentRequest.expires_at);
        setPaymentRequestIsExpired(isExpired);
        return !isExpired;
    }, [paymentRequest]);

    useEffect(() => {
        const isValid = verifyPaymentRequest();
        const timer = isValid ? setInterval(verifyPaymentRequest, 1000) : undefined;

        return () => {
            if (timer !== undefined) clearInterval(timer);
        };
    }, [verifyPaymentRequest]);

    if (isLoadingData) {
        return <Loading />;
    }

    if (notFound) {
        return (
            <NotFound
                title={t('payment_request_not_found_title')}
                text={t('payment_request_not_found_text')}
            />
        );
    }

    if (loadingDataError || !paymentRequest) {
        return (
            <Page step={-1} mustHideTravelDetails>
                <ErrorMessage
                    content={`${t('payment_request_fetch_error')} (${loadingDataError})`}
                />
            </Page>
        );
    }

    const isZeroAmount = paymentRequest.amount === '0.00';

    const redirectToPaymentPage = async () => {
        if (!selectedGateway) return;

        setIsWaitingForRedirectToPayment(true);
        const isAvailable = await checkAvailability();

        if (isAvailable) {
            window.location.assign(
                getPaymentUrl({
                    type: PaymentType.PAYMENT_REQUEST,
                    id: paymentRequestId,
                    gateway: selectedGateway,
                })
            );
        }
    };

    const getCallbackNotification = () => {
        switch (callbackStatus) {
            case 'success':
                return <InfoMessage text={t('payment_request_callback_success')} />;
            case 'failed':
                return <ErrorMessage content={t('payment_request_callback_failed')} />;
            default:
                return <ErrorMessage content="Callback url malformed" />;
        }
    };

    const hasOneHourLeft = () =>
        moment(paymentRequest?.expires_at).diff(new Date(), 'seconds') < 3600;

    const renderText = (element: React.ReactNode, medium?: boolean) => (
        <Body marginTop={false} marginBottom={false} fontWeight={medium ? 'medium' : 'normal'}>
            {element}
        </Body>
    );

    const handleBookSameEvent = () => {
        const { event_id, package_type, ticket_category_id } = paymentRequest;

        if (!event_id) return;

        window.location.href = RouteHelper.getTicketRoute(event_id, {
            package_type: package_type || undefined,
            category_id: ticket_category_id || undefined,
        });
    };

    const websiteUrl = config?.websiteUrl;

    const handleBookOtherEvent = () => {
        if (websiteUrl) window.open(websiteUrl, '_blank');
    };

    const showPaymentMethods =
        !isCallback && !isZeroAmount && isAvailable && !paymentRequestIsExpired;

    return (
        <Page step={4} mustHideTravelDetails hideCountDown>
            <PageLayout.Container>
                <PageLayout.ContentLeft>
                    <Card>
                        <CardBody>
                            <>
                                <Heading variant="h1" marginTop={false}>
                                    {t('payment_request_title')}
                                </Heading>
                                <table className={$.detailsTable}>
                                    <tbody>
                                        <tr>
                                            <th>
                                                {renderText(
                                                    t('payment_request_order_number'),
                                                    true
                                                )}
                                            </th>
                                            <td>{renderText(paymentRequest.order_number)}</td>
                                        </tr>
                                        <tr>
                                            <th>{renderText(t('payment_request_id'), true)}</th>
                                            <td>{renderText(paymentRequest.id)}</td>
                                        </tr>
                                        {!isCallback && (
                                            <tr>
                                                <th>
                                                    {renderText(t('payment_request_amount'), true)}
                                                </th>
                                                <td>
                                                    {renderText(formatMoney(paymentRequest.amount))}
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>

                                <div className={$.countdownWrapper}>
                                    {!paymentRequestIsExpired &&
                                        hasOneHourLeft() &&
                                        !isCallback && (
                                            <CountDownTimer
                                                text={t('payment_request_countdown_text')}
                                                expiry={paymentRequest.expires_at}
                                                inline
                                            />
                                        )}
                                    {!isAvailable && (
                                        <>
                                            <ErrorMessage
                                                content={t('tickets_not_available_error_title')}
                                            />
                                            {!!websiteUrl && (
                                                <Button
                                                    text={t('payment_request_book_other_event')}
                                                    onClick={handleBookOtherEvent}
                                                    className={$.button}
                                                />
                                            )}
                                        </>
                                    )}
                                    {paymentRequestIsExpired && !isCallback && (
                                        <>
                                            <ErrorMessage content={t('payment_request_expired')} />
                                            {paymentRequest.event_id ? (
                                                <>
                                                    <Button
                                                        text={t('payment_request_book_same_event')}
                                                        onClick={handleBookSameEvent}
                                                        className={$.button}
                                                    />
                                                    {!!websiteUrl && (
                                                        <div className={$.bookOtherEvent}>
                                                            <a href={websiteUrl} target="_blank">
                                                                {t(
                                                                    'payment_request_book_other_event'
                                                                )}
                                                            </a>
                                                        </div>
                                                    )}
                                                </>
                                            ) : (
                                                <>
                                                    {!!websiteUrl && (
                                                        <Button
                                                            text={t(
                                                                'payment_request_book_other_event'
                                                            )}
                                                            onClick={handleBookOtherEvent}
                                                            className={$.button}
                                                        />
                                                    )}
                                                </>
                                            )}
                                        </>
                                    )}
                                </div>

                                {isCallback && (
                                    <>
                                        <VSpacer />
                                        {getCallbackNotification()}
                                    </>
                                )}

                                {!isCallback && isZeroAmount && (
                                    <>
                                        <VSpacer />
                                        <InfoMessage text={t('payment_request_zero_amount')} />
                                    </>
                                )}

                                {showPaymentMethods && (
                                    <div className={$.paymentSelectorWrapper}>
                                        <PaymentMethodSelector
                                            gateways={gateways || []}
                                            onSelectGateway={(g) => setSelectedGateway(g)}
                                            selectedGateway={selectedGateway}
                                        />
                                        <div className="clearfix" />
                                        <div>
                                            <Button
                                                className={$.button}
                                                text={t('payment_request_confirm')}
                                                icon="/images/icon-lock-white.svg"
                                                onClick={redirectToPaymentPage}
                                                disabled={isWaitingForRedirectToPayment}
                                                isLoading={isWaitingForRedirectToPayment}
                                            />
                                        </div>
                                    </div>
                                )}
                            </>
                        </CardBody>
                    </Card>
                </PageLayout.ContentLeft>

                <PageLayout.ContentRight>
                    <FaqBlockFeature hideFaq />
                </PageLayout.ContentRight>
            </PageLayout.Container>
        </Page>
    );
}

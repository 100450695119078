import { getAppLocale } from '../hooks/use-locale';

export const SUPPORTED_LOCALES = ['en', 'en-GB', 'de', 'nl', 'fr', 'es'] as const;

export type AppLocale = (typeof SUPPORTED_LOCALES)[number];

const supportedLocaleSet = new Set<AppLocale>(SUPPORTED_LOCALES);
export function isKnownLocale(locale: string | undefined): locale is AppLocale {
    if (!locale) return false;
    return supportedLocaleSet.has(locale as AppLocale);
}

/** Map the app locale with Travelware locale */
export const localeMapping: Record<AppLocale, string> = {
    en: 'en-US',
    'en-GB': 'en-GB',
    de: 'de-DE',
    nl: 'nl-NL',
    fr: 'fr-FR',
    es: 'es-ES',
} as const;

export const fallbackLocale = 'en';

export function getTravelwareLang() {
    const locale = getAppLocale();
    return localeMapping[locale];
}

export function getAppLocaleFromTravelwareLang(lang: string | null): AppLocale {
    if (!lang) return fallbackLocale;
    if (lang === 'en-GB' || lang === 'en_GB') return 'en-GB';
    // Pick the language, e.g. de-DE => de
    // TW1 could use the underscore or hyphen separated code.
    const locale = lang.split(/[-_]/)[0];
    return isKnownLocale(locale) ? locale : fallbackLocale;
}

/** The current app locale. This shouldn't be in the redux store
 * because it will also be used outside of the React component. */
let appLocale: AppLocale | undefined;

/** Return the `locale` route param for the NGINX API look up.
 * Note: BE doesn't always configure the right language for the domain on non-prod environment.
 * e.g. on STAGING, `.com` is using Dutch.
 * That's why we need to have this function instead of using getAppLocale.
 */
export function getRouteLocaleParam() {
    // Always prioritize the locale route
    const firstSegment = window.location.pathname.split('/')[1];
    return isKnownLocale(firstSegment) ? firstSegment : undefined;
}

export function withLocale(path: string) {
    const isLocalized = isKnownLocale(path.split('/')[1]);

    if (isLocalized) return path;

    const localeRouteParam = getRouteLocaleParam();

    if (!localeRouteParam) return path;

    return `/${localeRouteParam}${path}`;
}

export function localizeOrigin(origin: string) {
    const locale = getRouteLocaleParam();
    // If `locale` is present, it's single-domain.
    // Replace the country-specific domain `.es` or `.co.uk` with `.com`.
    const formattedOrigin = locale ? origin.replace(/\.((co\.uk)|([a-z]+))$/, '.com') : origin;
    return locale ? `${formattedOrigin}/${locale}` : formattedOrigin;
}

import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock';
import React, { useEffect } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { CloseButton } from '..';
import Button from '../Button/Button';
import $ from './Notification.module.scss';

interface NotificationProps extends WithTranslation {
    onConfirm: () => void;
    notification: string;
    title: string;
    onClose: () => void;
}

const Notification = ({ onConfirm, onClose, notification, title, t }: NotificationProps) => {
    const notificationRef = React.createRef<HTMLDivElement>();

    useEffect(() => {
        disableBodyScroll(notificationRef.current);
    }, []);

    const handleClose = () => {
        clearAllBodyScrollLocks();
        onClose();
    };

    const handleConfirm = () => {
        clearAllBodyScrollLocks();
        onConfirm();
    };

    return (
        <div className={$.wrap}>
            <button type="button" className={$.background} onClick={handleClose} />
            <div className={$.notificationLayout} ref={notificationRef}>
                <div className={$.notificationLayoutContent}>
                    <h1 className={$.header}>{title}</h1>
                    <div className={$.text}>{notification}</div>
                    <CloseButton onClick={handleClose} />
                </div>
                <div className={$.confirmButton}>
                    <Button onClick={handleConfirm} text={t('notification_confirm')} />
                </div>
            </div>
        </div>
    );
};

export default withTranslation()(Notification);

import { RootState } from 'src/app/store';
import { getVariant } from 'src/app/store/appSlice';

interface Experiment {
    optimizeExperimentIds: {
        test: string;
        production: string;
    };
    enabled: boolean;
    variants: { [key: string]: string };
}

interface Experiments {
    tco496: Experiment;
    tco504: Experiment;
    tco582: Experiment;
}

const experiments: Experiments = {
    tco496: {
        optimizeExperimentIds: {
            test: 'FaT4Z2kPTNmKale6QI6yMg',
            production: 'Mn70ISWwT3OfwfANl6Gffg',
        },
        enabled: true,
        variants: {
            hideUspNotification: '1',
        },
    },
    tco504: {
        optimizeExperimentIds: {
            test: 'hChXxwsWSA-23tg_J8dixg',
            production: 'dUURQbGzSgyu7MiyT7mSDg',
        },
        enabled: true,
        variants: {
            showMicroCopyText: '1',
        },
    },
    tco582: {
        optimizeExperimentIds: {
            test: '2vCts29cS12316km7tOZww',
            production: 'UsHFg2UWQ6q2nIJs2bbw0g',
        },
        enabled: true,
        variants: {
            fieldValidationOnBlurWithState: '1',
        },
    },
};

export const getAbExperiment = (
    state: RootState,
    experimentName: keyof typeof experiments,
    variantName: string
) => {
    const {
        optimizeExperimentIds: { test, production },
        variants,
    } = experiments[experimentName];

    return getVariant(state, {
        experimentIds: [test, production],
        variantId: variants[variantName],
    });
};

export default experiments;

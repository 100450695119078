import type Core from '@adyen/adyen-web/dist/types/core/core';
import { Alert, Box } from '@mui/material';
import { useEffect, useState, type Dispatch, type ReactNode, type SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Loading } from 'src/app/pages/Loading/Loading';
import { useTypedDispatch } from 'src/app/store';
import { selectPaymentGateways, setSessionOrder } from 'src/app/store/appSlice';
import { PaymentGateway, PaymentType, getPaymentUrl } from 'src/data/services/payments';
import Button from 'src/view/components/Button/Button';
import PaymentMethodSelector from 'src/view/components/PaymentMethodSelector';
import { useAdyenCheckout } from '../use-adyen-checkout';
import './PaymentFeature.scss';

export function PaymentFeature({
    setIsOrderStatusBooked,
}: {
    setIsOrderStatusBooked: Dispatch<SetStateAction<boolean>>;
}) {
    const { checkout, attempt, orderId, error, isAlreadyBooked } = useAdyenCheckout();
    const [isShowingLegacy, setIsShowingLegacy] = useState(false);
    const dispatch = useTypedDispatch();
    const { t } = useTranslation();

    useEffect(() => {
        if (isAlreadyBooked) {
            setIsOrderStatusBooked(true);
            dispatch(setSessionOrder({ orderId: '', orderSecret: undefined }));
        }
    }, [isAlreadyBooked]);

    const isBookingError = error === t('payment_book_error');

    /** If it is a /book endpoint error, and the order is not 'booked'
     * we display the 'This order cannot be booked anymore error  */
    if (isBookingError) {
        return <Alert severity="error">{t('payment_book_error')}</Alert>;
    }

    return (
        <>
            {/* If there is an error in the iframe, we show the legacy payment method */}
            {error || isShowingLegacy ? (
                <LegacyPaymentMethod orderId={orderId} />
            ) : checkout ? (
                <CheckoutScreen checkout={checkout} attempt={attempt} />
            ) : (
                <Loading />
            )}

            {/** If there is not an error coming from Adyen iframe, we give the user the ability
             * to switch back to the legacy payment method, regardless of any error state.
             * This was introduced to rule out a possible issue with the new payment method.
             */}
            {!error && (
                <LegacyAndNewFlowToggle
                    setIsShowingLegacy={setIsShowingLegacy}
                    isShowingLegacy={isShowingLegacy}
                />
            )}
        </>
    );
}

function LegacyAndNewFlowToggle({
    setIsShowingLegacy,
    isShowingLegacy,
}: {
    setIsShowingLegacy: Dispatch<SetStateAction<boolean>>;
    isShowingLegacy: boolean;
}) {
    const { t } = useTranslation();

    return (
        <Box marginY={2}>
            <Alert severity="info">
                <>
                    {isShowingLegacy ? (
                        <TogglePaymentMethod toggle={() => setIsShowingLegacy((prev) => !prev)}>
                            {t(
                                'switchBackToPreviousPaymentOptions',
                                'Switch back to previous payment options.'
                            )}
                        </TogglePaymentMethod>
                    ) : (
                        <>
                            {t('experiencingPaymentIssues', 'Experiencing payment issues?')}
                            <TogglePaymentMethod toggle={() => setIsShowingLegacy((prev) => !prev)}>
                                {t('clickHere', 'Click here')}.
                            </TogglePaymentMethod>
                        </>
                    )}
                </>
            </Alert>
        </Box>
    );
}

function LegacyPaymentMethod({ orderId }: { orderId: string | null }) {
    const gateways = useSelector(selectPaymentGateways);
    const [selectedGateway, setSelectedGateway] = useState<PaymentGateway>();

    function goToPayment() {
        if (!selectedGateway || !orderId) return;

        window.location.assign(
            getPaymentUrl({
                type: PaymentType.ORDER_PAYMENT,
                id: orderId,
                gateway: selectedGateway,
            })
        );
    }

    return (
        <Box>
            <PaymentMethodSelector
                gateways={gateways || []}
                onSelectGateway={setSelectedGateway}
                selectedGateway={selectedGateway}
            />
            <Box marginTop={2}>
                <Button text="Pay" disabled={!selectedGateway} onClick={goToPayment}></Button>
            </Box>
        </Box>
    );
}

function TogglePaymentMethod({ toggle, children }: { toggle: () => void; children: ReactNode }) {
    return (
        <Box
            fontWeight="bold"
            style={{ cursor: 'pointer', textDecoration: 'underline', fontFamily: 'inherit' }}
            component="button"
            marginLeft={0.5}
            onClick={toggle}
        >
            {children}
        </Box>
    );
}

function CheckoutScreen({ checkout, attempt }: { checkout: Core | undefined; attempt: number }) {
    useEffect(() => {
        checkout?.create('dropin').mount('#dropin-container');
        return () => {
            checkout?.update();
        };
    }, [checkout, attempt]);

    return <div id="dropin-container" />;
}

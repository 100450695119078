import React, { useEffect, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import classnames from 'classnames';
import { colors } from 'src/app/constants/theme';
import { motion, useAnimation } from 'framer-motion';

const useStyles = makeStyles(() => ({
    container: {
        display: 'flex',
        alignItems: 'center',
    },
    incrementor: {
        fontSize: '.875rem',
        color: colors.grey,
        marginLeft: '.5rem',
    },
}));

interface CountUpProps {
    start?: number;
    end: number;
    duration?: number;
    numberSpanProps?: React.HTMLAttributes<HTMLSpanElement>;
    incrementSpanProps?: React.HTMLAttributes<HTMLSpanElement>;
    suffix?: string;
    step?: number;
}

export const CountUp = ({
    start = 0,
    end,
    duration = 1,
    numberSpanProps,
    incrementSpanProps,
    suffix,
    step = 5,
}: CountUpProps) => {
    const classes = useStyles();
    const controls = useAnimation();
    const [count, setCount] = useState(start);
    const [intervalId, setIntervalId] = useState<NodeJS.Timer | null>(null);
    const difference = end - start;

    useEffect(() => {
        if (!start) return;

        controls.start(
            {
                x: [0, 0, 0, 0, -20],
                y: [-5, 0, 0, 0, 0],
                opacity: [1, 1, 1, 1, 0],
            },
            {
                duration: 1,
            }
        );
    }, [start, end]);

    useEffect(() => {
        return () => {
            if (!intervalId) return;

            clearInterval(intervalId);
        };
    }, [intervalId]);

    const renderDifference = () => (
        <span
            className={classnames(classes.incrementor, incrementSpanProps?.className)}
            {...incrementSpanProps}
        >
            {difference > 0 && <span>+</span>}
            {difference}
            {suffix}
        </span>
    );

    const onDifferenceAnimationCompleted = () => {
        if (intervalId) return;

        const interval = setInterval(() => {
            setCount((c) => {
                const incrementor = c > end ? -step : step;
                const hasReachedEndWithPositiveIncrementor =
                    incrementor > 0 && c < end && c + incrementor > end;
                const hasReachedEndWithNegativeIncrementor =
                    incrementor < 0 && c > end && c - incrementor < end;
                const hasNotReachedEnd =
                    (incrementor > 0 && c < end) || (incrementor < 0 && c > end);

                if (hasReachedEndWithPositiveIncrementor || hasReachedEndWithNegativeIncrementor) {
                    return end;
                }

                if (hasNotReachedEnd) {
                    return c + incrementor;
                }

                clearInterval(interval);
                setIntervalId(null);

                return c;
            });
        }, duration);

        setIntervalId(interval);
    };

    return (
        <div className={classes.container}>
            <div>
                <span {...numberSpanProps}>
                    {count}
                    {suffix}
                </span>
            </div>
            <motion.div
                style={{ opacity: 0 }}
                animate={controls}
                transition={{ duration: 1 }}
                onAnimationComplete={() => onDifferenceAnimationCompleted()}
            >
                {renderDifference()}
            </motion.div>
        </div>
    );
};

import React from 'react';
import classnames from 'classnames';
import $ from './AlertMessage.module.scss';

interface AlertMessageProps {
    message: string;
    icon?: JSX.Element;
}

const AlertMessage = ({ message, icon }: AlertMessageProps) => {
    return (
        <div className={classnames($.alertWrapper)}>
            {icon && <div className={$.alertIcon}>{icon}</div>}
            <div className={$.alertTitle}>{message}</div>
        </div>
    );
};

export default AlertMessage;

import { localeToLanguageAndCountry } from '../utils/utils';
import { useCookieBot } from './use-cookie-bot';
import { useAppLocale } from './use-locale';
import { usePageViewEvent } from './use-page-visit-event';
import { useTagManager } from './use-tag-manager';

export const useInitAnalytics = (shouldInitialize: boolean) => {
    const locale = useAppLocale();
    const { country, language } = localeToLanguageAndCountry(locale);

    // Initializes Cookie Bot
    useCookieBot({ shouldInitialize, language });

    // Initializes Google Tag Manager
    useTagManager({ shouldInitialize });

    // Fires the single page visit event we need
    usePageViewEvent({ shouldInitialize, country, language });
};

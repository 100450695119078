import { EventTicketEntity, TicketType } from './EventTicket';

/* eslint-disable lines-between-class-members */
export interface TicketEntity {
    id: string;
    category_id: string;
    name: string;
    seatplan_image: string;
    supplement_pp: number;
    description?: string;
    images: {
        url: string;
        title: string;
    }[];
    possibleTicketTypes?: string[];
}

export interface TicketOptions {
    ticket_id: string;
    supplement_pp: string;
    content: {
        name: string;
    };
}

export enum TicketSourceType {
    'Event',
    'Availability',
}

export class Ticket {
    public readonly id: string;
    public readonly categoryId: string;
    public readonly name: string;
    public seatplanImage: string;
    public supplementPP: number;
    public readonly description?: string;
    public images: { url: string; title: string }[];
    public readonly possibleTicketTypes?: TicketType[];

    public constructor(ticket: TicketEntity) {
        this.id = ticket.id;
        this.categoryId = ticket.category_id;
        this.name = ticket.name;
        this.seatplanImage = ticket.seatplan_image;
        this.supplementPP = +ticket.supplement_pp;
        this.images = ticket.images && ticket.images.length > 0 ? ticket.images : [];
        this.description = ticket.description;
        this.possibleTicketTypes = ticket.possibleTicketTypes;
    }
}

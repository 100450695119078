import React from 'react';
import { BaseComponent } from 'src/app/interfaces/BaseComponent';
import $ from './PageLayout.module.scss';

const Container = ({ children }: BaseComponent) => {
    return <div className={$.container}>{children}</div>;
};

export const ContentLeft = ({ children }: BaseComponent) => (
    <div className={$.contentLeft}>{children}</div>
);

export const ContentRight = ({ children }: BaseComponent) => (
    <div className={$.contentRight}>{children}</div>
);

export default { Container, ContentLeft, ContentRight };

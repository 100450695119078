import { Grid } from '@mui/material';
import classnames from 'classnames';
import { sum } from 'lodash';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getAbExperiment } from 'src/ab-testing/experiments';
import { RootState } from 'src/app/store';
import { pushStepEventToDataLayer } from 'src/app/utils/googleAnalytics';
import { generateId } from 'src/app/utils/utils';
import { EventDetail } from 'src/data/models/EventDetail';
import { PackageType } from 'src/data/models/Order';
import { Preferences } from 'src/data/models/Preferences';
import * as Cache from 'src/data/services/cache';
import { FormLabel } from 'src/view/components/FormLabel/FormLabel';
import { VSpacer } from 'src/view/components/Page';
import { UspNotification } from 'src/view/components/UspNotification/UspNotification';
import FormCheckbox from '../FormCheckbox';
import Spinner from '../Spinner';
import $ from './OrderPreferences.module.scss';
import { OrderPreferencesTravelerControls } from './order-preferences-traveler-controls';

enum ADULT_EXTRA_OPTIONS {
    MORE_THAN_10_PERSONS = 'MORE_THAN_10_PERSONS',
}

interface Props {
    errors: string[];
    onRefreshOrderWithPreferencesUpdate(updatePreferences: boolean, preferences: Preferences): void;
    autoConfirmPreferences: boolean;
    maxOccupancy: number;
    isSubmitting: boolean;
    ticketPrice: string;
    accommodationPrice: string;
    packageType: PackageType;
    eventDetail: EventDetail;
    preferencesConfirmed: boolean;
    onConfirmPreferences(preferences: Preferences): void;
    handleOrderTypeChange(value: string, preferences: Preferences): void;
    onChange(preferences: Preferences): void;
    checkIfOrderIsUpToDate(preferences: Preferences): boolean;
    priceChanged: boolean;
}

export function OrderPreferences({
    errors,
    onChange,
    onRefreshOrderWithPreferencesUpdate,
    autoConfirmPreferences,
    maxOccupancy,
    isSubmitting,
    packageType,
    eventDetail,
    preferencesConfirmed,
    handleOrderTypeChange,
    onConfirmPreferences,
    priceChanged,
}: Props) {
    const { t } = useTranslation();
    const cachedPreferences = useMemo(() => {
        return Cache.getPreferences();
    }, []);
    const hideUspNotificationForOrganizers = ['497bf20b-9ac9-469d-9e8e-8c4e49e7cb85'];

    const hideTicketsFromOrganizerVariant = useSelector((state: RootState) =>
        getAbExperiment(state, 'tco496', 'hideUspNotification')
    );

    const offersTickets = eventDetail.offersPackageType(PackageType.TICKET_ONLY);
    const offersTicketHotels = eventDetail.offersPackageType(PackageType.TICKET_HOTEL);

    const optionsGrid: { disabled: boolean; element: (key: string) => JSX.Element }[] = [
        {
            disabled: !offersTickets,
            element: (key: string) => (
                <Grid item xs={12} md={4} key={key} data-cy="standard-ticket">
                    <FormCheckbox
                        title={t('ticketTickets')}
                        subTitle={
                            !offersTickets
                                ? t('ticketOptionNotAvailable')
                                : t('ticketTicketsSubtitle')
                        }
                        subTitlePopover={
                            offersTickets ? t('ticketPriceFromExplanationTicketOnly') : undefined
                        }
                        name="flighttickets"
                        id="tickets"
                        value={PackageType.TICKET_ONLY}
                        checked={packageType === PackageType.TICKET_ONLY}
                        onChange={(e) => handleOrderTypeChange(e.target.value, cachedPreferences)}
                        disabled={isSubmitting || !offersTickets}
                    />
                </Grid>
            ),
        },
        {
            disabled: !offersTicketHotels,
            element: (key: string) => (
                <Grid item xs={12} md={4} key={key} data-cy="hotel-ticket">
                    <FormCheckbox
                        title={t('ticketHotelTickets')}
                        subTitle={
                            !offersTicketHotels
                                ? t('ticketOptionNotAvailable')
                                : t('ticketHotelTicketsSubtitle')
                        }
                        subTitlePopover={
                            !offersTicketHotels
                                ? undefined
                                : t('ticketPriceFromExplanationTicketHotel')
                        }
                        name="flighttickets"
                        id="hoteltickets"
                        value={PackageType.TICKET_HOTEL}
                        checked={packageType === PackageType.TICKET_HOTEL}
                        onChange={(e) => handleOrderTypeChange(e.target.value, cachedPreferences)}
                        disabled={isSubmitting || !offersTicketHotels}
                    />
                </Grid>
            ),
        },
    ];

    const sortedOptionsGrid = [
        ...optionsGrid.filter((og) => !og.disabled),
        ...optionsGrid.filter((og) => og.disabled),
    ];

    return (
        <>
            {isSubmitting && (
                <section className={$.spinnerWrap}>
                    <Spinner color="#1b1c25" size={25} />
                </section>
            )}

            {!hideTicketsFromOrganizerVariant &&
                !hideUspNotificationForOrganizers.includes(eventDetail.id) && (
                    <div className={$.uspNotificationContainer}>
                        <UspNotification text={t('orderPreferencesTicketsFromOrganizerText')} />
                    </div>
                )}

            <FormLabel grey>{t('ticketPackageType')}</FormLabel>
            <Grid container className={classnames([isSubmitting && $.submitting])} spacing={2}>
                {sortedOptionsGrid.map((g) => g.element(generateId()))}
            </Grid>
            <VSpacer />
            <OrderPreferencesTravelerControls
                onChange={onChange}
                onConfirmPreferences={(preferences) => {
                    pushStepEventToDataLayer({
                        event_type: 'step_complete',
                        step_name: 'package',
                        step_data: getNumberOfPersonsForCheckoutStepEvent(packageType, preferences),
                    });
                    onConfirmPreferences(preferences);
                }}
                onRefreshOrderWithPreferencesUpdate={onRefreshOrderWithPreferencesUpdate}
                autoConfirmPreferences={autoConfirmPreferences}
                isSubmitting={isSubmitting}
                priceChanged={priceChanged}
                packageType={packageType}
                preferencesConfirmed={preferencesConfirmed}
                errors={errors}
            />
        </>
    );
}

// Formats the roomlayout or number of persons into a string for the checkout_step event
function getNumberOfPersonsForCheckoutStepEvent(
    packageType: PackageType,
    preferences: Preferences
) {
    if (packageType === 'TICKET_ONLY')
        return `${packageType}|${preferences.adults.length} ${getPersonsText(
            preferences.adults.length
        )}`;

    const totalPersons = sum(preferences.roomLayout.map((r) => r.adults + r.children));

    return `${packageType}|${totalPersons} ${getPersonsText(totalPersons)}`;
}

function getPersonsText(numberOfPersons: number) {
    return numberOfPersons === 1 ? 'person' : 'persons';
}

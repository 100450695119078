export enum PackageType {
    TICKET_ONLY = 'TICKET_ONLY',
    TICKET_HOTEL = 'TICKET_HOTEL',
    TICKET_HOTEL_FLIGHT = 'TICKET_HOTEL_FLIGHT',
}

export interface OrderTicket {
    id: string;
    quantity: number;
}

export interface OrderAccommodationRate {
    id: string;
    room_id: string;
    room_name: string;
}

export interface OrderAccommodation {
    id: string;
    partner: string;
    partner_id: string;
    rates: OrderAccommodationRate[];
}

export interface OrderFlight {
    id: string;
}

type OrderStatus = 'draft' | 'booked' | 'ordered' | 'cancelled';

/* eslint-disable lines-between-class-members */
export interface OrderEntity {
    id: string;
    event_id: string;
    secret: string;
    package_type: PackageType;
    base_ticket_cat_id: string | null;
    tickets: OrderTicket[];
    accommodation: OrderAccommodation | null;
    flights: OrderFlight[];
    down_payment_percentage: number;
    down_payment_lead_time_days: number;
    currency: string;
    status: OrderStatus;
}

export class Order {
    public readonly id: string;
    public readonly eventId: string;
    public readonly secret: string;
    public readonly packageType: PackageType;
    public readonly baseTicketCatId: string | null;
    public readonly tickets: OrderTicket[];
    public readonly accommodation: OrderAccommodation | null;
    public readonly flights: OrderFlight[];
    public readonly downPaymentPercentage: number;
    public readonly downPaymentLeadTimeDays: number;
    public readonly currency: string;
    public readonly status: OrderStatus;

    public constructor(order: OrderEntity) {
        this.id = order.id;
        this.eventId = order.event_id;
        this.secret = order.secret;
        this.packageType = order.package_type;
        this.baseTicketCatId = order.base_ticket_cat_id || null;
        this.tickets = order.tickets || [];
        this.accommodation = order.accommodation;
        this.flights = order.flights || null;
        this.downPaymentPercentage = order.down_payment_percentage;
        this.downPaymentLeadTimeDays = order.down_payment_lead_time_days;
        this.currency = order.currency;
        this.status = order.status;
    }

    public hasTickets(): boolean {
        return this.tickets.length > 0;
    }

    public hasAccommodation(): boolean {
        return !!this.accommodation;
    }

    public hasFlights(): boolean {
        return this.flights.length > 0;
    }

    public containsAllElements(): boolean {
        switch (this.packageType) {
            case PackageType.TICKET_ONLY:
                return this.hasTickets();
            case PackageType.TICKET_HOTEL:
                return this.hasTickets() && this.hasAccommodation();
            case PackageType.TICKET_HOTEL_FLIGHT:
                return this.hasTickets() && this.hasAccommodation() && this.hasFlights();
            default:
                return false;
        }
    }
}

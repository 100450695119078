interface StepIconProps {
    step: number | string;
}

export const StepIcon = ({ step = 1 }: StepIconProps) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
        <g data-name="Group 4717">
            <g
                data-name="Ellipse 63"
                style={{ stroke: '#bcbdc9', strokeWidth: '2px', fill: 'none' }}
            >
                <circle cx="11" cy="11" r="11" style={{ stroke: 'none' }} />
                <circle cx="11" cy="11" r="10" style={{ fill: 'none' }} />
            </g>
            <text
                data-name={step}
                transform="translate(11 12)"
                textAnchor="middle"
                dominantBaseline="middle"
                style={{
                    fontSize: '14px',
                }}
            >
                {step}
            </text>
        </g>
    </svg>
);

import React from 'react';

export const IDealIcon = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="29.825"
        height="26.292"
        viewBox="0 0 29.825 26.292"
    >
        <path
            data-name="Path 5362"
            d="M7.375 7.38v26.292H22.7c10.112 0 14.5-5.663 14.5-13.172 0-7.48-4.385-13.12-14.5-13.12H7.375z"
            transform="translate(-7.375 -7.38)"
            style={{ fill: '#fff' }}
        />
        <path
            data-name="Path 5363"
            d="M1.657 1.658h13.674c9.281 0 12.838 5.068 12.838 11.461 0 7.666-4.968 11.513-12.838 11.513H1.657zm1.326 1.328v20.322h12.348c7.455 0 11.511-3.474 11.511-10.188 0-6.9-4.4-10.136-11.511-10.136H2.983z"
        />
        <path data-name="Path 5364" d="M4.639 14.614h3.953v7.035H4.639z" />
        <circle
            data-name="Ellipse 122"
            cx="2.457"
            cy="2.457"
            transform="translate(4.158 8.613)"
            r="2.457"
        />
        <path
            data-name="Path 5365"
            d="M39.786 26.412v1.139h-2.817V22.98H39.7v1.139h-1.592v.529h1.5v1.139h-1.5v.625h1.678zm.493 1.14 1.378-4.574h1.62l1.378 4.574H43.47l-.259-.885h-1.49l-.259.885zm1.777-2.023h.824l-.38-1.291h-.069zm3.172-2.551h1.14v3.434h1.687c-.463-6.23-5.363-7.577-9.819-7.577h-4.749v4.145h.7a2.02 2.02 0 0 1 2.077 2.267 2.02 2.02 0 0 1-2.077 2.3h-.7v8.294h4.749c7.24 0 9.762-3.363 9.851-8.295h-2.859v-4.567zM33.486 24.12v2.293h.7c.488 0 .938-.141.938-1.165 0-1-.5-1.128-.938-1.128z"
            transform="translate(-22.904 -14.193)"
            style={{ fill: '#d50072' }}
        />
    </svg>
);

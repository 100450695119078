import moment, { Moment } from 'moment';

export const hasDurationMoreThanOneDay = (start: Moment, end: Moment) =>
    moment(end).diff(start, 'years') > 0;

export const formatDate = (
    date: Moment | string,
    config?: {
        hideHM?: boolean;
    }
) => {
    if (config?.hideHM) {
        return moment(date).format('DD MMM YYYY');
    }

    return moment(date).format('DD MMM YYYY, HH:mm');
};

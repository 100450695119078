import * as React from 'react';
import { CSSProperties } from 'react';
import {
    Circle,
    GoogleMap,
    GoogleMapProps,
    InfoWindow,
    Marker,
    withGoogleMap,
    withScriptjs,
} from 'react-google-maps';
import { WithTranslation, withTranslation } from 'react-i18next';
import { compose, withProps } from 'recompose';
import { getEnvironment } from 'src/app/utils/config';
import { Accommodation } from 'src/data/models/Accommodation';
import { EventDetail } from 'src/data/models/EventDetail';
import { formatMoney } from 'src/data/services/formatting';
import { Stars } from '..';
import Button from '../Button/Button';
import $ from './Map.module.scss';

interface OuterProps extends GoogleMapProps {
    defaultZoom?: number;
    containerElement: React.ReactElement;
}

const EventsTravelMap = compose<GoogleMapProps, OuterProps>(
    withProps({
        googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${getEnvironment(
            'REACT_APP_MAPS_API_KEY'
        )}&v=3.exp&libraries=geometry,drawing,places`,
        loadingElement: <div style={{ height: '100%' }} />,
        mapElement: <div style={{ height: '100%' }} />,
    }),
    withScriptjs,
    withGoogleMap
)((props) => <GoogleMap {...props}>{props.children}</GoogleMap>);

interface Props extends WithTranslation {
    accomodations: Accommodation[];
    eventDetail: EventDetail;
    breakfast?: boolean;
    height: CSSProperties['height'];
    selectAccommodation?: (accommodation: Accommodation) => void;
    selectedAccommodation?: Accommodation;
    defaultZoom?: number;
}

interface State {
    openAcccommodation?: Accommodation;
}

class Map extends React.Component<Props, State> {
    public static defaultProps = {
        height: '15rem',
        defaultZoom: 12,
    };

    public constructor(props) {
        super(props);
        this.state = {};
        this.openMarker = this.openMarker.bind(this);
        this.closeMarker = this.closeMarker.bind(this);
        this.selectAccommodation = this.selectAccommodation.bind(this);
    }

    public openMarker(accommodation: Accommodation) {
        const { selectAccommodation } = this.props;
        if (selectAccommodation != null) {
            this.setState({ openAcccommodation: accommodation });
        }
    }

    public closeMarker() {
        this.setState({ openAcccommodation: undefined });
    }

    public async selectAccommodation(accommodation: Accommodation) {
        const { selectAccommodation } = this.props;
        if (selectAccommodation != null) {
            this.closeMarker();
            await selectAccommodation(accommodation);
            this.setState({});
        }
    }

    public render() {
        const {
            t,
            accomodations,
            eventDetail,
            breakfast,
            height,
            selectedAccommodation,
            defaultZoom,
        } = this.props;
        const { openAcccommodation } = this.state;

        const { latitude, longitude } = eventDetail.venue;
        const { latitude: cityLatitude, longitude: cityLongitude } = eventDetail.venue.city;

        const containerElement = <div style={{ height }} />;

        return (
            <EventsTravelMap
                defaultZoom={defaultZoom}
                defaultCenter={{ lat: latitude, lng: longitude }}
                options={{ disableDefaultUI: true }}
                containerElement={containerElement}
            >
                <Marker
                    position={{ lat: latitude, lng: longitude }}
                    icon="/images/venuemarker.png"
                />
                {accomodations.map((accommodation) => {
                    let price = '';
                    if (accommodation.availability != null) {
                        price = breakfast
                            ? `${accommodation.availability.cheapestBreakfast.supplementPP}`
                            : `${accommodation.availability.cheapest.supplementPP}`;
                    }

                    return (
                        <Marker
                            position={{ lat: accommodation.latitude, lng: accommodation.longitude }}
                            icon={
                                selectedAccommodation != null &&
                                selectedAccommodation.id === accommodation.id
                                    ? '/images/hotelselected.png'
                                    : '/images/hotelmarker.png'
                            }
                            key={accommodation.id}
                            onClick={() => this.openMarker(accommodation)}
                        >
                            {openAcccommodation != null &&
                                openAcccommodation.id === accommodation.id && (
                                    <InfoWindow onCloseClick={this.closeMarker}>
                                        <div className={$.infoContent}>
                                            <p className={$.title}>{accommodation.name}</p>
                                            <p className={$.price}>
                                                +{' '}
                                                <span
                                                    className={
                                                        accommodation.recommended ? $.green : $.blue
                                                    }
                                                >
                                                    {formatMoney(price, { hideZeroDecimals: true })}
                                                </span>{' '}
                                                <small>p.p.</small>
                                            </p>
                                            <div className={$.clear} />
                                            <div className={$.stars}>
                                                <Stars stars={accommodation.stars} />
                                            </div>
                                            <div className={$.button}>
                                                {selectedAccommodation != null &&
                                                    selectedAccommodation.id ===
                                                        accommodation.id && (
                                                        <Button
                                                            white
                                                            text={t('hotel_map_selected')}
                                                            className={$.buttonSelect}
                                                            leftAligned
                                                        />
                                                    )}
                                                {selectedAccommodation != null &&
                                                    selectedAccommodation.id !==
                                                        accommodation.id && (
                                                        <Button
                                                            white
                                                            text={t('hotel_map_select')}
                                                            onClick={() =>
                                                                this.selectAccommodation(
                                                                    accommodation
                                                                )
                                                            }
                                                            className={$.buttonSelect}
                                                            leftAligned
                                                        />
                                                    )}
                                            </div>
                                        </div>
                                    </InfoWindow>
                                )}
                        </Marker>
                    );
                })}
                <Circle
                    defaultCenter={{ lat: cityLatitude, lng: cityLongitude }}
                    defaultRadius={2000}
                    options={{
                        fillColor: '#0CA2F5',
                        fillOpacity: 0.1,
                        strokeWeight: 2,
                        strokeColor: '#0CA2F5',
                        strokeOpacity: 0.5,
                    }}
                />
            </EventsTravelMap>
        );
    }
}

export default withTranslation()(Map);

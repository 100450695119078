import * as React from 'react';
import $ from './TeaserBanner.module.scss';

interface Props {
    title: string;
}

const TeaserBanner = (props: Props) => {
    const { title } = props;

    return <div className={$.teaserBanner}>{title}</div>;
};

export default TeaserBanner;

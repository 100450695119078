import { ImageEntity, Image } from './Image';
import { AvailabilityAccommodation } from './AvailabilityAccommodation';
import { AccommodationContentEntity, AccommodationContent } from './AccommodationContent';

/* eslint-disable lines-between-class-members */
export interface AccommodationEntity {
    id: string;
    name: string;
    type: string;
    chain_name: string;
    stars: number;
    recommended: boolean;
    images: ImageEntity[];
    preview_image: string;
    distance_to_city_center: number;
    distance_to_venue: number;
    latitude: number;
    longitude: number;
    content?: AccommodationContentEntity;
}

export class Accommodation {
    public readonly id: string;
    public readonly name: string;
    public readonly type: string;
    public readonly chainName: string;
    public readonly stars: number;
    public readonly image: string;
    public readonly images: Image[];
    public readonly distanceToCityCenter: string;
    public readonly distanceToVenue: string;
    public readonly distanceToCityCenterRaw: number;
    public readonly distanceToVenueRaw: number;
    public readonly latitude: number;
    public readonly longitude: number;
    public readonly recommended: boolean;
    public readonly content?: AccommodationContent;

    public availability?: AvailabilityAccommodation;

    public constructor(accommodation: AccommodationEntity) {
        this.id = accommodation.id;
        this.name = accommodation.name;
        this.type = accommodation.type;
        this.chainName = accommodation.chain_name;
        this.stars = accommodation.stars;
        this.image = accommodation.preview_image;
        this.latitude = accommodation.latitude;
        this.longitude = accommodation.longitude;
        this.recommended = accommodation.recommended;

        if (accommodation.images && accommodation.images.length) {
            this.images = accommodation.images.map((image) => new Image(image));
        }

        if (accommodation.content) {
            this.content = new AccommodationContent(accommodation.content);
        }

        if (accommodation.distance_to_city_center) {
            const distance = accommodation.distance_to_city_center;
            this.distanceToCityCenter =
                distance > 1000 ? `${Math.round(distance / 100) / 10} km` : `${distance} m`;
            this.distanceToCityCenterRaw = distance;
        }
        if (accommodation.distance_to_venue) {
            const distance = accommodation.distance_to_venue;
            this.distanceToVenue =
                distance > 1000 ? `${Math.round(distance / 100) / 10} km` : `${distance} m`;
            this.distanceToVenueRaw = distance;
        }
    }
}

import React from 'react';
import { makeStyles } from '@mui/styles';
import { OrderSummaryTable, OrderSummaryTableProps } from './OrderSummaryTable';
import { Theme } from '@mui/material';
import { Body } from '../Body/Body';

const useStyles = makeStyles((theme: Theme) => ({
    orderlineContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        [theme.breakpoints.up('md')]: {
            flexDirection: 'row',
            gap: '2rem',
        },
    },
    orderlineImage: {
        width: '100%',
    },
    titleContainer: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1),
        [theme.breakpoints.up('md')]: {
            marginTop: 0,
        },
    },
    success: {
        color: theme.palette.success.main,
    },
    imageContainer: {
        flexShrink: 0,
        maxWidth: 180,
        height: '100%',
        objectFit: 'contain',
    },
}));

interface OrderSummaryItemProps {
    imageUrl?: string | null;
    noImageUrl: string;
    noImageText?: string;
    alt?: string;
    title: string;
    tableItems: OrderSummaryTableProps['items'];
    microCopy?: string;
}

export const OrdersummaryItem = ({
    imageUrl,
    noImageUrl,
    noImageText,
    alt,
    title,
    tableItems,
    microCopy,
}: OrderSummaryItemProps) => {
    const classes = useStyles();

    const renderTitleSection = (text: React.ReactNode) => (
        <Body marginTop={false} marginBottom={false} small fontWeight="bold">
            {text}
        </Body>
    );

    const imageSrc = imageUrl ? imageUrl : noImageUrl;

    return (
        <div className={classes.orderlineContainer}>
            <div className={classes.imageContainer}>
                <img className={classes.orderlineImage} src={imageSrc} alt={alt} />

                {!imageUrl && noImageText && (
                    <Body small grey align="center">
                        {noImageText}
                    </Body>
                )}
            </div>
            <div>
                <div className={classes.titleContainer}>{renderTitleSection(title)}</div>
                <OrderSummaryTable items={tableItems} />
                {microCopy && (
                    <Body className={classes.success} marginBottom={false}>
                        {microCopy}
                    </Body>
                )}
            </div>
        </div>
    );
};

import { getAppLocaleFromTravelwareLang, isKnownLocale } from '../constants/appLocale';

export function useAppLocale() {
    return getAppLocale();
}

/** Always detect locale from the URL. With the current setup,
 * it's not reliable to store the locale in the memory.
 */
export function getAppLocale() {
    const url = new URL(window.location.href);
    const params = url.searchParams;
    const localeSearchParam = params.get('lang');
    const localeSegment = url.pathname.split('/')[1];

    /** Partner config contains "locale", but we cannot use it.
     * The main reason to have different partners is because a Partner can only have one currency.
     * Therefore, `.com`, `.de`, `.nl` and `.fr` are actually the same partner in TW, because they all use EURO.
     * But TW always returns the locale `en_EN` in the config response, which is apparently not usable.
     *
     * We need to detect the locale by the URL.
     * - /nl/....           (single-domain)
     * - ......&lang=nl_NL  (non single-domain)
     */
    const locale = isKnownLocale(localeSegment)
        ? localeSegment
        : getAppLocaleFromTravelwareLang(localeSearchParam);

    return locale;
}

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectReferrerUrl } from 'src/app/store/appSlice';
import { Page } from 'src/view/components';
import Button from 'src/view/components/Button/Button';
import $ from './NotFound.module.scss';

interface Props {
    title?: string;
    text?: string;
    buttonText?: string;
    onButtonClick?: () => void;
}

export const NotFound: (props: Props) => JSX.Element = (props: Props) => {
    const { t } = useTranslation();
    const partnerReferrerUrl = useSelector(selectReferrerUrl);

    const { title, text, buttonText, onButtonClick } = props;

    const onDefaultButtonClick = () => {
        if (!partnerReferrerUrl) return;

        window.location.href = partnerReferrerUrl.toString();
    };

    return (
        <Page step={0} mustHideTravelDetails>
            <section className={$.root} data-cy="event-not-found">
                <h1 className={$.title}>{title || t('pagenotfound_title')}</h1>
                <p className={$.text}>{text || t('pagenotfound_text')}</p>

                {!!onButtonClick && (
                    <div className={$.button}>
                        <Button
                            text={buttonText || t('pagenotfound_buttontext')}
                            onClick={onButtonClick || onDefaultButtonClick}
                        />
                    </div>
                )}
            </section>
        </Page>
    );
};

import React from 'react';

export const PaypalIcon = () => (
    <svg
        data-name="Group 1831"
        xmlns="http://www.w3.org/2000/svg"
        width="34.706"
        height="23.86"
        viewBox="0 0 34.706 23.86"
    >
        <rect
            data-name="Image 135"
            width="34.706"
            height="23.86"
            rx="2"
            style={{ fill: '#f4f4f3' }}
        />
        <path
            data-name="Path 5371"
            d="M38.259 6.749h-1.685a.234.234 0 0 0-.231.2l-.681 4.32a.14.14 0 0 0 .139.162h.8a.234.234 0 0 0 .231-.2l.184-1.165a.234.234 0 0 1 .231-.2h.533a1.73 1.73 0 0 0 1.917-1.6 1.3 1.3 0 0 0-.215-1.087 1.563 1.563 0 0 0-1.223-.43zm.194 1.578c-.092.6-.554.6-1 .6H37.2l.177-1.127a.14.14 0 0 1 .139-.118h.117c.3 0 .591 0 .739.173a.561.561 0 0 1 .081.472z"
            transform="translate(-24.936 3.069)"
            style={{ fill: '#253b80' }}
        />
        <path
            data-name="Path 5372"
            d="M54.979 12.843h-.807a.141.141 0 0 0-.139.118l-.033.226-.056-.082a1.148 1.148 0 0 0-.953-.338 1.855 1.855 0 0 0-1.8 1.622 1.523 1.523 0 0 0 .3 1.239 1.262 1.262 0 0 0 1.016.41 1.543 1.543 0 0 0 1.117-.462l-.036.224a.14.14 0 0 0 .138.163h.727a.234.234 0 0 0 .231-.2L55.118 13a.14.14 0 0 0-.139-.157zm-1.124 1.57a.9.9 0 0 1-.91.77.625.625 0 0 1-.668-.784.9.9 0 0 1 .9-.776.681.681 0 0 1 .538.22.7.7 0 0 1 .14.57z"
            transform="translate(-36.621 -1.466)"
            style={{ fill: '#253b80' }}
        />
        <path
            data-name="Path 5373"
            d="M72.6 13.075h-.811a.235.235 0 0 0-.194.1l-1.118 1.647-.477-1.58a.235.235 0 0 0-.225-.167h-.8a.14.14 0 0 0-.133.186l.893 2.62-.835 1.185a.14.14 0 0 0 .115.222h.81a.234.234 0 0 0 .192-.1l2.7-3.892a.14.14 0 0 0-.117-.221z"
            transform="translate(-49.941 -1.699)"
            style={{ fill: '#253b80' }}
        />
        <path
            data-name="Path 5374"
            d="M87.04 6.749h-1.685a.234.234 0 0 0-.231.2l-.681 4.32a.14.14 0 0 0 .138.162h.865a.164.164 0 0 0 .162-.138l.193-1.224a.234.234 0 0 1 .231-.2h.533a1.73 1.73 0 0 0 1.918-1.6 1.3 1.3 0 0 0-.215-1.087 1.561 1.561 0 0 0-1.228-.433zm.194 1.578c-.092.6-.554.6-1 .6h-.254l.178-1.127a.14.14 0 0 1 .138-.118h.117c.3 0 .591 0 .739.173a.561.561 0 0 1 .082.472z"
            transform="translate(-61.701 3.069)"
            style={{ fill: '#179bd7' }}
        />
        <path
            data-name="Path 5375"
            d="M103.763 12.843h-.806a.14.14 0 0 0-.138.118l-.036.226-.057-.082a1.148 1.148 0 0 0-.952-.338 1.855 1.855 0 0 0-1.8 1.622 1.523 1.523 0 0 0 .3 1.239 1.263 1.263 0 0 0 1.016.41 1.543 1.543 0 0 0 1.117-.462l-.036.224a.14.14 0 0 0 .139.163h.726a.234.234 0 0 0 .231-.2L103.9 13a.141.141 0 0 0-.137-.157zm-1.124 1.57a.9.9 0 0 1-.91.77.625.625 0 0 1-.668-.784.9.9 0 0 1 .9-.776.68.68 0 0 1 .538.22.7.7 0 0 1 .139.57z"
            transform="translate(-73.389 -1.466)"
            style={{ fill: '#179bd7' }}
        />
        <path
            data-name="Path 5376"
            d="m117.174 6.867-.691 4.4a.14.14 0 0 0 .138.162h.7a.234.234 0 0 0 .231-.2l.682-4.319a.14.14 0 0 0-.138-.162h-.778a.141.141 0 0 0-.144.119z"
            transform="translate(-85.849 3.07)"
            style={{ fill: '#179bd7' }}
        />
        <path
            data-name="Path 5377"
            d="m2.625 8.112.132-.837-.294-.007h-1.4l.974-6.177a.082.082 0 0 1 .027-.048.08.08 0 0 1 .052-.019h2.362a2.1 2.1 0 0 1 1.609.486 1.107 1.107 0 0 1 .258.483 1.744 1.744 0 0 1 0 .666v.189l.133.075a.93.93 0 0 1 .268.2.953.953 0 0 1 .218.488 2.066 2.066 0 0 1-.031.709 2.5 2.5 0 0 1-.29.8 1.65 1.65 0 0 1-.46.5 1.866 1.866 0 0 1-.619.279A3.089 3.089 0 0 1 4.787 6H4.6a.553.553 0 0 0-.547.467l-.014.075-.229 1.476-.01.054a.047.047 0 0 1-.015.031.039.039 0 0 1-.024.009z"
            transform="translate(1.53 7.362)"
            style={{ fill: '#253b80' }}
        />
        <path
            data-name="Path 5378"
            d="m10.437 7.667-.024.139a2.478 2.478 0 0 1-2.74 2.153h-.694a.337.337 0 0 0-.333.285L6.291 12.5l-.1.638a.177.177 0 0 0 .175.205H7.6a.3.3 0 0 0 .292-.249l.012-.062.232-1.47.015-.081a.3.3 0 0 1 .292-.25h.184a2.168 2.168 0 0 0 2.4-1.884 1.6 1.6 0 0 0-.246-1.417 1.176 1.176 0 0 0-.344-.263z"
            transform="translate(-2.305 2.393)"
            style={{ fill: '#179bd7' }}
        />
        <path
            data-name="Path 5379"
            d="M11.47 6.819q-.071-.021-.147-.038t-.156-.029a3.852 3.852 0 0 0-.611-.045H8.7a.3.3 0 0 0-.292.25l-.394 2.5-.011.073a.337.337 0 0 1 .333-.285h.693a2.478 2.478 0 0 0 2.74-2.153l.024-.139a1.662 1.662 0 0 0-.256-.108z"
            transform="translate(-3.664 3.111)"
            style={{ fill: '#222d65' }}
        />
        <path
            data-name="Path 5380"
            d="M2.423 1.94a.3.3 0 0 1 .292-.25h1.852a3.852 3.852 0 0 1 .611.045q.08.013.156.029t.147.038l.07.022a1.687 1.687 0 0 1 .256.108A1.511 1.511 0 0 0 5.488.573 2.348 2.348 0 0 0 3.685 0H1.321a.338.338 0 0 0-.334.286L0 6.526a.2.2 0 0 0 .2.235h1.462l.366-2.325z"
            transform="translate(2.324 8.128)"
            style={{ fill: '#253b80' }}
        />
    </svg>
);

import React from 'react';

export const ChevronDownIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="13.2" height="7.9" viewBox="0 0 15.191 8.947">
        <g data-name="Arrow Dark Blue">
            <path
                data-name="Union 60"
                d="M1.393 8.994V9L0 7.594 7.556 0l1.386 1.4-6.159 6.2 6.164 6.193-1.392 1.4z"
                transform="rotate(90 7.595 7.596)"
            />
        </g>
    </svg>
);

import { DiscountStatusEnum } from 'src/app/store/orderSlice';
import { Notification, NOTIFICATION_STATUS } from 'src/app/core/lib/notification/notification';
import { Discount } from 'src/data/models/Discount';
import moment from 'moment';
import { TFunction } from 'i18next';

const formatPendingVoucherReservationMessage = (expiry?: string, t) => {
    if (!expiry) return t('ticket_discount_code_reserved');

    return t('ticket_discount_code_reserved_with_expiry', { time: moment(expiry).fromNow(true) });
};

export const mapAddDiscountStatusToNotification = (
    status: DiscountStatusEnum,
    t,
    expiry?: string
): Notification | undefined => {
    switch (status) {
        case DiscountStatusEnum.CODE_IS_REQUIRED:
            return {
                type: NOTIFICATION_STATUS.ERROR,
                message: t('ticket_discount_code_required'),
            };
        case DiscountStatusEnum.CODE_ALREADY_ADDED:
            return {
                type: NOTIFICATION_STATUS.ERROR,
                message: t('ticket_discount_code_already_added'),
            };
        case DiscountStatusEnum.CODE_COULD_NOT_ADD:
            return {
                type: NOTIFICATION_STATUS.ERROR,
                message: t('ticket_discount_code_could_not_add'),
            };
        case DiscountStatusEnum.CODE_NOT_VALID:
            return {
                type: NOTIFICATION_STATUS.ERROR,
                message: t('ticket_discount_code_not_valid'),
            };
        case DiscountStatusEnum.VOUCHER_HAS_PENDING_RESERVATION:
            return {
                type: NOTIFICATION_STATUS.ERROR,
                message: formatPendingVoucherReservationMessage(expiry, t),
            };
        case DiscountStatusEnum.OK:
            return {
                type: NOTIFICATION_STATUS.SUCCESS,
                message: t('ticket_discount_code_ok'),
            };
        default:
            return;
    }
};

export const mapRemoveDiscountStatusToNotification = (
    status: DiscountStatusEnum,
    t: TFunction
): Notification | undefined => {
    switch (status) {
        case DiscountStatusEnum.CODE_COULD_NOT_REMOVE:
            return {
                type: NOTIFICATION_STATUS.ERROR,
                message: t('ticket_discount_code_could_not_remove'),
            };
        default:
            return;
    }
};

export const getTotalCouponAmount = (coupons: Discount[]) => {
    let total = 0;

    coupons.forEach((d) => {
        total += parseInt(d.value);
    });

    return total;
};

export const getTotalVoucherValue = (totalPrice: number, d: Discount[], v: Discount[]) => {
    const leftForVoucher = totalPrice - getTotalCouponAmount(d);

    if (getTotalCouponAmount(v) > leftForVoucher) {
        return leftForVoucher;
    }

    return getTotalCouponAmount(v);
};

export const reduceWithDiscount = (totalPrice: number, d: Discount[], v: Discount[]): number => {
    let discountValue = 0;

    d.forEach((discount) => {
        if (discount.value) discountValue += parseInt(discount.value);
    });

    v.forEach((voucher) => {
        if (voucher.value) discountValue += parseInt(voucher.value);
    });

    if (discountValue > totalPrice) {
        discountValue = totalPrice;
    }

    return discountValue;
};

import classnames from 'classnames';
import * as React from 'react';
import { colors } from 'src/app/constants/theme';
import { InfoIcon } from 'src/app/icons/InfoIcon';
import { Body } from 'src/view/components/Body/Body';
import { CheckIconTriangle } from 'src/view/components/CheckedIconTriangle/CheckIconTriangle';
import { Heading } from 'src/view/components/Heading/Heading';
import { Popover, Price } from '..';
import $ from './FormCheckbox.module.scss';

interface Props {
    title: string;
    subTitle?: string;
    subTitlePopover?: string;
    name: string;
    id: string;
    onChange?: React.EventHandler<React.ChangeEvent<HTMLInputElement>>;
    checked: boolean;
    value?: string;
    price?: number | string;
    priceLabel?: string;
    greenPriceText?: boolean;
    white?: boolean;
    icon?: string;
    fullWidth?: boolean;
    isPayment?: boolean;
    secondary?: boolean;
    disabled?: boolean;
    spacingBottom?: boolean;
    boldTitle?: boolean;
}

const FormCheckbox = (props: Props) => {
    const {
        icon,
        title,
        id,
        name,
        subTitle,
        subTitlePopover,
        onChange,
        checked,
        value,
        secondary,
        price,
        priceLabel,
        greenPriceText,
        white,
        fullWidth,
        isPayment,
        disabled,
        spacingBottom,
        boldTitle = true,
    } = props;

    const formRowStyles = classnames(
        $.formrow,
        fullWidth && $.formrowFullWidth,
        isPayment && $.formrowMarginTop,
        secondary && $.secondary,
        spacingBottom && $.spacingBottom
    );

    const labelStyles = classnames(
        disabled && $.disabledOption,
        $.checklabel,
        subTitle && $.subtitlelabel,
        white && $.white,
        isPayment && $.fullHeight
    );

    const priceLabelStyles = classnames(
        $.priceLabel,
        greenPriceText && $.greenPriceText,
        !greenPriceText && $.smallPriceText
    );

    return (
        <div className={formRowStyles}>
            {checked && <CheckIconTriangle className={$.checkedIconContainer} />}
            <input
                className={$.checkbox}
                type="radio"
                name={name}
                id={id}
                value={value}
                onChange={onChange}
                checked={checked}
                disabled={disabled}
            />
            <label className={labelStyles} htmlFor={id}>
                <Heading
                    variant="h4"
                    className={classnames(
                        $.labelWrapper,
                        !boldTitle && $.labelNormal,
                        disabled && $.disabledLabel
                    )}
                    marginTop={false}
                    marginBottom={false}
                    uppercase={false}
                    bold={checked}
                >
                    {icon}
                    {title}
                </Heading>

                {price !== undefined && (
                    <Price bold price={price} alternateColor={price === 0 || price === '0'} />
                )}
                {subTitle && (
                    <Body
                        small
                        grey
                        marginBottom={false}
                        marginTop={false}
                        className={classnames($.subTitle, disabled && $.disabledLabel)}
                    >
                        {subTitle}
                        {subTitlePopover && (
                            <Popover
                                trigger={<InfoIcon stroke={colors.grey} />}
                                desktopContent={
                                    <Body marginTop={false} marginBottom={false}>
                                        {subTitlePopover}
                                    </Body>
                                }
                                buttonClassName={$.subtitlePopoverButton}
                            />
                        )}
                    </Body>
                )}
                {priceLabel && <small className={priceLabelStyles}>{priceLabel}</small>}
            </label>
        </div>
    );
};

export default FormCheckbox;

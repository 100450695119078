import * as React from 'react';
import moment from 'moment-timezone';
import classnames from 'classnames';
import { withTranslation, WithTranslation } from 'react-i18next';
import $ from './ProgressCard.module.scss';
import { EventDetail } from 'src/data/models/EventDetail';
import { Accommodation } from 'src/data/models/Accommodation';
import { Availability } from 'src/data/models/Availability';
import * as Cache from 'src/data/services/cache';
import OrderDetailList from '../TravelDetailList';
import Card from 'src/view/components/Card';
import { PackageType } from 'src/data/models/Order';
import { Ticket } from 'src/data/models/Ticket';
import { PaymentGateway } from 'src/data/services/payments';
import { RemoveDiscountStatusType } from 'src/app/store/orderSlice';
import { Heading } from 'src/view/components/Heading/Heading';
import { Body } from 'src/view/components/Body/Body';
import TravelDetailTable from 'src/view/components/TravelDetailTable/TravelDetailTable';
import { Occupancy } from 'src/data/services/cache';

interface Props extends WithTranslation {
    gateways: PaymentGateway[];
    eventDetail?: EventDetail;
    orderDetailOpen: boolean;
    loading: boolean;
    toggleDetail: () => void;
    openHotelDetail: (accommodation: Accommodation) => void;
    availability: Availability | null;
    showBooking: boolean;
    step: number;
    packageType: PackageType;
    leadTimePercentage?: number;
    leadTimeDays?: number;
    orderSelectedTicket: Ticket | null;
    orderSelectedAccommodation: Accommodation | null;
    discountRemoveStatus: RemoveDiscountStatusType;
    totalOrderPrice: number;
    occupancy: Occupancy;
    showPricing?: boolean;
}

class ProgressCard extends React.Component<Props> {
    public constructor(props) {
        super(props);
    }

    public render() {
        const {
            t,
            gateways,
            eventDetail,
            openHotelDetail,
            step,
            packageType,
            leadTimePercentage,
            leadTimeDays,
            availability,
            orderSelectedTicket,
            orderSelectedAccommodation,
            discountRemoveStatus,
            totalOrderPrice,
            occupancy,
            showPricing,
        } = this.props;

        if (!eventDetail) {
            return <div />;
        }

        return (
            <Card>
                <section className={$.progressCard} data-testid="progressCard">
                    {(!availability || !showPricing) && (
                        <Body className={$.noAvailabilityOverlay}>
                            {t('progresscard_no_price')}
                        </Body>
                    )}

                    <div
                        className={classnames([
                            $.content,
                            (!availability || !showPricing) && $.noAvailabilityBlur,
                        ])}
                    >
                        <Heading variant="h3" marginTop={false}>
                            {t('progresscard_your_ticket')}
                        </Heading>

                        <div className={$.detailWrap}>
                            {step >= 1 && eventDetail && (
                                <div className={$.booking}>
                                    <TravelDetailTable
                                        eventDetail={eventDetail}
                                        orderSelectedTicket={orderSelectedTicket}
                                        packageType={packageType}
                                        orderSelectedAccommodation={orderSelectedAccommodation}
                                        onHotelDetailClick={openHotelDetail}
                                    />
                                </div>
                            )}
                        </div>
                        <OrderDetailList
                            {...this.props}
                            gateways={gateways}
                            availability={availability}
                            eventDetail={eventDetail}
                            extraIndent
                            packageType={packageType}
                            leadTimePercentage={leadTimePercentage}
                            leadTimeDays={leadTimeDays}
                            orderSelectedTicket={orderSelectedTicket}
                            discountRemoveStatus={discountRemoveStatus}
                            totalOrderPrice={totalOrderPrice}
                        />
                    </div>
                </section>
            </Card>
        );
    }
}

export default withTranslation()(ProgressCard);

import React from 'react';

export const UspIcon = () => (
    <svg
        data-name="UspIcon"
        xmlns="http://www.w3.org/2000/svg"
        width="14"
        height="14"
        viewBox="0 0 14 14"
    >
        <path
            data-name="Path 3918"
            d="M7 14a7 7 0 0 0 6.723-8.952.583.583 0 1 0-1.12.328 5.826 5.826 0 1 1-2.351-3.221.583.583 0 0 0 .652-.967A7 7 0 1 0 7 14z"
        />
        <path
            data-name="Path 3919"
            d="m121.466 32.184-6.366 6.369-2.038-2.037a.619.619 0 1 0-.875.875l2.475 2.475a.619.619 0 0 0 .875 0l6.807-6.807a.619.619 0 0 0-.875-.875z"
            transform="translate(-108.522 -30.765)"
        />
    </svg>
);

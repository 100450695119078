import * as Sentry from '@sentry/react';
import { TravelwareApiConfig } from 'src/app/core/entities/TravelwareApiConfig';
import { getEnvironment } from 'src/app/utils/config';
import { environment } from 'src/app/utils/utils';
import { Config } from 'src/data/models/Config';
import { EventDetail } from 'src/data/models/EventDetail';
import { Order } from 'src/data/models/Order';

export function init(): void {
    const sentryDsn = getEnvironment('REACT_APP_SENTRY_DSN');
    if (sentryDsn !== '$REACT_APP_SENTRY_DSN') {
        Sentry.init({
            dsn: sentryDsn,
            environment: environment(),
        });
    }
}

export function setTravelwareApiConfigScope(config: TravelwareApiConfig) {
    Sentry.configureScope((scope) => {
        scope.setTag('travelwareBaseUrl', config.baseUrl);
        scope.setTag('travelwareApiKey', config.key || '');
    });
}

export function setConfigScope(config: Config) {
    Sentry.configureScope((scope) => {
        scope.setTag('partner.currency', config.currency);
        scope.setTag('partner.currency', config.currency);
        scope.setTag('partner.locale', config.locale);
        scope.setTag('partner.websiteUrl', config.websiteUrl || '');
        scope.setTag('partner.gateways', config.payment.gateways.join(','));
        scope.setTag('partner.googleTagManagerId', config.gtmId || '');
    });
}

export function setActiveEventScope(event: EventDetail) {
    Sentry.configureScope((scope) => {
        scope.setTag('event.id', event.id);
        scope.setTag('event.name', event.name);
        scope.setTag(
            'event.hasPackages.ticketOnly',
            event.basePackage?.price?.TICKET_ONLY ? '1' : '0'
        );
        scope.setTag(
            'event.hasPackages.ticketHotel',
            event.basePackage?.price?.TICKET_HOTEL ? '1' : '0'
        );
    });
}

export function setActiveOrderScope(order: Order) {
    Sentry.configureScope((scope) => {
        scope.setTag('order.id', order.id);
        scope.setTag('order.packageType', order.packageType);
        scope.setTag(
            'order.downPaymentPercentage',
            order.downPaymentPercentage?.toString() || '(not set)'
        );
        scope.setTag(
            'order.downPaymentLeadTimeDays',
            order.downPaymentLeadTimeDays?.toString() || '(not set)'
        );
    });
}

export const breakpoints = {
    small: 480,
    medium: 700,
    large: 1001,
    xLarge: 1280,
};

export const MuiBreakpoints = {
    medium: 770,
};

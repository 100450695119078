import makeStyles from '@mui/styles/makeStyles';
import { colors, fontSizes, layout } from 'src/app/constants/theme';

interface FieldErrorMessageProps {
    message?: string | JSX.Element;
    dataCy?: string;
}

const useStyles = makeStyles((t) => ({
    message: {
        background: colors.lightFlamingo,
        color: colors.red,
        borderRadius: layout.borderRadiusBase,
        padding: '0.375rem 0.625rem',
        marginTop: '0.625rem',
        fontSize: fontSizes.sm1,
    },
}));

const FieldErrorMessage = ({ message, dataCy = 'default' }: FieldErrorMessageProps) => {
    const classes = useStyles();

    if (!message) return null;

    return (
        <div className={classes.message} data-cy={`error-field-${dataCy}`}>
            {message}
        </div>
    );
};

export default FieldErrorMessage;

/* eslint-disable lines-between-class-members */
export interface BookResultEntity {
    order_id: string;
    order_number: string;
    payment_base_url: string;
}

export class BookResult {
    public readonly orderId: string;
    public readonly orderNumber: string;
    public readonly paymentBaseUrl: string;

    public constructor(bookResult: BookResultEntity) {
        this.orderId = bookResult.order_id;
        this.orderNumber = bookResult.order_number;
        this.paymentBaseUrl = bookResult.payment_base_url;
    }
}

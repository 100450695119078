import {
    AvailabilityBaseAccommodationRateEntity,
    AvailabilityBaseAccommodationRate,
} from './AvailabilityBaseAccommodationRate';
import { BasePackagePricesEntity, BasePackageEntity } from './BaseAccommodation';

/* eslint-disable lines-between-class-members */

export interface AvailabilityBasePackageEntity {
    ticket_id: string;
    accommodation_id?: string;
    prices_pp: BasePackagePricesEntity;
    ticket_category_id: string;
}

export class AvailabilityBasePackage {
    public readonly ticketId: string;
    public readonly accommodationId: string;
    public readonly price: BasePackagePricesEntity;
    public readonly categoryId: string;

    public constructor(availabilityBasePackage: AvailabilityBasePackageEntity) {
        this.ticketId = availabilityBasePackage.ticket_id;
        this.price = availabilityBasePackage.prices_pp;
        this.categoryId = availabilityBasePackage.ticket_category_id;

        if (availabilityBasePackage.accommodation_id != null) {
            this.accommodationId = availabilityBasePackage.accommodation_id;
        }
    }
}

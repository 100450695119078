/* eslint-disable lines-between-class-members */
export interface TeamEntity {
    abbreviation: string | null;
    color: {
        r: number;
        g: number;
        b: number;
    };
    country_code: string;
    id: string;
    name: string;
    shortname: string;
}

export class Team {
    public readonly abbreviation: string | null;
    public readonly countryCode: string;
    public readonly id: string;
    public readonly name: string;
    public readonly shortName: string;

    public constructor(team: TeamEntity) {
        this.abbreviation = team.abbreviation;
        this.id = team.id;
        this.name = team.name;
        this.shortName = team.shortname;
        this.countryCode = team.country_code;
    }
}

import {
    FormControl,
    ListSubheader,
    MenuItem,
    Select,
    SelectChangeEvent,
    Typography,
} from '@mui/material';
import classnames from 'classnames';
import * as React from 'react';
import { useState } from 'react';
import { colors } from 'src/app/constants/theme';
import { CheckIcon } from 'src/images/icons/CheckIcon';
import $ from './FormSelect.module.scss';

export interface SelectOption {
    label: string;
    value: string | number;
}

export interface OptionsListItem {
    groupName?: string;
    options: SelectOption[];
}

interface Props {
    options: OptionsListItem[];
    wide?: boolean;
    onChange?: (value: string | number, id?: string) => void;
    onBlur?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
    value?: string | number; // Ensure value is properly passed
    id?: string;
    hasError?: boolean;
    placeholder?: string;
}

const FormSelect: React.FC<Props> = ({
    options,
    wide,
    onChange,
    onBlur,
    value,
    id,
    hasError,
    placeholder,
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const selectStyles = classnames([
        $.select,
        wide && $.wide,
        hasError && $.invalid,
        isOpen && $.open,
    ]);

    // Flatten the options for autocomplete
    const flattenOptions = () =>
        options.flatMap((optionGroup) =>
            optionGroup.options.map((option) => ({
                label: option.label,
                value: option.value,
                group: optionGroup.groupName,
            }))
        );

    // Find the default value based on the existing options
    const defaultOption = flattenOptions().find((opt) => opt.value === value);

    const handleChange = (event: SelectChangeEvent<string | number>) => {
        const selectedValue = event.target.value;

        if (typeof selectedValue === 'string' && selectedValue.includes('+')) {
            onChange?.(selectedValue, id);
            return;
        }

        const parsedValue = parseInt(selectedValue.toString());

        if (isNaN(parsedValue)) {
            onChange?.(selectedValue, id);
            return;
        }

        onChange?.(parsedValue, id);
    };

    const handleOpen = () => {
        setIsOpen(true);
        setTimeout(() => {
            const menuItem = document.querySelector(
                `li[aria-selected="true"][data-form-select="option-group-item"`
            );
            menuItem?.scrollIntoView({ block: 'center' });
        }, 100); // Delay can be adjusted if necessary
    };

    const renderValue = (selected: string | number) => {
        const selectedOption = flattenOptions().find((opt) => opt.value === selected);
        return value != null ? selectedOption?.label : placeholder; // Display label of selected option or placeholder
    };

    return (
        <FormControl className={selectStyles} error={hasError}>
            <Select
                id={id}
                value={value ?? ''} // Ensure it falls back to an empty string
                onChange={handleChange}
                onBlur={onBlur}
                onOpen={handleOpen}
                onClose={() => setIsOpen(false)}
                renderValue={renderValue} // Render the selected value without the checkmark
                sx={{
                    height: '100%',
                }}
            >
                {options.map((optionGroup) => [
                    <ListSubheader key={optionGroup.groupName} disableSticky>
                        {optionGroup.groupName}
                    </ListSubheader>,
                    ...optionGroup.options.map((option) => {
                        const isSelected = defaultOption?.value === option.value; // Check if this option is selected

                        return (
                            <MenuItem
                                key={option.value + (optionGroup.groupName || '')}
                                value={option.value}
                                sx={{
                                    backgroundColor: isSelected
                                        ? `${colors.backgroundPrimary} !important`
                                        : 'transparent',
                                }}
                                data-form-select="option-group-item"
                                data-value={option.value} // Unique data attribute for finding duplicates
                            >
                                <CheckIcon color={isSelected ? 'inherit' : 'transparent'} />
                                <Typography variant="body1" marginLeft={1}>
                                    {option.label}
                                </Typography>
                            </MenuItem>
                        );
                    }),
                ])}
            </Select>
        </FormControl>
    );
};

export default FormSelect;

import React from 'react';
import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Body } from '../Body/Body';

const useStyles = makeStyles((theme: Theme) => ({
    columnValue: {
        paddingLeft: theme.spacing(1),
    },
}));

export interface OrderSummaryTableProps {
    items: {
        title: React.ReactNode;
        value: React.ReactNode;
    }[];
}

export const OrderSummaryTable = ({ items }: OrderSummaryTableProps) => {
    const classes = useStyles();

    const renderTableColumnTitle = (text: React.ReactNode) => (
        <td>
            <Body small grey marginTop={false} marginBottom={false}>
                <span>{text}:</span>
            </Body>
        </td>
    );

    const renderTableColumnValue = (text: React.ReactNode) => (
        <td className={classes.columnValue}>
            <Body marginBottom={false} marginTop={false} small>
                {text}
            </Body>
        </td>
    );

    return (
        <table>
            <tbody>
                {items.map((item) => (
                    <tr key={item.title?.toString()}>
                        {renderTableColumnTitle(item.title)}
                        {renderTableColumnValue(item.value)}
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

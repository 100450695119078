import { breakpoints } from 'src/app/constants/breakpoints';
import { colors } from 'src/app/constants/theme';

export const themeObject = {
    palette: {
        success: {
            main:
                getComputedStyle(document.documentElement).getPropertyValue(
                    '--tco-btn-primary-background'
                ) || colors.blue,
        },
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: breakpoints.small,
            md: breakpoints.medium,
            lg: breakpoints.large,
            xl: breakpoints.xLarge,
        },
    },
    components: {
        MuiDivider: {
            styleOverrides: {
                root: {
                    marginTop: '2rem',
                    marginBottom: '2rem',
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    borderRadius: '4px',
                    background: 'white',
                    fontFamily: 'inherit',
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        border: `1px solid #76809a`,
                    },
                },
                notchedOutline: {
                    borderColor: `#76809a`,
                    borderWidth: `1px`,
                },
                input: {
                    height: 16,
                    borderRadius: '4px',
                },
            },
        },
    },
};

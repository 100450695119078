import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import moment, { Moment } from 'moment';
import { appConstants } from 'src/app/constants/app';
import { reduceWithDiscount } from 'src/app/core/lib/discount/discount';
import { selectEventAccommodations } from 'src/app/store/eventSlice';
import { AppThunk, RootState, TypedDispatch } from 'src/app/store/index';
import { hasMarketingCookieConsent, hasStatisticsCookieConsent } from 'src/app/utils/consent';
import { getGADataLayerProperty } from 'src/app/utils/googleAnalytics';
import {
    getAffiliateId,
    getAffiliateMisc,
    getFacebookClickId,
    getFacebookClientId,
    getGoogleClickId,
    getMicrosoftClickId,
} from 'src/app/utils/tradeTracker';
import { Accommodation } from 'src/data/models/Accommodation';
import { Availability, AvailabilityEntity } from 'src/data/models/Availability';
import { AvailabilityAccommodation } from 'src/data/models/AvailabilityAccommodation';
import { AvailabilityBasePackage } from 'src/data/models/AvailabilityBaseAccommodation';
import { COUPON_TYPE, Discount } from 'src/data/models/Discount';
import { Order, OrderEntity, PackageType } from 'src/data/models/Order';
import { Ticket } from 'src/data/models/Ticket';
import * as Cache from 'src/data/services/cache';
import { getPrice } from 'src/data/services/calculations';
import {
    addDiscountOrVoucher,
    deleteDiscountOrVoucher,
    getAvailability,
    OrderBaseDetails,
    OrderMetadata,
    OrderMetadataKey,
    putMetadata,
    setOrderAccommodation,
    setOrderTickets,
    startOrder,
    updateOrderPreferences as updateOrderPreferencesService,
} from 'src/data/services/order';

import { getAppLocale } from '../hooks/use-locale';
import { localeToLanguageAndCountry } from '../utils/utils';

// region Enumerations and state definition
export enum OrderStatusEnum {
    EMPTY = 'EMPTY',
    FETCH_CREATE = 'FETCH_CREATE',
    FETCH_PREFERENCES_UPDATE = 'FETCH_PREFERENCES_UPDATE',
    FETCH_UPDATE = 'FETCH_UPDATE',
    OK = 'OK',
    ERROR = 'ERROR',
}

export enum AvailabilityStatusEnum {
    EMPTY = 'EMPTY',
    FETCH = 'FETCH',
    OK = 'OK',
    ERROR = 'ERROR',
}

export enum DiscountStatusEnum {
    EMPTY = 'EMPTY',
    FETCH = 'FETCH',
    OK = 'OK',
    CODE_IS_REQUIRED = 'CODE_IS_REQUIRED',
    CODE_ALREADY_ADDED = 'CODE_ALREADY_ADDED',
    CODE_COULD_NOT_ADD = 'CODE_COULD_NOT_ADD',
    CODE_NOT_VALID = 'CODE_NOT_VALID',
    CODE_COULD_NOT_REMOVE = 'CODE_COULD_NOT_REMOVE',
    VOUCHER_HAS_PENDING_RESERVATION = 'VOUCHER_HAS_PENDING_RESERVATION',
}

export enum OrderErrorEnum {
    NONE = 'NONE',
    CREATE_ERROR_UNKNOWN = 'CREATE_ERROR_UNKNOWN',
    UPDATE_ERROR_UNKNOWN = 'UPDATE_ERROR_UNKNOWN',
    UPDATE_ERROR_NOT_FOUND = 'UPDATE_ERROR_NOT_FOUND',
    UPDATE_ERROR_VALIDATION = 'UPDATE_ERROR_VALIDATION',
}

export enum AvailabilityErrorEnum {
    NONE = 'NONE',
    NOT_AVAILABLE_GENERIC = 'NOT_AVAILABLE_GENERIC',
    NOT_AVAILABLE_TICKET = 'NOT_AVAILABLE_TICKET',
    NOT_AVAILABLE_HOTEL = 'NOT_AVAILABLE_HOTEL',
    ORDER_NOT_FOUND = 'ORDER_NOT_FOUND',
    SERVER_ERROR = 'SERVER_ERROR',
    UNKNOWN = 'UNKNOWN',
    ERROR_ORDER_AVAILABILITY_EXPIRED = 'ERROR_ORDER_AVAILABILITY_EXPIRED',
    ERROR_TICKET_NOT_AVAILABLE = 'ERROR_TICKET_NOT_AVAILABLE',
}

export type SelectedAccommodation = {
    id: string;
    breakfast: boolean;
};

export type OrderSelections = {
    ticket: string | null;
    accommodation: SelectedAccommodation | null;
};

export type RemoveDiscountStatusType = {
    [key: string]: DiscountStatusEnum;
};

export type DiscountStatusType = {
    status: DiscountStatusEnum;
    expiry?: string;
};

export type OrderState = {
    lastFetch: Date | null;
    status: OrderStatusEnum;
    error: OrderErrorEnum;
    validationErrors: {
        update: string[];
    };
    orderPreferences: OrderBaseDetails | null;
    orderEntity: OrderEntity | null;
    availabilityEntity: AvailabilityEntity | null;
    availabilityError: AvailabilityErrorEnum;
    availabilityStatus: AvailabilityStatusEnum;
    availabilityExpired: boolean;
    selections: OrderSelections;
    showDiscountInput: boolean;
    discounts: Discount[];
    vouchers: Discount[];
    discountStatus: DiscountStatusType;
    discountRemoveStatus?: RemoveDiscountStatusType;
};

export type OrderSummary = {
    id: string;
    packageType: PackageType;
    dateStart: Moment;
    dateEnd: Moment;
    numPax: number;
    numAdults: number;
    numRooms: number;
    currency: string;
    ticketId?: string;
    ticketCategoryId?: string;
    ticketName?: string;
    accommodationId?: string;
    accommodationBreakfast?: boolean;
    accommodationName?: string;
    accommodationChainName?: string;
    // Price of current selection in UI (not of actual order entity from server).
    // Use this over normal price when sync with UI is more important than
    // what the server actually already has.
    selectedPricePerPax: string;
    selectedPriceTotal: string;
    // Price of actual order entity from server. Can be out of sync with what's
    // selected in the UI for a short time while waiting for server response.
    pricePerPax: string;
    priceTotal: string;
    discountedPriceTotal: number;
};

const initialState: OrderState = {
    lastFetch: null,
    status: OrderStatusEnum.EMPTY,
    error: OrderErrorEnum.NONE,
    validationErrors: {
        update: [],
    },
    orderPreferences: null,
    orderEntity: null,
    availabilityEntity: null,
    availabilityError: AvailabilityErrorEnum.NONE,
    availabilityStatus: AvailabilityStatusEnum.EMPTY,
    availabilityExpired: false,
    selections: {
        ticket: null,
        accommodation: null,
    },
    showDiscountInput: false,
    discounts: [],
    vouchers: [],
    discountStatus: { status: DiscountStatusEnum.EMPTY },
    discountRemoveStatus: undefined,
};
// endregion

// region Utilities
const mergeOrderEntityWithSecret = (entity: OrderEntity, secret: string | undefined) =>
    Object.assign(entity, { secret });

const getOrderOrFail = (state: RootState): Order => {
    const order = selectOrder(state);

    if (!order) {
        throw new Error('Order is required');
    }

    return order;
};
// endregion

// region Slice
export const orderSlice = createSlice({
    name: 'order',
    initialState,
    reducers: {
        startCreating: (state: OrderState) => {
            Object.assign(state, initialState, { status: OrderStatusEnum.FETCH_CREATE });
        },
        receiveCreateOrder: (
            state: OrderState,
            action: PayloadAction<{ order: OrderEntity; preferences: OrderBaseDetails }>
        ) => {
            Object.assign(state, initialState, {
                lastFetch: new Date(),
                orderEntity: action.payload.order,
                orderPreferences: action.payload.preferences,
                status: OrderStatusEnum.OK,
                error: OrderErrorEnum.NONE,
            });
        },
        receiveCreateFailed: (state: OrderState, action: PayloadAction<OrderErrorEnum>) => {
            Object.assign(state, initialState, {
                status: OrderStatusEnum.ERROR,
                error: action.payload,
            });
        },
        startPreferencesUpdate: (state: OrderState) => {
            state.status = OrderStatusEnum.FETCH_PREFERENCES_UPDATE;
            state.availabilityEntity = initialState.availabilityEntity;
            state.availabilityStatus = initialState.availabilityStatus;
            state.availabilityError = initialState.availabilityError;
            state.availabilityExpired = false;
        },
        receivePreferencesUpdate: (
            state: OrderState,
            action: PayloadAction<{ order: OrderEntity; preferences: OrderBaseDetails }>
        ) => {
            Object.assign(state, initialState, {
                lastFetch: new Date(),
                orderEntity: mergeOrderEntityWithSecret(
                    action.payload.order,
                    state.orderEntity?.secret
                ),
                orderPreferences: action.payload.preferences,
                status: OrderStatusEnum.OK,
                error: OrderErrorEnum.NONE,
            });
        },
        receivePreferencesUpdateFailed: (
            state: OrderState,
            action: PayloadAction<OrderErrorEnum>
        ) => {
            if (action.payload === OrderErrorEnum.UPDATE_ERROR_NOT_FOUND) {
                Object.assign(state, initialState);
            }

            state.status = OrderStatusEnum.ERROR;
            state.error = action.payload;
        },
        resetOrder: (state: OrderState) => {
            Object.assign(state, initialState);
            Cache.resetOrder();
        },
        startFetchAvailability: (state: OrderState) => {
            state.availabilityError = AvailabilityErrorEnum.NONE;
            state.availabilityStatus = AvailabilityStatusEnum.FETCH;
            state.availabilityEntity = null;
            state.availabilityExpired = false;
        },
        receiveFetchAvailability: (
            state: OrderState,
            action: PayloadAction<AvailabilityEntity>
        ) => {
            state.availabilityError = AvailabilityErrorEnum.NONE;
            state.availabilityStatus = AvailabilityStatusEnum.OK;
            state.availabilityEntity = action.payload;
            state.availabilityExpired = false;
        },
        receiveFetchAvailabilityFailed: (
            state: OrderState,
            action: PayloadAction<AvailabilityErrorEnum>
        ) => {
            if (action.payload === AvailabilityErrorEnum.ORDER_NOT_FOUND) {
                Object.assign(state, initialState);
            }

            state.availabilityEntity = null;
            state.availabilityError = action.payload;
            state.availabilityStatus = AvailabilityStatusEnum.ERROR;
            state.availabilityExpired = false;
        },
        expireAvailability: (state: OrderState) => {
            state.availabilityEntity = initialState.availabilityEntity;
            state.availabilityError = initialState.availabilityError;
            state.availabilityStatus = initialState.availabilityStatus;
            state.availabilityExpired = true;
        },
        setSelectedTicket: (state: OrderState, action: PayloadAction<string | null>) => {
            state.selections.ticket = action.payload;
        },
        setSelectedAccommodation: (
            state: OrderState,
            action: PayloadAction<SelectedAccommodation | null>
        ) => {
            state.selections.accommodation = action.payload;
        },
        startUpdateOrder: (state: OrderState) => {
            state.status = OrderStatusEnum.FETCH_UPDATE;
            state.validationErrors.update = initialState.validationErrors.update;
        },
        receiveUpdateOrder: (state: OrderState, action: PayloadAction<OrderEntity>) => {
            state.status = OrderStatusEnum.OK;
            state.error = OrderErrorEnum.NONE;
            state.validationErrors.update = initialState.validationErrors.update;
            state.orderEntity = mergeOrderEntityWithSecret(
                action.payload,
                state.orderEntity?.secret
            );
        },
        receiveUpdateOrderFailed: (
            state: OrderState,
            action: PayloadAction<{ error: OrderErrorEnum; body?: any }>
        ) => {
            state.status = OrderStatusEnum.ERROR;
            state.error = action.payload.error;

            if (action.payload.error === OrderErrorEnum.UPDATE_ERROR_VALIDATION) {
                const errorsObject =
                    typeof action.payload.body?.errors === 'object'
                        ? action.payload.body.errors
                        : {};

                const errorsArray: string[] = [];

                Object.keys(errorsObject).forEach((key) => {
                    if (typeof errorsObject[key] !== 'string') {
                        return;
                    }

                    errorsArray.push(errorsObject[key]);
                });

                state.validationErrors.update = errorsArray;
            } else {
                state.validationErrors.update = initialState.validationErrors.update;
            }
        },
        addDiscount: (state: OrderState, action: PayloadAction<Discount>) => {
            state.discountStatus = { status: DiscountStatusEnum.EMPTY };

            if (!action.payload.code) {
                state.discountStatus = { status: DiscountStatusEnum.CODE_IS_REQUIRED };

                return;
            }

            const index = state.discounts.findIndex((d) => d.code === action.payload.code);

            if (index < 0) {
                if (action.payload.type === COUPON_TYPE.VOUCHER) {
                    state.vouchers.push(action.payload);

                    return;
                }

                state.discounts.push(action.payload);
            } else {
                state.discountStatus = { status: DiscountStatusEnum.CODE_ALREADY_ADDED };
            }
        },
        removeDiscount: (state: OrderState, action: PayloadAction<Discount>) => {
            const index = state.discounts.findIndex((d) => d.code === action.payload.code);

            if (index > -1) {
                state.discounts.splice(index, 1);
            }
        },
        removeVoucher: (state: OrderState, action: PayloadAction<Discount>) => {
            const index = state.vouchers.findIndex((d) => d.code === action.payload.code);

            if (index > -1) {
                state.vouchers.splice(index, 1);
            }
        },
        setDiscountStatus: (state: OrderState, action: PayloadAction<DiscountStatusType>) => {
            state.discountStatus = action.payload;
        },
        setDiscountRemoveStatus: (
            state: OrderState,
            action: PayloadAction<RemoveDiscountStatusType>
        ) => {
            if (!action.payload) {
                state.discountRemoveStatus = {};

                return;
            }

            state.discountRemoveStatus = { ...state.discountRemoveStatus, ...action.payload };
        },
    },
});
// endregion

// region Selectors
export const selectOrder = createSelector(
    (state: RootState) => state.order.orderEntity,
    (orderEntity) => (orderEntity === null ? null : new Order(orderEntity))
);

export const selectDiscountInputOpen = createSelector(
    (state: RootState) => state.order.showDiscountInput,
    (open: boolean) => open
);

export const selectDiscounts = createSelector(
    (state: RootState) => state.order.discounts,
    (discounts: Discount[]) => discounts
);

export const selectVouchers = createSelector(
    (state: RootState) => state.order.vouchers,
    (vouchers: Discount[]) => vouchers
);

export const selectDiscountStatus = createSelector(
    (state: RootState) => state.order.discountStatus,
    (discountStatus: DiscountStatusType) => discountStatus
);

export const selectDiscountRemoveStatus = createSelector(
    (state: RootState) => state.order.discountRemoveStatus,
    (discountRemoveStatus: RemoveDiscountStatusType) => discountRemoveStatus
);

export const selectOrderPreferences = createSelector(
    (state: RootState) => state.order.orderPreferences,
    (preferences: OrderBaseDetails | null) => preferences
);

export const selectOrderSummary = createSelector(
    (state: RootState) => state.order.orderEntity,
    (state: RootState) => state.order.orderPreferences,
    (state: RootState) => state.order.selections,
    (state: RootState) => state.order.availabilityEntity,
    (state: RootState) => state.event.accommodations.data,
    (state: RootState) => state.order.discounts,
    (state: RootState) => state.order.vouchers,
    (
        orderEntity,
        orderPreferences,
        selections,
        availabilityEntity,
        accommodations,
        discounts,
        vouchers
    ): OrderSummary | null => {
        if (!orderEntity || !orderPreferences || !availabilityEntity || !accommodations) {
            return null;
        }

        const availability = new Availability(availabilityEntity);

        if (orderEntity.tickets.length === 0) return null;

        const ticket = availability.findTicketById(orderEntity.tickets[0].id);

        const orderAccomodation = orderEntity.accommodation;
        const accommodation = orderAccomodation
            ? accommodations.find((a) => a.id === orderAccomodation.id)
            : null;

        let totalAdults = 0;

        orderPreferences.occupancy.forEach((roomOccupancy) => {
            totalAdults += roomOccupancy.adults;
        });

        const totalRooms = orderPreferences.occupancy.length;
        const totalPax = totalAdults;

        const priceFromSelections = getPrice(
            selections.accommodation?.id || null,
            availability,
            selections.ticket,
            orderEntity.package_type,
            selections.accommodation?.breakfast || false
        );

        const priceFromOrder = getPrice(
            orderEntity.accommodation?.id || null,
            availability,
            orderEntity.tickets[0].id,
            orderEntity.package_type,
            selections.accommodation?.breakfast || false
        );

        const totalPriceForAllPax = priceFromOrder.totalPrice * totalPax;

        const orderSummary = {
            id: orderEntity.id,
            packageType: orderEntity.package_type,
            dateStart: moment(orderPreferences.dateStart),
            dateEnd: moment(orderPreferences.dateEnd),
            numPax: totalPax,
            numAdults: totalAdults,
            numRooms: totalRooms,
            currency: orderEntity.currency,
            ticketName: ticket?.name,
            ticketCategoryId: ticket?.categoryId,
            ticketId: ticket?.id,
            accommodationName: accommodation?.name,
            accommodationChainName: accommodation?.chain_name,
            accommodationBreakfast: selections.accommodation?.breakfast,
            accommodationId: accommodation?.id,
            pricePerPax: priceFromOrder.totalPrice.toString(),
            priceTotal: priceFromOrder.totalPrice.toString(),
            selectedPricePerPax: priceFromSelections.totalPrice.toString(),
            selectedPriceTotal: (priceFromSelections.totalPrice * totalPax).toString(),
            discountedPriceTotal:
                totalPriceForAllPax - reduceWithDiscount(totalPriceForAllPax, discounts, vouchers),
        };

        return orderSummary;
    }
);

export const selectOrderStatus = (state: RootState): OrderStatusEnum => state.order.status;

export const selectAvailability = createSelector(
    (state: RootState) => state.order.availabilityEntity,
    (availabilityEntity) =>
        availabilityEntity === null ? null : new Availability(availabilityEntity)
);

export const selectAvailabilityError = (state: RootState): AvailabilityErrorEnum =>
    state.order.availabilityError;

export const selectAccommodationsAvailability = (state: RootState): AvailabilityAccommodation[] =>
    selectAvailability(state)?.accomodations || [];

export const selectEventAccommodationsWithAvailability = (state: RootState) => {
    const accommodations = selectEventAccommodations(state);
    const accommodationsAvailability = selectAccommodationsAvailability(state);

    return accommodations
        .map((a) => {
            const accommodation = a;
            accommodation.availability = accommodationsAvailability.find((aA) => aA.id === a.id);

            return accommodation.availability ? a : null;
        })
        .filter((a): a is Accommodation => a !== null);
};

export const selectBasePackage = (state: RootState): AvailabilityBasePackage | null =>
    selectAvailability(state)?.basePackage || null;

export const selectAvailabilityStatus = (state: RootState): AvailabilityStatusEnum =>
    state.order.availabilityStatus;

export const selectOrderIsFetching = (state: RootState) =>
    state.order.status === OrderStatusEnum.FETCH_CREATE ||
    state.order.status === OrderStatusEnum.FETCH_PREFERENCES_UPDATE ||
    state.order.availabilityStatus === AvailabilityStatusEnum.FETCH;

export const selectOrderSelections = (state: RootState): OrderSelections => state.order.selections;

export const selectSelectedTicket = (state: RootState): Ticket | null => {
    if (!state.order.selections.ticket) {
        return null;
    }

    const availability = selectAvailability(state);
    return availability?.findTicketById(state.order.selections.ticket) || null;
};

export const selectSelectedAccommodation = (state: RootState): Accommodation | null => {
    if (!state.order.selections.accommodation) {
        return null;
    }

    const availability =
        selectAvailability(state)?.findAccommodationById(state.order.selections.accommodation.id) ||
        undefined;
    const accommodation = selectEventAccommodations(state)?.find(
        (eA) => eA.id === state.order.selections.accommodation?.id
    );

    if (!accommodation) {
        return null;
    }

    accommodation.availability = availability;

    return accommodation;
};

export const selectSelectedAccommodationIncludesBreakfast = (state: RootState): boolean | null =>
    state.order.selections.accommodation?.breakfast || null;
// endregion

// region Actions
export const { actions } = orderSlice;
export const {
    resetOrder,
    expireAvailability,
    setSelectedTicket,
    setSelectedAccommodation,
    addDiscount,
    removeDiscount,
    removeVoucher,
    setDiscountStatus,
    setDiscountRemoveStatus,
} = actions;

const handleOrderUpdateError = (err: AxiosError, dispatch: TypedDispatch<RootState>) => {
    if (!err.response) {
        actions.receiveUpdateOrderFailed({
            error: OrderErrorEnum.UPDATE_ERROR_UNKNOWN,
        });
        throw err;
    }

    switch (err.response.status) {
        case 404:
            dispatch(
                actions.receiveUpdateOrderFailed({
                    error: OrderErrorEnum.UPDATE_ERROR_NOT_FOUND,
                })
            );
            break;
        case 422:
            dispatch(
                actions.receiveUpdateOrderFailed({
                    error: OrderErrorEnum.UPDATE_ERROR_VALIDATION,
                    body: err.response.data,
                })
            );
            break;
        default:
            dispatch(actions.receiveFetchAvailabilityFailed(AvailabilityErrorEnum.UNKNOWN));
            break;
    }
};

const handleOrderUpdateSuccess = (orderEntity: OrderEntity, dispatch) => {
    return dispatch(actions.receiveUpdateOrder(orderEntity));
};

/**
 * Update the order with the selections on the backend.
 */
export const pushTicketsSelection =
    (): AppThunk =>
    (dispatch, getState): Promise<void> => {
        const order = getOrderOrFail(getState());
        const { ticket } = selectOrderSelections(getState());

        if (!ticket) {
            return Promise.reject();
        }

        dispatch(actions.startUpdateOrder());

        return setOrderTickets(order.id, order.secret, ticket)
            .then((orderEntity) => handleOrderUpdateSuccess(orderEntity, dispatch))
            .catch((err) => handleOrderUpdateError(err, dispatch));
    };

export const pushAccommodationSelection =
    (): AppThunk =>
    (dispatch, getState): Promise<void> => {
        const order = getOrderOrFail(getState());
        const accommodationSelection = selectOrderSelections(getState());
        const accommodation = selectSelectedAccommodation(getState());

        if (!accommodation || !accommodationSelection) {
            return Promise.reject();
        }

        dispatch(actions.startUpdateOrder());

        return setOrderAccommodation(order.id, order.secret, {
            id: accommodation.id,
            partner: accommodation.availability?.partner,
            partner_id: accommodation.availability?.partnerId,
            rates: accommodationSelection.accommodation?.breakfast
                ? accommodation.availability?.cheapestBreakfast.rates
                : accommodation.availability?.cheapest.rates,
        })
            .then((orderEntity) => {
                return handleOrderUpdateSuccess(orderEntity, dispatch);
            })
            .catch((err) => {
                return handleOrderUpdateError(err, dispatch);
            });
    };

export const resetOrderSelections = () => () => {
    Cache.resetOrder();
};

export const fetchAvailability =
    (forceFresh: boolean = false): AppThunk =>
    (dispatch, getState) => {
        const order = getOrderOrFail(getState());

        dispatch(actions.startFetchAvailability());

        getAvailability(order.id, order.secret, forceFresh ? 1 : 0)
            .then((availabilityEntity) => {
                dispatch(actions.receiveFetchAvailability(availabilityEntity));
            })
            .catch((err) => {
                if (!err.response) {
                    dispatch(actions.receiveFetchAvailabilityFailed(AvailabilityErrorEnum.UNKNOWN));
                    throw err;
                }

                const handleUnavailableResponse = (error) => {
                    const errorCodeFromResponseBody = error.response?.data?.errors[0]?.code;

                    switch (errorCodeFromResponseBody) {
                        case 30001:
                            dispatch(
                                actions.receiveFetchAvailabilityFailed(
                                    AvailabilityErrorEnum.NOT_AVAILABLE_TICKET
                                )
                            );
                            break;
                        case 30002:
                            dispatch(
                                actions.receiveFetchAvailabilityFailed(
                                    AvailabilityErrorEnum.NOT_AVAILABLE_HOTEL
                                )
                            );
                            break;
                        default:
                            dispatch(
                                actions.receiveFetchAvailabilityFailed(
                                    AvailabilityErrorEnum.NOT_AVAILABLE_GENERIC
                                )
                            );
                            break;
                    }
                };

                switch (err.response.status) {
                    case 400:
                        dispatch(
                            actions.receiveFetchAvailabilityFailed(AvailabilityErrorEnum.UNKNOWN)
                        );
                        break;
                    case 404:
                        dispatch(
                            actions.receiveFetchAvailabilityFailed(
                                AvailabilityErrorEnum.ORDER_NOT_FOUND
                            )
                        );
                        break;
                    case 424:
                        handleUnavailableResponse(err);
                        break;
                    case 500:
                        dispatch(
                            actions.receiveFetchAvailabilityFailed(
                                AvailabilityErrorEnum.SERVER_ERROR
                            )
                        );
                        break;
                    default:
                        dispatch(
                            actions.receiveFetchAvailabilityFailed(AvailabilityErrorEnum.UNKNOWN)
                        );
                        break;
                }
            });
    };

const setMetadata = (): AppThunk => (dispatch, getState) => {
    const order = getOrderOrFail(getState());
    const appMetadata = getState().app.metadata;

    const metadata: OrderMetadata[] = [];

    const addIfDefined = (key: OrderMetadataKey, value: string | undefined | null) => {
        if (value === undefined || value === null || value.length === 0) {
            return;
        }

        metadata.push({ key, value });
    };

    const { session_id: sessionId, client_id: clientId } = getGADataLayerProperty() || {};
    const locale = getAppLocale();
    const { country, language } = localeToLanguageAndCountry(locale);

    addIfDefined('AFFILIATE_ID', getAffiliateId());
    addIfDefined('AFFILIATE_MISC', getAffiliateMisc());
    addIfDefined('GTM_CLIENT_ID', clientId);
    addIfDefined('GCL_ID', getGoogleClickId());
    addIfDefined('FB_CLICK_ID', getFacebookClickId());
    addIfDefined('FB_CLIENT_ID', getFacebookClientId());
    addIfDefined('MS_CLICK_ID', getMicrosoftClickId());

    addIfDefined('COOKIE_CONSENT_MARKETING', hasMarketingCookieConsent() ? '1' : '0');
    addIfDefined('COOKIE_CONSENT_STATISTICS', hasStatisticsCookieConsent() ? '1' : '0');
    addIfDefined('UTM_SOURCE', appMetadata.utmSource);
    addIfDefined('UTM_CAMPAIGN', appMetadata.utmCampaign);
    addIfDefined('UTM_MEDIUM', appMetadata.utmMedium);
    addIfDefined('REF_UTM_SOURCE', appMetadata.refUtmSource);
    addIfDefined('REF_UTM_CAMPAIGN', appMetadata.refUtmCampaign);
    addIfDefined('REF_UTM_MEDIUM', appMetadata.refUtmMedium);
    addIfDefined('SOURCE', appConstants.metadataSourceName);
    addIfDefined('TCO_DOMAIN', window.location.host);
    addIfDefined('TCO_REFERRER', window.document.referrer);
    addIfDefined('TCO_LANGUAGE', language);
    addIfDefined('TCO_COUNTRY', country);

    addIfDefined('GTM_SESSION_ID', sessionId);

    putMetadata(order.id, order.secret, metadata).catch((e) => {
        console.log('Something went wrong:', e);
    });
};

export const createOrder =
    (eventId: string, details: OrderBaseDetails) => async (dispatch: TypedDispatch<RootState>) => {
        dispatch(actions.resetOrder());
        dispatch(actions.startCreating());

        await startOrder(
            eventId,
            details.occupancy,
            details.dateStart,
            details.dateEnd,
            details.packageType,
            details.baseTicketCategoryId,
            details.source
        )
            .then((orderEntity) => {
                dispatch(actions.receiveCreateOrder({ order: orderEntity, preferences: details }));
                Cache.setOrderCachedAt();
                dispatch(fetchAvailability());

                try {
                    dispatch(setMetadata());
                } catch (e) {
                    console.error(e);
                }
            })
            .catch((err) => {
                dispatch(actions.receiveCreateFailed(OrderErrorEnum.CREATE_ERROR_UNKNOWN));
            });
    };

export const reviveCachedOrder = (): AppThunk => (dispatch) => {
    dispatch(setMetadata());
};

export const updateOrderPreferences =
    (details: OrderBaseDetails): AppThunk =>
    (dispatch, getState) => {
        const order = getOrderOrFail(getState());

        dispatch(resetOrderSelections());
        dispatch(actions.startPreferencesUpdate());

        updateOrderPreferencesService(
            order.id,
            order.secret,
            details.occupancy,
            details.dateStart,
            details.dateEnd,
            details.packageType,
            details.baseTicketCategoryId
        )
            .then((orderEntity) => {
                dispatch(
                    actions.receivePreferencesUpdate({ order: orderEntity, preferences: details })
                );
                dispatch(fetchAvailability());
            })
            .catch((err) => {
                if (!err.response) {
                    dispatch(
                        actions.receivePreferencesUpdateFailed(OrderErrorEnum.UPDATE_ERROR_UNKNOWN)
                    );
                    throw err;
                }

                switch (err.response.status) {
                    case 404:
                        dispatch(
                            actions.receivePreferencesUpdateFailed(
                                OrderErrorEnum.UPDATE_ERROR_NOT_FOUND
                            )
                        );
                        break;
                    default:
                        dispatch(
                            actions.receivePreferencesUpdateFailed(
                                OrderErrorEnum.UPDATE_ERROR_UNKNOWN
                            )
                        );
                        break;
                }
            });
    };

export const addDiscountToOrder =
    (orderId: string, secret: string, code: string, callback?: () => void): AppThunk =>
    (dispatch) => {
        if (!code) {
            dispatch(setDiscountStatus({ status: DiscountStatusEnum.CODE_IS_REQUIRED }));

            return;
        }

        dispatch(setDiscountStatus({ status: DiscountStatusEnum.FETCH }));
        dispatch(setDiscountRemoveStatus({ [code]: DiscountStatusEnum.EMPTY }));

        addDiscountOrVoucher(orderId, secret, code)
            .then((res: Discount) => {
                dispatch(
                    addDiscount({
                        code,
                        value: res.value,
                        type: res.type,
                    })
                );

                dispatch(setDiscountStatus({ status: DiscountStatusEnum.OK }));

                if (callback) callback();
            })
            .catch((e) => {
                if (e.response.status === 422) {
                    const errorData = e.response.data.data;

                    if (errorData?.type === DiscountStatusEnum.VOUCHER_HAS_PENDING_RESERVATION) {
                        dispatch(
                            setDiscountStatus({
                                status: DiscountStatusEnum.VOUCHER_HAS_PENDING_RESERVATION,
                                expiry: errorData?.reservation_till,
                            })
                        );

                        return;
                    }

                    dispatch(setDiscountStatus({ status: DiscountStatusEnum.CODE_NOT_VALID }));

                    return;
                }

                dispatch(setDiscountStatus({ status: DiscountStatusEnum.CODE_COULD_NOT_ADD }));
            });
    };

export const removeDiscountFromOrder =
    (orderId: string, secret: string, discounts: Discount[]): AppThunk =>
    async (dispatch) => {
        dispatch(setDiscountStatus({ status: DiscountStatusEnum.EMPTY }));

        for (let i = 0; i < discounts.length; i++) {
            dispatch(setDiscountRemoveStatus({ [discounts[i].code]: DiscountStatusEnum.FETCH }));

            try {
                await deleteDiscountOrVoucher(orderId, secret, discounts[i].code);

                if (discounts[i].type === COUPON_TYPE.VOUCHER) {
                    dispatch(removeVoucher(discounts[i]));
                } else {
                    dispatch(removeDiscount(discounts[i]));
                }

                dispatch(setDiscountRemoveStatus({ [discounts[i].code]: DiscountStatusEnum.OK }));
            } catch (e) {
                dispatch(
                    setDiscountRemoveStatus({
                        [discounts[i].code]: DiscountStatusEnum.CODE_COULD_NOT_REMOVE,
                    })
                );
            }
        }
    };

export interface OrderErrorStatus {
    status: OrderStatusEnum;
    error: OrderErrorEnum;
}

export const selectErrorAndStatus = createSelector(
    (state: RootState) => state.order,
    (orderState): OrderErrorStatus => {
        const { status, error } = orderState;

        return { status, error };
    }
);

// endregion

export default orderSlice.reducer;

import * as React from 'react';
import { Heading } from 'src/view/components/Heading/Heading';
import $ from './NextStepBanner.module.scss';

interface Props {
    text: string;
}

const NextStepBanner = (props: Props) => {
    const { text } = props;
    return (
        <div className={$.container} data-testid="nextStepBanner">
            <Heading variant="h2" grey marginTop={false} marginBottom={false}>
                {text}
            </Heading>
        </div>
    );
};

export default NextStepBanner;

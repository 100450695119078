import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
    businessunitId: string;
    theme?: 'dark' | 'light';
}

const TrustpilotWidget = ({ businessunitId, theme = 'dark' }: Props) => {
    // Create a reference to the <div> element which will represent the TrustBox
    const ref = React.useRef(null);
    const { i18n } = useTranslation();
    React.useEffect(() => {
        // If window.Trustpilot is available it means that we need to load the TrustBox from our ref.
        // If it's not, it means the script you pasted into <head /> isn't loaded  just yet.
        // When it is, it will automatically load the TrustBox.
        if (window.Trustpilot) {
            window.Trustpilot.loadFromElement(ref.current, true);
        }
    }, []);
    return (
        <div
            ref={ref} // We need a reference to this element to load the TrustBox in the effect.
            className="trustpilot-widget" // Renamed this to className.
            data-locale={i18n.language}
            data-template-id="5419b732fbfb950b10de65e5"
            data-businessunit-id={businessunitId}
            data-style-height="18px"
            data-style-width="100%"
            data-theme={theme}
        />
    );
};
export default TrustpilotWidget;

import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { colors } from 'src/app/constants/theme';
import { Session } from 'src/app/store/appSlice';
import { getTravellerOccupancy, getTripDate } from 'src/app/utils/utils';
import { EventDetail } from 'src/data/models/EventDetail';
import { Ticket } from 'src/data/models/Ticket';
import { CheckIcon } from 'src/images/icons/CheckIcon';
import { Body } from 'src/view/components/Body/Body';
import { Heading } from 'src/view/components/Heading/Heading';
import * as Cache from '../../../data/services/cache';
import $ from './PreviousStepBanner.module.scss';

interface Props extends WithTranslation {
    eventDetail: EventDetail;
    session: Session;
    orderSelectedTicket: Ticket | null;
}

const PreviousStepBanner = (props: Props) => {
    const { t } = props;

    const { startDate, endDate } = Cache.getInOutDateFormats();
    const { adults, children, roomLayout } = getTravellerOccupancy();

    const adultsLabel = adults === 1 ? t('adult') : t('adults');
    const childrenLabel = children === 1 ? t('child') : t('children');
    const roomsLabel = roomLayout.length === 1 ? t('room') : t('rooms');

    const adultsText = `${adults} ${t(adultsLabel)}`;
    const childrenText = `${children} ${t(childrenLabel)}`;
    const roomsText = `${roomLayout.length} ${t(roomsLabel)}`;

    const renderDetailHeader = (element: React.ReactNode) => {
        return (
            <Heading variant="h4" grey uppercase={false} marginBottom={false} marginTop={false}>
                {element}
            </Heading>
        );
    };

    const renderBody = (text: React.ReactNode) => (
        <Body className={$.detail} fontWeight="medium" marginBottom={false}>
            {text}
        </Body>
    );

    return (
        <div className={$.container} data-testid="previousStepBanner">
            <div className={$.headerWrapper}>
                <Heading variant="h2" marginTop={false} marginBottom={false}>
                    <CheckIcon color={colors.successGreen} />
                    {t('state_traveldetails')}
                </Heading>
            </div>
            <ul>
                <li>
                    {renderDetailHeader(t('state_traveldates'))}
                    {renderBody(getTripDate(startDate, endDate))}
                </li>
                <li>
                    {renderDetailHeader(t('ticketTravelCompany'))}
                    {renderBody(
                        <>
                            {adultsText}, {children > 0 ? `${childrenText},` : ''} {roomsText}
                        </>
                    )}
                </li>
            </ul>
        </div>
    );
};

export default withTranslation()(PreviousStepBanner);

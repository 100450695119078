import * as React from 'react';
import classnames from 'classnames';
import $ from './Price.module.scss';

export interface PriceProps {
    bold?: boolean;
    price: string;
    alternateColor?: boolean;
    perPersonText?: string;
    big?: boolean;
}

export default function Price(props: PriceProps) {
    const { bold, price, alternateColor, big, perPersonText = 'p.p.' } = props;
    const priceClass = classnames([
        $.price,
        $.priceNumber,
        alternateColor ? $.priceNumberAlternate : $.priceNumberPrimary,
        big && $.priceBig,
    ]);

    return (
        <p className={$.price}>
            <span className={classnames(priceClass, bold && $.boldPrice)}>+ {price}</span>{' '}
            <small className={$.small}>{perPersonText}</small>
        </p>
    );
}

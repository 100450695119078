import Cookies from 'js-cookie';

export function getAffiliateId(): string | undefined {
    return Cookies.get('_afclid');
}

export function getAffiliateMisc(): string | undefined {
    return Cookies.get('_afmisc');
}

export function getGoogleClickId(): string | undefined {
    return Cookies.get('FPGCLAW');
}

export function getFacebookClickId(): string | undefined {
    return Cookies.get('_fbc');
}

export function getFacebookClientId(): string | undefined {
    return Cookies.get('_fbp');
}

export function getMicrosoftClickId(): string | undefined {
    return Cookies.get('_uetmsclkid');
}

import classnames from 'classnames';
import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { colors } from 'src/app/constants/theme';
import { InfoIcon } from 'src/app/icons/InfoIcon';
import { EventDetail } from 'src/data/models/EventDetail';
import { CheckIcon } from 'src/images/icons/CheckIcon';
import { InfoMessage, Popover } from 'src/view/components';
import { Body } from '../Body/Body';
import { EventDateRenderer } from '../EventDateRenderer/EventDateRenderer';
import $ from './ConfirmLabel.module.scss';

interface Props extends WithTranslation {
    className?: string;
    isConfirmed: boolean;
    withTooltip?: boolean;
    eventDetail?: EventDetail;
}

const ConfirmLabel: React.FunctionComponent<Props> = ({
    className = null,
    isConfirmed = true,
    t,
    withTooltip = false,
    eventDetail,
}) => {
    const label = isConfirmed ? t('event_confirmed') : t('event_not_confirmed');
    const description = isConfirmed ? t('event_confirmed_text') : t('event_not_confirmed_text');
    return (
        <span
            className={classnames($.root, isConfirmed ? $.isConfirmed : $.notConfirmed, className)}
        >
            {isConfirmed && (
                <CheckIcon
                    color={isConfirmed ? colors.successGreen : colors.warningYellow}
                    className={$.checkIcon}
                />
            )}
            <div
                className={classnames(
                    $.label,
                    isConfirmed ? $.labelConfirmed : $.labelNotConfirmed
                )}
            >
                {label}
            </div>
            {withTooltip && !isConfirmed && (
                <Popover
                    buttonClassName={$.popoverTrigger}
                    trigger={
                        <InfoIcon
                            className={classnames(
                                $.confirmIcon,
                                isConfirmed ? $.labelConfirmed : $.labelNotConfirmed
                            )}
                        />
                    }
                    desktopContent={
                        <Body marginBottom={false} marginTop={false}>
                            {description}
                        </Body>
                    }
                    mobileTitle={eventDetail?.name}
                    mobileContent={
                        eventDetail ? (
                            <div className={$.mobileContent}>
                                <div>
                                    <EventDateRenderer
                                        dateTime={eventDetail.dateTime}
                                        dateTimeEnd={eventDetail.dateTimeEnd}
                                    />
                                    <Body marginTop={false} marginBottom={false} grey small>
                                        {eventDetail?.venue.name}
                                    </Body>
                                    <Body marginTop={false} marginBottom={false} grey small>
                                        {eventDetail?.serie.name}
                                    </Body>
                                </div>
                                <InfoMessage text={description} />
                            </div>
                        ) : (
                            description
                        )
                    }
                />
            )}
        </span>
    );
};

export default withTranslation()(ConfirmLabel);

import { Grid } from '@mui/material';
import classnames from 'classnames';
import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getContactData } from 'src/app/constants/contactData';
import { selectIsB2BMode } from 'src/app/store/appSlice';
import { ContactPossiblitiesList } from 'src/app/views/contactPossibilties/ContactPossiblitiesList';
import { Traveler } from 'src/data/models/Traveler';
import { FormLabel } from 'src/view/components/FormLabel/FormLabel';
import { OptionsListItem } from 'src/view/components/FormSelect/FormSelect';
import FormSelect from '../FormSelect';
import { MODAL_WIDTH, Modal, ModalBody } from '../Modal/Modal';
import $ from './OrderPreferences.module.scss';
import { createAdults } from './order-preferences-traveler-controls';

const MORE_THAN_10_PERSONS = 'MORE_THAN_10_PERSONS';

// TODO: Improve the naming convention of the component
export const TravelerControls = ({
    incrementalStepOptions,
    onChange,
    value,
}: {
    incrementalStepOptions: number[];
    onChange: (travelers: Traveler[]) => void;
    value: number;
}) => {
    const { t } = useTranslation();
    const [showGroupBookingModal, setShowGroupBookingModal] = useState(false);
    const adultDropdownOptions = useAdultDropdownOptions(incrementalStepOptions);

    useEffect(() => {
        const createdAdults = createAdults(Number(value));
        onChange(createdAdults);
    }, [onChange, value]);

    /** Upon change, creates an array of Traveler[] and calls the onChange value */
    const handlePersonsChange = useCallback((value: string | number) => {
        if (value === MORE_THAN_10_PERSONS) {
            setShowGroupBookingModal(true);

            return;
        }

        const createdAdults = createAdults(Number(value));
        onChange(createdAdults);
    }, []);

    return (
        <>
            <Grid
                item
                xs={12}
                md={4}
                className={classnames([$.options, $.col])}
                data-cy="ticket-persons-selector"
            >
                <FormLabel grey>{t('ticketTravelCompany')}</FormLabel>
                <FormSelect
                    options={adultDropdownOptions}
                    onChange={handlePersonsChange}
                    value={value}
                />
            </Grid>
            {showGroupBookingModal && (
                <MoreThanTenPeopleModal setModalState={setShowGroupBookingModal} />
            )}
        </>
    );
};

// The modal which pops up when you try to select more than 10 people in the Travelers dropdown
const MoreThanTenPeopleModal = ({
    setModalState,
}: {
    setModalState: Dispatch<SetStateAction<boolean>>;
}) => {
    const isB2BAllowed = useSelector(selectIsB2BMode);
    const { t } = useTranslation();

    return (
        <Modal
            size={MODAL_WIDTH.SMALL}
            open={true}
            onClose={() => setModalState(false)}
            title={t('ticketGroupBookingModalTitle')}
            subTitle={t('ticketGroupBookingModalSubtitle')}
        >
            <ModalBody>
                <ContactPossiblitiesList
                    whatsappUrl={getContactData(t).whatsappUrl}
                    phoneNumber={
                        isB2BAllowed
                            ? getContactData(t).b2bPhoneNumberSales
                            : getContactData(t).phoneNumber
                    }
                    email={isB2BAllowed ? getContactData(t).b2bEmailSales : getContactData(t).email}
                    onClickItem={() => setModalState(false)}
                    config={{
                        hide: {
                            email: !isB2BAllowed,
                            whatsapp: isB2BAllowed,
                            phonenumber: isB2BAllowed,
                            chat: isB2BAllowed,
                        },
                    }}
                />
            </ModalBody>
        </Modal>
    );
};

const useAdultDropdownOptions = (incrementalStepOptions: number[]): OptionsListItem[] => {
    const { t } = useTranslation();

    return useMemo(() => {
        const optionsInIncrements = incrementalStepOptions.map((option) => {
            return t('travelDetailPersonsOption', { count: option });
        });

        return [
            {
                options: [
                    ...optionsInIncrements.map((a, i) => ({
                        label: a,
                        value: incrementalStepOptions[i],
                    })),
                    {
                        label: t('ticketGroupBookingOption'),
                        value: MORE_THAN_10_PERSONS,
                    },
                ],
            },
        ];
    }, [incrementalStepOptions]);
};

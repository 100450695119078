import React from 'react';

export const AMEIcon = () => (
    <svg
        data-name="Group 1828"
        xmlns="http://www.w3.org/2000/svg"
        width="35.248"
        height="23.84"
        viewBox="0 0 35.248 23.84"
    >
        <defs>
            <clipPath id="4fpefi4yya">
                <path
                    data-name="Rectangle 1256"
                    style={{ fill: 'none' }}
                    d="M0 0h35.248v23.84H0z"
                />
            </clipPath>
        </defs>
        <path
            data-name="Path 5339"
            d="M110.93 426.612h1.358l-.679-1.624z"
            transform="translate(-109.1 -417.976)"
            style={{ fill: '#fff' }}
        />
        <g data-name="Group 1795">
            <g data-name="Group 1794" style={{ clipPath: 'url(#4fpefi4yya)' }}>
                <path
                    data-name="Path 5340"
                    d="M996.493 434.777a.407.407 0 0 0 .177-.384.439.439 0 0 0-.177-.384 1.3 1.3 0 0 0-.5-.059h-1.21v.915h1.211a.9.9 0 0 0 .5-.089"
                    transform="translate(-978.368 -426.791)"
                    style={{ fill: '#fff' }}
                />
                <path
                    data-name="Path 5341"
                    d="m1535.806 424.989-.679 1.624h1.358z"
                    transform="translate(-1509.798 -417.977)"
                    style={{ fill: '#fff' }}
                />
                <path
                    data-name="Path 5342"
                    d="M1254.108 865.206a.8.8 0 0 0-.472-.089h-1.21v.945l1.21-.03a1.225 1.225 0 0 0 .472-.059.516.516 0 0 0 .207-.413.375.375 0 0 0-.207-.354"
                    transform="translate(-1231.761 -850.843)"
                    style={{ fill: '#fff' }}
                />
                <path
                    data-name="Path 5343"
                    d="M833.506 843.422v-3.336l-1.535 1.623z"
                    transform="translate(-818.244 -826.225)"
                    style={{ fill: '#fff' }}
                />
                <path
                    data-name="Path 5344"
                    d="M985.354 865.117h-1.3v1.063h1.3a.535.535 0 0 0 .59-.561c0-.354-.236-.5-.59-.5"
                    transform="translate(-967.818 -850.843)"
                    style={{ fill: '#fff' }}
                />
                <path
                    data-name="Path 5345"
                    d="M452.687 768.109h-1.918v-.886h1.918a.612.612 0 0 0 .384-.088.376.376 0 0 0 .118-.266.45.45 0 0 0-.118-.3.513.513 0 0 0-.354-.089c-.915-.03-2.066.029-2.066-1.269a1.245 1.245 0 0 1 1.417-1.24h1.948v-.826h-1.83a2.135 2.135 0 0 0-1.24.354v-.354h-2.686a1.963 1.963 0 0 0-1.21.354v-.354h-4.812v.354a2.524 2.524 0 0 0-1.358-.354h-3.19v.354a2.226 2.226 0 0 0-1.387-.354h-3.572l-.827.885-.768-.885h-5.314v5.786h5.226l.856-.886.767.886h3.247v-1.358h.3a3.247 3.247 0 0 0 1.387-.207v1.565h2.657v-1.501h.147c.148 0 .177 0 .177.177v1.328h8.089a2.277 2.277 0 0 0 1.328-.354v.354h2.568a3.615 3.615 0 0 0 1.447-.266v-1.052a1.583 1.583 0 0 1-1.329.491m-16.413-1.387h-1.24v1.387h-1.919l-1.21-1.358-1.269 1.358h-3.926v-4.133h3.985l1.21 1.358 1.269-1.358h3.159c.768 0 1.653.236 1.654 1.358 0 1.151-.856 1.387-1.712 1.387m5.963-.177a1.362 1.362 0 0 1 .147.767v.8h-.974v-.5a1.318 1.318 0 0 0-.177-.827c-.148-.147-.355-.177-.709-.177h-1.063v1.505h-1v-4.133h2.273a2.477 2.477 0 0 1 1.21.207.927.927 0 0 1 .5.915 1.137 1.137 0 0 1-.708 1.092 1.152 1.152 0 0 1 .5.354m4.074 1.565H443v-4.133h3.306v.856h-2.332v.768h2.273v.826h-2.273v.827h2.332zm2.509 0H446.9v-.886h1.919a.672.672 0 0 0 .384-.088.375.375 0 0 0 .118-.266.451.451 0 0 0-.118-.3.512.512 0 0 0-.354-.089c-.915-.03-2.067.029-2.067-1.269 0-.591.354-1.24 1.417-1.24h1.978v.886h-1.83a.7.7 0 0 0-.384.059.346.346 0 0 0-.147.325.321.321 0 0 0 .206.3 1.513 1.513 0 0 0 .384.059h.531a1.675 1.675 0 0 1 1.152.325 1.273 1.273 0 0 1 .3.886c0 .886-.561 1.3-1.565 1.3"
                    transform="translate(-418.797 -750.559)"
                    style={{ fill: '#fff' }}
                />
                <path
                    data-name="Path 5346"
                    d="M2.1 336.8h.856l.354.945h3.332v-.709l.3.709h1.737l.3-.738v.738h8.261v-1.535h.147c.118 0 .148.03.148.207v1.328h4.281v-.354a3.538 3.538 0 0 0 1.594.354h1.8l.384-.945h.856l.384.945h3.454v-.886l.531.886h2.775v-5.786h-2.745v.679l-.413-.679h-2.8v.679l-.354-.679h-3.813a3.516 3.516 0 0 0-1.653.325v-.325h-2.627v.325a1.745 1.745 0 0 0-1.122-.325H8.473l-.649 1.476-.679-1.476H4.133v.679l-.354-.679H1.21L0 334.7v3.04h1.712zm19.926-3.542a2 2 0 0 1 1.505-.472h.945v.886h-.945a1.044 1.044 0 0 0-.767.236 1.338 1.338 0 0 0-.266.945 1.478 1.478 0 0 0 .266.974.985.985 0 0 0 .679.207h.443l1.417-3.247h1.476l1.653 3.9v-3.9h1.535l1.742 2.863v-2.863h1v4.133h-1.417l-1.86-3.1v3.1h-2.007l-.384-.92h-2.066l-.384.915H23.44a2.135 2.135 0 0 1-1.446-.473 2.161 2.161 0 0 1-.5-1.565 2.134 2.134 0 0 1 .531-1.624m-2.036-.472h1v4.133h-1zm-4.547 0h2.273a2.376 2.376 0 0 1 1.181.177 1.023 1.023 0 0 1 .531.945 1.172 1.172 0 0 1-.711 1.097 1.236 1.236 0 0 1 .472.354 1.1 1.1 0 0 1 .177.738v.827h-1v-.531a1.254 1.254 0 0 0-.147-.8 1.012 1.012 0 0 0-.738-.177h-1.066v1.506h-.974zm-4.015 0h3.306v.856h-2.3v.738H14.7v.856h-2.272v.827h2.3v.856h-3.303zm-10.335 4.135H.03l1.77-4.133h1.477l1.683 3.9v-3.9h1.594l1.3 2.8 1.21-2.8h1.623v4.133h-1v-3.247l-1.447 3.247h-.86l-1.446-3.247v3.247H3.926L3.543 336H1.476z"
                    transform="translate(0 -326.48)"
                    style={{ fill: '#fff' }}
                />
                <path
                    data-name="Path 5347"
                    d="M1993.3 866.966a.423.423 0 0 0-.147.325.322.322 0 0 0 .206.3 1.509 1.509 0 0 0 .384.059h.531a1.676 1.676 0 0 1 1.152.325c.021.024.04.049.059.074v-1.137h-1.8a.7.7 0 0 0-.384.059"
                    transform="translate(-1960.269 -852.603)"
                    style={{ fill: '#fff' }}
                />
                <path
                    data-name="Path 5348"
                    d="M538.544 865.131v.768h2.184v.826h-2.184v.827h2.45l1.122-1.21-1.092-1.21z"
                    transform="translate(-529.658 -850.856)"
                    style={{ fill: '#fff' }}
                />
                <path
                    data-name="Path 5349"
                    d="M489.129 813.238h-3.159l-1.27 1.362-1.21-1.358h-3.985v4.133h3.926l1.269-1.358 1.21 1.358h1.919v-1.387h1.24c.856 0 1.712-.236 1.712-1.388 0-1.122-.886-1.358-1.654-1.358m-6.2 3.277h-2.45v-.827h2.184v-.826h-2.184v-.768h2.48l1.092 1.21zm3.926.5-1.535-1.712 1.535-1.623zm2.273-1.86h-1.3v-1.063h1.3c.354 0 .59.148.59.5a.535.535 0 0 1-.59.561"
                    transform="translate(-471.593 -799.82)"
                    style={{ fill: '#0077a6' }}
                />
                <path
                    data-name="Path 5350"
                    d="M1195.6 814.361a.926.926 0 0 0-.5-.915 2.478 2.478 0 0 0-1.21-.207h-2.273v4.133h1v-1.505h1.063c.354 0 .561.03.708.177a1.317 1.317 0 0 1 .177.827v.5h.974v-.8a1.363 1.363 0 0 0-.147-.767 1.151 1.151 0 0 0-.5-.354 1.137 1.137 0 0 0 .708-1.092m-1.3.591a1.227 1.227 0 0 1-.472.059l-1.21.03v-.938h1.21a.8.8 0 0 1 .472.089.376.376 0 0 1 .207.354.516.516 0 0 1-.207.413"
                    transform="translate(-1171.949 -799.821)"
                    style={{ fill: '#0077a6' }}
                />
                <path
                    data-name="Path 5351"
                    d="M5.7 386.179h2.007v-3.247l1.446 3.247h.856l1.447-3.247v3.247h1v-4.133h-1.62l-1.21 2.8-1.3-2.8H6.733v3.9l-1.683-3.9H3.574L1.8 386.179h1.066l.384-.915h2.066zm-2.1-1.8.679-1.624.679 1.624z"
                    transform="translate(-1.773 -375.742)"
                    style={{ fill: '#0077a6' }}
                />
                <path
                    data-name="Rectangle 1255"
                    transform="translate(35.219 13.418)"
                    style={{ fill: '#0077a6' }}
                    d="M0 0h.029v.886H0z"
                />
                <path
                    data-name="Path 5352"
                    d="M1933.843 815.174a1.675 1.675 0 0 0-1.151-.325h-.531a1.512 1.512 0 0 1-.384-.059.322.322 0 0 1-.207-.3.422.422 0 0 1 .147-.325.7.7 0 0 1 .384-.059h1.8v-.886h-1.948a1.245 1.245 0 0 0-1.417 1.24c0 1.3 1.152 1.24 2.066 1.269a.512.512 0 0 1 .354.089.449.449 0 0 1 .118.3.375.375 0 0 1-.118.266.611.611 0 0 1-.384.088h-1.918v.886h1.918a1.584 1.584 0 0 0 1.329-.491v-1.618a1.386 1.386 0 0 0-.059-.075"
                    transform="translate(-1898.683 -799.807)"
                    style={{ fill: '#0077a6' }}
                />
                <path
                    data-name="Path 5353"
                    d="M1699.441 815.174a1.675 1.675 0 0 0-1.151-.325h-.531a1.515 1.515 0 0 1-.384-.059.321.321 0 0 1-.207-.3.346.346 0 0 1 .147-.325.7.7 0 0 1 .384-.059h1.83v-.886h-1.978c-1.063 0-1.417.65-1.417 1.24 0 1.3 1.151 1.24 2.067 1.269a.512.512 0 0 1 .354.089.452.452 0 0 1 .118.3.375.375 0 0 1-.118.266.672.672 0 0 1-.384.088h-1.919v.886h1.919c1 0 1.564-.413 1.564-1.3a1.274 1.274 0 0 0-.294-.884z"
                    transform="translate(-1668.149 -799.807)"
                    style={{ fill: '#0077a6' }}
                />
                <path
                    data-name="Path 5354"
                    d="M695.723 385.336h-2.3v-.827h2.273v-.856h-2.276v-.738h2.3v-.856h-3.306v4.133h3.306z"
                    transform="translate(-680.992 -375.755)"
                    style={{ fill: '#0077a6' }}
                />
                <path
                    data-name="Path 5355"
                    d="M1304.475 386.179h1.152l.384-.915h2.066l.384.915h2.007v-3.1l1.86 3.1h1.417v-4.133h-1v2.863l-1.745-2.863h-1.535v3.9l-1.653-3.9h-1.476l-1.417 3.247h-.443a.985.985 0 0 1-.679-.207 1.478 1.478 0 0 1-.266-.974 1.338 1.338 0 0 1 .266-.945 1.044 1.044 0 0 1 .767-.236h.945v-.886h-.945a2 2 0 0 0-1.505.472 2.134 2.134 0 0 0-.531 1.624 2.161 2.161 0 0 0 .5 1.565 2.135 2.135 0 0 0 1.446.472m2.568-3.424.679 1.624h-1.358z"
                    transform="translate(-1281.036 -375.742)"
                    style={{ fill: '#0077a6' }}
                />
                <path
                    data-name="Path 5356"
                    d="M1468.115 816.529v-.829h2.273v-.826h-2.273v-.768h2.332v-.856h-3.306v4.133h3.306v-.856h-2.332z"
                    transform="translate(-1442.933 -799.834)"
                    style={{ fill: '#0077a6' }}
                />
                <path
                    data-name="Path 5357"
                    d="M1982.389 632.531z"
                    transform="translate(-1949.68 -622.094)"
                    style={{ fill: '#0077a6' }}
                />
                <path
                    data-name="Path 5358"
                    d="M936.717 384.673h1.063a1.012 1.012 0 0 1 .738.177 1.254 1.254 0 0 1 .147.8v.531h1v-.827a1.1 1.1 0 0 0-.177-.738 1.235 1.235 0 0 0-.472-.354 1.172 1.172 0 0 0 .709-1.092 1.023 1.023 0 0 0-.531-.945 2.375 2.375 0 0 0-1.181-.177h-2.273v4.133h.974zm0-1.771h1.21a1.3 1.3 0 0 1 .5.059.439.439 0 0 1 .177.384.407.407 0 0 1-.177.384.9.9 0 0 1-.5.089h-1.211z"
                    transform="translate(-920.304 -375.742)"
                    style={{ fill: '#0077a6' }}
                />
                <path
                    data-name="Path 5359"
                    d="M1212.3 382.046h-1v4.133h1z"
                    transform="translate(-1191.312 -375.742)"
                    style={{ fill: '#0077a6' }}
                />
                <path
                    data-name="Path 5360"
                    d="M33.388 12.59h1.83V1.108A1.11 1.11 0 0 0 34.108 0h-33A1.11 1.11 0 0 0 0 1.108V8.22l1.21-2.745h2.569l.354.679v-.679h3.011l.679 1.476.649-1.476h9.594a1.745 1.745 0 0 1 1.122.325v-.325h2.627V5.8a3.516 3.516 0 0 1 1.653-.325h3.808l.354.679v-.679h2.8l.413.679v-.679h2.745v5.786H30.82l-.531-.886v.886h-3.454l-.384-.945h-.856l-.384.945h-1.8a3.538 3.538 0 0 1-1.594-.354v.354h-4.281V9.933c0-.177-.03-.207-.148-.207h-.148v1.535H8.974v-.738l-.3.738H6.938l-.3-.709v.709H3.306l-.354-.945H2.1l-.384.945H0v11.467a1.11 1.11 0 0 0 1.11 1.11h33a1.11 1.11 0 0 0 1.11-1.11V18.11a3.614 3.614 0 0 1-1.447.266H31.2v-.354a2.277 2.277 0 0 1-1.328.354h-8.086v-1.329c0-.177-.029-.177-.177-.177h-.147v1.505H18.8v-1.564a3.248 3.248 0 0 1-1.387.206h-.3v1.358h-3.238l-.767-.886-.856.886H7.026V12.59h5.314l.768.885.827-.885h3.572a2.227 2.227 0 0 1 1.387.354v-.354h3.188a2.525 2.525 0 0 1 1.358.354v-.354h4.812v.354a1.963 1.963 0 0 1 1.21-.354h2.686v.354a2.135 2.135 0 0 1 1.24-.354"
                    transform="translate(0 .002)"
                    style={{ fill: '#0077a6' }}
                />
            </g>
        </g>
    </svg>
);

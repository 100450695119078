import classnames from 'classnames';
//@ts-ignore
import IntlTelInput from 'intl-tel-input/reactWithUtils';
import 'intl-tel-input/styles';
import { useAppLocale } from 'src/app/hooks/use-locale';
import $ from './PhoneNumberSelect.module.scss';
//@ts-ignore
import { de, en, es, fr, nl } from 'intl-tel-input/i18n';

const localizedInput = {
    en,
    'en-GB': en,
    nl,
    es,
    de,
    fr,
};
interface Props {
    onChange?: (value: string) => void;
    initialValue?: string;
    hasError?: boolean;
}

export const PhoneNumberSelect = ({ initialValue = '', onChange, hasError }: Props) => {
    const locale = useAppLocale();

    const getInitialCountry = () => {
        switch (locale) {
            case 'en-GB':
                return 'gb';
            case 'en':
                return 'nl';
            default:
                return locale;
        }
    };

    /** ---- Remove after a few weeks. ---
     *  Because the current localStorage of our existing users, the initialValue is seen as [object Object]
     *  [object Object] because of past value {dialCode, phoneNumber}
     *  The [object Object] is a string, though, because the form converts it as such. */
    const isValueString = typeof initialValue === 'string';
    const safeInitialValue = isValueString
        ? initialValue.includes('object')
            ? ''
            : initialValue
        : '';

    return (
        <div className={classnames($.formContainer, hasError && $.errorInputField)}>
            <IntlTelInput
                onChangeNumber={onChange}
                initialValue={safeInitialValue}
                initOptions={{
                    initialCountry: getInitialCountry(),
                    separateDialCode: true,
                    formatOnDisplay: true,
                    i18n: localizedInput[locale] || en,
                    formatAsYouType: true,
                    strictMode: true,
                    countryOrder: ['be', 'fr', 'de', 'nl', 'es', 'gb'],
                }}
            />
        </div>
    );
};

import React from 'react';
import { FormCheckbox } from 'src/view/components';
import { PaymentGateway } from 'src/data/services/payments';

interface Props {
    gateway: PaymentGateway;
    priceLabel?: string;
    checked: boolean;
    onChangeHandler: (gateway: PaymentGateway) => void;
    fullWidth?: boolean;
    greenPriceText?: boolean;
}

export default ({
    gateway,
    priceLabel,
    checked,
    onChangeHandler,
    fullWidth,
    greenPriceText,
}: Props) => (
    <>
        <FormCheckbox
            white
            boldTitle={false}
            icon={gateway.icon}
            title={gateway.displayName}
            value={gateway.displayName}
            priceLabel={priceLabel}
            name="paymentMethod"
            id={gateway.name}
            fullWidth={fullWidth}
            checked={checked}
            onChange={() => onChangeHandler(gateway)}
            key={gateway.name}
            isPayment
            spacingBottom
            greenPriceText={greenPriceText}
        />
    </>
);

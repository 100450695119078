import classnames from 'classnames';
import { ChangeEvent } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Notification } from 'src/app/core/lib/notification/notification';
import $ from 'src/view/components/Discount/Discount.module.scss';
import FormInput from 'src/view/components/FormInput';
import { FormInputSize } from 'src/view/components/FormInput/FormInput';
import NotificationMessage from 'src/view/components/NotificationMessage/NotificationMessage';
import Button from '../Button/Button';

export interface DiscountInputProps extends WithTranslation {
    onAdd: () => void;
    onInputChange: (event: ChangeEvent<HTMLInputElement>) => void;
    onBlur?: (event: ChangeEvent<HTMLInputElement>) => void;
    placeholder?: string;
    notification?: Notification;
    label?: string;
    value: string;
    disabled?: boolean;
}

const DiscountInput = ({
    onAdd,
    onInputChange,
    onBlur,
    placeholder,
    label,
    value,
    notification,
    disabled,
    t,
}: DiscountInputProps) => {
    return (
        <>
            {label && (
                <div className={classnames($.discountOverviewTitle, $.discountInputLabel)}>
                    <span>{label}</span>
                </div>
            )}

            <div className={$.addDiscountInputWrapper} data-cy="discount-input-container">
                <FormInput
                    onChange={onInputChange}
                    onBlur={onBlur}
                    size={FormInputSize.ActionCode}
                    className={$.discountInput}
                    placeholder={placeholder}
                    value={value}
                    required
                    validationConfig={{
                        disableSuccessValidation: true,
                        disableErrorValidation: true,
                    }}
                />
                <Button
                    disabled={disabled}
                    onClick={onAdd}
                    className={$.addDiscountButton}
                    isLoading={disabled}
                    text={t('ticket_discount_submit_text')}
                />
            </div>
            {!!notification && (
                <div className={$.notificationMessage} data-cy="discount-notification">
                    <NotificationMessage
                        severity={notification.type}
                        message={notification.message}
                    />
                </div>
            )}
        </>
    );
};

export default withTranslation()(DiscountInput);

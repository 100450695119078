import { FaqItemsType } from 'src/app/constants/faq';
import { Heading } from 'src/view/components/Heading/Heading';
import { FaqItem } from '..';
import $ from './Faq.module.scss';

interface Props {
    title: string;
    faqItems: FaqItemsType;
}

const Faq = (props: Props) => {
    const { title, faqItems } = props;

    return (
        <section className={$.base}>
            <Heading variant="h3" marginTop={false}>
                {title}
            </Heading>
            <ul className={$.list}>
                {faqItems.map((faqItem) => (
                    <FaqItem
                        question={faqItem.question}
                        answer={faqItem.answer}
                        key={faqItem.question}
                        options={faqItem.options}
                        highlight={faqItem.highlight}
                        initialIsOpen={faqItem.initialIsOpen}
                    />
                ))}
            </ul>
        </section>
    );
};

export default Faq;

import makeStyles from '@mui/styles/makeStyles';
import { colors } from 'src/app/constants/theme';
import { ThumbsUpIcon } from 'src/images/icons/ThumbsUpIcon';

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'block',
        background: '#E9F8F1',
        borderLeft: `3px solid ${colors.successGreen}`,
        borderRadius: 2,
        padding: theme.spacing(1),
        '& svg': {
            marginRight: theme.spacing(0.875),
            marginBottom: -1,
        },
    },
    text: {
        color: colors.black,
    },
}));

interface UspNotificationProps {
    text: string;
}

export const UspNotification = ({ text }: UspNotificationProps) => {
    const classes = useStyles();

    return (
        <div data-cy="green-disclaimers" className={classes.container}>
            <ThumbsUpIcon color={colors.black} />
            <span className={classes.text}>{text}</span>
        </div>
    );
};

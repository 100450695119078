import React, { useCallback, useEffect, useState } from 'react';
import classnames from 'classnames';
import moment from 'moment';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Body } from 'src/view/components/Body/Body';
import { TimeIcon } from '../../../images/icons/TimeIcon';
import $ from './CountDownTimer.module.scss';

interface CountDownTimerProps extends WithTranslation {
    expiry: string;
    text: string;
    inline?: boolean;
}

const CountDownTimer = ({ expiry, text, inline = false, t }: CountDownTimerProps) => {
    const getTimeDiffDuration = useCallback(
        (currentDate: Date, endDate: moment.MomentInput) =>
            moment.duration(moment(endDate).diff(currentDate)),
        []
    );

    const [duration, setDuration] = useState(getTimeDiffDuration(new Date(), expiry));
    const interval = 1000;

    const timerCallback = useCallback(() => {
        setDuration(getTimeDiffDuration(new Date(), expiry));
    }, [expiry, getTimeDiffDuration]);

    useEffect(() => {
        const timer = setInterval(timerCallback, interval);

        return () => clearInterval(timer);
    }, [expiry, interval, timerCallback]);

    return (
        <div className={classnames($.countDownWrapper, inline && $.countdownWrapperInline)}>
            <TimeIcon />
            <Body small marginTop={false} marginBottom={false}>
                <span>{text}</span>
                <span className={$.durationText}>
                    {duration.days() > 0 && (
                        <>
                            {duration.days()}
                            {t('progresscard_order_reserved_days')}{' '}
                        </>
                    )}
                    {duration.hours() > 0 && (
                        <>
                            {duration.hours()}
                            {t('progresscard_order_reserved_hours')}{' '}
                        </>
                    )}
                    {duration.minutes() >= 0 ? duration.minutes() : 0}
                    {t('progresscard_order_reserved_mins')}{' '}
                    {duration.seconds() >= 0 ? duration.seconds() : 0}
                    {t('progresscard_order_reserved_secs')}
                </span>
            </Body>
        </div>
    );
};

export default withTranslation()(CountDownTimer);

import React from 'react';

export const VisaIcon = () => (
    <svg
        data-name="Group 1829"
        xmlns="http://www.w3.org/2000/svg"
        width="36.631"
        height="23.107"
        viewBox="0 0 36.631 23.107"
    >
        <defs>
            <clipPath id="a6ctxkx0aa">
                <path
                    data-name="Rectangle 1257"
                    style={{ fill: 'none' }}
                    d="M0 0h36.631v23.107H0z"
                />
            </clipPath>
        </defs>
        <g data-name="Group 1796" style={{ clipPath: 'url(#a6ctxkx0aa)' }}>
            <path
                data-name="Path 5366"
                d="M35.657 2.123H4.4a1.978 1.978 0 0 0-2.268 2.216v17.843A2.138 2.138 0 0 0 4.4 24.4h31.257a1.961 1.961 0 0 0 2.217-2.216V4.339a1.967 1.967 0 0 0-2.217-2.216"
                transform="translate(-1.714 -1.707)"
                style={{ fill: '#fff' }}
            />
            <path
                data-name="Path 5367"
                d="M33.943 0H2.69A2.483 2.483 0 0 0 .723.72 2.56 2.56 0 0 0 0 2.632v17.843a2.58 2.58 0 0 0 .72 1.912 2.668 2.668 0 0 0 1.967.72h31.256a2.362 2.362 0 0 0 2.688-2.632V2.632A2.357 2.357 0 0 0 33.943 0m2.217 20.475a1.961 1.961 0 0 1-2.217 2.216H2.69a2.138 2.138 0 0 1-2.272-2.216V2.632A1.978 1.978 0 0 1 2.689.416h31.254a1.967 1.967 0 0 1 2.217 2.216z"
                style={{ fill: '#315881' }}
            />
            <path
                data-name="Path 5368"
                d="M9.1 7.359a1.647 1.647 0 0 0-1.177.3A1.99 1.99 0 0 0 7.66 8.85v3.87h33.632V8.855a1.317 1.317 0 0 0-1.5-1.5z"
                transform="translate(-6.158 -5.918)"
                style={{ fillRule: 'evenodd', fill: '#315881' }}
            />
            <path
                data-name="Path 5369"
                d="M7.657 86.865c0 1.025.485 1.551 1.441 1.551H39.8a1.351 1.351 0 0 0 1.5-1.551V83H7.657z"
                transform="translate(-6.158 -66.75)"
                style={{ fill: '#ecab30', fillRule: 'evenodd' }}
            />
            <path
                data-name="Path 5370"
                d="M37.632 39.576a.713.713 0 0 1-.1.416l-2.423 4.225-.153-5.154H31.08l1.3.623a1.064 1.064 0 0 1 .568.928l.36 5.472h1.9l4.544-7.024h-2.328a.69.69 0 0 1 .208.513m15.682-.3.152-.208H51.1c.083.083.111.125.111.152a2.67 2.67 0 0 1-.111.263l-3.449 6.289a5.275 5.275 0 0 1-.263.319h1.953l-.1-.208a2.974 2.974 0 0 1 .3-.983l.416-.776h2.013c.083.651.139 1.192.166 1.593l-.166.374h2.424l-.36-.526-.72-6.289zm-1.441 3.865h-1.44l1.233-2.369zm-5.984-4.44a3.194 3.194 0 0 0-1.953.623 1.574 1.574 0 0 0-.928 1.386 2.223 2.223 0 0 0 .666 1.648l1.344.928c.485.347.72.651.72.928a.844.844 0 0 1-.36.679 1.3 1.3 0 0 1-.831.263 6.483 6.483 0 0 1-2.467-.983v1.288a5.1 5.1 0 0 0 2.618.818 3.032 3.032 0 0 0 2.065-.72 1.817 1.817 0 0 0 .208-3.034l-1.29-.924c-.429-.346-.665-.651-.665-.886 0-.54.319-.817.97-.817a4.356 4.356 0 0 1 1.967.776l.264-1.344a4.615 4.615 0 0 0-2.328-.624zm-5.153 7.384c-.028-.291-.083-.554-.111-.831l1.759-5.68.416-.513h-2.536c.028.125.055.291.083.416a2.156 2.156 0 0 1-.028.416l-1.759 5.627-.36.568h2.535z"
                transform="translate(-24.995 -31.125)"
                style={{ fillRule: 'evenodd', fill: '#315881' }}
            />
        </g>
    </svg>
);

import React, { useState } from 'react';
import { BaseComponent } from 'src/app/interfaces/BaseComponent';
import { Body } from 'src/view/components/Body/Body';
import classnames from 'classnames';
import { ChevronDownIcon } from 'src/images/icons/ChevronDownIcon';
import $ from './Collapsable.module.scss';

interface CollapsableProps extends BaseComponent {
    title: string;
    initialOpened?: boolean;
}

export const Collapsable = ({
    title,
    children,
    style,
    className,
    initialOpened,
    dataCy,
}: CollapsableProps) => {
    const [opened, setOpened] = useState(initialOpened);

    return (
        <div className={className} style={style}>
            <div className={$.container} onClick={() => setOpened((opened) => !opened)}>
                <Body
                    marginTop={false}
                    marginBottom={false}
                    className={$.title}
                    small
                    fontWeight="bold"
                    dataCy={dataCy}
                >
                    {title}
                </Body>
                <span className={classnames($.chevron, opened && $.chevronOpen)}>
                    <ChevronDownIcon />
                </span>
            </div>
            {opened && children}
        </div>
    );
};

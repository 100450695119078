import { fetchUrl } from 'src/data/services/fetchUrl';

type Suggestion = null | string;

export type VerifyEmailResponse = {
    possibly_wrong: boolean;
    suggestions: Suggestion[];
    certainly_wrong: boolean;
};

export const postVerifyEmail = async (email: string) => {
    return fetchUrl<VerifyEmailResponse>({
        showCompleteResponse: true,
        endpoint: `/v2/verify-email`,
        method: 'POST',
        body: { email },
    });
};

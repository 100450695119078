import * as React from 'react';
import { Icon as MdiIcon } from '@mdi/react';
import { mdiCheck, mdiCheckBold, mdiChevronLeft, mdiSquareOutline } from '@mdi/js';
import $ from './Icon.module.scss';

enum IconType {
    MDI,
}

interface IconData {
    type: IconType;
    key: string;
}

const icons: {
    [key: string]: IconData;
} = {
    arrowLeft: {
        type: IconType.MDI,
        key: mdiChevronLeft,
    },
    check: {
        type: IconType.MDI,
        key: mdiCheckBold,
    },
};

const notFoundIcon: IconData = {
    type: IconType.MDI,
    key: mdiSquareOutline,
};

type IconName = keyof typeof icons;

interface Props {
    icon: IconName;
    className?: string;
}

const Icon = ({ icon, className }: Props) => {
    const iconData = icons[icon] || notFoundIcon;

    const mdiIconProps = {
        className: `${$.svgIcon} icon_${icon.toString()} ${className || ''}`,
    };

    if (iconData.type === IconType.MDI) {
        return <MdiIcon {...mdiIconProps} path={iconData.key} />;
    }

    return <MdiIcon {...mdiIconProps} path={mdiSquareOutline} />;
};

export default Icon;

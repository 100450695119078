import React from 'react';

export const TimeIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 19 19">
        <g data-name="Icon time">
            <g
                data-name="Ellipse 129"
                style={{ stroke: '#1a1b33', strokeWidth: '1.5px', fill: 'none' }}
                transform="translate(1.5 1.5)"
            >
                <circle cx="8" cy="8" r="8" style={{ stroke: 'none' }} />
                <circle cx="8" cy="8" r="8.75" style={{ fill: 'none' }} />
            </g>
            <path
                data-name="Path 6378"
                d="M5464 771.533v5.579l2.976 1.756"
                transform="translate(-5454.5 -767.612)"
                style={{
                    strokeLinecap: 'round',
                    strokeLinejoin: 'round',
                    stroke: '#1a1b33',
                    strokeWidth: '1.5px',
                    fill: 'none',
                }}
            />
        </g>
    </svg>
); 

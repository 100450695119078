import { withTranslation, WithTranslation } from 'react-i18next';
import { Loading } from 'src/app/pages/Loading/Loading';
import { COUPON_TYPE } from 'src/data/models/Discount';
import { formatMoney } from 'src/data/services/formatting';
import { DeleteIcon } from 'src/images/icons';
import $ from 'src/view/components/Discount/Discount.module.scss';

export interface DiscountRowProps extends WithTranslation {
    onIconClick: () => void;
    isLoading?: boolean;
    code: string;
    totalDiscountValue: number;
    type: COUPON_TYPE;
}

const DiscountRow = ({
    onIconClick,
    isLoading,
    t,
    code,
    totalDiscountValue,
    type,
}: DiscountRowProps) => {
    const getDiscountTypeText = () => {
        if (type) return 'Voucher:';

        return t('ticket_discount');
    };

    return (
        <div className={$.discountRow} data-cy="discount-row">
            <div className={$.discountTravelPart}>
                {isLoading ? (
                    <span>
                        <Loading />
                    </span>
                ) : (
                    <span onClick={onIconClick}>
                        <DeleteIcon />
                    </span>
                )}

                <span data-cy="discount-code">
                    {getDiscountTypeText()} {code}
                </span>
            </div>
            <span className={$.discountAmount} data-cy="discount-amount">
                - {formatMoney(totalDiscountValue.toString(), { hideZeroDecimals: true })}
            </span>
        </div>
    );
};

export default withTranslation()(DiscountRow);

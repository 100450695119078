import { colors, createTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { themeObject } from '../theme/theme';

export const useCustomTheme = () => {
    const [theme, setTheme] = useState(createTheme(themeObject));

    useEffect(() => {
        const cssVariableValue = getComputedStyle(document.documentElement)
            .getPropertyValue('--tco-form-checked-success-color')
            .trim();
        const currentColor = cssVariableValue || colors.blue;

        setTheme(
            createTheme({
                ...theme,
                palette: {
                    ...theme.palette,
                    success: {
                        ...theme.palette.success,
                        main: `${currentColor}`,
                    },
                },
            })
        );
    }, []);

    return theme;
};

import { Grid, TextField } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { colors } from 'src/app/constants/theme';
import { FormLabel } from 'src/view/components/FormLabel/FormLabel';

const useStyles = makeStyles((t) => ({
    label: {
        fontWeight: 'normal',
        fontFamily: 'inherit',
        border: '1px solid transparent !important',
    },
    errorField: {
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: `${colors.red} !important`,
            },
        },
    },
    successField: {
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: `${colors.successGreen} !important`,
            },
        },
    },
    input: {
        width: '100%',
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: `#76809a`,
            },
        },
    },
}));

interface DateValue {
    day?: string;
    month?: string;
    year?: string;
}

interface FormDateInputProps {
    onChange: (value: DateValue) => void;
    onBlur?: (value: DateValue) => void;
    day?: string;
    month?: string;
    year?: string;
    dayPlaceholder?: string;
    monthPlaceholder?: string;
    yearPlaceholder?: string;
    dayError?: boolean;
    daySuccess?: boolean;
    monthError?: boolean;
    monthSuccess?: boolean;
    yearError?: boolean;
    yearSuccess?: boolean;
}

export const FormDateInput = ({
    onChange,
    onBlur,
    day = '',
    month = '',
    year = '',
    dayPlaceholder,
    monthPlaceholder,
    yearPlaceholder,
    dayError,
    daySuccess,
    monthError,
    monthSuccess,
    yearError,
    yearSuccess,
}: FormDateInputProps) => {
    const classes = useStyles();
    const monthRef = React.useRef<HTMLInputElement>();
    const yearRef = React.useRef<HTMLInputElement>();
    const { t } = useTranslation();

    return (
        <Grid container columnSpacing={2}>
            <Grid item xs={4} data-cy="day-input">
                <FormLabel className={classes.label}>{t('day')}</FormLabel>
                <TextField
                    type="number"
                    className={classNames(
                        classes.input,
                        dayError && classes.errorField,
                        daySuccess && classes.successField
                    )}
                    value={day}
                    placeholder={dayPlaceholder || t('day-format')}
                    onChange={(e) => {
                        onChange({
                            day: e.target.value,
                            month,
                            year,
                        });

                        if (e.target.value.length === 2) monthRef.current?.focus();
                    }}
                    onBlur={(e) => {
                        onBlur?.({
                            day: e.target.value,
                            month,
                            year,
                        });
                    }}
                    inputProps={{
                        min: 1,
                        max: 31,
                    }}
                />
            </Grid>
            <Grid item xs={4} data-cy="month-input">
                <FormLabel className={classes.label}>{t('month')}</FormLabel>
                <TextField
                    type="number"
                    className={classNames(
                        classes.input,
                        monthError && classes.errorField,
                        monthSuccess && classes.successField
                    )}
                    inputProps={{
                        ref: monthRef,
                        min: 1,
                        max: 12,
                    }}
                    placeholder={monthPlaceholder || t('month-format')}
                    value={month}
                    onChange={(e) => {
                        onChange({
                            day,
                            month: e.target.value,
                            year,
                        });

                        if (e.target.value.length === 2) yearRef.current?.focus();
                    }}
                    onBlur={(e) => {
                        onBlur?.({
                            day,
                            month: e.target.value,
                            year,
                        });
                    }}
                />
            </Grid>
            <Grid item xs={4} data-cy="year-input">
                <FormLabel className={classes.label}>{t('year')}</FormLabel>
                <TextField
                    type="number"
                    className={classNames(
                        classes.input,
                        yearError && classes.errorField,
                        yearSuccess && classes.successField
                    )}
                    inputProps={{
                        ref: yearRef,
                        min: 1900,
                    }}
                    placeholder={yearPlaceholder || t('year-format')}
                    value={year}
                    onChange={(e) => {
                        onChange({
                            day,
                            month,
                            year: e.target.value,
                        });
                    }}
                    onBlur={(e) => {
                        onBlur?.({
                            day,
                            month,
                            year: e.target.value,
                        });
                    }}
                />
            </Grid>
        </Grid>
    );
};

import React from 'react';
import { IconProps } from 'src/app/interfaces/Icon';

export const ThumbsUpIcon = ({ color = '#00af05' }: IconProps) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
        <defs>
            <clipPath id="ayigfjlbia">
                <path data-name="Rectangle 3313" style={{ fill: color }} d="M0 0h14v14H0z" />
            </clipPath>
        </defs>
        <g data-name="Group 6478" style={{ clipPath: 'url(#ayigfjlbia)' }}>
            <path
                data-name="Path 10636"
                d="M13.709 9.989a2.078 2.078 0 0 0-.1-.5A1.887 1.887 0 0 0 14 8.348v-.053a1.919 1.919 0 0 0-.357-.983 1.7 1.7 0 0 0 .118-.6 1.731 1.731 0 0 0-.613-1.312 3.526 3.526 0 0 0-2-.619 3.589 3.589 0 0 0-.487.035A9.7 9.7 0 0 0 9.2 5l-.127.026a4.618 4.618 0 0 1 .3-1.247A3.17 3.17 0 0 0 9.267.79 2.117 2.117 0 0 0 7.62 0h-.035a.99.99 0 0 0-.758.363 2.541 2.541 0 0 0-.31 1.367 2.477 2.477 0 0 1-.292.841c-.2.456-.41.906-.64 1.347a6.149 6.149 0 0 1-1.319 1.87s-.053.044-.07.061a3.228 3.228 0 0 0-.5.7 1.47 1.47 0 0 0-.474-.086H1.434A1.435 1.435 0 0 0 0 7.877v4.6a1.438 1.438 0 0 0 1.451 1.413h1.778a1.47 1.47 0 0 0 .731-.208l.619.071a30.336 30.336 0 0 0 3.565.209h.338c.342.025.671.04.976.04h.092a6.931 6.931 0 0 0 1.3-.125 2.786 2.786 0 0 0 1.972-1.21 1.924 1.924 0 0 0 .244-.938c0-.057-.009-.114-.014-.171a2.015 2.015 0 0 0 .655-1.474zm-10.3 2.475a.177.177 0 0 1-.178.175H1.426a.172.172 0 0 1-.158-.167V7.879a.177.177 0 0 1 .177-.179h1.786a.178.178 0 0 1 .178.175zm8.935-2.791a.929.929 0 0 1 .105.375.893.893 0 0 1-.445.767.62.62 0 0 0-.256.5l.032.2.029.2a.734.734 0 0 1-.084.333 1.6 1.6 0 0 1-1.116.608 7.627 7.627 0 0 1-1.248.1 7.688 7.688 0 0 1-.82-.044H8.16a29.215 29.215 0 0 1-3.425-.2s-.053-.006-.05-.03V7.876a2.012 2.012 0 0 1 .193-.789 1.918 1.918 0 0 1 .227-.363c.358-.467.769-.927.927-1.1l.061-.068a4.318 4.318 0 0 0 .487-.676 8.637 8.637 0 0 0 .717-1.5 7.577 7.577 0 0 0 .352-1.211c.044-.282.079-.66.289-.735a.285.285 0 0 1 .189.007c.177.064.239.279.287.446a1.677 1.677 0 0 1 .059.484A2.732 2.732 0 0 1 8.415 3c-.184.88-.689 1.4-.663 2.245a1.227 1.227 0 0 0 .348.911.994.994 0 0 0 .134.092.89.89 0 0 0 .433.112c.009 0 .1-.014.273-.044a7.84 7.84 0 0 0 .5-.095l.044-.01a7.354 7.354 0 0 1 1.5-.159s1.054.015 1.329.288a.552.552 0 0 1 .061.719.615.615 0 0 0-.1.342.624.624 0 0 0 .208.459.822.822 0 0 1 .244.493.806.806 0 0 1-.285.581.609.609 0 0 0-.18.433.66.66 0 0 0 .085.311"
                style={{ fill: color }}
            />
        </g>
    </svg>
);

import { TicketOptions } from './Ticket';
import { PackageType } from 'src/data/models/Order';
import { enumToStringArray } from 'src/app/utils/utils';

/* eslint-disable lines-between-class-members */
export interface BasePackagePricesEntity {
    TICKET_ONLY?: string;
    TICKET_HOTEL?: string;
}

export interface BasePackageEntity {
    accommodation_id: string;
    ticket_id: string;
    prices_pp: BasePackagePricesEntity;
    ticket_options?: TicketOptions[];
    ticket_category_id: string;
}

export class BasePackage {
    public readonly accommodationId: string;
    public readonly ticketId: string;
    public readonly price: BasePackagePricesEntity;
    public readonly categoryId: string;

    public constructor(basePackage: BasePackageEntity) {
        this.accommodationId = basePackage.accommodation_id;
        this.ticketId = basePackage.ticket_id;
        this.price = basePackage.prices_pp;
        this.categoryId = basePackage.ticket_category_id;
    }

    public hasPackageType(type: PackageType): boolean {
        return !!this.price[type.toString()];
    }

    public getCheapestAvailablePackageType(): PackageType | null {
        const packageTypes = enumToStringArray(PackageType);
        let cheapestPrice: number | null = null;
        let cheapestPackageType: string | null = null;

        packageTypes.forEach((pT) => {
            if (!this.hasPackageType(PackageType[pT])) return;

            const priceInCents = Number(this.price[pT]) * 100;

            if (cheapestPrice !== null && priceInCents >= cheapestPrice) return;

            cheapestPrice = priceInCents;
            cheapestPackageType = pT;
        });

        return cheapestPackageType ? PackageType[cheapestPackageType] : null;
    }
}

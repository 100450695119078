import makeStyles from '@mui/styles/makeStyles';
import { colors, fontSizes } from 'src/app/constants/theme';
import { ChevronDown } from 'src/images/icons/ChevronDown';

const useStyles = makeStyles((t) => ({
    showMoreContainer: {
        display: 'flex',
    },
    numberOfItemsContainer: {
        flex: 1,
        textAlign: 'center',
        fontSize: fontSizes.sm1,
        color: colors.lightGrey,
        padding: '.5rem 0px',
        borderBottom: `1px solid ${colors.lightestGrey}`,
    },
    showMoreButton: {
        display: 'flex',
        alignItems: 'center',
        flexShrink: 0,
        borderBottom: `1px solid ${colors.blue}`,
        color: colors.blue,
        fontSize: fontSizes.sm1,
        fontWeight: 'bold',
        '& > svg': {
            fill: colors.blue,
            marginLeft: t.spacing(1),
            marginTop: 3,
        },
        '&:hover': {
            cursor: 'pointer',
        },
    },
}));

interface ShowMoreButtonProps {
    text: string;
    onClick: () => void;
    showMoreText?: string;
}

export const ShowMoreButton = ({
    text,
    onClick,
    showMoreText = 'Show more',
}: ShowMoreButtonProps) => {
    const classes = useStyles();

    return (
        <div className={classes.showMoreContainer}>
            <div className={classes.numberOfItemsContainer}>{text}</div>
            <button className={classes.showMoreButton} onClick={onClick}>
                {showMoreText}
                <ChevronDown />
            </button>
        </div>
    );
};
